import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ValidateFormsDirective } from 'src/app/directives/validate-forms.directive';
import { AlertService } from 'src/app/shared/services/alert.service';
import { DynamicControlsService } from 'src/app/shared/services/dynamic-controls.service';
import { DyamicFormService } from 'src/app/shared/services/dynamic-form.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'dnc-files',
  templateUrl: './dnc-files.component.html',
  styleUrls: ['./dnc-files.component.scss', '../../dynForm.comp.scss'],
  host: {
    "[style.order]": "check.position",
    "[class]": "'col-sm-12 col-md-' + (check.col_size || 4)"
  }
})
export class DncFilesComponent implements OnInit {

  @Input() check: any;
  @Input() editForm: any;
  @Input() editable : boolean = true;
  @Input() touched : boolean = false;

  @Output() onChange: EventEmitter<any> = new EventEmitter<any>();

  public DinamicForm: FormGroup;
  public contractFile = [{ index: 0, id: null, name: null, action: "", file: null }];
  public removedImages: string[] = [];
  public Filename = "";
  public apiUrl = environment.apiUrl;
  public Download: boolean = false
  public url: any;
  public tmpFile = null;


  constructor(
    private toast: AlertService,
    public fieldisValid: ValidateFormsDirective,
    private fb: FormBuilder,
    private dynamicControls: DynamicControlsService,
    private modalService: NgbModal,
    public sanitizer: DomSanitizer
  ) { }

  ngOnChanges(change: SimpleChanges) {

    if (!change.editForm?.firstChange) { // Reasigna los valores dependiendo del elemento a renderizar
      if (this.check?.form == change.editForm?.currentValue?.name) {
        if (change?.editForm?.currentValue?.form?.value[this.check?.formControlName]) {

            var file =  change?.editForm?.currentValue?.form?.value[this.check?.formControlName] != "" ? change?.editForm?.currentValue?.form?.value[this.check?.formControlName] : null;

          if (file) {
          // Alojamiento (Ruta)
          this.contractFile[0].name = file?.name;

          file?.id ? this.Download = true : this.Download = false;
          
          
          //Nombre del archivo
          this.Filename = (file?.nameOriginal) ? file?.nameOriginal : file.name;

          setTimeout(() => {
            this.DinamicForm.patchValue({
              [this.check.formControlName]: file
            });
          }, 150);

            this.tmpFile = file;

          }else{
            this.tmpFile = null;            
            this.deleteFile();
          }
          }
        }
    }

    if (change.touched?.currentValue) {
      this.DinamicForm.markAllAsTouched();
    }
  }

  async ngOnInit() {
    this.DinamicForm = this.fb.group({
      [this.check.formControlName]: [null]
    });

    this.DinamicForm = await this.dynamicControls.asignControls(this.check, this.DinamicForm);

    //Establece los valores de inicio del formulario
  }

  public onFileChanged(previous, event, remplace = false) {

    if (event?.target?.files[0]?.size < this.check?.file_size) {
      //Tomar imagen del input
      let file: File = event.target.files[0];
      // Imagen seleccionada
      var reader = new FileReader();
      reader.readAsDataURL(file);

      this.contractFile[previous.index].file = file;
      let url = URL.createObjectURL(file);
      this.url = this.sanitizer.bypassSecurityTrustResourceUrl(url);

      //Mostrar preview de la imagen y guardar en formulario.
      reader.onload = (event) => {
        this.contractFile[previous.index].name = event.target.result as string;
      }
      this.Filename = file.name;
      this.Download = false;

      this.DinamicForm.get(this.check.formControlName).setValue(file);
      this.DinamicForm.patchValue({ [this.check.formControlName]: file });
      
      this.tmpFile = file;


      if (remplace) {
        this.removedImages.push(previous.id)
      }
    } else {
      this.toast.infoToast("El archivo debe ser menor a 5MB.");
      event.target.value = '';
    }

    this.onChange.emit({event:this.DinamicForm, form: this.check?.form});
  }

  public validateRequired(): boolean {
    let required: boolean = this.check?.target?.validators?.required || false;
    return required;
  }

  async download(file: string) {
    if (this.Download) {
      window.open(`${this.apiUrl}${file}`, "_blank");
    }else{
      
      let url = URL.createObjectURL(this.contractFile[0]?.file);
      this.url = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }
   
  }

  public deleteFile(){
      this.DinamicForm.patchValue({ [this.check.formControlName]: null });
      this.contractFile = [{ index: 0, id: null, name: null, action: "", file: null }];
      this.Filename = "";
      this.Download = false;

      this.onChange.emit({event:this.DinamicForm, form: this.check?.form});
  }

  public openModal(template){
    let fileType = this.Filename.split(".")[1];
    this.modalService.open(template, {size: ((fileType == "pdf" || fileType == "png" || fileType == "jpeg" || fileType == "jpg") && this.url)? "lg" : 'sm'})
  }

}
