import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalAlertService } from '../../services/global-alert.service';
import { ZoomService } from '../../services/zoom.service';

@Component({
  selector: 'app-full-layout',
  templateUrl: './full-layout.component.html',
  styleUrls: ['./full-layout.component.scss']
})
export class FullLayoutComponent implements OnInit {

  constructor(
    private router: Router,
    private zoom: ZoomService,
    private globalAlert: GlobalAlertService
  ) { }

  ngOnInit(): void {
    this.globalAlert.openAlert({title: '¡Bienvenid@ a TASKIN!', subtitle: "Disfruta de nuestras herramientas y optimiza tus procesos de manera rápida, confiable y segura.", icon: 'celebrating', layer: 'confetti', background: 'dark'});

    // this.globalAlert.openAlert({title: 'El contrato #CO001 - Nuevo Proyecto está por expirar', subtitle: "Haz click para ver información del contrato.", icon: 'wall-clock', href: '/contracts', background: 'dark'});
  }

  mainSidebarOpen: any;
  hoverEffect($event, keepOpen = true) {
    if( $event?.type == 'mouseover' && keepOpen){
      this.mainSidebarOpen = 'main-sidebar-open';
      document.querySelector('body').classList.add('overflow-hidden');
    } else if($event?.x > 239 || !keepOpen){
      this.mainSidebarOpen = '';
      document.querySelector('body').classList.remove('overflow-hidden');
    }
  }
  
  clickonBody(){
    this.mainSidebarOpen = '';
    document.querySelector('body').classList.remove('overflow-hidden')
  }


}
