<form [formGroup]="filesForm"  novalidate>
    <mk-loader *ngIf="isLoading" style="z-index: 1;" class="loader-spinner"></mk-loader>

    <div class="modal-header">
        <h6 class="modal-title" id="modal-basic-title">{{modalOption?.type == "ADD" ? 'Agregar Archivos' : 'Editar Archivos'}}</h6>

        <button type="button" class="close" aria-label="Close" (click)="close(); this.filesForm.reset();">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">

        <div class="row">
            <div class="col-{{filesmanager ? 12 : 8}} pr-0">
                <h6>Nombre <span class="text-danger">*</span></h6>
                <input type="text" class="form-control" placeholder="Aa..." formControlName="title">
                <small *ngIf="compoIsValid('title')" class="text-danger">Campo requerido!</small>
            </div>
            <div class="col-4 " *ngIf="!filesmanager">
                <h6>Visibilidad</h6>
                <ng-select class="form-control" formControlName="type" placeholder="Elige tipo archivo" [clearable]="false">
                    <ng-option value="PUBLIC">Publico</ng-option>
                    <ng-option value="PRIVATE">Privado</ng-option>
                </ng-select>
                <small *ngIf="compoIsValid('type')" class="text-danger">Campo requerido!</small>
            </div>
        </div>
        <div *ngIf="!filesmanager" class="row mt-3">
            <div class="col-12">
                <h6>Descripción <span class="text-danger">*</span></h6>
                <textarea name="textarea" id="" maxlength="255" rows="5" class="form-control" formControlName="description" placeholder="Descripcion del archivo"></textarea>
                <small *ngIf="compoIsValid('description')" class="text-danger">Campo requerido</small> 
            </div>
        </div>
        <!-- *ngIf="modalOption.type != 'EDIT'" -->
        <ng-container >
            <div class="row mt-3"> 
            <ng-container *ngIf="modalOption?.type == 'ADD' else inputValue">
                <div class="col-md-12">
                    <div class="w-100 d-flex justify-content-between">
                        <h6>Archivo <span class="text-danger">*</span> <span class="text-info ml-2" [ngbTooltip]="fileTypes" placement="top"><i class="fa fa-info-circle mr-1"></i> Archivos permitidos</span></h6>
                        <span class="text-danger ml-2">{{tooltip}}</span>
                    </div>
                    <ng-template #fileTypes>
                        .pdf, .png, .jpeg, .jpg, .dwg, .zip, .rar, .dxf, .doc, .odt, .docx, .openxml, .csv, .ods, .xml, .vnd, .odp, .ppt, .pptx
                    </ng-template>
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <div class="input-group-text"><i class="fa fa-upload"></i></div>
                        </div>
                            <input  #file type="file" class="form-control" placeholder="Subir Archivo"
                                accept= ".pdf, .png, .jpeg, .jpg, .dwg, .zip, .rar, .dxf,
                                .doc,.odt,.docx,application/msword,
                                application/vnd.openxmlformats-officedocument.wordprocessingml.document,
                                .csv, .ods, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, 
                                application/vnd.ms-excel,
                                .odp, .ppt, .pptx, application/vnd.ms-powerpoint,
                                application/vnd.openxmlformats-officedocument.presentationml.slideshow,
                                application/vnd.openxmlformats-officedocument.presentationml.presentation"
                                (change)="selectFile($event)">
                    </div>
                </div>
            </ng-container>
            </div>
        </ng-container>
    </div>
</form>
 
<div class="modal-footer justify-content-between">
    <button type="button" class="btn btn-danger" id="closemodal" (click)="close(); this.filesForm.reset();">Cancelar</button>
    <button type="button"   class="btn btn-success"  
    (click)="filesmanager ?  sendFile() : modalOption.type === 'ADD' ? uploadFile() : updateFile()"  >{{modalOption.type == "ADD" ? 'Guardar': 'Actualizar'}}</button>
</div>

<!-- SWAL -->
<swal #alertPublicFile class="d-none" title="¿Estas seguro de subir el archivo como publico?" text="El {{userService.modulesName?.client || 'Cliente'}} podra ver este archivo." icon="question" [showCancelButton]="true" [focusCancel]="true" [swalOptions]="{confirmButtonText: 'Aceptar', cancelButtonText: 'Cancelar', confirmButtonColor: '#fd3666'}">
</swal>

<!-- TEMPLATES  -->
<ng-template #inputValue>
    <div class="col-md-8">
        <h6>Archivo <span class="text-info">(No puedes cambiar el archivo)</span></h6>
        <div class="input-group">
            <div class="input-group-prepend">
                <div class="input-group-text"><i class="fa fa-file"></i></div>
            </div>
            <input type="text" readonly="readonly" class="form-control" [value]="fileInfo.name">
        </div>
    </div>
    <div class="col-md-4" style="padding-top: 24px;" *ngIf="modalOption?.type != 'ADD'">
        <button class="btn btn-dark btn-block" [disabled]="!fileInfo" (click)="download()"><i class="fa fa-download mr-2"></i>Descargar</button>
    </div>
</ng-template>