<mk-loader *ngIf="isLoading" style="z-index: 1;" class="loader-spinner"></mk-loader>

<div class="modal-header">
  <h6 class="modal-title" id="modal-basic-title">Detalle {{userService?.modulesName?.contract || 'Contrato'}} </h6>
  <div>
    <div class="input-group _input">
      <div class="input-group-prepend"><div class="input-group-text">#</div></div>
      <input type="text" maxlength="15" [disabled]="true" [(ngModel)]="contractCode"  class=" form-control text-uppercase" [placeholder]="'No. de ' + (userService?.modulesName?.contract || 'Contrato')" [size]="(contractCode?.length * 1.28) || 1">
    </div>
  </div>
</div>


  <ng-container>
    <div class="modal-body border-top py-0">
        <div class="row">
          <ng-container *ngFor="let item of DataForm?.sections; index as i">
            <div class="col-sm-12 col-md-{{item.col_size || (DataForm?.sections.length > 1 ? ((i - 2) ? 3 : 6) : 12) }}" [class.border-left]="i">
                <h5 class="text-center my-3">{{item.name}}</h5>
                <form [formGroup]="FormGroup(item)" novalidate>

                  <dinamic-forms
                  [editable]="false"
                  (onFormChange)="FormChange($event)" 
                  (onFormChildrensChange)="FormChildrensChange($event)"
                    rowClass="row-sm"  
                    [dataInput]="dataInput" 
                    (onClick)="clickbtn()" 
                    (onDependencieChange)="FormDependencieChange($event)" 
                    [editForm]="editForm" style="width:100%;" 
                    [DataForm]="item"></dinamic-forms>
                </form>
              </div>
          </ng-container>
        </div>
    </div>
   

    <div class="modal-footer justify-content-end">
        <button type="button" class="btn btn-dark" (click)="close()">Cerrar</button>
    </div>
  </ng-container>



<ng-template #itemTEXT let-c='c'>
  <div class="{{c.col-class}}">
    <input placeholder="{{c.placeholder}}" maxlength="{{c.maxlength}}" type="text"
      formControlName="{{c.formControlName}}" />
  </div>
</ng-template>

<ng-template #itemSELECT let-c='c'>
  <ng-select class="form-control" formControlName="{{c.formControlName}}" placeholder="{{c.placeholder}}">
    <ng-option *ngFor="let option of c.options" value="{{option.value}}">{{option.name}}</ng-option>
  </ng-select>
</ng-template>

<ng-template #itemFILE let-c='c'>
  <mkt-text [readonly]="(c?.readonly || (c?.readonlyMobile && r?.createIn == 'MOBILE') )|| false"
    [value]="r[c?.target] || null" (valueChange)="change(r?._id,c?.target, $event, c?.type, c?.options ,null , false)">
  </mkt-text>
</ng-template>

<ng-template #itemTEXTAREA let-c='c'>
  <mkt-text [readonly]="(c?.readonly || (c?.readonlyMobile && r?.createIn == 'MOBILE') )|| false"
    [value]="r[c?.target] || null" (valueChange)="change(r?._id,c?.target, $event, c?.type, c?.options ,null , false)">
  </mkt-text>
</ng-template>

<swal #swalAssign title="¿Estas seguro de cambiar el estatus de este {{userService.modulesName?.client || 'cliente'}}?" icon="question"
  text="*Hay procesos internos que dependen del estatus del {{userService.modulesName?.client || 'cliente'}} para poder ejecutarse correctamente."
  [showCancelButton]="true" [focusCancel]="true"
  [swalOptions]="{confirmButtonText: 'Aceptar', cancelButtonText: 'Cancelar', confirmButtonColor: '#fd3666'}">
</swal>