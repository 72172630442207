import { Component, OnInit, Input, OnDestroy, OnChanges, Output, EventEmitter } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpService } from 'src/app/shared/services/http.service';

import { ValidateFormsDirective } from 'src/app/directives/validate-forms.directive';
import { DecryptPipe } from 'src/app/shared/pipes/decrypt.pipe';
import { AlertService } from 'src/app/shared/services/alert.service';
import { BuiltFormDataService } from 'src/app/shared/services/built-form-data.service';


import { ValidatorsService } from 'src/app/shared/services/validators.service';
import { environment } from 'src/environments/environment';

import Swal from 'sweetalert2';
import * as moment from 'moment';
import { datePicker } from 'src/app/shared/mk-configs/datePicker/datePickerLocale';
import { UserService } from 'src/app/shared/services/user.service';

@Component({
  selector: 'request-detail',
  templateUrl: './request-detail.component.html',
  styleUrls: ['./request-detail.component.scss']
})

export class RequestDetailComponent implements OnInit {

  public FormRequest: FormGroup;
  public FormBill: FormGroup;
  public detailTab: number = 0;

  @Input() biddingId: string = "";
  @Input() arrayStatus: any = [];
  @Input() refresh: boolean = false;
  @Input() goTo: number = 0;

  public dateOptions: any = datePicker.locale;

  public loading: boolean = false;
  public files = []
  public loadingFiles: boolean = false;
  public showChat: boolean = false;

  public modalOption: any = { type: '' };
  public biddingDetail: any;
  public optionsIva = [0, 8, 16]
  public apiUrl = environment.apiUrl;
  public audio;


  public today = new Date();
  public filterDate = { startDate: moment().startOf("month"), endDate: moment().endOf("month") };
  public timeZone: any = moment().tz(moment.tz.guess()).format('z');

  public minDate = moment();
  public mdlPeriod = moment();
  public startDate = moment(this.today);
  public endDate = moment(this.today);

  public showRequestForm: boolean = true;
  public createRequest: boolean = false;
  public editRequest: boolean = false;
  public cancelRequest: boolean = false;
  public declineBidding: boolean = false;
  public textBtnSubmit = "Guardar estimación"

  public showBill: boolean = false;
  public saveBill: boolean = false;

  public commentBill = "";
  public filesBill = [];
  public dateBill = "";

  public tmpDaysPeriod: any = 1;
  public chatId = "";

  public biddingDetailFiles: any = [];

  public selectedDate: any = { startDate: moment(), endDate: moment() };

  public DatePeriod: any = { startDate: moment(), endDate: moment() }
  public DateBill: any = { startDate: moment(), endDate: moment() }

  public loadingTabRequest: boolean = false;
  public chatMembers = 0;
  public url: string;

  submitted = true;
  request = {
    _id: "",
    biddingId: "",
    status: "",
    comment: "",
    subtotal: 0,
    iva: 0,
    amount: 0,
    files: [],
    period: null,
    daysPeriod: 1,
    date_creation: moment()
  }

  constructor(
    private modalService: NgbModal,
    private httpService: HttpService,
    private fb: FormBuilder,

    public validateInput: ValidatorsService,
    public validatorForm: ValidateFormsDirective,
    private alertService: AlertService,
    public validateForm: ValidateFormsDirective,
    public decrypt: DecryptPipe,

    public builtFormDataService: BuiltFormDataService,
    public userService: UserService
  ) { }

  ngOnInit(): void {

    this.url = environment.apiUrl;

    this.setFormRequest();

    this.files = [];
    this.request = {
      _id: "",
      biddingId: "",
      status: "",
      comment: "",
      subtotal: 0,
      iva: 0,
      amount: 0,
      files: [],
      period: null,
      daysPeriod: 1,
      date_creation: moment()
    }

    this.FormRequest.reset();

    this.FormRequest.patchValue({
      ...this.request
    });

    this.getDetail();
  }

  get requestFormControl() {
    return this.FormRequest.controls;
  }

  setFormRequest() {
    this.FormRequest = this.fb.group({

      subtotal: [null, Validators.compose([Validators.required, Validators.min(1)])],
      iva: [null, Validators.compose([Validators.required, Validators.min(0)])],
      amount: [null, Validators.compose([Validators.required, Validators.min(0)])],

      comment: [''],
      files: [''],
      period: [null],
      daysPeriod: ['1'],
    });


    this.FormBill = this.fb.group({
      id: [''],
      comment: [''],
      files: [''],
    });

  }

  get f() {
    return this.FormRequest.controls;
  }


  OnDestroy(): void {

    //this.destroyed$.next();
    //this.destroyed$.complete();
  }

  ngOnDestroy(): void {

    //this.destroyed$.next();
    //this.destroyed$.complete();
  }

  ngOnChanges(): void {
    //this.destroyed$.next();
    //this.destroyed$.complete();
    this.ngOnInit();
  }

  public async getChat() {
    if (this.chatId && this.chatId !== "") {
      var chat = await this.httpService.get(`api/chat/${this.chatId}`).toPromise()
        .then((response: any) => {
          var members = (response?.dataUsers) ? response.dataUsers : [];
          members = members.filter(member => { return member._id !== this.userService.user._id });
          this.chatMembers = members;
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
        });
    }
  }

  async getDetail() {
    var id = this.biddingId;
    this.loading = true;
    this.createRequest = false;
    this.editRequest = false;
    this.cancelRequest = false;
    this.declineBidding = false;
    this.showRequestForm = true;
    this.saveBill = false;

    this.showBill = false;
    this.commentBill = "";
    this.filesBill = [];
    this.dateBill = "";
    this.detailTab = this.goTo;

    if (id) {
      this.biddingDetail = null;
      this.chatId = '';

      try {
        await this.httpService.get(`api/bidding/provider/${id}`).toPromise()
          .then(async (response: any) => {
            this.chatId = (response?.chat) ? response.chat._id : '';

            var status = response?.request?.status;
            this.audio = response?.audio?.name;
            // F Estatus estimacion
            status = response.biddingStatus;
            status = response.status;
            this.biddingDetail = response;
            this.request = response?.request;

            this.minDate = moment();

            if (this.request) {

              var req = this.request;

              this.FormRequest.patchValue({
                subtotal: req.subtotal,
                amount: req.amount,
                iva: req.iva,
                comment: req.comment,
                daysPeriod: req.daysPeriod,
                period: null
              });

              this.files = (req.files ? req.files : []);

              this.request.date_creation = response.request.create.date;
            }
            else {
              this.request = {
                _id: "",
                biddingId: "",
                status: "",
                comment: "",
                subtotal: 0,
                iva: 0,
                amount: 0,
                files: [],
                period: null,
                daysPeriod: 1,
                date_creation: moment()
              }

              this.FormRequest.reset();
              this.FormRequest.patchValue({ ...this.request });
            }


            if (!this.biddingDetail.btnBill) {

              if (status === "REJECTED_PROVIDER") {
                this.showRequestForm = false;
              }


              else if (this.biddingDetail.biddingStatus === "STARTED") {
                this.showRequestForm = true;
                this.minDate = moment(this.biddingDetail?.dateEnd) < moment() ? moment() : moment(this.biddingDetail.dateEnd);

                if (this.request?._id?.length === 0) {
                  this.createRequest = true;
                  this.declineBidding = true;
                }

                else if (status !== "FINISHED" && status !== "CANCELLED" && status !== "CLOSED") {
                  this.editRequest = true;
                  this.cancelRequest = true;
                }
              }
              else {
                this.minDate = moment('01/01/2020');
              }
            }
            else {
              this.saveBill = true;
              this.showBill = true;
            }

            if (response?.request?.bill) {
              var today = moment();
              var dateBill = moment(this.biddingDetail?.dateBill);

              this.saveBill = (dateBill >= today);
              this.showBill = true;

              this.commentBill = response.request.bill.comment;
              this.filesBill = response.request.bill.files;
              this.dateBill = response.request.bill.date;
            }

            this.textBtnSubmit = (this.request?._id) ? "Actualizar estimación" : "Guardar estimación";
            this.selectedDate = (this.request?.period) ?
              {
                startDate: moment(this.request.period.start),
                endDate: moment(this.request.period.end)
              }
              :
              { startDate: moment(), endDate: moment() };

            this.biddingDetailFiles = (Array.isArray(response.files)) ? response.files : [];

            if (this.createRequest || this.editRequest) {
              this.declineBidding = true;
            }

            this.DatePeriod = (this.request?.period) ?
              {
                startDate: moment(this.request.period.start),
                endDate: moment(this.request.period.end)
              }
              :
              { startDate: moment(), endDate: moment() };

            this.setPeriodValue();
            this.getChat();

          }).catch((e) => {
            console.log(e);
          })
          .finally(() => {
            this.lockControls();

            setTimeout(() => {
              this.loading = false;
            }, 500);
          });

      } catch (error) {
        console.log(error);
      }
    }
  }

  public setPeriodValue() {

    var tmp_period = {
      start: moment(this.DatePeriod.startDate).format('YYYY-MM-DD'),
      end: moment(this.DatePeriod.endDate).format('YYYY-MM-DD')
    };

    this.FormRequest.patchValue({
      period: JSON.stringify(tmp_period)
    });

    var date = this.DatePeriod;

    var start = date?.startDate, end = date?.endDate;
    if (start && end) {
      var days = end.diff(start, 'days')
      days++;
      this.tmpDaysPeriod = days;

      this.FormRequest.patchValue({
        daysPeriod: days
      });

    }
  }


  async lockControls() {
    try {
      if (this.createRequest || this.editRequest) {

        this.FormRequest.get('subtotal').enable();
        this.FormRequest.get('iva').enable();
        this.FormRequest.get('comment').enable();
        this.FormRequest.get('files').enable();
        this.FormRequest.get('period').enable();
      }
      else {

        this.FormRequest.get('subtotal').disable();
        this.FormRequest.get('iva').disable();
        this.FormRequest.get('comment').disable();
        this.FormRequest.get('files').disable();
        this.FormRequest.get('period').disable();
      }


      if (this.saveBill) {
        this.FormBill.get('files').enable();
        this.FormBill.get('comment').enable();
      }
      else {
        this.FormBill.get('files').disable();
        this.FormBill.get('comment').disable();
      }

    } catch (error) {
      console.log(error);
    }
  }

  // -F GUARDAR

  // -f  Guardar estimación 


  async Submit() {
    try {
      if (!this.FormRequest.valid) {
        this.FormRequest.markAllAsTouched();
        this.alertService.info("Campos incompletos", "");
        return;
      }

      else if (this.createRequest || this.editRequest) {

        this.loadingTabRequest = true;
        this.loading = true;
        let formData = new FormData();

        var subtotal = this.get_subtotal.value;
        var amount = this.get_amount.value;

        this.FormRequest.patchValue({
          files: this.files,
          subtotal: parseFloat(subtotal),
          amount: parseFloat(amount)
        });

        this.FormRequest.patchValue({
          daysPeriod: this.tmpDaysPeriod
        });


        if (!this.request?._id) {

          formData.append('biddingId', this.biddingId);

          this.builtFormDataService.buildFormData(formData, this.FormRequest.value);

          await this.httpService.post(`api/bidding/request`, formData).toPromise()
            .then((response: any) => {

              if (response?.id) {
                this.alertService.successAlert("Estimación guardada exitosamente", "");
                this.ngOnInit();
              }
            }).catch((e) => {
              console.log(e);
            })
            .finally(() => {
              setTimeout(() => {
                this.loading = false;
                this.loadingTabRequest = false;
              }, 1000);
            });
        }

        else {
          this.builtFormDataService.buildFormData(formData, this.FormRequest.value);
          formData.append('id', this.request._id);

          // Separacion de archivos existentes y nuevos
          var originalF = [];
          this.files.forEach(element => {
            if (element.id) {
              originalF.push(element);
            }
          });

          var originalFstring = originalF.length > 0 ? JSON.stringify(originalF) : '';
          formData.append('filesOrigin', originalFstring);

          await this.httpService.put(`api/bidding/request`, formData).toPromise()
            .then((response: any) => {

              this.alertService.successAlert("Estimación actualizada exitosamente", "");
              this.ngOnInit();
            }).catch((e) => {
              console.log(e);
            })
            .finally(() => {
              setTimeout(() => {
                this.loading = false;
                this.loadingTabRequest = false;
              }, 1000);
            });
        }
      }
    } catch (error) {
      if (error.status != 403) {
        this.alertService.error("Error.", "");
      }
      console.log(error);
      this.loadingTabRequest = false;

    }
  }


  // -F FILES

  public addFile(event) {
    if (event.target.files[0].size < 5000000) {
      //Tomar Archivo del input
      let file: File = event.target.files[0];

      if (this.saveBill) {
        if (this.filesBill.length < 3) {
          this.filesBill.push(file);
        }
        else {
          this.alertService.infoToast("Máximo 3 archivos para " + (this.userService?.modulesName?.bill || 'factura'));
          event.target.value = '';
        }
      }
      else {
        if (this.files.length < 3) {
          this.files.push(file);
        }
        else {
          this.alertService.infoToast("Máximo 3 archivos para estimación.");
          event.target.value = '';
        }
      }

    } else {
      this.alertService.infoToast("El tamaño archivo debe ser menor a 5MB.");
      event.target.value = '';
    }


  }

  public recivido(event) {
    this.files.push(event);
  }

  public removeSelectedFile(index, type) {

    if (type === "files") {
      this.files.splice(index, 1);
    }
    else if (type === "filesBill") {
      this.filesBill.splice(index, 1);

    }
  }

  public validDownload(file, id) {
    id ? this.download(file) : "";
  }

  async download(file: string) {
    window.open(`${this.apiUrl}${file}`, "_blank");
  }



  openModal(template: any, size: string = 'lg') {
    this.modalService.open(template, { size: size })
  }


  // -F CALCULAR TOTAL

  // getting the form control elements
  get get_subtotal(): AbstractControl {
    return this.FormRequest.controls['subtotal'];
  }

  get get_iva(): AbstractControl {
    return this.FormRequest.controls['iva'];
  }

  get get_amount(): AbstractControl {
    return this.FormRequest.controls['amount'];
  }

  async calculateAmount() {
    var subtotal = this.get_subtotal.value;
    if (subtotal === "") { subtotal = "0.00" }
    subtotal = parseFloat(subtotal);
    var iva = this.get_iva.value;
    var amount = subtotal;
    if (iva !== 0) {
      var taxes = (iva / 100) * subtotal;
      amount = subtotal + taxes;
    }

    this.FormRequest.patchValue({
      amount: amount
    });

  }

  public isNumeric(evt: any) {

    var value = evt.target.value;
    var val = value;
    //var val = value.replace(/[^.\d]/g, '');

    if (val === "") { val = "0.00" }

    var dec = val.split(".");
    if (dec.length > 1 && dec[1].length > 4) {
      val = dec[0] + "." + dec[1].substring(0, 4);
    }

    evt.target.value = val;
  }

  public formatCurrency(value: any) {

    var val = parseFloat(value).toLocaleString('en-US', {
      style: 'decimal',
      maximumFractionDigits: 2,
      minimumFractionDigits: 2
    });

    return val;
  }


  // -f FUNCIONES CONFIRM

  // -P CANCELAR ESTIMACION
  async confirmCancelRequest(req: any) {

    Swal.fire({
      title: '<div class="div-title"> Cancelar estimación \n  <b class="ml-1 text-danger" ' +
        'style="font-size: large;"> Al cancelar, la estimación que se registró será eliminada permanentemente.  </b><div>',
      icon: 'question',
      text: "¿Deseas continuar?",

      showCancelButton: true,
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar',
      confirmButtonColor: "#fd3666",


    }).then((result) => {

      if (result.isConfirmed) {
        this.httpService.delete(`api/bidding/request/` + this.request._id).toPromise()
          .then(async (res: any) => {

            this.alertService.successAlert("Estimación cancelada.", "");
            this.ngOnInit();

          }).catch((e) => {
            console.log(e);
          })
      }
    })
  }

  // -P RECHAZAR PARTICIPAR EN LICITACION
  async confirmDeclineBidding(req: any) {

    Swal.fire({
      title: '¿Por qué no deseas participar en la licitacion?',
      input: 'textarea',
      inputAttributes: { autocapitalize: 'off' },
      inputLabel: "Motivo ",
      inputPlaceholder: "Aa...",


      inputValidator: (motivo) => {
        if (!motivo) return 'Por favor, escribe tu motivo.'
        if (motivo.length > 250) return 'Maximo 250 Caracteres'
      },
      showCancelButton: true,
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar',
      showLoaderOnConfirm: true,
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {

      if (result.isConfirmed) {
        let formData = new FormData();
        formData.append('biddingId', this.biddingId);
        formData.append('comment', result.value);
        this.httpService.post('api/bidding/request/reject', formData).toPromise()
          .then(async (res: any) => {
            this.alertService.successAlert("Registro exitoso.", "Gracias por tus comentarios.");
            this.ngOnInit();

          }).catch((e) => {
            console.log(e);
          })
      }
    })
  }

  // -f FACTURA
  async SaveBill() {
    if (this.request?._id) {
      if (this.filesBill.length > 0) {
        this.FormBill.patchValue({
          id: this.request._id,
          files: this.filesBill,
          comment: this.commentBill
        });

        let formData = new FormData();
        this.builtFormDataService.buildFormData(formData, this.FormBill.value);
        await this.httpService.post('api/bidding/provider/bill', formData).toPromise()
          .then(async (res: any) => {
            this.alertService.successAlert("Registro exitoso.", "");
            this.filesBill = [];
            this.commentBill = "";
            this.ngOnInit();

          }).catch((e) => {
            console.log(e);
          })
      }
      else {
        this.alertService.errorToast("Se debe agregar al menos un archivo para guardar " + (this.userService?.modulesName?.bill || 'factura') + ".");
      }
    }
  }
}
