import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'dnc-button-modal',
  templateUrl: './dnc-button-modal.component.html',
  styleUrls: ['./dnc-button-modal.component.scss', '../../dynForm.comp.scss'],
  host:{
    "[style.order]": "check.position",
    "[class]": "'col-sm-12 col-md-' + (check.col_size || 4)"
  }
})
export class DncButtonModalComponent implements OnInit {

  @Input() check: any;
  @Output() onClick: EventEmitter<any> = new EventEmitter<any>();


  constructor(
  ) { }

  ngOnInit(): void {
  }

  public dynamicEmit(event){
    this.onClick.emit(event);
    // this.onClick.emit({target:this.check?.target, size: this.check?.size});

  }

}
