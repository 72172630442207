import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { debug } from "console";
import { NavigationService } from "ngx-owl-carousel-o/lib/services/navigation.service";
import { Observable } from "rxjs";
import { DataBankService } from "src/app/components/mk-table/services/data-bank.service";
import { SocketService } from "./socket.service";
import { UserService } from "./user.service";
import { NavService } from '../services/nav.service';

@Injectable({
    providedIn: 'root'
})

/**
 * Guard para verificar que el usuario tenga permiso de acceso y/o rol. El nombre del rol se encuentra en UserService
 * 
 * INSTRUCCIONES DE USO: 
 * 1) Utilizar en canActivate de cada modulo
 * 2) Proporcionar un objeto data: {role: ROL_ESPERADO}
 */

export class RoleGuard implements CanActivate {
    private expectedRole: string
    private moduleFlag: boolean = false; 
    constructor(
        private userService: UserService, 
        private router: Router,
        private socket: SocketService,
        private bankData: DataBankService,
        public navServices: NavService
        ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        this.expectedRole = route.data.role

        this.bankData.loadData();
        
        this.socket.clearRooms();
        this.socket.addRoom(this.userService.user?.socketRoom);
        this.socket.addRoom(this.userService.user?.socketRoom+this.userService.user?._id);
        this.socket?.disconnect();
        this.socket.connect();

        //? validacion para modulos a los que no tenga permiso el cliente. 
        this.navServices.getModules().subscribe(menuItems => {
            this.moduleFlag = menuItems.some(item  => {return item.module ==  this.expectedRole});
        });

        if (!this.moduleFlag) {
            let firstPermission = this.userService.user.permissions?.find(permission => {return (permission?.access);});
            let url = (firstPermission?.module != 'home')?firstPermission?.module:`${firstPermission?.module}/${this.userService?.user?.department[0]?._id || '404'}`
            this.router.navigate([`/${url||'404'}`])        
        }
         //////////////////////////////////////////////?


        if (this.userService.user.roleName === "ADMIN") {
            return true
        }

        
        if (this.userService.user.permissions?.find(permission => {return permission.module == this.expectedRole})?.access) {

                return true;            
        } else {
            let firstPermission = this.userService.user.permissions?.find(permission => {return (permission?.access);});
            let url = (firstPermission?.module != 'home')?firstPermission?.module:`${firstPermission?.module}/${this.userService?.user?.department[0]?._id || '404'}`
            this.router.navigate([`/${url||'404'}`])
        }
    }

}