import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UserService } from 'src/app/shared/services/user.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-quote-modal',
  templateUrl: './quote-modal.component.html',
  styleUrls: ['./quote-modal.component.scss']
})
export class QuoteModalComponent implements OnInit {
  public url: string; 
  @Output() onClose: EventEmitter<any> = new EventEmitter<any>(); 

  @Input() quotesRecord;

  public company: any;
  public porcentIva: number = 16;
  public isClientView: boolean = false;

  constructor(public userService : UserService) {  
    const domain = window.location.hostname;
    if (
      domain.indexOf('.') < 0 ||
      domain.split('.')[0] === 'example' ||
      domain.split('.')[0] === 'lvh' ||
      domain.split('.')[0] === 'www' ||
      domain.split('.')[0] === '45'
    ) { this.company = 'propietario'; } else { this.company = domain.split('.')[0]; }
    this.url = environment.apiUrl; 
  }

  ngOnInit(): void {        
  }

  filterDeducible(array: any[] = [], isDeducible: boolean): any[]{
    if(isDeducible)
      return array?.filter(item => item.deducible != 'N/A') || []
    else
      return array?.filter(item => item.deducible == 'N/A') || []
  }

  public dowloadFile(item: any){
    window.open(`${this.url}${item?.name}`, "_blank");
  }
 
  // Cerrar modal
  close(): void { this.onClose.emit()}
}
