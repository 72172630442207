import { HttpHeaders } from '@angular/common/http';
import { Component, OnInit, Input, ViewChild, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { ValidateFormsDirective } from 'src/app/directives/validate-forms.directive';
import { datePicker } from 'src/app/shared/mk-configs/datePicker/datePickerLocale';
import { AlertService } from 'src/app/shared/services/alert.service';
import { BuiltFormDataService } from 'src/app/shared/services/built-form-data.service';
import { HttpService } from 'src/app/shared/services/http.service';
import { UserService } from 'src/app/shared/services/user.service';
import { environment } from 'src/environments/environment';
import { TabParticipantsComponent } from '../tab-participants/tab-participants.component';

@Component({
  selector: 'tab-info',
  templateUrl: './tab-info.component.html',
  styleUrls: ['./tab-info.component.scss']
})
export class TabInfoComponent implements OnInit {

  @ViewChild(TabParticipantsComponent) tabParticipants: TabParticipantsComponent;
  @Output() updateStatus: EventEmitter<any> = new EventEmitter<any>();

  @Output() onClose: EventEmitter<any> = new EventEmitter<any>();
  @Output() onSubmit: EventEmitter<any> = new EventEmitter<any>();
  close = ()=>{this.onClose.emit()}


  @Input() public id = null
  @Input() private movementId: string;

  public Files = [];
  public Prueba = [];
  public audio;
  public audioFile = null;
  public status = null;
  public GenInfoForm: FormGroup
  public DateEndbid: any = {startDate: moment(), endDate: moment()}
  public DateBill: any = {startDate: moment(), endDate: moment()}
  public dateOptions: any = datePicker.locale.spanish;
  public today = moment();
  public todayBill = moment();
  public isLoading: boolean = false;
  public apiUrl = environment.apiUrl;
  public disableForm: boolean = false;
  constructor(
    private modalService: NgbModal,
    private toast: AlertService,
    private fb: FormBuilder,
    public fieldisValid: ValidateFormsDirective,
    private httpService: HttpService,
    private builtservice: BuiltFormDataService,
    public userService: UserService
  ) { }

 
  async ngOnInit() {
    
    this.GenInfoForm = this.fb.group({
      title            : [null, Validators.compose([Validators.required, Validators.maxLength(50)])],
      description      : [null, Validators.compose([Validators.required])],
      status           : ['CREATED', Validators.compose([Validators.required])],
      dateEnd          : [null, Validators.compose([Validators.required])],
      dateStart        : [null, Validators.compose([Validators.required])],
      dateBill         : [null, Validators.compose([Validators.required])],
      ticketMovementId : [this.movementId, Validators.compose([Validators.required])],
      files            : [[]],
      filesOrigin    : [null]
    });

    if(this.id != null) {
      await this.detailBid();      
    }
  }

  openModal(template: any, size: string = 'lg'){
    this.modalService.open(template, {size: size})
  }

  public addFile(event){
    if (event.target.files[0].size < 6000000) {
      //Tomar Archivo del input
      let file: File = event.target.files[0];
      
       this.Files.push(file);
    } else {
      this.toast.infoToast("El peso del archivo debe ser menor a 5MB.");      
      event.target.value = '';
    }
  }

  public async createBid(){
    try {
      if (!this.GenInfoForm.valid) {
        this.GenInfoForm.markAllAsTouched();
        this.toast.infoToast("Campos incompletos, verifique porfavor");
        return;
      }
      this.isLoading = true;
  
      const formData = new FormData();
      this.builtservice.buildFormData(formData,this.GenInfoForm.value);

      this.Files.forEach(element => {
          formData.append('files', element,element.name);
      });

      await this.httpService.post(`api/bidding`, formData).toPromise().then( async (resp:any) => {
        this.toast.successToast(resp.message);
        this.isLoading = false;
        this.id = resp.id;
        let bidders = await this.tabParticipants.submit(resp.id);
        
        //? si la fecha de inicio es igual a la fecha de hoy, se etablece el status de activo, si no, solo se mandara el Nuevo
        let tempstatus = moment(this.GenInfoForm.value?.dateStart).format('YYYY-MM-DD') == moment().format('YYYY-MM-DD') ? 'STARTED' : 'CREATED'
        this.onSubmit.emit({
          "_id": resp?.id,
          "status": tempstatus,
          "dateEnd": moment(this.GenInfoForm.value?.dateEnd, "YYYY-MM-DD").format(),
          "bidders": bidders
      });
      
        this.toast.successToast("Se creó la Licitación Correctamente y se informó a los participantes");
      }).catch((err:any) => {
        if(err.status != 403){
          this.toast.errorToast(err.error.message)
          this.isLoading = false;
        }
      });

      if(this.audioFile){
       await this.updateAudioFile();
      }
      
    } catch (error) {
      console.log(error);
    }finally{
      this.isLoading = false;
    }
  }

  public async updateBid(){

      if (!this.GenInfoForm.valid) {
        this.GenInfoForm.markAllAsTouched();
        this.toast.infoToast("Campos incompletos, verifique porfavor");
        return;
      }

      this.isLoading = true;
      const formData = new FormData();

      // Separacion de archivos existentes y nuevos
      var originalF = [];
      this.Files.forEach(element => {
        if(element.id){
          originalF.push(element);
        }else{
          formData.append('files', element,element.name);
        }
      });
      
      var originalFstring = originalF.length > 0 ? JSON.stringify(originalF) : '';
      
      //  this.builtservice.buildFormData(formData,this.GenInfoForm.value);
      formData.append('id', this.id);
      formData.append('title', this.GenInfoForm.value.title);
      formData.append('description', this.GenInfoForm.value.description);
      formData.append('status', this.GenInfoForm.value.status);
      formData.append('dateEnd', this.GenInfoForm.value.dateEnd);
      formData.append('dateStart', this.GenInfoForm.value.dateStart);
      formData.append('dateBill', this.GenInfoForm.value.dateBill);
      formData.append('ticketMovementId', this.GenInfoForm.value.ticketMovementId);
      formData.append('filesOrigin', originalFstring);
      

      this.httpService.put(`api/bidding/`, formData).toPromise().then(async (resp:any) => {
        this.isLoading = false;
        this.toast.successToast(resp.message);
        // this.detailBid();
          //? si la fecha de inicio es igual a la fecha de hoy, se etablece el status de activo, si no, solo se mandara el Nuevo
          let tempstatus = moment(this.GenInfoForm.value?.dateStart).format('YYYY-MM-DD') == moment().format('YYYY-MM-DD') ? 'STARTED' : 'CREATED'
        this.onSubmit.emit({
          "_id": this.id,
          "status": tempstatus,
          "dateEnd": moment(this.GenInfoForm.value?.dateEnd, "YYYY-MM-DD").format(),
      });
      }).catch((err:any) => {
        if(err.status != 403){
          this.toast.errorToast(err.error.message)
        }

        this.isLoading = false;
      });

      await  this.updateAudioFile();
    
  }

  public async detailBid(){
    try {
      this.Files = [];
      this.isLoading = true;
      
      this.httpService.get(`api/bidding/${this.id}`).toPromise().then( (resp:any) => {
        this.GenInfoForm.patchValue({
          title: resp.title,
          description:  resp.description,
          status: resp.status,
          dateEnd:  moment(resp.dateEnd).format("YYYY-MM-DD"),
          dateStart: moment(resp.dateStart).format("YYYY-MM-DD"),
          dateBill: moment(resp.dateBill).format("YYYY-MM-DD HH:mm"),
          ticketMovementId: resp.ticketMovementId,
          filesOrigin: resp.files
        });

        this.status = resp.status;
        
        this.audio = resp?.audio?.name;        
        this.DateEndbid = { startDate: moment(resp.dateStart), endDate: moment(resp.dateEnd) };
        this.DateBill = { startDate: moment(resp.dateBill), endDate: moment(resp.dateBill) };

        this.todayBill = moment(this.DateEndbid.endDate);
        moment(this.DateEndbid.startDate) < moment() ?  this.today = moment(this.DateEndbid.startDate) : "";
        resp?.files?.forEach(element => {
          this.Files.push(element)
        });

        if (resp?.status != "CREATED" && resp?.status != "STARTED") {
            this.GenInfoForm.get('title').disable();    
            this.GenInfoForm.get('description').disable();    
            this.disableForm = true;   
        }else{
            this.disableForm = false;   
            this.GenInfoForm.get('title').enable();    
            this.GenInfoForm.get('description').enable();  
        }

        this.updateStatus.emit({status:this.status, extended: resp?.extended})
        
      }).catch((err:any) => {
        if(err.status != 403){
          this.toast.errorToast(err?.error?.message)
          console.log(err);
        }
      });
    } catch (error) {
      console.log(error);

    }finally{
      this.isLoading = false;

    }
  }

  async download(file: string) { 
    window.open(`${this.apiUrl}${file}`, "_blank");
  }

  public validDownload(file, id){
    id ? this.download(file) : "";
  }

  public setDateBid(){
     

    
    this.GenInfoForm.patchValue({
      dateEnd: moment(this.DateEndbid.endDate).format("YYYY-MM-DD"),
      dateStart: moment(this.DateEndbid.startDate).format("YYYY-MM-DD"),
    });

    this.DateBill = {startDate: moment(this.DateEndbid.endDate).endOf('day'), endDate: moment(this.DateEndbid.endDate).endOf('day')};
    this.todayBill = moment(this.DateEndbid.endDate).add(1,'d').startOf('day');
  }

  public setDateBill(){
    this.GenInfoForm.patchValue({
      dateBill: moment(this.DateBill.startDate).format("YYYY-MM-DD HH:mm")
    });
  }

  public removeSelectedFile(event){
    this.Files = this.Files.filter(file => {return file.name != event});

      this.GenInfoForm.patchValue({
        files: this.Files
      });            
  }
  
  public async Bidcancel(){
    if (this.id) {
      try {
      
        const data = await this.httpService.delete(`api/bidding/${this.id}`).toPromise();
        this.toast.successToast(data.message);
        this.updateStatus.emit('CANCELLED');
        this.onSubmit.emit(null);
        this.onClose.emit();
      } catch (error) {
        console.log(error);
      }
    }
  
  }

  public async addAudio( event){
    if (event.target.files[0].size < 6000000) {
      
      //Tomar imagen del input
      let file: File = event.target.files[0];
      this.audioFile = file;

    } else {
      this.toast.infoToast("El archivo de audio debe ser menor a 5MB.");
      event.target.value = '';
    }
  }

  public async deleteAudio(){
    try {

      await this.httpService.delete(`api/bidding/audio/${this.id}`).toPromise();
      this.toast.successToast("Audio Eliminado correctamente");
      await this.updateAudio();
    } catch (error) {
      console.log(error);
      this.toast.errorToast("Ocurrio un error");
    }
  }

  public async updateAudio(){
    try {
         let data = await this.httpService.get(`api/bidding/${this.id}`).toPromise();
         this.audio = data?.audio?.name;
    } catch (error) {
      console.log(error);   
    }
  }
  public deleteLocalAudio() {
    this.audioFile = null;
  }

  public async updateAudioFile(){
    let obj = {
      audio: this.audioFile,
    }

    let formDataAudio = new FormData();
    this.builtservice.buildFormData(formDataAudio, obj);

    await this.httpService.put(`api/bidding/audio/${this.id}`, formDataAudio).toPromise();
    await this.updateAudio();
    // this.toast.successToast("Audio guardado correctamente");
    this.audioFile = null
  }

}
