import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import * as moment from 'moment';
import { UserService } from 'src/app/shared/services/user.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'tab-bill',
  templateUrl: './tab-bill.component.html',
  styleUrls: ['./tab-bill.component.scss']
})
export class TabBillComponent implements OnInit {

  @Input() bidding;
  @Output() onClose: EventEmitter<any> = new EventEmitter<any>();

  public bill;
  public billdata;
  public apiUrl = environment.apiUrl;

  constructor(
    public userService: UserService
  ) { }

  ngOnInit(): void {
    this.bill = this.bidding?.requestData.filter(bid => {return bid.status == "ACCEPTED"})[0]?.bill?.files; 
    this.billdata =  this.bidding?.requestData.filter(bid => {return bid.status == "ACCEPTED"})[0]?.bill;            
  }

  async download(file: string) { 
    window.open(`${this.apiUrl}${file}`, "_blank");
  }

  close = ()=>{this.onClose.emit()}

}
