<form [formGroup]="DinamicForm">
    <dnc-title [check]="check" [editable]="editable"></dnc-title>
    <div class="input-group">
        <!-- ! ESTRUCTURA DE PRUEBA  -->
        <ng-container *ngFor="let field of check.fields; let index as i">
            <ng-container [ngSwitch]="field.type" class="mb-1">
                <ng-container *ngSwitchCase="'input'">
                    <input [type]="field.options.type" class="form-control" 
                    [placeholder]="field.placeholder"
                    [ngClass]="(field?.options?.type=='float') ? 'inpNumber' : '' "
                    (change)="setValueField(field.name, $event);  dynamicEmit()" [id]="field?.name"
                    maxlength="15"
                    (keypress)="(field?.options?.type=='float') ? isNumeric($event) : '' ; "
                    [name]="field?.name" [defaultValue]="valInput"    #inputVal 
                    [readonly]="( check?.target?.readonly || !editable)" 
                    [disabled]="( check?.target?.readonly || !editable)" 
                    >

                </ng-container>

                <ng-container *ngSwitchCase="'select'">
                    <ng-select class="form-control" [placeholder]="field.placeholder" 
                    [items]="ValidOptions(field)"
                    bindLabel="label" 
                    bindValue="value" 
                    [clearable]="false"
                    (change)="setValueField(field.name, $event); dynamicEmit()"
                    [id]="field?.name" [name]="field?.name" [(ngModel)]="valSelect" #selectVal
                    [ngModelOptions]="{standalone: true}"
                    [readonly]="(check?.target?.readonly || !editable)" 
                    [disabled]="(check?.target?.readonly || !editable)" 
                    
                    ></ng-select>
                </ng-container>

                <ng-container *ngSwitchCase="'date'">
                    <div class="input-group-append">
                        <div class="input-group-text border-right-0"><i class="fe fe-calendar lh--9 op-6"></i></div>
                    </div>
                    <input type="text" class="form-control _date" [placeholder]="field.placeholder"
                        (change)=" setValueField(field.name, $event); dynamicEmit()" [id]="field?.name"
                        (keypress)="setValueField(field.name, $event);"
                        [name]="field?.name"  #dateVal 
                        [readonly]="( check?.target?.readonly || !editable)" 
                        [disabled]="( check?.target?.readonly || !editable)" 
                        >
                </ng-container>

                <!-- TODO AGREGUEN LOS CAMPOS QUE VEAN NECESARIOS -->
            </ng-container>
        </ng-container>
    </div>
    <!-- <small class="text-danger" *ngIf="editable && fieldsError">{{fieldRequired ? "Todos los campos son requeridos." : "Favor de llenar los campos faltantes."}}</small> -->
    <small class="text-danger" *ngIf="editable && fieldisValid.campoEsValido(check.formControlName, DinamicForm)">{{check.validateMessage}}</small>
</form>