<mk-loader *ngIf="isLoading" class="loader-spinner" style="z-index: 120"></mk-loader>

<!-- Page Header -->
<div class="page-header">
    <div>
        <h2 class="main-content-title tx-24 mg-b-5">Licitaciones</h2>
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="javascript:;">Licitaciones</a></li>
            <li class="breadcrumb-item active" aria-current="page">{{((( user?.user?.roleName == 'CONTRACTOR' ?  user?.modulesName?.contractor != '' ? user?.modulesName?.contractor : '...'  : user?.modulesName?.provider != '' ? user?.modulesName?.provider : '...') | status) | titlecase )}}</li>
        </ol>
    </div>
    
    <div class="card custom-card __filters" >
        <div class="input-group search">
            <div class="input-group-prepend">
                <div class="input-group-text">
                    <i class="fe fe-search lh--9 op-6"></i>
                </div>
            </div>
            <input class="form-control" (keyup)="search($event)" type="text" placeholder="Buscar...">
        </div>
    </div>
</div>

<!-- Tables -->
<div class="row">
    <div class="col-12 box-drag" *ngFor="let t of _TABLESCONFIG; index as ti">
        <div class="card custom-card overflow-hidden __border-top" [ngStyle]="{'border-color': t.color}">
            <div class="card-header py-2">
                <div class="row px-2 justify-content-between">
                    <div>
                      <label class="main-content-label mb-0"><i class="fa fa-circle mr-2" [style.color]="t.color" [class.text-primary]="!t.color"></i> {{t?.name}} </label>
                      <span class="d-block tx-12 mb-0 text-muted text-overflow">{{t?.description}}</span>
                    </div>
                    <div class="my-auto">
                        <span class="text-muted"><b>{{data[t._id]?.length}}</b> Registro(s)</span>
                        <button (click)="t.hidden = true" *ngIf="!t.hidden"[style.color]="t.color" class="btn btn-sm _hide_table btn-rounded ml-3"><i class="fa fa-eye-slash"></i></button>
                        <button (click)="t.hidden = false" *ngIf="t.hidden"[style.background-color]="t.color" class="btn btn-sm _hide_table btn-rounded text-white ml-3"><i class="fa fa-eye"></i></button>
                    </div>
                </div>
            </div>
 
            <div class="card-body p-0" *ngIf="!t.hidden">
                <mk-table [input]="false" [table_id]="t._id" (onAdd)="newItem(t._id, $event)" [color]="t.color">
                    <mkt-title [columns]="t.main"
                    [optionsTable]="false"  
                    ></mkt-title>
                    <div  data-id="{{t._id}}">
                    <!-- <div  data-id="{{t._id}}"> -->
                        <!-- ROWS -->
                        <ng-container *ngFor="let r of (data[t._id] | filtro:textoBuscar);  let i = index">
                            <mkt-row data-id="{{r?._id}}" department="{{r?.department}}"
                                [optionsTable]="false"     
                                [item]="r" 
                                [columns]="t.main" 
                                [subitems]="t.subitems" 
                                [subDetail]="true" 
                                [detail]="false"
                                [module]="'BIDS'"
                                (onSubDetail)="detailTicket = {group: t._id , id: r?._id, idSub: $event._id}; subDetail($event, detail); detailColor = t.color" 
                                (onChange)="$event.changeGroup?(changeGroup($event.id, $event.newGroup, t._id, r.department, $event.target, $event.value, $event.type, $event.options, $event.subitem, $event.isSub, $event.socket)):(updateValue(t._id, $event.id, $event.target, $event.value, $event.type, $event.options, $event.subitem, $event.isSub))" 
                                (onCancelItem)="calcelItem($event, t._id)"
                                (onOpenSubitem)="openSubitems($event)">
                            </mkt-row>
                        </ng-container>
                        <!-- NO CONTENT -->
                        <mkt-row *ngIf="!(data[t._id] )?.length" [noData]="true"></mkt-row>
                    </div>
                    
                </mk-table>

                <mkt-paginator class="border-top" 
                [arrayLength]="(data[t._id] | filtro:textoBuscar)?.length" 
                [pageSize]="_TABLESIZE"
                 [color]="t.color" 
                 (onSelect)="t.pageIndex = $event"
                 ></mkt-paginator>
            </div>
        </div>
    </div>
</div>

<!-- Detail Component -->

<ng-template #detail>
    <request-detail style="--det-color: {{detailColor}}"
    [biddingId]="biddingSelectedId"
    >
    </request-detail>
</ng-template>