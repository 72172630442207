<div class="card overflow-auto m-0">
  <table class="table table-bordered table-striped mb-0">
    <thead>
      <tr>
        <th *ngFor="let item of check.target.columns "><div class="text-center">{{item.label}}</div></th>
    </thead>
    <tbody>
      <tr *ngFor="let item of dataTable; index as i">
        <td *ngFor="let item2 of dataColumn">{{item2 == 'birthday' ? ((item[item2] && item[item2] != 'Fecha inválida')? (item[item2] | moment:'DD/MM/YYYY') : ''): item[item2]}}</td>
         <td>
          <div class="text-center d-flex justify-content-center">
            <label class="custom-switch cursor-pointer">
              <input type="checkbox" [swal]="(item.primary) ?  swalPrimaryRemove : swalPrimary" (confirm)='ChangePrimaryContact(i, item.primary)'
                name="custom-switch-checkbox" class="custom-switch-input" 
                [checked]="item.primary" 
                />
              <span class="custom-switch-indicator" matTooltip="Cambiar estatus"></span>
            </label>
          </div>
        </td> 
        <td *ngIf="action" [width]="size">
          <div class="text-center" style="white-space: nowrap">
            <button class="btn btn-info mr-2" type="button" title="Editar" (click)='editRegister(i)'>
              <i class="zmdi zmdi-edit"></i>
            </button>
            <!-- [swal]="deleteCourseSwal" (confirm)='removeRegister(i)' -->
            <button class="btn btn-danger" type="button" title="Eliminar"  (click)='removeRegister(i)' >
              <i class="zmdi zmdi-delete"></i>
            </button>
          </div>
        </td>
      </tr>
      <tr *ngIf="!dataTable?.length">
        <td [attr.colspan]="check?.target?.columns.length" class="text-center tet-muted">{{check.target.nodatamessage}}</td>
      </tr>
    </tbody>
  </table> 
</div>


<swal #swalPrimary class="d-none" title="¿Estas seguro de establecer este contacto como principal?" icon="question"
  text="*Este contacto será la referencia principal del {{userService.modulesName?.client || 'Cliente'}}. Si ya había uno asignado será removido."
  [showCancelButton]="true" [focusCancel]="true"
  [swalOptions]="{confirmButtonText: 'Aceptar', cancelButtonText: 'Cancelar'  }">
</swal>

<swal #swalPrimaryRemove class="d-none" title="¿Estas seguro de remover este contacto como principal?" icon="question"
text="*No habrá contacto de referencia principal del {{userService.modulesName?.client || 'Cliente'}}."
  [showCancelButton]="true" [focusCancel]="true"
  [swalOptions]="{confirmButtonText: 'Aceptar', cancelButtonText: 'Cancelar', confirmButtonColor: '#fd3666'}">
</swal>

<swal #deleteCourseSwal class="d-none" title="¿Estas seguro de eliminar este contacto?" icon="question"
  [showCancelButton]="true" [focusCancel]="true"
  [swalOptions]="{confirmButtonText: 'Aceptar', cancelButtonText: 'Cancelar', confirmButtonColor: '#fd3666'}">
</swal>

