import { Component, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LayoutService } from '../../services/layout.service';
import { NotificationService } from '../../services/notification.service';
import { SocketService } from '../../services/socket.service';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {

  @Input() notifications: any[] = [];
  @ViewChild('detail') detail:any;
  @ViewChild('detailBidding') detailBidding:any;
  @ViewChild('modal') modal: NgbModal;
  @ViewChild('modalContract') modalContract: NgbModal;
  public clickNotification:any = { id: null, type: null, data: null}

  iconType = {
    NEW_MESSAGE: 'message',
    MESSAGE_MENTIONED: 'message',
    NEW_MESSAGE_BID: 'message',
    PUSH_FILE: 'file',
    NEW_TICKET_AREA: 'ticket',
    ASSIGN_YOU_TICKET: 'account-box',
    AUTHORIZE: 'shield-key',
  }

  constructor(
    private notificationsService: NotificationService,
    public layoutService: LayoutService,
    public modalService: NgbModal,
    private userServices: UserService
  ) {}

  ngOnInit(): void {
  }
  
  public markAsReadAll():void{
    this.notificationsService.updateNotifications();
  }

  public goToNotification(item: any, event:any):void{    
    this.notificationsService.updateNotifications(false, item?._id);
    this.layoutService.emitSidebarNotifyChange(event, null); 
    if (item?.type == "NEW_MESSAGE_BID") {
      this.clickNotification.id = item?.data?.bidId;
      if (this.userServices?.user?.roleName != "PROVIDER" && this.userServices?.user?.roleName != "CLIENT" && this.userServices?.user?.roleName != "CONTRACTOR") {
        this.clickNotification.movementId = item?.data?.movementId
        this.clickNotification.status = item?.data?.status;
        this.modalService.open(this.modal, {size: 'lg'});
      }else{
        this.clickNotification.step = 1;
        this.layoutService.emitSidebarNotifyChange(event, this.detailBidding);
      }
      
    }else if(item?.type == "MESSAGE_MENTIONED"){
      
      if (this.userServices?.user?.roleName != "PROVIDER" && this.userServices?.user?.roleName != "CONTRACTOR" && this.userServices?.user?.roleName != "CLIENT") {
        if (item?.data?.bidId) {
          this.clickNotification.id = item?.data?.bidId;
          this.clickNotification.movementId = item?.data?.movementId
          this.clickNotification.status = item?.data?.status;
          this.modalService.open(this.modal, {size: 'xl'});
        }else{
          this.clickNotification.id = item?.itemId;
          this.clickNotification.type = item?.data?.chatType
          this.layoutService.emitSidebarNotifyChange(event, this.detail);
        }
        
      }else if(this.userServices?.user?.roleName == "PROVIDER" || this.userServices?.user?.roleName == "CONTRACTOR"){
        this.clickNotification.id = item?.data?.bidId;
        this.clickNotification.step = 1;
        this.layoutService.emitSidebarNotifyChange(event, this.detailBidding);
      }
      
    }else if(item?.type == "INVITADO_LICITACION" || item?.type == "LICITACION_CANCELADA" || item?.type == "LICITACION_TERMINADA" || item?.type == "FECHA_LICITACION"){
      this.clickNotification.id = item?.data?.biddingId;
      this.clickNotification.step = 0;
      this.layoutService.emitSidebarNotifyChange(event, this.detailBidding);
    }else if(item?.type == "PROPUESTA_GANADORA"){
      this.clickNotification.id = item?.data?.biddingId;
      this.clickNotification.step = 3;
      this.layoutService.emitSidebarNotifyChange(event, this.detailBidding);
    }else if(item?.type == "CONTRACT_LEASE" || item?.type == "CONTRACT_INCREASE"){
      this.clickNotification.id = item?.data?.contractId;
      this.clickNotification.code = item?.data?.code;
      this.modalService.open(this.modalContract, {size: 'xxl'});
    }else{
      this.clickNotification.id = item?.itemId;
      this.clickNotification.type = item?.data?.chatType
      this.layoutService.emitSidebarNotifyChange(event, this.detail);
   

      
    }
    
  }

}
