<div class="center d-flex justify-content-between" (click)="modalBid(modal)">
        <ng-container *ngIf="value else noBid">
            <span class="my-auto">
                <i class="mdi mdi-{{value?.status == 'WARNING' ? 'alert' : 'tag'}} text-color my-auto tx-16"></i>
            </span>
            <div class="d-flex flex-column text-right lh-2">
                <ng-container>
                    <small class="text-color"><b>{{value?.bidders || 0}}</b> Licitante(s)</small>
                    <small class="text-muted"><i class="fa fa-calendar tx-10 mr-1"></i> <b>{{value?.dateEnd | moment:"l"}}</b></small>
                </ng-container>
            </div>
        </ng-container>
</div>

<ng-template #noBid>
    <span class="my-auto">
        <i class="mdi mdi-tag text-muted my-auto tx-16"></i>
    </span>
    <div class="d-flex flex-column text-right lh-2">
        <ng-container><span class="m-auto text-muted tx-12">Abrir Licitación</span></ng-container>
    </div>
</ng-template>

<ng-template #modal let-modal>
    <app-modal-bid (onClose)="modal.dismiss()" [id]="value?._id || null" [movementId]="subId" [status]="value?.status" (onSubmit)="submitBid($event)"></app-modal-bid>
</ng-template>