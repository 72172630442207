import { CryptoSharkService } from '../services/crypto-shark.service';
import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'decryptChat'
})
export class DecryptChatPipe implements PipeTransform {

  constructor(private shark: CryptoSharkService){}

  transform(text: string, key: string): string {
    return text?this.shark.decrypt(text, key):"";
  }

}
