
<div class="line">
    <!-- <div class="line _border">
        <div class="mkt_col controls"></div>
        <div class="mkt_col col-{{c?.type | lowercase}}" *ngFor="let c of columns.main"></div>
    </div> -->
    <div class="mkt_col _controls">
        <div class="btns justify-content-center">Acción</div>
        <div class="folio justify-content-center cursor-pointer" (click)="sortAsc = !sortAsc; sort(sortAsc, 'folio')">
            <small>
                <b><i class="fa sort fa-arrow-{{sortAsc?'up':'down'}} mr-1" *ngIf="'folio' == target"></i></b>
                <span>folio</span>
            </small>
        </div>
    </div>
    <ng-container  *ngFor="let c of columns"   >
        <ng-container *ngIf="validatePermissions(c?.permissions)">
            <div class="mkt_col" class="col-{{c?.type | lowercase}} cursor-pointer" (click)="c.sortAsc = !c.sortAsc; sort(c.sortAsc, c.target);">
                <b><i class="fa sort fa-arrow-{{c.sortAsc?'up':'down'}} mr-1" *ngIf="c?.target == target"></i></b>
                <i class="fa fa-lock text-muted" *ngIf="c?.readonly"></i>
                <i class="mdi mdi-cellphone text-muted" *ngIf="c?.readonlyMobile"></i>
                {{c.name}}
            </div>
        </ng-container>
    </ng-container>


    <div class="_delete" *ngIf="optionsTable" ><div class="ml-auto"><small><span>Cancelar</span></small></div></div>
</div>