<!-- Main Header-->
<div class="main-header side-header sticky __zoom">
    
    <div class="container-fluid">
        <div class="main-header-center">
            <app-global-alert></app-global-alert>
            
            <!--Mensaje de alerta!-->
            <ngb-alert type="danger" [dismissible]="false" style="margin-top: 12px; text-align: center;" *ngIf="userService.mensaje"> 
                <strong>{{userService.mensaje}}</strong>
            </ngb-alert> 
        </div>
        <div class="main-header-right">
            <!-- NOTIFICACIONES  -->
            <div class="main-header-notification mr-2" (click)="layoutService.emitSidebarNotifyChange($event, notifications); openNotification();">
                <a href="javascript:void(0)" class="nav-link icon">
                    <i class="fe fe-bell header-icons"></i>
                    <span class="badge bg-danger text-white nav-link-badge" *ngIf="notificationService.unread != 0">{{notificationService.unread}}</span>
                </a>
            </div>
            <!-- PERFIL  -->
            <div ngbDropdown class="main-profile-menu">
                <a ngbDropdownToggle class="d-flex" href="javascript:void(0)">
                    <span class="main-img-user" >{{user.name | initials}}</span>
                </a>
                <div ngbDropdownMenu>
                    <div class="header-navheading">
                        
                        <h6 class="main-notification-title"> {{user.name || "User"}}</h6>
                        <p class="main-notification-text">{{user.email || "mail@mail.com"}}</p>
                    </div>
                    <button type="button" ngbDropdownItem class="" (click)="signOutHandler()">
                        <i class="fe fe-power"></i> Sign Out
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Main Header-->

<div class="jumps-prevent"></div>

<ng-template #notifications>
    <app-notifications [notifications]="this.notificationService._Notifications"></app-notifications>
</ng-template>