import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ValidateFormsDirective } from 'src/app/directives/validate-forms.directive';
import { AlertService } from 'src/app/shared/services/alert.service';
import { CryptoSharkService } from 'src/app/shared/services/crypto-shark.service';
import { HttpService } from 'src/app/shared/services/http.service';
import { ValidatorsService } from 'src/app/shared/services/validators.service';
import { MustMatch } from 'src/app/shared/validations/passwordValidator';

@Component({
  selector: 'credentials-modal',
  templateUrl: './credentials-modal.component.html',
  styleUrls: ['./credentials-modal.component.scss']
})
export class CredentialsModalComponent implements OnInit {

  @Output() onClose: EventEmitter<any> = new EventEmitter<any>();
  @Output() onConfirm: EventEmitter<any> = new EventEmitter<any>();

  public form: FormGroup; 
  public isLoading: boolean = false;

  constructor(
    private fb: FormBuilder,
    private alertService: AlertService,
    private httpService: HttpService,
    public validatorForm: ValidateFormsDirective,
    private cryptoService: CryptoSharkService,
    public validateInput: ValidatorsService
  ) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      newNip: [null, Validators.compose([Validators.required, Validators.maxLength(4)])],
      newPass: [null, Validators.compose([Validators.required, Validators.maxLength(16)])],
      confirmNewNip: [null, Validators.compose([Validators.required, Validators.maxLength(4)])],
      confirmNewPass: [null, Validators.compose([Validators.required, Validators.maxLength(16)])],
    },
    {
      validator: [MustMatch('newPass','confirmNewPass'),MustMatch('newNip','confirmNewNip')] 
    });
  }

  async submit(){ 
    try {
      this.isLoading = true;
      if(!this.form.valid){
        this.form.markAllAsTouched();
        this.alertService.infoToast("Campos incompletos");
        this.isLoading = false;
        return;
      };

      this.httpService.put(`api/user/data/credentials`, {
        newPass: this.cryptoService.encryptSha( this.form.value?.newPass ),
        newNip: this.cryptoService.encryptSha( this.form.value?.newNip )
      }).subscribe(response=>{
        this.onConfirm.emit();
        this.close(); 
        this.isLoading = false;
      },error => {
        this.isLoading = false;
        if (error.status != 403) {
          this.alertService.errorToast(error.error.message);
        }
      })
    } catch (error) {
      console.log(error);
      if (error.status != 403) {
        this.alertService.errorToast("No se pudo guardar.");
      }
    }finally{
      this.isLoading = false;
    }
  }

  public close(): void { this.onClose.emit(); }
}
