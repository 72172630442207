import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges, SimpleChange, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DyamicFormService } from 'src/app/shared/services/dynamic-form.service';

@Component({
  selector: 'dinamic-forms',
  templateUrl: './dinamic-forms.component.html',
  styleUrls: ['./dinamic-forms.component.scss']
})
export class DinamicFormsComponent implements OnInit {

  @Input() DataForm: any = [];
  @Input() dataInput;
  @Input() editForm;
  @Input() iniTable;
  @Input() rowClass = "";
  @Input() modalOptions:any = {}; 

  @Input() editable : boolean = true;
  @Input() touched : boolean = false;
  @Input() automat: any = null;
  @Output() onOpenModal: EventEmitter<any> = new EventEmitter<any>();
  @Output() onClick: EventEmitter<any> = new EventEmitter<any>();

  @Output() onFormChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() onFormChildrensChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() onDependencieChange: EventEmitter<any> = new EventEmitter<any>();

  @Output() onEditRegister: EventEmitter<any> = new EventEmitter<any>();
  @Output() onSectionsemit: EventEmitter<any> = new EventEmitter<any>();

  @Output() onDeleteRegister: EventEmitter<any> = new EventEmitter<any>();

  public flag = true;
  public dependencie = null;
  public DinamicAutoForm: FormGroup
  public DinamicAutomatizationForm: FormGroup;
  public DataFormCopy: any = [];
  public childrenValue: any = [];
  public editConditions: any = {};
  public loopCount: number = 0;
  public ConditionConfig : any;
  constructor(
    public dynamicForm: DyamicFormService,
    public cd: ChangeDetectorRef

  ) { }

  ngOnInit() {  
    this.DataFormCopy =  JSON.parse( JSON.stringify(this.DataForm));    
  }


  ngOnChanges(changes: SimpleChanges): void {    
    this.cd.detectChanges();           
  }
  asignedValuesForm(event: any){
     this.onFormChange.emit(event); 
  }

  asignedChildrenValuesForm(event: any){
    this.childrenValue = event; 
   this.onFormChildrensChange.emit(event);   
 }

  clickEvent(event :any){    
    this.onOpenModal.emit(event)        
  }
  clickEventBtn(event :any){
    this.onClick.emit();
  }
  //-y TABLES
  clickBtnEditRegister(event : any){    
    this.onEditRegister.emit(event)    
  }

  clickBtnDeleteRegister(event : any){    
    this.onDeleteRegister.emit(event)    
  }

  asignedDependencieValuesForm(event : any){    
    
    if (event?.depend?.type == "hide" && !event?.value) {
      this.loopCount = 0;
      event?.actions?.length ? this.DataFormCopy.fields = this.DataFormCopy.fields.filter(prov => {return !event.actions.some(act => {return prov.formControlName == act.formControlNametarget})}) : '';        

    }else if(event?.depend?.type == "hide" && event.value && this.loopCount == 0){
      this.loopCount ++;

      // .Y Se filtran campos de lista principal
      let addComponents = [];
      event?.actions?.length ? addComponents = this.DataForm.fields.filter(prov => {return event.actions.some(act => {return prov.formControlName == act.formControlNametarget})}) : ""; 
      
      // .Y Se filtran los valores a actualizar segun los campos necesarios
      let valueComponents = {form: this.childrenValue.form, event:[]};
      event?.actions?.length ? valueComponents.event = this.childrenValue.event?.filter(val => {return event.actions.some(act => {return val.formControlName == act.formControlNametarget})}) : "";


      // .Y Se hace push al array de los componentes que se necesitan ver
      for (const iterator of addComponents) {
        this.DataFormCopy?.fields.push(iterator);
      }
      
      setTimeout(() => {
        // .W Manda la data a sus respectivos campos.
        this.onFormChildrensChange.emit(valueComponents);   
      }, 100);  
         
    }
      
    this.dependencie = event;
  }

  public DependencieRoot(data:any){    
    this.onSectionsemit.emit(data)    
  }

  public ValidCondition(condition){
      this.editConditions =  {data: condition};      
  }
}


