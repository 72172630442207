import { Routes} from '@angular/router';
import { RoleGuard } from '../services/role-guard.service';
import { UserService } from '../services/user.service';


//Route for content layout without sidebar, navbar and footer for pages like Login, Registration etc...
export const Pages_Routes: Routes = [
    {
        path: '', redirectTo: '/tickets', pathMatch: 'full'
    },
    {
        path: 'home/:id',
        canActivate: [RoleGuard],
        data: { role: 'home'},
        loadChildren: () => import('../../pages/home/home.module').then(m => m.HomeModule)
    },
    {
        path: 'tickets',
        canActivate: [RoleGuard],
        data: { role: 'tickets'},
        loadChildren: () => import('../../pages/tickets/tickets.module').then(m => m.TicketsModule)
    },
    {
        path: 'contracts',
        canActivate: [RoleGuard],
        data: { role: 'contracts'},
        loadChildren: () => import('../../pages/_catalogs/contracts-temp/contracts-temp.module').then(m => m.ContractsTempModule)
    },
    // {
    //     path: 'contracts-temp',
    //     canActivate: [RoleGuard],
    //     data: { role: 'contracts-temp'},
    //     loadChildren: () => import('../../pages/_catalogs/contracts-temp/contracts-temp.module').then(m => m.ContractsTempModule)
    // },
    {
        path: 'clients',
        canActivate: [RoleGuard],
        data: { role: 'clients'},
        loadChildren: () => import('../../pages/_catalogs/clients/clients.module').then(m => m.ClientsModule)
    },
    {
        path: 'memberships',
        canActivate: [RoleGuard],
        data: { role: 'memberships'},
        loadChildren: () => import('../../pages/memberships/memberships.module').then(m => m.MembershipsModule)
    },
    {
        path: 'providers',
        canActivate: [RoleGuard],
        data: { role: 'providers'},
        loadChildren: () => import('../../pages/_catalogs/suppliers/suppliers.module').then(m => m.SuppliersModule)
    },
    {
        path: 'contractors',
        canActivate: [RoleGuard],
        data: { role: 'contractors'},
        loadChildren: () => import('../../pages/_catalogs/contractors/contractors.module').then(m => m.ContractorsModule)
    },
    {
        path: 'departments',
        canActivate: [RoleGuard],
        data: { role: 'departments'},
        loadChildren: () => import('../../pages/_catalogs/departments/departments.module').then(m => m.DepartmentsModule)
    },
    {
        path: 'users',
        canActivate: [RoleGuard],
        data: { role: 'users'},
        loadChildren: () => import('../../pages/users/users.module').then(m => m.UsersModule)
    },
    {
        path: 'roles',
        canActivate: [RoleGuard],
        data: { role: 'roles'},
        loadChildren: () => import('../../pages/roles/roles.module').then(m => m.RolesModule)
    },
    {
        path: 'supports',
        canActivate: [RoleGuard],
        data: { role: 'supports'},
        loadChildren: () => import('../../pages/supports/supports.module').then(m => m.SupportsModule)
    },  
    {
        path: 'quotes',
        canActivate: [RoleGuard],
        data: { role: 'quotes'},
        loadChildren: () => import('../../pages/quotes/quotes.module').then(m => m.QuotesModule)
    },
    {
        path: 'summary',
        canActivate: [RoleGuard],
        data: { role: 'summary'},
        loadChildren: () => import('../../pages/summary/summary.module').then(m => m.SummaryModule)
    },
    {
        path: 'finances',
        canActivate: [RoleGuard],
        data: { role: 'finances'},
        loadChildren: () => import('../../pages/finances/finances.module').then(m => m.FinancesModule)
    },
    {
        path: 'binnacle',
        canActivate: [RoleGuard],
        data: { role: 'binnacle'},
        loadChildren: () => import('../../pages/binnacle/binnacle.module').then(m => m.BinnacleModule)
    },
    {
        path: 'admin-tickets',
        canActivate: [RoleGuard],
        data: { role: 'admin-tickets'},
        loadChildren: () => import('../../pages/admin/admin-tickets/admin-tickets.module').then(m => m.AdminTicketsModule)
    },
    {
        path: 'ticketHistory',
        canActivate: [RoleGuard],
        data: { role: 'ticketHistory'},
        loadChildren: () => import('../../pages/ticketHistory/ticketHistory.module').then(m => m.TicketHistoryModule)
    },
    {
        path: 'quoteHistory',
        canActivate: [RoleGuard],
        data: { role: 'quoteHistory'},
        loadChildren: () => import('../../pages/quote-history/quote-history.module').then(m => m.QuoteHistoryModule)
    },
    {
        path: 'bids',
        canActivate: [RoleGuard],
        data: { role: 'bids'},
        loadChildren: () => import('../../pages/provider-contractor/provider-contractor.module').then(m => m.ProviderContractorModule)
    },
    {
        path: 'properties',
        canActivate: [RoleGuard],
        data: { role: 'properties'},
        loadChildren: () => import('../../pages/_catalogs/properties/properties.module').then(m => m.PropertiesModule)
    },
    {
        path: 'tags',
        canActivate: [RoleGuard],
        data: { role: 'tags'},
        loadChildren: () => import('../../pages/_catalogs/tags/tags.module').then(m => m.TagsModule)
    },
    {
        path: 'inventory',
        canActivate: [RoleGuard],
        data: { role: 'inventory'},
        loadChildren: () => import('../../pages/inventory/inventory/inventory.module').then(m => m.InventoryModule)
    },
    {
        path: 'inventory/locations',
        canActivate: [RoleGuard],
        data: { role: 'inventory'},
        loadChildren: () => import('../../pages/inventory/locations/locations.module').then(m => m.LocationsModule)
    },
    {
        path: 'inventory/categories',
        canActivate: [RoleGuard],
        data: { role: 'inventory'},
        loadChildren: () => import('../../pages/inventory/categories/categories.module').then(m => m.CategoriesModule)
    },
    {
        path: 'inventory/articles',
        canActivate: [RoleGuard],
        data: { role: 'inventory'},
        loadChildren: () => import('../../pages/inventory/articles/articles.module').then(m => m.ArticlesModule)
    },
    {
        path: 'inventory/assignments',
        canActivate: [RoleGuard],
        data: { role: 'inventory'},
        loadChildren: () => import('../../pages/inventory/assignments/assignments.module').then(m => m.AssignmentsModule)
    },
    {
        path: 'inventory/responsible',
        canActivate: [RoleGuard],
        data: { role: 'inventory'},
        loadChildren: () => import('../../pages/inventory/responsible/responsible.module').then(m => m.ResponsibleModule)
    },
    {
        path: 'reports',
        canActivate: [RoleGuard],
        data: { role: 'reports'},
        loadChildren: () => import('../../pages/_catalogs/reports/reports.module').then(m => m.ReportsModule)
    }
]