import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ValidateFormsDirective } from 'src/app/directives/validate-forms.directive';
import { DynamicControlsService } from 'src/app/shared/services/dynamic-controls.service';
import { DyamicFormService } from 'src/app/shared/services/dynamic-form.service';

@Component({
  selector: 'dnc-map',
  templateUrl: './dnc-map.component.html',
  styleUrls: ['./dnc-map.component.scss', '../../dynForm.comp.scss'],

  host: {
    "[style.order]": "check.position",
    "[class]": "'col-sm-12 col-md-' + (check.col_size || 12)"
  }
})
export class DncMapComponent implements OnInit {

  public DinamicForm: FormGroup

  @Input() check: any;
  @Input() editForm: any;
  @Input() editable : boolean = true;
  @Input() touched : boolean = false;
  @Output() onChange: EventEmitter<any> = new EventEmitter<any>();

  public mapPos = { lat: 32.51931488772683, lng: -117.0124009307757, zoom: 13 };
  public markerPos = { lat: 32.51931488772683, lng: -117.0124009307757, zoom: 13 };
  public centerBounds: any;

  public mapStyle: any = [
    {
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#f5f5f5"
        }
      ]
    },
    {
      "elementType": "labels.icon",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#616161"
        }
      ]
    },
    {
      "elementType": "labels.text.stroke",
      "stylers": [
        {
          "color": "#f5f5f5"
        }
      ]
    },
    {
      "featureType": "administrative.land_parcel",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#bdbdbd"
        }
      ]
    },
    {
      "featureType": "poi",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#eeeeee"
        }
      ]
    },
    {
      "featureType": "poi",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#757575"
        }
      ]
    },
    {
      "featureType": "poi.park",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#e5e5e5"
        }
      ]
    },
    {
      "featureType": "poi.park",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#9e9e9e"
        }
      ]
    },
    {
      "featureType": "road",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#ffffff"
        }
      ]
    },
    {
      "featureType": "road.arterial",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#757575"
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#dadada"
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#616161"
        }
      ]
    },
    {
      "featureType": "road.local",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#9e9e9e"
        }
      ]
    },
    {
      "featureType": "transit.line",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#e5e5e5"
        }
      ]
    },
    {
      "featureType": "transit.station",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#eeeeee"
        }
      ]
    },
    {
      "featureType": "water",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#c9c9c9"
        }
      ]
    },
    {
      "featureType": "water",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#9e9e9e"
        }
      ]
    }
  ];

  constructor(
    public fieldisValid: ValidateFormsDirective,
    private fb: FormBuilder,
    private dynamicForm: DyamicFormService,
    private dynamicControls: DynamicControlsService,
    public sanitizer: DomSanitizer
  ) { }

  ngOnChanges(change: SimpleChanges) {
    if (!change.editForm?.firstChange) { // Reasigna los valores dependiendo del elemento a renderizar
      if (this.check?.form == change.editForm?.currentValue?.name) {
        //? Unico componente hasta ahora que se mapea de forma estatica.
        // this.DinamicForm = this.dynamicForm.ReMapEditForm(change.editForm.currentValue?.form?.value?.address, this.DinamicForm);
       let lat = change?.editForm?.currentValue?.form?.value?.address?.lat;
       let lng = change?.editForm?.currentValue?.form?.value?.address?.lng;

        // this.DinamicForm.patchValue({
        //   address: change?.editForm?.currentValue?.form?.value?.address?.address,
        //   lat: lat,
        //   lng: lng
        // });
       
      

        this.mapPos = {lat: lat, lng:lng, zoom:15}
        if (change?.editForm?.currentValue?.form?.value?.address?.address) {
          this.SetDirecction(lat, lng);
        }
      }
      
    }

    if (change.touched?.currentValue) {
      this.DinamicForm.markAllAsTouched();
    }
  }

  async ngOnInit() {
    this.DinamicForm = this.fb.group({
      address: [null, Validators.compose([Validators.required, Validators.maxLength(500)])],
      lat: [ this.markerPos.lat, Validators.compose([Validators.required])],
      lng: [ this.markerPos.lng, Validators.compose([Validators.required])],
    });

    // this.SetDirecction(this.mapPos.lat, this.mapPos.lng);

    // let Http = new XMLHttpRequest();
    // let url = 'https://maps.googleapis.com/maps/api/geocode/json?latlng=' + this.markerPos.lat + ',' + this.markerPos.lng +
    //   '&key=AIzaSyB7AZ8E0BsiK2X10ituHHsFjSBLPUNz1Qk';
    //   await Http.open('POST', url);
    //   await Http.send();
    //   Http.onreadystatechange = async (e) => {
    //     let tJson = Http.responseText || '{}';

    //     this.DinamicForm.patchValue({
    //       address: JSON.parse(tJson)?.results?.[0]?.formatted_address || '',
    //       lat: this.markerPos.lat,
    //       lng: this.markerPos.lng
    //     });

     
    // }

    this.dynamicEmit();
    this.DinamicForm = await this.dynamicControls.asignControls(this.check, this.DinamicForm);

  }

  public dynamicEmit() {        
    this.onChange.emit({ event:this.DinamicForm , form: this.check?.form, type: this.check?.type});
  }

  public validateRequired(): boolean {
    let required: boolean = this.check?.target?.validators?.required || false;
    return required;
  }

  public handleAddressChange(event) {   

    // Redirect to address selected
    this.markerPos.lat = event.geometry.location.lat();
    this.markerPos.lng = event.geometry.location.lng();

    let northEast = new google.maps.LatLng(this.markerPos.lat + 0.001, this.markerPos.lng + 0.001);
    let southWest = new google.maps.LatLng(this.markerPos.lat - 0.001, this.markerPos.lng - 0.001);

    this.centerBounds = new google.maps.LatLngBounds(southWest, northEast);

    this.DinamicForm.patchValue({
          address: event.formatted_address,
          lat: event.geometry.location.lat(),
          lng: event.geometry.location.lng()
    });

    this.dynamicEmit();
  }

  // public async clickMap(event:any){
  //   let lat = event.coords.lat;
  //   let lng = event.coords.lng;

  //   await this.SetDirecction(lat,lng);

  //   setTimeout(() => {
  //     this.dynamicEmit();
  //   }, 200);

  // }

  public async SetDirecction(lat: number, lng: number) {
    try {
      let Http = new XMLHttpRequest();
      let url = 'https://maps.googleapis.com/maps/api/geocode/json?latlng=' + lat + ',' + lng +
        '&key=AIzaSyB7AZ8E0BsiK2X10ituHHsFjSBLPUNz1Qk';
      await Http.open('POST', url);
      await Http.send();
       Http.onreadystatechange = (e) => {
        let tJson = Http.responseText || '{}';

          this.DinamicForm.patchValue({
            address: JSON.parse(tJson)?.results?.[0]?.formatted_address || '',
            lat: lat,
            lng: lng
          });
       
          this.dynamicEmit();
      }

      // Set map position
      this.markerPos.lat = lat;
      this.markerPos.lng = lng;

    } catch (error) {
      throw error;
    }
  }

  public async markerDragEnd(event) {
    
    this.markerPos.lat = event.coords.lat;
    this.markerPos.lng = event.coords.lng;

    let Http = new XMLHttpRequest();
    let url = 'https://maps.googleapis.com/maps/api/geocode/json?latlng=' + this.markerPos.lat + ',' + this.markerPos.lng +
      '&key=AIzaSyB7AZ8E0BsiK2X10ituHHsFjSBLPUNz1Qk';
    await Http.open('POST', url);
    Http.send();
    Http.onreadystatechange = (e) => {
      let tJson = Http.responseText || '{}';
      this.DinamicForm.patchValue({
        address: JSON.parse(tJson)?.results?.[0].formatted_address || '',
        lat: this.markerPos.lat,
        lng: this.markerPos.lng
      });

      let northEast = new google.maps.LatLng(this.markerPos.lat + 0.001, this.markerPos.lng + 0.001);
      let southWest = new google.maps.LatLng(this.markerPos.lat - 0.001, this.markerPos.lng - 0.001);

      this.centerBounds = new google.maps.LatLngBounds(southWest, northEast);

      setTimeout(() => {
        this.dynamicEmit();
     }, 200);
    }
    

  }


}
