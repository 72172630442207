import { Directive } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Directive({
  selector: '[ValidateForms]'
})
export class ValidateFormsDirective {

  constructor() { }

  campoEsValido( campo: any, formRules: FormGroup, isGroup = false, group = ""){
    return isGroup?
      formRules.get(group)['controls'][campo].errors && formRules.get(group)['controls'][campo].touched:
      formRules.controls[ campo ].errors && formRules.controls[ campo ].touched;
  }

}
