<div class="center" *ngIf="options?.type != 'AUDIO'">
    <div class="_block d-flex justify-content-center " [class.fill]="value" (click)="value ? openImg(modal, $event) : {}">
        <span class="icon"><i class="fa fa-file-image"></i></span>
        <!-- <input class="text-center" type="number" [value]="value" placeholder="00.00"> -->
        <span>{{ type === "number" ? value : (length || 'Sin')}} imgs.</span>

    </div>
</div>
<div class="center" *ngIf="options?.type == 'AUDIO'">
    <audio controls *ngIf="value">
        <source [src]="url+value" type="audio/mpeg">
    </audio>
    <div class="_block d-flex justify-content-center" *ngIf="!value">
        <span class="icon"><i class="fa fa-music"></i></span>
        <span>Sin audio</span>
    </div>
</div>


<ng-template #modal>
<div class="mkt_dropdown">
    <div class="head">{{images.length > 1 ? "Imagenes" : "Imagen"}} 
        <button class="close" type="button"><i class="mdi mdi-close"></i></button>
    </div>
    <div class="content p-0 mktd-block">
        <!-- <img [src]="url+item[target.split('s')[0]]" alt=""> -->
            <carousel
                [images]="images"
                [margin]="10"
                [borderRadius]="5"
                [dots]="true"
                transitionTimingFunction="linear"
                [counter]="true"
                counterSeparator="/"
                class="mktd-block"
                cellWidth="100%"
                [arrows]="true">
            </carousel>

            <!-- <carousel
            [dots]="true"
            [counter]="true"
            counterSeparator="/"
            [lightDOM]="true"
            [borderRadius]="5"
            >
                <div *ngFor="let item of images" class="carousel-cell">
                    <img class="mktd-block" [src]="item.path">
                </div>
            </carousel> -->
            
    </div>
 
</div>
</ng-template>