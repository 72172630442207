import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'dnc-blank',
  templateUrl: './dnc-blank.component.html',
  styleUrls: ['./dnc-blank.component.scss', '../../dynForm.comp.scss'],
  host:{
    "[style.order]": "check.position",
    "[class]": "'col-sm-12 col-md-' + (check.col_size || 4)"
  }
})
export class DncBlankComponent implements OnInit {

  @Input() check: any;
  
  constructor() { }

  ngOnInit(): void {
  }

}
