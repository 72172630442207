import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ValidateFormsDirective } from 'src/app/directives/validate-forms.directive';
import { DecryptPipe } from 'src/app/shared/pipes/decrypt.pipe';
import { CalculatorService } from 'src/app/shared/services/calculator.service';
import { DynamicControlsService } from 'src/app/shared/services/dynamic-controls.service';
import { DyamicFormService } from 'src/app/shared/services/dynamic-form.service';
import { ValidatorsService } from 'src/app/shared/services/validators.service';

@Component({
  selector: 'dnc-sum-number',
  templateUrl: './dnc-sum-number.component.html',
  styleUrls: ['./dnc-sum-number.component.scss'],
  host:{
    "[style.order]": "check.position",
    "[class]": "'col-sm-12 col-md-' + (check.col_size || 4)"
  }
})
export class DncSumNumberComponent implements OnInit {

  @Output() onChangeChindren: EventEmitter<any> = new EventEmitter<any>();
  @Output() onChange: EventEmitter<any> = new EventEmitter<any>();
  @Input() check: any;
  @Input() editForm;
  @Input() touched : boolean = false;
  @Input() editable : boolean = true;

  public Total = 0;


  public DinamicForm: FormGroup
  constructor(
    public fieldisValid: ValidateFormsDirective,
    private fb: FormBuilder,    
    private dynamicControls: DynamicControlsService,
    private dynamicForm: DyamicFormService,
    public validatorsService: ValidatorsService,
    public decrypt: DecryptPipe,
    private calculatorServices: CalculatorService,
    public sanitizer: DomSanitizer
  ) { }

  async ngOnInit() {
    this.DinamicForm = this.fb.group({
      [this.check.formControlName]:[null]
    });

    // this.DinamicForm = await this.dynamicControls.asignControls(this.check,  this.DinamicForm);

    //Establece los valores de inicio del formulario
    //  this.dynamicEmit(this.DinamicForm);
  }

  async ngOnChanges(change: SimpleChanges){

    if (!change.editForm?.firstChange) { // Reasigna los valores dependiendo del elemento a renderizar
        this.DinamicForm = this.dynamicForm.ReMapEditForm(change.editForm?.currentValue, this.DinamicForm);    
        // console.log("cambio",change.editForm.currentValue);

      //   console.log(" this.check", this.check);
        

      // let total =  await this.calculatorServices.Sum(change?.editForm?.currentValue?.value, this.check?.childrens);
      // console.log("total",total);
      
    }

    if (change.touched?.currentValue) {
      this.DinamicForm.markAllAsTouched();
    }
  }

  public validateRequired(): boolean{
    let required: boolean = this.check?.target?.validators?.required || false;
    return required;
  }


  // dynamicEmit(event){    
  //   this.validatorsService.isInteger(event);
    
  //   this.onChange.emit(this.DinamicForm); 
  // }

}
