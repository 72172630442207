<!-- Page -->
<div class="page main-signin-wrapper bg-primary construction">

    <div class="container ">
        <div class="construction1 text-center details text-white">
            <div class="row align-items-center">
                <div class="col-lg-12">
                    <!-- <h1 class="tx-140 mb-0">404</h1> -->
                    <i class="fa fa-lock-keyhole"></i>
                </div>
                <div class="col-lg-12 ">
                    <h1>Lo sentimos, no es posible acceder a algun modulo</h1>
                    <span class="d-block w-100 tx-15 mt-3 mb-4 text-white-50">Parece que este usuario no cuenta con ningun permiso, contacta con el administrador para mas informacion</span>
                    
                    <a class="btn ripple btn-success text-center" href="/login">Ir a Login</a>
                </div>
            </div>
        </div>
    </div>
  
  </div>
  <!-- End Page -->