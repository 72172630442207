<form [formGroup]="DinamicForm" >
      <h6><span [innerHtml]="sanitizer.bypassSecurityTrustHtml(check?.label)"></span> <span class="text-danger" *ngIf="validateRequired() && editable ">*</span></h6>
      <div class="input-group mb-0">
        <div class="input-group-prepend" *ngIf="check?.options?.prefix"><div class="input-group-text"><span class="lh--9 op-6">{{check?.options.prefix}}</span></div></div>
        <ng-select class="form-control" #type [placeholder]="check.placeholder" [formControlName]="check.formControlName"
          (change)="dynamicEmit()" [clearable]="false"
          [readonly]="check?.target?.readonly || !editable"  
          [ngClass]="(check?.target?.readonly || !editable) ? 'disabled' : ''"  
          [placeholder]="check?.target?.placeholder"   
          >
          <ng-option *ngFor="let item of check?.target?.options" [value]="item.value">{{item?.label}}</ng-option>
        </ng-select>
      </div>
      <small class="text-danger" *ngIf="false">{{check?.validateMessage}}</small>
</form>