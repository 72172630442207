<!-- Sidebar -->
<app-overlay [class.show]="isOpen" (click)="onClose()"></app-overlay>
<div #sidebar class="sidebar sidebar-right sidebar-animate" >
    <div class="sidebar-icon __zoom">
        <a href="javascript:void(0)" class="text-left float-left text-dark fs-20" (click)="onClose()">
            <i class="fa fa-chevron-left fa-2x"></i>
        </a>
    </div>
    <div class="sidebar-body">
        <div class="scroll __zoom">
            <!-- <h5>Todo</h5> -->
            <ng-container [ngTemplateOutlet]="template"></ng-container>
        </div>
    </div>
</div>
<!-- End Sidebar -->