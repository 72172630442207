import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { ValidateFormsDirective } from 'src/app/directives/validate-forms.directive';
import { DynamicControlsService } from 'src/app/shared/services/dynamic-controls.service';
import { DyamicFormService } from 'src/app/shared/services/dynamic-form.service';


@Component({
  selector: 'dnc-editor',
  templateUrl: './dnc-editor.component.html',
  styleUrls: ['./dnc-editor.component.scss']
})
export class DncEditorComponent implements OnInit {

  @Input() check: any;
  @Input() editForm: any;

  @Output() onChange: EventEmitter<any> = new EventEmitter<any>();

  public DinamicForm: FormGroup
  public config: AngularEditorConfig = {
    sanitize: false,
    editable: true,
    spellcheck: true,
    height: '328px',
    minHeight: '4rem',
    placeholder: '',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [
      [
        // 'undo',
        // 'redo',
        // 'bold',
        // 'italic',
        // 'underline',
        // 'strikeThrough',
        'subscript',
        'superscript',
        // 'justifyLeft',
        // 'justifyCenter',
        // 'justifyRight',
        // 'justifyFull',
        'indent',
        'outdent',
        'insertUnorderedList',
        'insertOrderedList',
        // 'heading',
        'textColor',

        // 'fontName'
      ],
      [
        // 'fontSize',
        // 'textColor',
        'backgroundColor',
        'customClasses',
        'link',
        'unlink',
        'insertImage',
        'insertVideo',
        'insertHorizontalRule',
        'removeFormat',
        'toggleEditorMode'
      ]
    ],
  };

  constructor(

    private fb: FormBuilder,
    private dynamicControls: DynamicControlsService,
    public fieldisValid: ValidateFormsDirective,
    private dynamicForm: DyamicFormService,
    public sanitizer: DomSanitizer

  ) { }

  ngOnChanges(change: SimpleChanges){
    if (!change.editForm.firstChange) { // Reasigna los valores dependiendo del elemento a renderizar
        this.DinamicForm = this.dynamicForm.ReMapEditForm(change.editForm.currentValue, this.DinamicForm);
    }
  }

  async ngOnInit() {    

    this.DinamicForm = this.fb.group({
      [this.check.formControlName]:[null]
    });

    this.DinamicForm = await this.dynamicControls.asignControls(this.check,  this.DinamicForm);

    //Establece los valores de inicio del formulario
     this.dynamicEmit();
  }

  dynamicEmit(){
    this.onChange.emit(this.DinamicForm);     
  }

}
