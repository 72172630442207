import { TitleCasePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { EVENT_MANAGER_PLUGINS } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from 'src/app/shared/services/alert.service';
import { HttpService } from 'src/app/shared/services/http.service';
import { UserService } from 'src/app/shared/services/user.service';

@Component({
  selector: 'tab-participants',
  templateUrl: './tab-participants.component.html',
  styleUrls: ['./tab-participants.component.scss']
})
export class TabParticipantsComponent implements OnInit {

  @ViewChild('Sort') sort: MatSort;
  @Input() id: string = null;
  @Input() infoTab: boolean = true;
  @Input() tabIndex;
  @Input() status;
  @Output() onClose: EventEmitter<any> = new EventEmitter<any>();
  @Output() participants: EventEmitter<any> = new EventEmitter<any>();

  close = ()=>{this.onClose.emit()}
  
  public isLoading: boolean = false;
  public membersData: any = [];
  public messageData: string;
  public usersData: any = [];
  public membersFilter: any = [];
  public usersFilter: any = [];
  public selectedCategory: any = {value:"ALL", label:"Todos"};
  public selectedMember: any = null;
  public selectedUser:any = null;
  public disabledbtn:boolean = true;
  public validUser:boolean = true;
  public selectlabel: string = "Selecciona " + this.titlecase.transform((this.userService.modulesName?.provider || 'Proveedor'));
  public selectedTag = null;
  public typeMembers: any = [
                             {value:"ALL", label:"Todos"},
                             {value:"CONTRACTOR",label:this.titlecase.transform((this.userService.modulesName?.contractors || 'Contratistas'))},
                             {value:"PROVIDER",label:this.titlecase.transform((this.userService.modulesName?.providers || 'Proveedores'))}
                            ];
  public tagsArray: any = [];

  public DT_members: MatTableDataSource<any>;
  public displayColumns: string[] ;

  constructor(
    private httpServices: HttpService,
    private alertServices: AlertService,
    public userService: UserService,
    public titlecase: TitleCasePipe,
    private modal: NgbModal
  ) { 
    this.DT_members = new MatTableDataSource;
  }

  public async ngOnInit() {
    
    this.displayColumns = this.infoTab ? ['name','provider', 'email', 'phone','tags', 'status', 'action'] : ['name','provider' ,'email', 'phone','tags', 'action'];
    // await this.changeCatalog('provider');
    await this.getTags();
    await this.getCategory()
    await this.loadMembersList();  
      
  }

  private async loadMembersList(){
    try {
      if (this.id) {
        this.isLoading = true;
        let data = await this.httpServices.get(`api/bidding/users/${this.id}`).toPromise();

        data.forEach(element => {
          element?.requestData ? element.status = element?.requestData.status : element.status = 'Pendiente';
          element.provider = element?.providerData?.name;
          element.tags = element?.providerData?.tagsData;
        });

        this.membersData = this.membersData.filter(prov => {return !data.some(item => {return prov._id == item.providerData._id})});        
        
        this.DT_members.data = data;
        this.DT_members.sort = this.sort;
        this.participants.emit(this.DT_members?.data) 
      }
 
    } catch (error) {
      this.isLoading = false;
      console.log(error);
    }finally{
      this.isLoading = false;
    }
  }

  public async deleteMember(item:any){
    this.DT_members.data = this.DT_members.data.filter(member => {return member?._id != item?._id});
    // await this.changeCatalog('provider');
    await this.getCategory();
    this.disabledbtn = false;
    this.validUser = true;
    this.selectedUser = null;
    this.reloadSelect();
    this.validStatus();
  }

  public async addMember(){

    try {
      this.DT_members.data.push({
        _id: this.selectedUser?._id, 
        name: this.selectedUser?.name,
        email: this.selectedUser?.email, 
        phone: this.selectedUser?.phone, 
        provider:this.selectedUser?.providerData?.name,
        status: "PENDING", 
        tags:this.selectedUser?.providerData?.tagsData
      });

      this.DT_members.data = this.DT_members.data;
      this.membersData = this.membersData.filter(prov => {return prov._id != this.selectedUser?.providerData?._id});

      this.validUser = true;
      this.disabledbtn = false;
      this.selectedUser = null;
      this.usersFilter = [];
      this.selectedMember = null;
      this.participants.emit(this.DT_members?.data);
    
      this.reloadSelect();
      this.validStatus();
      
    } catch (error) {
      console.log(error);
    }
    
  }

  public async enableProvider(event){

    const formData = new FormData();    

    try {

      formData.append('providerId', event?.requestData?.provider);
      formData.append('biddingId', event?.requestData?.biddingId);

      const data = await this.httpServices.put(`api/bidding/active/provider`, formData).toPromise();
      this.alertServices.successToast(data?.message);

      this.loadMembersList();

    } catch (error) {
      console.log(error); 
    }
  }

  public async submit(id?){

     return new Promise<any>((resolve, reject) => {
      id ? this.id = id : '';
      try {
        this.isLoading = true;
        let arrayMembers = [];
        for (const member of this.DT_members.data) { arrayMembers.push(member._id); };

        let obj= {
          id: this.id,
          participants: arrayMembers
        }

        this.httpServices.put(`api/bidding/competitors`,obj).subscribe(response=>{
          this.tabIndex == 1 ? this.alertServices.successToast(response?.message) : "";
          this.isLoading = false;
          resolve(arrayMembers.length || 0);
        },error => {
          if (error.status != 403) {
            this.alertServices.errorToast(error.error.message);
          }
          this.isLoading = false;
          resolve(0);
        });
      } catch (error) {
        this.isLoading = false;
        console.log(error);
        resolve(0);
      }
     })
  }

  private reloadSelect():void{
    this.usersFilter = this.usersData.filter(member => {return !this.DT_members.data?.find(participant => {return participant._id == member._id}) });
  }

  public validDisable():boolean{
    let valid;

    return valid;
  }

  public validStatus(){
    if (this.status != "CREATED" && this.status != "STARTED") 
    {
      this.disabledbtn = true;
    }else{
      this.disabledbtn = false;

    }
  }

  public async getUsers(event:any){

    try {
      const data = await this.httpServices.get(`api/user/list/provider/${event._id}`).toPromise();
      this.validUser = false;
      this.usersData = data;
      this.reloadSelect();
      
    } catch (error) {
      console.log(error);
    }
    
  }

  public async getTags(){
    try {
      const data = await this.httpServices.get(`api/catalogue/tags`).toPromise();
      this.tagsArray = data;
      
    } catch (error) {
      console.log(error);
    }
    
  }

  public async getCategory(){ 
    try {
      this.selectedCategory?.value == "ALL" ? this.selectlabel = this.titlecase.transform((this.userService.modulesName?.contractor || 'Contratista')) + "/" + this.titlecase.transform((this.userService.modulesName?.provider || 'Proveedor'))
        : this.selectedCategory?.value == "CONTRACTOR" ? this.selectlabel = "Selecciona " + this.titlecase.transform((this.userService.modulesName?.contractor || 'Contratista')) 
      : this.selectedCategory?.value == "PROVIDER" ?  this.selectlabel = "Selecciona " + this.titlecase.transform((this.userService.modulesName?.provider || 'Proveedor')) : "";

      
      //? convierte a minuscula todos los tags
      let params = {type: this.selectedCategory?.value,tags: this.selectedTag}

      this.membersData = await this.httpServices.post(`api/catalogue/providers/contractors`, params).toPromise();
     
        this.membersData = this.membersData.filter(prov => {return !this.DT_members.data.some(item => {return prov?._id == item?.providerData?._id})});
      this.selectedMember = null;
    } catch (error) {
      console.log(error);
    }
    
  }

  openModal(template: any, message) {
    this.messageData = message
    this.modal.open(template,{size:"sm"});
  }
  
}
