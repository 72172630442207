import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'mkt-rating',
  templateUrl: './mkt-rating.component.html',
  styleUrls: ['./mkt-rating.component.scss', '../../mk-table.comp.scss', '../../mk-table.comp.input.scss'],
  host:{
    "class" : 'mkt_col col-rating',
    "[class.has-change]": "_hasChange"
  }
})
export class MktRatingComponent implements OnInit {

  @Input() value: string;
  @Output() valueChange: EventEmitter<number> = new EventEmitter<number>();
  @Input() readonly: boolean;
  
  private _hasChange: boolean = false;
  constructor() { }

  ngOnInit(): void {
  }

  //ngOnChanges(): void{this._hasChange = true; setTimeout(() => {this._hasChange = false}, 2000);}
  public emitValue(ev: any){this.valueChange.emit(ev)}
}
