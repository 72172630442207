<div>
    <div class="d-flex w-100 justify-content-end" *ngIf="hasPrivate">
        <span *ngIf="!filesmanager" class="text-{{file?.type == 'PRIVATE' ? 'danger' : 'muted'}}"><i class="fa fa-{{file?.type == 'PRIVATE' ? 'lock' : 'lock-open'}} op-7 mr-1"></i> Archivo {{file?.type == 'PRIVATE' ? 'privado' : 'público'}}</span>
    </div>
    <b [class.mt-0]="forceName" [class.mt-0]="filesmanager">{{forceName || file?.title}}</b>
    <b *ngIf="filesmanager" [class.mt-0]="forceName">{{file?.nameOriginal ? file?.nameOriginal : file?.name}}</b>

</div>
<div class="d-flex w-100 justify-content-between">
    <span *ngIf="!filesmanager" class="text-primary text-overflow" [title]="file?.name"><i class="{{getFileExtension(file?.name)}}"></i> {{file?.name?.split('.').pop() | uppercase}}</span>
    
        <!-- 1 - Se usa cuando ya esta guardado el archivo -->
        <span *ngIf="filesmanager && !file?.file?.lastModified" class="text-primary text-overflow" [title]="file?.file?.name"><i class="{{file?.file?.name ? getFileExtension(file?.file?.name) : getFileExtension(file?.name) }}"></i> {{file?.nameOriginal ? (file?.nameOriginal.split('.').pop() | uppercase) : (file?.file?.name.split('.').pop() | uppercase)}}</span>

        <!-- se complementa con el 1 para mostrar la extencion del archivo que se agregara -->
        <span style="margin-left: -1rem;" *ngIf="filesmanager && file?.lastModified && filesBill" class="text-primary text-overflow" [title]="file?.name">{{file?.name.split('.').pop() | uppercase}}</span>
        <span style="margin-left: -5.5rem;" *ngIf="filesmanager && file?.lastModified && !filesBill" class="text-primary text-overflow" [title]="file?.name">{{file?.name.split('.').pop() | uppercase}}</span>
    
    <!-- 2 - Se usa cuando el archivo solo se agrega pero todavia no se guarda -->
    <span *ngIf="filesmanager && file?.file?.lastModified" class="text-primary text-overflow" [title]="file?.file?.name"><i class="{{getFileExtension(file?.file?.name)}}"></i> {{file?.file?.name.split('.').pop() | uppercase}}</span>


    <div class="d-flex">
        <!-- <span *ngIf="!blocked && !filesmanager" class="mr-2"><a class="text-info" (click)="editFile(file)"><i class="fa fa-edit"></i></a></span> -->
    
        <span *ngIf="!blocked && !filesmanager"><a class="text-danger"[swal]="deleteFileSwal" (confirm)='deleteFile(file)'><i class="fa fa-trash"></i></a></span>
        <span *ngIf="!blocked && filesmanager && !deletebtn"><a class="text-danger"[swal]="deleteFileSwal" (confirm)='spliceFile($event)'><i class="fa fa-trash"></i></a></span>
    </div>

</div>

<!-- SWAL -->
<swal #deleteFileSwal class="d-none" title="¿Estas seguro de eliminar el archivo?" icon="question" [showCancelButton]="true" [focusCancel]="true" [swalOptions]="{confirmButtonText: 'Aceptar', cancelButtonText: 'Cancelar', confirmButtonColor: '#fd3666'}">
</swal>
