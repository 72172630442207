<form [formGroup]="DinamicForm">
        <dnc-title [check]="check" [editable]="editable"></dnc-title>
        <div class="input-group date">
          <div class="input-group-prepend">
            <div class="input-group-text">
              <i class="fe fe-calendar lh--9 op-6"></i>
            </div>
          </div>
          <input type="text" 
            ngxDaterangepickerMd 
            [ngModelOptions]="{standalone: true}"
            [timePicker]="false" 
            [timePickerIncrement]="5" 
            [timePicker24Hour]="false" 
            [locale]="dateOptions" 
            [singleDatePicker]="check?.singleDatePicker" 
            [(ngModel)]="selectedDate" 
            [autoApply]="true" 
            [showDropdowns]="check?.showDropdowns"
            (change)="dynamicEmit()"
            [closeOnAutoApply]="false"
            name="date" 
            class="form-control" 
            [class.no-corner]="!check?.target?.validators?.required && selectedDate != ''"
            [class.last]="check?.singleDatePicker && !(!check?.target?.validators?.required && selectedDate != '')"
            [placeholder]="check?.target?.placeholder" 
            autocomplete="off" 
            readonly  
            tabindex="-1"
            [disabled]="!editable || check?.target?.readonly"
            />

          <ng-container *ngIf="!(check?.target?.readonly || !editable)">
            <div class="input-group-append" *ngIf="!check?.target?.validators?.required && selectedDate != ''">
              <button class="btn btn-dark border-left-0 px-2" type="button" (click)="onFilterFormClear()"><i class="fa fa-refresh"></i></button>
            </div> 
          </ng-container>
       
        </div>
        <small class="tx-danger" *ngIf="fieldisValid.campoEsValido(this.check.formControlName, DinamicForm) && editable">{{check.validateMessage}}</small>

</form>
