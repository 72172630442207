import {Pipe, PipeTransform} from '@angular/core'

@Pipe({
    name: 'binnacle'
})

export class binnaclePipe implements PipeTransform {

    public lenguages = {
        MOBILE: "Movil",
        WEB: "Web",
        SELECT: "Consulta",
        UPDATE: "Actualización",
        INSERT: "Creado",
        CREATE: "Creación",
        CANCEL: "Cancelado",
        FINISH: "Completado",
        REJECT : "Rechazado",
        DELETE : "Eliminado",

    }
    constructor() {}

    transform(value: string, locale: string = "es"): string {
        return value? this.lenguages[value.toUpperCase()] || value :"" ;
    }

}

interface ILenguege{
    en?: any,
    es?: any,
}

