<div class="center" (click)="!readonly ? SelectStatus(type, $event) : {}">
  <span class="_block text-overflow _input cursor-pointer"  placement="top" 
  [ngbTooltip]="dinamicOptions?((value | selects: options?.collection) | decrypt ):(value | status)" tooltipClass="tooltip-small" 
  [style.background-color]="statusColor(value)">
    {{dinamicOptions?((value | selects: options?.collection) | decrypt ):(value | status) | titlecase}}
  </span>
</div>

<div class="center-sm" [class.disabled]="disable || !value" (click)="(readonly || disable) ?'': SelectValue(menu, $event)">
  <div class="cursor-pointer text-overflow text-left _input __pad"placement="top" [ngbTooltip]="(valueSelect?valueSelect:'Seleccionar') | selects: collection" tooltipClass="tooltip-small">
    <span class="text-overflow">{{(valueSelect?valueSelect:'Seleccionar') | selects: collection}}</span>
  </div>
  <i class="fa fa-caret-down"></i>
</div>

<ng-template #type>
  <div class="mkt_dropdown mkt_col col-text">
      <div class="head">Seleccionar</div>
      <div class="content p-0">
        <div class="_browser"><i class="fa fa-search"></i> <input type="text" class="_block mktd-block" placeholder="Buscar" (keyup)="filterValue(inputsearchValue.value)" #inputsearchValue/></div>
          <div class="pt-2">
            <ng-container *ngFor="let status of filteringValue">
                <div *ngIf="!status.hidden" (click)="emitValue(status);setFocus(false)" [style.background-color]="status?.color || '#797e93'" class="_block text-overflow _input cursor-pointer mb-2 ">{{((status?.name | status) | decrypt)| titlecase}}</div>
            </ng-container>
            <ng-container *ngIf="!filteringValue.length">
              <div class="__list-item text-muted">Sin Registros</div>
            </ng-container>
          </div>
      </div>
  </div>
</ng-template>

<ng-template #menu>
  <div class="mkt_dropdown mkt_col col-text">
      <div class="head">Seleccionar</div>
      <div class="content p-0">
          <div class="_browser"><i class="fa fa-search"></i> <input type="text" class="_block mktd-block" placeholder="Buscar" (keyup)="filter(inputsearch.value)" #inputsearch/></div>
          <div class="p-0">
            <ng-container *ngFor="let option of filtering">
              <div (click)="emitValueSelect(option._id); setFocus(false)" class="__list-item" [class.disabled]="option?.status == 'INACTIVE'">{{option?.name | titlecase}}</div>
          </ng-container>
          <ng-container *ngIf="!filtering.length">
            <div class="__list-item text-muted">Sin Registros</div>
          </ng-container>
          </div>
      </div>
  </div>
</ng-template>