import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ValidateFormsDirective } from 'src/app/directives/validate-forms.directive';
import { DynamicControlsService } from 'src/app/shared/services/dynamic-controls.service';
import { DyamicFormService } from 'src/app/shared/services/dynamic-form.service';


@Component({
  selector: 'dnc-select-date',
  templateUrl: './dnc-select-date.component.html',
  styleUrls: ['./dnc-select-date.component.scss', '../../dynForm.comp.scss'],
  host:{
    "[style.order]": "check.position",
    "[class]": "'col-sm-12 col-md-' + (check.col_size || 4)"
  }
})
export class DncSelectDateComponent implements OnInit {

  @Output() onChange: EventEmitter<any> = new EventEmitter<any>();

  @Input() check: any;
  @Input() editForm: any;
  @Input() editable : boolean = true;
  @Input() touched : boolean = false;
  @Input() editConditions: any = [];


  public monthSelected = {value: '1', label: 'Enero', days: 31};
  public daySelected = {value: '1', label: '1'};
  public disableSelect: boolean = true;
  public DinamicForm: FormGroup;
  public fieldisValid: ValidateFormsDirective;

  public days = [];

  constructor(
    private fb: FormBuilder,
    public dynamicControls: DynamicControlsService,
    private dynamicForm: DyamicFormService,
    public sanitizer: DomSanitizer
  ) { }

  async ngOnChanges(change: SimpleChanges){
    if (!change.editForm?.firstChange) { // Reasigna los valores dependiendo del elemento a renderizar

    // if(change.editForm?.currentValue?.value?.incrementsDate){
      if (this.check?.form == change?.editForm?.currentValue?.name) {
        // Valida que el campo venga en el formulario
        if (change?.editForm?.currentValue?.form?.value[this.check?.formControlName]) {

        let day =  (change.editForm?.currentValue?.form?.value[this.check?.formControlName])?.split('/')[0];
        let month = (change.editForm?.currentValue?.form?.value[this.check?.formControlName])?.split('/')[1];
        
        this.daySelected = day;

        switch (month) {
          case '1':
            this.monthSelected = {value: '1', label: 'Enero', days: 31}
            this.monthSelect(this.monthSelected);
            break;
          case '2':
            this.monthSelected = {value: '2', label: 'Febrero', days: 28}
            this.monthSelect(this.monthSelected);
            break;
          case '3':
            this.monthSelected = {value: '3', label: 'Marzo', days: 31}
            this.monthSelect(this.monthSelected);
            break;
          case '4':
            this.monthSelected = {value: '4', label: 'Abril', days: 30}
            this.monthSelect(this.monthSelected);
            break;
          case '5':
            this.monthSelected = {value: '5', label: 'Mayo', days: 31}
            this.monthSelect(this.monthSelected);
            break;
          case '6':
            this.monthSelected = {value: '6', label: 'Junio', days: 30}
            this.monthSelect(this.monthSelected);
            break;
          case '7':
            this.monthSelected = {value: '7', label: 'Julio', days: 31}
            this.monthSelect(this.monthSelected);
            break;
          case '8':
            this.monthSelected = {value: '8', label: 'Agosto', days: 31}
            this.monthSelect(this.monthSelected);
            break;
          case '9':
            this.monthSelected = {value: '9', label: 'Septiembre', days: 30}
            this.monthSelect(this.monthSelected);
            break;
          case '10':
            this.monthSelected = {value: '10', label: 'Octubre', days: 31}
            this.monthSelect(this.monthSelected);
            break;
          case '11':
            this.monthSelected = {value: '11', label: 'Noviembre', days: 30}
            this.monthSelect(this.monthSelected);
            break;
          case '12':
            this.monthSelected = {value: '12', label: 'Diciembre', days: 31}
            this.monthSelect(this.monthSelected);
            break;
          default: undefined
            break;
        }     
        }
      }
        
        this.DinamicForm = this.dynamicForm.ReMapEditForm(change?.editForm?.currentValue?.form, this.DinamicForm);
        
      //}
    }

        // Validadores Dynamicos
        if (!change.editConditions?.firstChange) { 
               
          if ((change.editConditions?.currentValue?.data)?.some(item => {return item?.condition?.target == this.check?.formControlName})) {
            let conditionConfig = (change.editConditions?.currentValue?.data).find(async item => {return item?.condition?.target == this.check?.formControlName});

            let validatorsRAW = conditionConfig?.validators
 
            if (validatorsRAW?.required && this.check?.target?.validators?.required) {
              this.check.target.validators.required = true;  
         
            }else if(!validatorsRAW.required && this.check?.target?.validators?.required){
              this.check.target.validators.required = false;
    
            }else if(validatorsRAW.required && !this.check?.target?.validators?.required){
              this.check.target.validators.required = true;          
    
            }else if(validatorsRAW.required && !this.check?.target?.validators?.required){
              this.check.target.validators.required = false;
            } 
          }

          this.validateRequired()
          
        }

    if (change.touched?.currentValue) {
      this.DinamicForm?.markAllAsTouched();
    }
  }

  async ngOnInit() {
    this.DinamicForm = this.fb.group({
      [this.check.formControlName]:'1/1'
    });

    this.DinamicForm = await this.dynamicControls.asignControls(this.check,  this.DinamicForm);        
    //Establece los valores de inicio del formulario
     this.dynamicEmit();
  }

  dynamicEmit(){

    let day = this.daySelected.value ? this.daySelected.value : this.daySelected;

      this.DinamicForm.patchValue({
        [this.check.formControlName]: ((day ||'1') + '/' + (this.monthSelected?.value || '1'))
      }); 
 
      this.onChange.emit({ event:this.DinamicForm, form: this.check?.form});  
  }

  public monthSelect(event){
    this.days = []

    for (let index = 1; index <= event.days; index++) {
        this.days.push({
          value: index,
          label: index
        });
    }  
    
    this.dynamicEmit();
    
    this.disableSelect = false;
  }

  public validateRequired(): boolean{
    let required: boolean = this.check?.target?.validators?.required || false;
    return required;
  }
}
