<div class="modal-header">
    <h6 class="modal-title" id="modal-basic-title">{{options?.title || "Reporte"}}</h6>
    <button type="button" class="close" aria-label="Close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body p-0">
    <div class="row">
        <div class="col-12">
          <div class="pdf" style="height: 900px;">
            <object [data]="pdf" type="application/pdf" class="w-100" style="height: 100%" *ngIf="pdf">
              <embed [src]="pdf">
            </object>
          </div>
        </div>
    </div>
  </div>
  <div class="modal-footer justify-content-end">
    <button class="btn btn-success" (click)="close()">Aceptar</button>
  </div>
  