import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { ProviderContractorComponent } from './provider-contractor.component';

const routes: Routes = [
    {
        path: '',
        children: [
            {
                path: '',
                component: ProviderContractorComponent
            },
        ], 
    }
];
@NgModule({
    imports: [RouterModule.forChild(routes),
        
    ],
    exports: [RouterModule]
})
export class ProviderContractorRoutingModule { }
