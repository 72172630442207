<!--Por el momento no se usa el target de la configuracion del campo en este componente -->

<div class="center mt-1">
        <label class="cursor-pointer">
            <span class="btn btn-success btn-sm"><i
                    class="fa fa-file-excel mr-1"></i>
                Subir
                <input accept=".xlsx"
                    type="file" style="display: none;"  (change)="setFileSubitems($event)">
            </span>
        </label>
</div>
  
