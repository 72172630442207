import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from 'src/app/shared/services/alert.service';
import { HttpService } from 'src/app/shared/services/http.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2'

@Component({
  selector: 'tab-estimate',
  templateUrl: './tab-estimate.component.html',
  styleUrls: ['./tab-estimate.component.scss']
})
export class TabEstimateComponent implements OnInit {

  @Input() id: string = null;
  @Input() status;

  @ViewChild('Sort') sort: MatSort;
  
  @Output() onClose: EventEmitter<any> = new EventEmitter<any>();
  close = ()=>{this.onClose.emit()}

  public apiUrl = environment.apiUrl;
  public isLoading: boolean = false;
  public DT_estimate: MatTableDataSource<any>;
  public displayColumns: string[] = ['name', 'subtotal', 'iva', 'total', 'files', 'selected'];
  public Files;
  public estimateId;
  public btnLabel;
  public disablebtn:boolean = true;

  constructor(
    private modalService: NgbModal,
    private httpServices: HttpService,
    private alertServices: AlertService

  ) { 
    this.DT_estimate = new MatTableDataSource;
  }

  ngOnInit(): void {

    this.detailEstimate();
  }

  openModal(template: any, size: string = 'lg', files){

    if(files?.length > 0){
      this.Files = files;      
      this.modalService.open(template, {size: size});
    }else{
      this.alertServices.warning("Atención", "El participante no adjunto archivos en su propuesta");
    }
  }

  public async detailEstimate(){
    try {
      
      if (this.id) {
        
        this.isLoading = true;
        let data = await this.httpServices.get(`api/bidding/users/${this.id}`).toPromise();        
        this.DT_estimate.data = data.filter(request => {return request?.requestData && request?.requestData.status != "REJECTED_PROVIDER" });
        
        this.DT_estimate.sort = this.sort;
        this.isLoading = false;
      }else{
        this.alertServices.errorToast("No se pudo consultar los participantes de la licitación.")
      }
    } catch (error) {
      this.isLoading = false;
      console.log(error); 
    }
  }

  async download(file: string) { 
    window.open(`${this.apiUrl}${file}`, "_blank");
  }

  public async selectEstimate(){

    try {
    
      this.httpServices.put(`api/bidding/request/select/${this.estimateId}`, {}).toPromise().then( (resp:any) => {
        this.isLoading = false;
        this.alertServices.successToast(resp.message);
        this.detailEstimate();
      }).catch((err:any) => {
        if(err.status != 403){
          this.alertServices.infoToast(err.error.message)
        }
        this.isLoading = false;
      });

    } catch (error) {
      console.log(error);
    }
    
  }

  public validcheck(id, event){
    
   this.DT_estimate.data.forEach(element => {
      element.requestData.status = (id == element.requestData._id && event.target.checked) ? "ACCEPTED" : "CREATED"
    });

    this.estimateId = id
  }

  public validSelectedEstimete():boolean{
    let valid: boolean;
    if(this.status != "CREATED" && this.status != "STARTED"){
      valid = false;
    }else{
      valid = this.DT_estimate.data.filter(item => {return item.requestData.status == "ACCEPTED"}).length > 0;
    }
    return !valid;
  }
}
