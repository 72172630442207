import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mk-without-permissions',
  templateUrl: './mk-without-permissions.component.html',
  styleUrls: ['./mk-without-permissions.component.scss']
})
export class MkWithoutPermissionsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
