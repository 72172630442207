import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UserService } from 'src/app/shared/services/user.service';

@Component({
  selector: 'mkt-title',
  templateUrl: './mkt-title.component.html',
  styleUrls: ['./mkt-title.component.scss'],
  host: {
    "class": 'mkt_row _title'
  }
})
export class MktTitleComponent implements OnInit {

  @Input() columns: any;
  @Input() optionsTable: boolean = true;

  @Output() changeSort: EventEmitter<any> = new EventEmitter<any>(); 

  public sortAsc: boolean = true; 
  public target: string = null;

  constructor(
    private User: UserService

  ) { }

  ngOnInit(): void {    
  }

  public description(c){
    if(c?.readonly){return `${c.name} - Columna bloqueada.`}
    if(c.readonlyMobile){return `${c.name} - Los registros creados desde móvil no pueden ser cambiados.`}
    return `${c.name}`
  }

  public validatePermissions(permissions): boolean{
    let valid: boolean = true;
    let idUser = this.User.user._id;
    let roleUser = this.User.user.roleName;
  
        
    if (permissions && (roleUser != "ADMIN" && roleUser != "SUPERVISOR")) {

      let permission =  permissions.find(item => {return item.type == 'SHOW' })

      if (permission?.filter == "EXCLUDE" && permission?.users.some(element => {return element == idUser})) {
        valid = false
        
      }else if(permission?.filter == "INCLUDE" && !permission?.users.some(element => {return element == idUser})){
        valid = false
      }
  }
    

    // if (permissions?.length) {
    //         permissions.forEach(element => {
    //           //Validacion de typo de permiso
    //           if (element?.type == 'SHOW') {

    //             //Filtrado de usuarios
    //             var tempUsers;
    //             tempUsers = element?.users.filter( item => {return idUser == item});

                
    //        if(roleUser == "ADMIN" || roleUser == "SUPERVISOR"){   

    //            valid = true;               
    //           }else{
    //              //validacion de usuario encontrado y tipo de filtro                 
    //              if (tempUsers.length) {
    //               if (element?.filter == "EXCLUDE") {
    //                 valid = false;
    //               }else if(element?.filter == "INCLUDE"){
    //                 valid = true;
    //               }
    //             }
    //           }
    //        } 
    //         });
         
    //   }

    return valid;
  }

  public sort(sortAsc:boolean, target: string){
    this.changeSort.emit({sortAsc: sortAsc, target: target});
    if(this.target != target){ this.target = target; sortAsc = true; };
  }

}
