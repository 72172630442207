import { Component, EventEmitter, OnInit, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService } from 'src/app/shared/services/alert.service';
import { HttpService } from 'src/app/shared/services/http.service';
import { NavService } from 'src/app/shared/services/nav.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-info-change-modal',
  templateUrl: './info-change-modal.component.html',
  styleUrls: ['./info-change-modal.component.scss']
})
export class InfoChangeModalComponent implements OnInit {

  public isLoading: boolean = false
  public imgUser:string = "";
  public textlength: number = 0;

  @Input() modalOptions: any;
  @Input() ContractorName = null;
  @Output() onClose: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onAction: EventEmitter<boolean> = new EventEmitter<boolean>();

  close = ()=>{this.onClose.emit()}

  public RequestForm: FormGroup;
  public RequestData = null;

  constructor(
      private httpService: HttpService,
      private fb: FormBuilder,
      private alertService: AlertService,
      private navService: NavService 
    ) { }

  ngOnInit(): void {
    this.RequestForm = this.fb.group({
      comment: [null]
    })
    this.getRequest();
  }

  public mapObj(obj){return Object.keys(obj).map(key => ({type: key, value: obj[key]}));}

  public async getRequest(){    
    try {
      this.isLoading = true;
      this.RequestData = await this.httpService.get(`api/requestProfile/${this.modalOptions?.id}`).toPromise();
      this.imgUser = environment.apiUrl + this.RequestData?.user?.image
      
    } catch (error) {
      console.log(error);
    }finally{
      this.isLoading = false;
    }
  }

  public async approveRequest(){
    try {
      this.isLoading = true;
      let obj = {
        requestId: this.modalOptions?.id,
        status: "ACCEPTED",
        comment: this.RequestForm?.value?.comment 
      }
      const resp = await this.httpService.put(`api/requestProfile/`, obj).toPromise();
      this.alertService.successToast('Solicitud Aprobada');
      this.navService.requestProfile(); //? trae los nuevos valores del badget
      this.onAction.emit();
      
    } catch (error) {
      console.log(error);
    }finally{
      this.isLoading = false;
    }
  }


  public asigntextlength(event){
    this.textlength = event.length;    
  }

  public async rejectRequest(){
    try {
      this.isLoading = true;
      let obj = {
        requestId: this.modalOptions?.id,
        status: "REJECTED",
        comment: this.RequestForm?.value?.comment 
      }
      const resp = await this.httpService.put(`api/requestProfile/`, obj).toPromise();
      this.alertService.successToast('Solicitud Rechazada');
      this.navService.requestProfile();
      this.onAction.emit();

    } catch (error) {
      console.log(error);
    }finally{
      this.isLoading = false;
    }
  }
}


