import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ValidateFormsDirective } from "src/app/directives/validate-forms.directive";
import { AlertService } from "src/app/shared/services/alert.service";
import { BuiltFormDataService } from "src/app/shared/services/built-form-data.service";
import { DyamicFormService } from "src/app/shared/services/dynamic-form.service";
import { HttpService } from "src/app/shared/services/http.service";
import { UserService } from "src/app/shared/services/user.service";
import { ValidatorsService } from "src/app/shared/services/validators.service";
@Component({
  selector: "app-contract-modal",
  templateUrl: "./contract-modal.component.html",
  styleUrls: ["./contract-modal.component.scss"],
})
export class ContractModalComponent implements OnInit {
  @Input() idContract: string = null;
  @Input() contractCode: string = null;
  @Output() onClose: EventEmitter<any> = new EventEmitter<any>();
  public isLoading: boolean = false;
  public contract: any;

  public DataForm;
  public editForm;

  public FormContract: FormGroup;
  public tmpFormContract: FormGroup;


  constructor(
    public validateForm: ValidateFormsDirective,
    private httpService: HttpService,
    public userService: UserService,
    public validatorService: ValidatorsService,
    public dynamicForm: DyamicFormService,
    public fieldisValid: ValidateFormsDirective,
    private fb: FormBuilder,


  ) { }

  ngOnInit(): void {
    this.FormContract = this.fb.group({
      idContractType: [null]
    });
    this.tmpFormContract = this.FormContract;
    this.detailContract();
  }

  public async detailContract() {

    try {
      this.isLoading = true;

      const Form = await this.httpService.get(`api/contract/detail/${this.idContract}`).toPromise();
      const FormContract = await this.httpService.get(`api/dynamicForms/${Form?.idContractType}`).toPromise();
      this.DataForm = FormContract;

      this.FormContract = await this.dynamicForm.validForm(FormContract.sections);
      setTimeout(() => {
        for (const iterator in this.FormContract.controls) {
          this.FormContract.patchValue({
            [iterator]: Form[iterator]
          })
        };
        //asigna los datos de edicion

        this.editForm = this.FormContract;

      }, 500);

    } catch (error) {
      console.log(error);
    } finally {

      setTimeout(() => {
        this.isLoading = false;
      }, 1000);
    }
  }


  public async FormChange(event: any) {
    this.FormContract = this.dynamicForm.MapForm(event, this.FormContract);
    
  }

  public FormChildrensChange(childrens: any) {
    this.isLoading = true;

    childrens.forEach(children => {
      this.FormContract.get(children.formControlName).setValue(children.value);
      this.FormContract.patchValue({ [children.formControlName]: children.value });
    });

    this.editForm = this.tmpFormContract;

    setTimeout(() => {
      this.editForm = this.FormContract;
      this.isLoading = false;
    }, 150);


  }

  close(): void {
    this.onClose.emit();
  }
}
