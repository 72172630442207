<div class="col-sm-12">
    <div class="row my-3">
        <div class="col-md-12 d-flex justify-content-between">
            <h4 class="text-secondary my-auto">Estimaciones</h4>
            <span class="text-muted my-auto">Revisión y selección de ganador</span>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-12">
            <div class="card table-responsive mb-3">
                <table class="table table-striped table-bordered mb-0 material-table-container" mat-table [dataSource]="DT_estimate" 
                    matSort #Sort="matSort" matSortActive="name" matSortDirection="desc">
                    
                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Nombre </th>
                        <td mat-cell *matCellDef="let item">
                            <b>{{item?.name}}</b> 
                        </td>
                    </ng-container>
    
                    <ng-container matColumnDef="subtotal">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Subtotal </th>
                        <td mat-cell *matCellDef="let item">
                            {{item?.requestData?.subtotal | currency}}
                        </td>
                    </ng-container>
    
                    <ng-container matColumnDef="iva">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> IVA </th>
                        <td mat-cell *matCellDef="let item">
                            {{item?.requestData?.iva + '%'}}
                        </td>
                    </ng-container>
    
                    <ng-container matColumnDef="total">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Total </th>
                        <td mat-cell *matCellDef="let item">
                            {{item?.requestData?.amount  | currency}}
                        </td>
                    </ng-container>
    
                    <ng-container matColumnDef="files">
                        <th mat-header-cell *matHeaderCellDef width="160px"> <div class="text-center">Archivos</div> </th>
                        <td mat-cell *matCellDef="let item">
                            <!-- {{item?.request?.files}} -->
                            <div class="card py-1 px-2 d-flex flex-row justify-content-between cursor-pointer" (click)="openModal(pdf, 'lg',item?.requestData?.files);">
                                <span class="text-primary"><i class="fa fa-file mr-1"></i> {{item?.requestData?.files?.length}} Archivos</span>
                                <span *ngIf="item?.requestData?.files?.length > 0" class="text-muted"><i class="mdi mdi-eye"></i></span>
                            </div>
                        </td>
                    </ng-container>
    
                    <ng-container matColumnDef="selected">
                        <th mat-header-cell *matHeaderCellDef width="80px"><div class="text-center">Ganador</div></th>
                        <td mat-cell *matCellDef="let item">
                            <label class="ckbox cursor-pointer"><input [checked]="item.requestData.status == 'ACCEPTED' ? true : false"  type="checkbox" (click)='validcheck(item.requestData._id, $event)'><span></span></label>
                        </td>
                    </ng-container>
    
    
                    <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayColumns;"></tr>
    
                    <tr class="mat-row" *matNoDataRow><td class="mat-cell text-center" colspan="10"> {{isLoading?"Cargando ...":"Sin datos encontrados"}}</td></tr>
    
                </table>
            </div>
        </div>
    </div>
</div>

<div class="modal-footer justify-content-between">
    <button type="button" class="btn btn-dark" (click)="close()">Cerrar</button>

    <div>
        <button type="button" class="btn btn-info"  [swal]="selectestimateSwal" [disabled]="validSelectedEstimete()" (confirm)='selectEstimate()'><i class="mdi mdi-checkbox-marked ml-1"></i> Elegir Ganador</button>
    </div>
</div>

<ng-template #pdf let-modalpdf>
    <div class="modal-header">
        <h6 class="modal-title" id="modal-basic-title">Archivos Adjuntos</h6>
        <button type="button" class="close" aria-label="Close" (click)="modalpdf.close()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body">
        <div class="row">
            <div class="col-sm-12">
                <span class="text-muted">Listado de archivos adjuntos</span>
            </div>
            <div class="col-sm-12">
                <div class="d-flex flex-wrap" style="margin: 0 -10px;">
                    <ng-container *ngFor="let file of Files">
                        <app-file-item [filesmanager]="true" [deletebtn]="true" [file]="file" (click)="download(file.name)"></app-file-item>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer justify-content-end">
        <button type="button" class="btn btn-dark" (click)="modalpdf.close()">Cerrar</button>
    </div>
</ng-template>

<swal #selectestimateSwal class="d-none" title="¿Estas seguro de seleccionar esta estimación como ganadora?" icon="question" [showCancelButton]="true" [focusCancel]="true" [swalOptions]="{confirmButtonText: 'Aceptar', cancelButtonText: 'Cancelar', confirmButtonColor: '#fd3666'}">
</swal>

