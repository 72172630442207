<mk-loader *ngIf="isLoading" class="loader-spinner"></mk-loader>
<div class="modal-header">
  <h6 class="modal-title" id="modal-basic-title">
     Autorización
  </h6>
  <button type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body"> 
    <form [formGroup]="form" novalidate>
        <div class="row">
            <div class="col-12">
                <h6>Usuario <span class="text-danger">*</span></h6>
                <input type="text" class="form-control" placeholder="Aa..." formControlName="user" maxlength="30">  
                <small *ngIf="validatorForm.campoEsValido('user',form)" class="text-danger">Campo requerido!</small>
            </div>
            <div class="col-12">
                <h6>NIP <span class="text-danger">*</span></h6>
                <input type="password" class="form-control" placeholder="Aa..." formControlName="nip" maxlength="4">  
                <small *ngIf="validatorForm.campoEsValido('nip',form)" class="text-danger">Campo requerido!</small>
            </div>
        </div>
    </form>
</div>
<div class="modal-footer justify-content-between">
    <button type="button" class="btn btn-danger" (click)="close()">Cancelar</button>
    <button type="button" class="btn btn-success" (click)="submit()"><i class="fa fa-check mr-2"></i> Autorizar</button>
</div>