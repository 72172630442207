import { Component, forwardRef, OnInit, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { element } from 'protractor';
import { HttpService } from 'src/app/shared/services/http.service';
import { getJSDocAugmentsTag } from 'typescript';
import { runInThisContext } from 'vm';

@Component({
  selector: 'app-tag-input',
  templateUrl: './tag-input.component.html',
  styleUrls: ['./tag-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TagInputComponent),
      multi: true
    }
  ]
})
export class TagInputComponent implements OnInit, ControlValueAccessor {
  _value = [];

  _openList: boolean = false;
  _hasFocus: boolean = false;
  _hasListHover: boolean = false;

  public tagCatalog:any = []
  public tagCatalogFiltred:any = []

  @Input() placeholder: string = "Agregar etiqueta"

  // public addTag = (value) => {  
  //   console.log(value);
  //     console.log(this._value);
  //     console.log(this.tagCatalog);
      
      
  
  //   if(value){
  //     let obj = this._value;
      
  //       if (value?.text && !obj.some(item => {return item?.label == value?.text})){
  //         //entra cuando se elige uno del catalogo
  //         if (value?.add && this.Tag) {
  //           obj.push({label:this.Tag}); 
  //           this.tagCatalog.push({text:this.Tag});   
  //           this.Tag = null; 
  //           this.tagCatalogFiltred = this.tagCatalog;        
  //         }else{
  //           obj.push({label:value?.text}); 
  //           this.tagCatalog.push({text:value?.text})
  //         }

  //           this.value = obj;
  //           this._openList = this._hasFocus;
            
  //       } else if(!obj.some(item => {return item?.label == value?.text}) && !obj.some(item => {return item?.label == value})) {
  //         //entra cuando se agrega
  //         obj.push({label:value}); 
  //         this.value = obj;
  //         this._openList = this._hasFocus;
  //         this.tagCatalog.push({text:value});

  //       }   
 
  //   }
  // }

  constructor(private httpService: HttpService) { 
    this.getTags();
  }

  async ngOnInit() {
  }

  public addTag = (value) => {  
    if((value?.text || value) && !this._value.some(item => {return item.label == value}) && !this._value.some(item => {return item.label == value?.text})){
      this._value?.push({label: (value?.text || value)});
    }

    if ((value?.text || value) && !this.tagCatalog.some(item => {return item.text == value}) && !this.tagCatalog.some(item => {return item.text == value?.text})) {
      this.tagCatalog?.push({text: (value?.text || value)});
      this.tagCatalogFiltred = this.tagCatalog;
    }

    this._openList = this._hasFocus;
    this.propagateChange(JSON.stringify(this._value));
  }

  public DeleteValue(event){

  }

  removeTag = (index) => {let obj: string[] = this._value; obj.splice(index, 1); this.value = obj;}

  get value(): string[] {
    return this._value;
  }

  set value(value: string[]) {      
    for (const element of value) {
        this.tagCatalog.forEach(tag => {
          
          if (tag?._id == element) {
            this._value.push({id:element, label: tag?.text})
          }
        });
    }

    this.propagateChange(JSON.stringify(this._value));
  }

  writeValue(value: any) {
    if ( value && (typeof(value) != "object" )) {
      this.value = JSON.parse(value);
    }else if(value){
      this.value = value
    }
  }

  propagateChange = (_: any) => {};
  propagateTouched = (_: any) => {};

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched(fn) {
    this.propagateTouched = fn;
  }

  touched($event) {
    this.propagateTouched($event);
  }

  backspace(event){
    if (event == '') {
      let obj: string[] = this._value;
       obj.splice(obj?.length - 1, 1); 
       this.value = obj;
    }
  }

  public async getTags(){
    try {
      this.tagCatalog = await this.httpService.get(`api/tags/list`).toPromise();  
      this.tagCatalogFiltred = this.tagCatalog;    
    } catch (error) {
      console.log(error);
    }
  }

  tagExists(findTag){return this._value?.find(tag => {return tag?.label == findTag})}

  blurFocus(){if (!(this._hasFocus || this._hasListHover)) {this._openList = false;}}

  public async filtercat(word){
    
    if (word?.length) {
      const regex = new RegExp(word);

      this.tagCatalogFiltred = this.tagCatalog?.filter(({text}) => text?.match(regex));

      !this.tagCatalogFiltred?.length ? this.tagCatalogFiltred.push({text:"+ Agregar tag", add: true}) : "";
      
    }else{
      this.tagCatalogFiltred = this.tagCatalog;
    }
  }
}
