import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, ControlContainer, NgForm } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ValidateFormsDirective } from 'src/app/directives/validate-forms.directive';
import { DynamicControlsService } from 'src/app/shared/services/dynamic-controls.service';
import { DyamicFormService } from 'src/app/shared/services/dynamic-form.service';
import { ValidatorsService } from 'src/app/shared/services/validators.service';

@Component({
  selector: 'dnc-input-group',
  templateUrl: './dnc-input-group.component.html',
  styleUrls: ['./dnc-input-group.component.scss', '../../dynForm.comp.scss'],
  host: {
    "[style.order]": "check.position",
    "[class]": "'col-sm-12 col-md-' + (check.col_size || 4)"
  },

  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class DncInputGroupComponent implements OnInit {
  
  
  @Input() check: any = {
    label: "Grupo",
    col_size: 6,
    fields: [
      // { type: 'select', options: ["o1", "o2", "o3"], placeholder: "Selecciona" },
      // { type: 'input', options: { type: "text" }, placeholder: "Aa..." },
      // { type: 'input', options: { type: "number" }, placeholder: "00.00" },
      // { type: 'date', placeholder: "00/00/00" },
    ]
  };
  
  @Input() editForm: any;
  @Input() editable : boolean = true;
  @Input() touched : boolean = false;
  
  @Output() onChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() onAutomatization: EventEmitter<any> = new EventEmitter<any>();


  @ViewChild('inputVal') inputVal: ElementRef;
  @ViewChild('selectVal') selectVal: ElementRef;
  @ViewChild('dateVal') dateVal: ElementRef;


  public DinamicForm: FormGroup;
  public fieldsError: boolean = false;
  public fieldRequired: boolean = false;


  public group: any = new Object;
  public tmpForm: any;

  public valInput: any = "";
  public valSelect: any = null;
  public valDate: any = null;
  public selectedAttributes: any;

  public currencyOptions = [
    "MXN", "USD"
  ]
  public measuresOptions = [
    "sf"
  ]

  constructor(
    private fb: FormBuilder,
    private dynamicControls: DynamicControlsService,
    public fieldisValid: ValidateFormsDirective,
    private dynamicForm: DyamicFormService,
    public validatorsService: ValidatorsService,
    public sanitizer: DomSanitizer
  ) { }

  ngOnChanges(change: SimpleChanges) {
    if (!change.editForm?.firstChange) { // Reasigna los valores dependiendo del elemento a renderizar
      
      if (this.check?.form == change.editForm?.currentValue?.name) {
        var editValue = change.editForm?.currentValue?.form?.value[this.check.formControlName];
        
      this.tmpForm = editValue;
      this.DinamicForm = this.dynamicForm.ReMapEditForm(change.editForm?.currentValue?.form, this.DinamicForm);      
      
      if (this.check?.target?.configBlock) {
    
        switch (this.check?.target?.configBlock?.condition) {
          case "EQUAL":
            if (change.editForm?.currentValue?.form?.value[this.check?.target?.configBlock?.target] == this.check?.target?.configBlock?.value) {
              this.check.target.readonly = true;              
            }

            break;
        
          default:
            break;
        }
      }

      if (editValue) {
        if (this.check?.groupType === "leaseTerms") {
          this.inputVal.nativeElement.value = (editValue?.type) ? editValue?.type : "";
          this.valInput = (editValue?.type) ? editValue?.type : "";
          this.valSelect = (editValue?.size) ? editValue?.size : null;
        }else if (this.check?.groupType === "currency") {
          this.inputVal.nativeElement.import = (editValue?.import) ? editValue?.import : "";
          this.valInput = (editValue?.import) ? editValue?.import : "";
          this.valSelect = (editValue?.currency) ? editValue?.currency : null;
        }else if (this.check?.groupType === "additionalPeriods") {
          this.inputVal.nativeElement.value = (editValue?.type) ? editValue?.type : "";
          this.valInput = (editValue?.type) ? editValue?.type : "";
          this.valSelect = (editValue?.periods) ? editValue?.periods : null;
        }else if (this.check?.groupType === "measure") {
          this.inputVal.nativeElement.value = (editValue?.size) ? editValue?.size : "";
          this.valInput = (editValue?.size) ? editValue?.size : "";
          this.valSelect = (editValue?.measure) ? editValue?.measure : null;
          this.measuresOptions = this.check.fields.find(item => {return item.name == "measure"}).options || ["sf"] ;
        }else{
          for (const item of this.check?.fields || []) {
            if (item?.type == 'input') {
              this.inputVal.nativeElement.value = editValue[item?.name];
              this.valInput = editValue[item?.name];
            }else{
              this.valSelect = editValue[item?.name];
            }
          }
        }
      }
     
      }
      
    }

    if (change.touched?.currentValue) {
      this.DinamicForm?.markAllAsTouched();
    }
  }

  async ngOnInit() {
    this.DinamicForm = this.fb.group({
      [this.check.formControlName]: [null]
    });

    //this.measuresOptions = this.check.fields.find(item => {return item.name == "measure"}).options || ["sf"] ;

    this.DinamicForm = await this.dynamicControls.asignControls(this.check, this.DinamicForm);

    //Establece los valores de inicio del formulario
    this.dynamicEmit();


    this.group = new Object;
    this.check?.fields.forEach((field, index) => {
      var name = field?.name;
      this.group[name] = null;
    });
  }

  dynamicEmit() {

    if (this.check?.groupType === "leaseTerms") {
      this.group['size'] = this.valSelect;
      this.group['type'] = this.inputVal.nativeElement.value.toString().trim();
    }else if (this.check?.groupType === "currency") {
      this.group['currency'] = this.valSelect;
      this.group['import'] = this.inputVal.nativeElement.value.toString().trim();
    }else if (this.check?.groupType === "additionalPeriods") {
      this.group['periods'] = this.valSelect;
      this.group['type'] = this.inputVal.nativeElement.value.toString().trim();
    }else if (this.check?.groupType === "measure") {
      if(!this.valSelect) {this.valSelect = this.measuresOptions[0]}
      this.group['measure'] = this.valSelect;
      this.group['size'] = this.inputVal.nativeElement.value.toString().trim();
    }else{
      for (const item of this.check?.fields || []) {
        if (item?.type == 'input') {
          this.group[item?.name] = this.inputVal.nativeElement.value.toString().trim();
        }else{
          this.group[item?.name] = this.valSelect?.value || this.valSelect;
        }
      }
    }


 
    //SE RECORRE OBJETO
    /*
    let fields = Object.keys(this.group), error = false, tmpValue = false, successValue = 0;
    for (let index = 0; index < fields.length; index++) {
      const name = fields[index];
      var value = this.group[name];
      


      if (this.check?.groupType === "leaseTerms") {
        if (name === "size" && this.valSelect && !value) {
          value = this.valSelect;
          this.group[name] = value;
        }
      }

      if (this.check?.groupType === "currency") {

        if (name === "currency" && this.valSelect && !value) {
          value = this.valSelect;
          this.group[name] = value;
        }
      }


      if (!this.group[name]) {
        this.group[name] = "";
      }
    }
    */

    //si algun campo esta vacio, el formulario se envia con valores null y es invalido
    let validformat = true;

    for (const key in this.group) {
      if(this.group[key] == '' || this.group[key] == null){
        validformat = false 
        break
      } 
    }

      if(!validformat){
        this.DinamicForm.patchValue({
          [this.check.formControlName]: null
        })
      }else{
        this.DinamicForm.patchValue({
          [this.check.formControlName]: this.group
        })
      }
    
    this.onChange.emit({ event:this.DinamicForm, form: this.check?.form, automation: this.check?.target?.automation, fieldType: this.check?.type, value: this.DinamicForm.value[this.check.formControlName], field: this.check?.formControlName});

  }

  public validateRequired(): boolean {
    let required: boolean = this.check?.target?.validators?.required || false;
    this.fieldRequired = required;
    return required;
  }


  public setValueField(name: string, event: any) {
    var data = event?.target ? event.target.value : event;

    if (this.check?.groupType === "leaseTerms") {
      this.group['size'] = this.valSelect;
      this.group['type'] = this.inputVal.nativeElement.value;
    }else if (this.check?.groupType === "currency") {
      this.group['currency'] = this.valSelect;
      this.group['import'] = this.inputVal.nativeElement.value;
    }else if (this.check?.groupType === "additionalPeriods") {
      this.group['periods'] = this.valSelect;
      this.group['type'] = this.inputVal.nativeElement.value;
    }else if (this.check?.groupType === "measure") {
      this.group['measure'] = this.valSelect;
      this.group['size'] = this.inputVal.nativeElement.value;
    }else{
      this.group[name] = data?.value || data;
    }


    //this.group[name] = value;
    //SE RECORRE OBJETO

    let fields = Object.keys(this.group), error = false, tmpValue = false, successValue = 0;
    
    for (let index = 0; index < fields.length; index++) {
      const name = fields[index];
      var value = this.group[name];

      /*
            if (this.check?.groupType === "leaseTerms") {
              if (name === "size" && this.valSelect) {
                value = this.valSelect;
      
              }
              if (name === "type" && this.valInput) {
                value = this.valInput;
      
              }
            }
      
            if (this.check?.groupType === "currency") {
              if (name === "currency" && this.valSelect) {
                value = this.valSelect;
      
              }
              if (name === "import" && this.valInput) {
                value = this.valInput;
      
              }
            }
      
      */
      if ((value) && value !== "") {
        successValue++;
      }
    }

    this.fieldsError = (successValue !== 0 && successValue !== fields.length || successValue === 0 && this.fieldRequired);
  }

  // public numericValitation(evt: any) {

  //   var value = evt.target.value;
  //   //var val = value;
  //   var val = value.replace(/[^.\d]/g, '');

  //   if (val === "" && this.fieldRequired) { val = "0.00" }
  //   else{
  //     val = parseFloat(val);
  //     val = val.toString();
  //   }
  //   var dec = (val !== "") ? val.split(".") : null;
  //   if (dec) {
  //     if (dec.length > 1 && dec[1].length > 4) {
  //       val = dec[0] + "." + dec[1].substring(0, 4);
  //     }
  //   }

  //   evt.target.value = val;
  //   // return val;
  // }

  public isNumeric(evt : any){    
    var theEvent = evt || window.event;
    var key = theEvent.keyCode || theEvent.which;
    if (theEvent.keyCode != 13) {
      key = String.fromCharCode(key);
      var regexKex = /[0-9]|\./;
      if (!regexKex.test(key)) {
        theEvent.returnValue = false;
        if (theEvent.preventDefault) theEvent.preventDefault();
      }
    }
  }

  public ValidOptions(field): any[]{  
    if (field.name === 'currency') {
      return field.options ? field.options : this.currencyOptions;
    }else if (field.name === 'measure'){
      return field.options ? field.options : this.measuresOptions;
    }else{
      return field.options
    }
  }

}
