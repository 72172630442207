import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ValidateFormsDirective } from 'src/app/directives/validate-forms.directive';
import { DynamicControlsService } from 'src/app/shared/services/dynamic-controls.service';
import { DyamicFormService } from 'src/app/shared/services/dynamic-form.service';

@Component({
  selector: 'dnc-input',
  templateUrl: './dnc-input.component.html',
  styleUrls: ['./dnc-input.component.scss', '../../dynForm.comp.scss'],
  host: {
    "[style.order]": "check.position",
    "[class]": "'col-sm-12 col-md-' + (check.col_size || 4)"
  }
})

export class DncInputComponent implements OnInit {

  @Input() check: any;
  @Input() editForm: any;
  @Input() editable : boolean = true;
  @Input() touched : boolean = false;
  @Input() dependencie: any;
  public DinamicForm: FormGroup
  public tempChangeForm: FormGroup
  public tempChange = null;

  @Output() onChange: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private fb: FormBuilder,
    private dynamicControls: DynamicControlsService,
    public fieldisValid: ValidateFormsDirective,
    private dynamicForm: DyamicFormService,
    public sanitizer: DomSanitizer
  ) { }


  ngOnChanges(change: SimpleChanges) {

    if (!change?.editForm?.firstChange) { // Reasigna los valores dependiendo del elemento a renderizar      
      if (this.check?.form == change?.editForm?.currentValue?.name) {

        if (this.check?.target?.readObj && change?.editForm?.currentValue?.form?.value[this.check?.formControlName]?.length) {

          if (change?.editForm?.currentValue?.form?.value[this.check?.formControlName]?.length) {
            
            let data = change?.editForm?.currentValue?.form?.value[this.check?.formControlName].filter(dat => {return dat[this.check?.target?.readObj?.targetcondition]})[0][this.check?.target?.readObj?.field];
            this.tempChange = change?.editForm?.currentValue?.form?.value[this.check?.formControlName];

            this.DinamicForm.patchValue({
              [this.check.formControlName]: data
            });  
          }     
        }else{          
          this.DinamicForm = this.dynamicForm.ReMapEditForm(change.editForm.currentValue?.form, this.DinamicForm);
        }
      }
    }
    
    if (!change.dependencie?.firstChange) {
      
      if (this.check?.formControlName == change?.dependencie?.currentValue?.depend?.formControlNametarget) {
        this.check.target.readonly =  !change?.dependencie?.currentValue?.value;       
        if (!change?.dependencie?.currentValue?.value) {
          this.DinamicForm.patchValue({
            [this.check.formControlName]: null
          })
        }        
      }
    }

    if (change.touched?.currentValue) {
      this.DinamicForm?.markAllAsTouched();
    }
  }

  async ngOnInit() {
    this.DinamicForm = this.fb.group({
      [this.check.formControlName]: [null]
    });

    this.tempChangeForm = this.fb.group({
      [this.check.formControlName]: [null]
    });

    

    this.DinamicForm = await this.dynamicControls.asignControls(this.check, this.DinamicForm);

    //Establece los valores de inicio del formulario
    this.dynamicEmit();
  }

  dynamicEmit() {
    var value = this.DinamicForm.value[this.check.formControlName];
    //.o validacion espacios vacios 
    if (value && this.check?.type === "TEXT") {
      value = value.trim();

      if (this.check.target.uppercase) {
        value = value.toUpperCase();
      }
      if (this.check.target.lowercase) {
        value = value.toLowerCase();
      }
      this.DinamicForm.patchValue({
        [this.check.formControlName]: value
      });
    }

  // Codigo para obtener contacto primario de un cliente
  if (this.check?.target?.readObj) {

    let index = this.tempChange?.findIndex(tmp => {return tmp[this.check?.target?.readObj?.targetcondition]});

    if (index >= 0) {
      // en Caso de existir un contacto primario actualiza el nombre
      this.tempChange[index][this.check?.target?.readObj?.field] = this.DinamicForm.value[this.check?.formControlName];
       this.tempChangeForm.patchValue({
        [this.check?.formControlName]: JSON.stringify(this.tempChange)
       });

       this.onChange.emit({ event:this.tempChangeForm, form: this.check?.form});
    }else{
      if (value) {
        //En caso de que no exista un contacto primario crea uno con el nombre ingresado
      let arraytemp = [];
      this.tempChange?.lenght ?  arraytemp = this.tempChange :  "";

      //  window[this.check?.formControlName + ''] = {
      //    [this.check?.target?.readObj?.field] : value,
      //    primary: true
      //   }

      //   arraytemp?.push(obj);
        
        this.tempChangeForm.patchValue({
          [this.check?.formControlName]: {name:this.check?.target.readObj.field, value: value }
         });

      this.onChange.emit({ event:this.tempChangeForm, form: this.check?.form, readObj: this.check});

      }
    }
    
    }else{
      this.onChange.emit({ event:this.DinamicForm, form: this.check?.form});      
    }

  }

  public validateRequired(): boolean {
    let required: boolean = this.check?.target?.validators?.required || false;
    return required;
  }
}
