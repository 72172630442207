<div class="center" *ngIf="options?.type != 'AUDIO'">

    <!-- (click)="value ? openImg(modal, $event) : {}" -->
    <div class="_block d-flex justify-content-center cursor-pointer" [class.fill]="value" (click)="openModal(modalShowFiles, 'md');">
        <span class="icon"><i class="fa fa-file"></i></span>
        <!-- <input class="text-center" type="number" [value]="value" placeholder="00.00"> -->
        <span>{{ type === "number" ? value : (length || 'Sin')}} Arch.</span>
    </div>
</div>
<div class="center" *ngIf="options?.type == 'AUDIO'">
    <audio controls *ngIf="value">
        <source [src]="url+value" type="audio/ogg">
    </audio>
    <div class="_block d-flex justify-content-center" *ngIf="!value">
        <span class="icon"><i class="fa fa-music"></i></span>
        <span>Sin audio</span>
    </div>
</div>
 

<ng-template #modal>
    <div class="mkt_dropdown mkt_col">
        <div class="head">Archivos</div>
        
        <div class="content p-0">
            <img [src]="url+item[target.split('s')[0]]" alt="">
        </div>
    </div>
</ng-template>


<ng-template #modalShowFiles let-modal>
    <div class="modal-body pb-0"> 
        <div class="row pb-3 border-bottom">
            <div class="col-8">
              <h3 class="text-primary m-0">Archivos</h3>
              <span class="text-muted">Ver y agregar archivos al {{this.userService?.modulesName?.ticket || 'ticket'}}.</span>
            </div>
            <div class="col-4 d-flex justify-content-end">
                <button class="btn btn-primary my-auto" (click)="modalOption.type = 'ADD'; openModal(modalFile, 'md')"><i class="fa fa-plus mr-2"></i> Agregar</button>
            </div>
          </div>
        <div class="d-flex flex-wrap" style="margin: 0 -10px;"> 
            <ng-container *ngIf="!item?.attachments"> 
                <span class="text-muted mx-auto my-4">Sin Archivos</span>
            </ng-container>  

            <ng-container *ngFor="let file of item?.attachments; index as i">
                <app-file-item [file]="file" (onDelete)="removeSelectedFile(item._id, file.file, i)"></app-file-item>
            </ng-container>
        </div>   
    </div>
    
    <div class="modal-footer justify-content-end">
        <button type="button" class="btn btn-dark" (click)="modal.close(); this.filesForm.reset();">Cerrar</button> 
    </div>
    
    <ng-template #modal_respuesta let-modal>
        <modal-respuesta (onClose)="modal.close(); getUpdates();" [modalOptions]="modalOptions"></modal-respuesta>
    </ng-template>
     
</ng-template>




<ng-template #modalFile let-modal (onClose)="modal.close();"> 
    <app-file-modal (onClose)="modal.close()" [modalOptions]="modalOption"></app-file-modal>
    <!-- <form [formGroup]="filesForm"  novalidate>
        <div class="modal-header">
        <h6 class="modal-title" id="modal-basic-title">Agregar Archivos</h6>
         <button type="button" class="close" aria-label="Close" (click)="modal.close(); this.filesForm.reset();">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-12">
                <h6>Nombre <span class="text-danger">*</span></h6>
                <input type="text" class="form-control" placeholder="Aa..." formControlName="title">
                <small *ngIf="fieldIsValid('title')" class="text-danger">Campo Nombre es requerido!</small>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-12">
                <h6>Descripción <span class="text-danger">*</span></h6>
                <textarea name="textarea" id="" rows="5" class="form-control" formControlName="description" placeholder="Descripcion del archivo"></textarea>
                <small *ngIf="fieldIsValid('description')" class="text-danger">Campo Descripción es requerido!</small> 
            </div>
        </div>
        <div class="row mt-3"> 
            <div class="col-12">
                <h6>Archivo <span class="text-danger">*</span></h6>
                <div class="input-group mb-2"  placement="top" ngbTooltip="Tamaño máximo 10 MB." tooltipClass="tooltip-danger">
                    <div class="input-group-prepend">
                        <div class="input-group-text"><i class="fa fa-upload"></i></div>
                    </div>
                    <input  #file type="file" class="form-control" placeholder="Subir Archivo" accept= ".pdf, .png, .jpeg, .jpg, .dwg" (change)="selectFile($event)" multiple [disabled]="fileList.length >= 3">
                </div>
            </div> 
        </div>
    </div>
    </form>
     
    <div class="modal-footer justify-content-between">
        <button type="button" class="btn btn-danger" (click)="modal.close(); this.filesForm.reset();">Cancelar</button>
        <button type="button"   class="btn btn-success"  
        (click)="uploadFile(); modal.close();" [disabled]="!file.value" >Guardar</button>
    </div> -->
</ng-template>


<swal #deleteFileSwal class="d-none" title="¿Estas seguro de eliminar el archivo?" icon="question" [showCancelButton]="true" [focusCancel]="true" [swalOptions]="{confirmButtonText: 'Aceptar', cancelButtonText: 'Cancelar', confirmButtonColor: '#fd3666'}">
</swal>