
<mk-loader *ngIf="isLoading" class="loader-spinner"></mk-loader>
<div class="modal-body pb-0"> 
  <div class="row border-bottom pb-3 mb-3">
    <div class="col-12">
      <h3 class="text-primary m-0">Confirmar Cotización</h3>
      <span class="text-muted">Ingresa un título para la cotización</span>
    </div>
  </div>
  <form [formGroup]="FormQuoteConfirme" novalidate>
    <div class="row">  
      <div class="col-2">
        <h6>Folio <span class="text-danger">*</span></h6>
        <input type="text" class="form-control" placeholder="Aa..." formControlName="folio" maxlength="8" (keypress)="validator.isAlphaNumeric($event)">  
      </div>  
      <div class="col-7">
        <h6>Título <span class="text-danger">*</span></h6>
        <input type="text" class="form-control" placeholder="Aa..." formControlName="title" maxlength="60">  
      </div>  
      <div class="col-3">
        <h6>Válido hasta</h6>
        <div class="input-group date mb-0">
          <div class="input-group-prepend">
          <div class="input-group-text"> <i class="fe fe-calendar lh--9 op-6"></i> </div>
          </div> 
            <input 
            class="form-control" theme="dp-material"
            [(ngModel)]="selectDate"
            [ngModelOptions]="{standalone: true}" 
            readonly
            ngxDaterangepickerMd opens="left" 
            placeholder="DD/MM/YYYY"
            [locale] = dateOptions
            [singleDatePicker]="true"
            [autoApply]="true"
            [minDate]="today"
            [closeOnAutoApply]="true"
            > 
          </div> 
      </div>  
    </div>
    <div class="row mb-3">
      <div class="col-4">
        <small *ngIf="validForm.campoEsValido('folio', FormQuoteConfirme)" class="text-danger">Campo requerido, Máx 8 caracteres.</small>
      </div>
      <div class="col-5 text-right">
        <small *ngIf="validForm.campoEsValido('title', FormQuoteConfirme)" class="text-danger">Campo requerido, Máx 60 caracteres.</small>
      </div>
    </div>
    <div class="row border-top pt-2 bg-dark-1">
      <div class="col-7 d-flex">
        <h4 class="w-100 d-flex m-0">
          <span class="my-auto">Archivos</span>
            <div class="d-flex flex-column my-auto pl-3">
            <small class="text-muted card-sub-title m-0">Tipos de archivos validos: <b>(PDF, Imagenes, excel).</b></small>
            <small class="text-warning card-sub-title m-0">Máximo 10 MB por archivo.</small>
          </div>
        </h4>
        
      </div>
      <div class="col-5">
        <div class="input-group">
          <div class="input-group-prepend">
            <div class="input-group-text"> <i class="fa fa-plus lh--9 op-6 mr-2"></i>  Agregar</div>
          </div> 
          <input type="file" class="form-control" placeholder="Subir Archivo" accept= ".pdf, .png, .jpeg, .jpg, .dwg
          .csv, .ods, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel," multiple (change)="selectFile($event)" [disabled]="fileList.length >= 3">
        </div>
      </div>
    </div>
    <div class="row pb-2 bg-dark-1">
      <div class="col-12">
        <div class="d-flex flex-wrap" style="margin: 0 -10px;">
          <ng-container *ngFor="let file of fileList; index as i">
              <app-file-item [file]="file" (onDelete)='fileList.splice(i,1)' forceName="Archivo {{i + 1}}" [hasPrivate]="false"></app-file-item>
          </ng-container>
      </div>
      </div>
    </div>
    <ng-container *ngIf="false">
      <div class="row border-top py-2">
        <div class="col-7 d-flex flex-column">
          <h4 class="m-0">Elementos</h4>
          <span class="text-muted">Selecciona los elementos deseados en la cotización</span>
        </div>
        <div class="col-5 d-flex justify-content-end">
          <h3 class="text-success my-auto">Total: {{quoteTotal | currency}}</h3>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-12">
          <div class="card _subitems">
              <ng-container *ngFor="let item of quoteItems; index as i">
                <div class="row">
                <div class="col-6 d-flex ">
                  <label class="ckbox"><input type="checkbox" id="_chksub{{i}}" checked><span></span></label>
                  <div class="name pl-3">
                    <label for="_chksub{{i}}" class="m-0 cursor-pointer">
                      <b>{{item.code}} <span>{{item.description}}</span></b>
                    </label>
                  </div>
                </div>
                <div class="col-6 d-flex justify-content-between align-items-center"> <!--AQUI SI REEMPLAZA POR LAS VARIABLES CON LOS MONTOS EN VEZ DE CALCULAR :b-->
                  <b><span class="badge mr-2 {{item.unit}}">{{item.unit | status}}</span> {{item.qty}} <span>x</span> {{item.unitPrice | currency}} <span class="text-muted" [ngbTooltip]="'Margen de ganancia: '+item.earnMargin + '%'">(+ {{(item.qty * item.unitPrice) * (item.earnMargin / 100) | currency}})</span></b> <b class="text-success">{{item.qty * item.unitPrice + ((item.qty * item.unitPrice) * (item.earnMargin / 100)) | currency}}</b>
                </div>
              </div>
              </ng-container>
          </div>
        </div>
      </div>
    </ng-container>
    <div class="row border-top py-2 bg-dark-1">
      <div class="col-12 d-flex justify-content-between">
        <h4 class="my-auto">Porcentaje de Anticipo: <span class="__advInput">{{advancePercentValue | currency}}</span></h4>
        <div style="width: 120px;">
          <div class="input-group">
            <input type="text" class="form-control" maxlength="5" [ngModelOptions]="{standalone: true}" [(ngModel)]="advancePercent" (change)="advancePercentChanged()" (keypress)="validator.isNumber($event)" placeholder="0">
          <div class="input-group-append">
            <div class="input-group-text"> <i class="fe fe-percent lh--9 op-6"></i> </div>
            </div> 
          </div>
          <div class="text-right"><small *ngIf="validForm.campoEsValido('percent', FormQuoteConfirme, true, 'advance')" class="text-danger">Min 0, Máx 100</small></div>
          <!-- <ngx-slider [(value)]="advancePercent" [options]="sliderOptions" (valueChange)="getValue()"></ngx-slider> -->
        </div>
        
      </div>
      <div class="col-12 mb-2">
        <h6>Comentario Administrativo</h6>
        <textarea cols="30" rows="3" maxlength="250" class="form-control" formControlName="comment" placeholder="Aa..."></textarea>
        <small *ngIf="validForm.campoEsValido('comment', FormQuoteConfirme)" class="text-danger">Máx 250 caracteres.</small>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer justify-content-between">
    <button type="button" class="btn btn-danger" (click)="close(true)">Cancelar</button>
    <button type="button" class="btn btn-success" (click)="submit()"><i class="fa fa-file-text mr-2"></i> Crear Cotización</button>
</div>

<!-- SWAL -->
<swal #deleteFileSwal class="d-none" title="¿Estas seguro de eliminar el archivo?" icon="question" [showCancelButton]="true" [focusCancel]="true" [swalOptions]="{confirmButtonText: 'Aceptar', cancelButtonText: 'Cancelar', confirmButtonColor: '#fd3666'}">
</swal>