import { Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { DropdownService } from 'src/app/shared/services/dropdown.service';

@Component({
  selector: 'mkt-status',
  templateUrl: './mkt-status.component.html',
  styleUrls: ['./mkt-status.component.scss', '../../mk-table.comp.scss', '../../mk-table.comp.input.scss'],
  host:{
    "class" : 'mkt_col col-status',
    "(click)": '!readonly && !disabled ? this.setFocus(true) : {}',
    "[class.has-change]": "_hasChange"
  }
})
export class MktStatusComponent implements OnInit {
  
  public focus = false;

  private _hasChange: boolean = false;
  @ViewChild('input', {static: false}) input: ElementRef;

  @Input() options: IOption[];
  @Input() value: string;
  @Output() valueChange: EventEmitter<string> = new EventEmitter<string>();
  @Input() readonly: boolean;
  @Input() disabled: boolean;
  @Input() module: string = "DEFAULT";
  
  public setFocus = (focus: boolean) => {this.focus = focus; if(!focus) this.dropdown.closeDialog();}

  public statusColor = (name: string)=>{return this.options?.find(op => op.name == name)?.color || '#c2c5cf'}

  constructor(private dropdown: DropdownService) { }

  ngOnInit(): void {
  
  }

  ngOnChanges(changes: SimpleChanges): void{
    if (!changes?.value?.currentValue) {
    let defaultValue: any = changes?.options?.currentValue?.find(option => {return option?.default});
      defaultValue ? this.emitValue(defaultValue?.name) : null;
    }
  }

  //ngOnChanges(): void{this._hasChange = true; setTimeout(() => {this._hasChange = false}, 2000);}
  public emitValue(ev: any){this.valueChange.emit(ev)}

  SelectStatus(template: any, event: any){
    this.dropdown.openDialog(template, event, 60);
    this.dropdown.setHeight(42 + ((this.options.length * 40) > 300 ? 300 : (this.options.length * 40)));
  }
}

interface IOption{name: string, color: string}