import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { environment } from "src/environments/environment";
import { AlertService } from "src/app/shared/services/alert.service";
import { HttpService } from 'src/app/shared/services/http.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from 'src/app/shared/services/user.service';

@Component({
  selector: 'app-file-modal',
  templateUrl: './file-modal.component.html',
  styleUrls: ['./file-modal.component.scss']
})
export class FileModalComponent implements OnInit {
  
  public filesForm: FormGroup;
  public fileEvent: any;
  public isLoading: boolean;
  @Input() filesmanager = false;
  @Input() fileInfo;
  @Input() modalOption: any;
  @Input() file: any;
  @Input() ticket: any;
  @Input() tooltip: string = "Tamaño máximo 10 MB";

  @Output() onDidUpload: EventEmitter<any> = new EventEmitter<any>();
  @Output() onClose: EventEmitter<any> = new EventEmitter<any>();
  @Output() pushFile: EventEmitter<any> = new EventEmitter<any>();
  @Output() onReload: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild("file") inputFile: ElementRef<any>;
  
  public apiUrl = environment.apiUrl;

  constructor(
    private fb: FormBuilder,
    private alertService: AlertService,
    private httpService: HttpService,
    private modalService: NgbModal,
    public userService: UserService
    ) { }

  ngOnInit(): void {
    this.formFiles(); 
    this.fileDetail();       
  }


  formFiles() {

    
    this.filesForm = this.fb.group({
      title: [null, Validators.compose([Validators.required])],
      description: [null, Validators.compose([Validators.required])],
      file: [null, Validators.compose([Validators.required])],
      type: ['PUBLIC', Validators.compose([Validators.required])],
    });

  }

  compoIsValid(campo: string) {
    return (
      this.filesForm.controls[campo].errors &&
      this.filesForm.controls[campo].touched
    );
  }
  public selectFile(event: any): void {
    if (event.target?.files[0].size < 57671680) {
      let file: File = event.target.files[0];

      this.fileEvent = file;
      var reader = new FileReader();
      reader.readAsDataURL(file);
      // reader.onload = (event) => {
      //   this.imageName = event.target.result as string;
      // }
      this.filesForm.get("file").setValue(this.fileEvent);
      
    } else {
      this.alertService.infoToast("El archivo debe ser menor a 55MB.");
      event.target.value = "";
    } this.filesForm
  }

  async uploadFile() {
    
    let formData = new FormData();
    this.filesForm.value.id = this.modalOption?.id;

    if (!this.filesForm.valid) {
      this.filesForm.markAllAsTouched();
      this.alertService.infoToast("Campos incompletos, verifique porfavor");
      return;
    }
    
    if (this.filesForm.value.type == "PUBLIC" ) {
      this.alertService.confirmAlert("¿Estas seguro de subir el archivo como publico?",async ()=>{
        this.isLoading = true;
        this.buildFormData(formData, this.filesForm.value);        
    
        await this.httpService.put(`api/ticket/attachment`, formData).toPromise().then((resp: any) => {
            this.alertService.successToast(resp.message);
            // this.getDetail(this.ticket.id);
            this.modalService.dismissAll();
            this.filesForm.reset();
            this.onDidUpload.emit()
            this.isLoading = false;
        })
        .catch((err: any) => {
          console.log(err.message);
          this.isLoading = false;

          //this.alertService.error("Hubo un error", err.message);
        });
      }, `El ${this.userService.modulesName?.client || 'Cliente'} podra ver este archivo.`)
    }else{
      this.buildFormData(formData, this.filesForm.value);
      this.isLoading = true;

      await this.httpService.put(`api/ticket/attachment`, formData).toPromise().then((resp: any) => {
          this.alertService.successToast(resp.message);
          // this.getDetail(this.ticket.id);
          this.modalService.dismissAll();
          this.filesForm.reset();
          this.onDidUpload.emit()
          this.isLoading = false;
      })
      .catch((err: any) => {
        console.log(err.message);
        //this.alertService.error("Hubo un error", err.message);
        this.isLoading = false;

      });
    }
  }



  buildFormData(formData, data, parentKey?) {
    if (
      data &&
      typeof data === "object" &&
      !(data instanceof Date) &&
      !(data instanceof File)
    ) {
      Object.keys(data).forEach((key) => {
        // this.buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
        this.buildFormData(
          formData,
          data[key],
          parentKey ? `${parentKey}` : key
        );
      });
    } else {
      const value = data == null ? "" : data;

      formData.append(parentKey, value);
    }
  }

  close(){
    this.onClose.emit();
  }

  sendFile(){

    if (this.filesmanager) {
      this.filesForm.get('description').clearValidators();
      this.filesForm.get('description').setValue(null); 
           
    }
    if (!this.filesForm.valid) {
      this.filesForm.markAllAsTouched();
      this.alertService.infoToast("Campos incompletos, verifique porfavor");
      return;
    }
    
    this.pushFile.emit(this.filesForm.value);    
    this.close();
  }
  
  async download() {
    window.open(`${this.apiUrl}${this.fileInfo.file}`, "_blank");
  }

  public fileDetail(){
    if (this.modalOption.type == 'EDIT') {
      this.filesForm.patchValue({
        title: this.fileInfo?.title,
        description: this.fileInfo?.description,
        type: this.fileInfo?.type,
      });
    }
  }

  public async updateFile(){

    const formData = new FormData();
    this.filesForm.get('file').clearValidators();
    this.filesForm.get('file').updateValueAndValidity();

    if (!this.filesForm.valid) {
      this.filesForm.markAllAsTouched();
      this.alertService.infoToast("Campos incompletos, verifique porfavor");
      return;
    }

    formData.append('ticketId', this.ticket.id);
    formData.append('fileId', this.fileInfo.id);
    formData.append('title', this.filesForm.value.title);
    formData.append('description', this.filesForm.value.description);
    formData.append('type', this.filesForm.value.type);

    if (this.filesForm.value.type == "PUBLIC" ) {
      this.alertService.confirmAlert("¿Estas seguro de actualizar el archivo como publico?",async ()=>{
        this.isLoading = true;
       
        try {
          await this.httpService.put(`api/ticket/update/attachment`, formData).toPromise().then((resp: any) => {
            this.alertService.successToast(resp.message);
            // this.getDetail(this.ticket.id);
            this.modalService.dismissAll();
           this.filesForm.reset();
            this.onReload.emit();
           this.isLoading = false;
        });
        } catch (error) {
          console.log(error);
        }finally{
          this.isLoading = false;
        }

      }, `El ${this.userService.modulesName?.client || 'Cliente'} podra ver este archivo.`)
    }else{
      try {
        await this.httpService.put(`api/ticket/update/attachment`, formData).toPromise().then((resp: any) => {
          this.alertService.successToast(resp.message);
          // this.getDetail(this.ticket.id);
          this.modalService.dismissAll();
         this.filesForm.reset();
          this.onReload.emit();
         this.isLoading = false;
      });
      } catch (error) {
        console.log(error);
      }finally{
        this.isLoading = false;
      }
    }
  }
}
