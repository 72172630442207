import { AuthConstants } from '../../config/AuthConstants';
// import { StorageService } from './storage.service';
import { Injectable } from '@angular/core';
// import { Socket, SocketIoModule } from 'ngx-socket-io';
import { environment } from '../../../environments/environment';
import * as io from 'socket.io-client';
import { UserService } from './user.service';
import { Observable } from 'rxjs';
import { NotificationService } from './notification.service';
import { NavService } from './nav.service';
@Injectable({
  providedIn: 'root'
})
export class SocketService{

  private socket: io.Socket;
  private rooms: string[] = [];

  constructor(
    private userServices: UserService,
    private notificationService: NotificationService,
    private navServices: NavService
  ) { 
    if(this.userServices.login) this.connect();
  }

  public events(){
    this.socket.on('update', data => console.log(data))
    this.socket.on('connect_error', err => console.log(err))
    this.socket.on('connect_failed', err => console.log(err))
    this.socket.on('disconnect', (reason) => {
      
      if (
        reason == "io server disconnect" ||
        reason == "ping timeout" || 
        reason == "transport close" ||
        reason == "transport error"
        ) {
          // this.connect();
      }
    })
    this.socket.on('connect',() =>{
      console.log("Successfully connected! ",environment.socketUrl);
      this.connectRooms();
    })
  }

  public listenData(chennel:string): Observable<any> {
    let observable = new Observable(observer => {
      this.socket.on(chennel,(data)=>{
        observer.next(data);
       });
    })
    return observable;
  }

  public disconnect(): any {
    return this.socket?.disconnect();
  }

  async connect(token: string = null) {   
    this.socket = io.connect(environment.socketUrl,{
      auth: {
        token: token?token:this.userServices.login.token
      }
    });
    this.events();
  }

  async connectRooms(){
    console.log(this.rooms);
    
    this.rooms.forEach(room => {
      this.socket.emit('joinRoom', {room: room});
    });
    this.globalListener();
  }

  async addRoom(r){
    this.rooms.push(r);
    // this.socket.emit('joinRoom', {room: r});
  }

  async removeRoom(r){
    this.rooms = this.rooms.filter(room => {return room != r});
  }

  async clearRooms(){
    this.rooms = [];
  }

  private globalListener(){
    this.listenData(`newNotification`).subscribe(async (data) => {
      this.notificationService.update(data);
      this.notificationService.unread = this.notificationService._Notifications.filter(notification =>{return !notification.view;}).length;
    });

    this.listenData("profileRequest").subscribe(data=>{
      this.navServices?.requestProfile();
    });
  }

}
