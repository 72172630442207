import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { AuthenticationComponent } from './authentication/authentication.component';
import { FullLayoutComponent } from './shared/layouts/full-layout/full-layout.component';
import { Pages_Routes } from './shared/routes/pages.routes';
import { MkNotFoundComponent } from './components/mk-not-found/mk-not-found.component';
import { AuthGuard } from './shared/services/auth-guard.service';
import { MkWithoutPermissionsComponent } from './components/mk-without-permissions/mk-without-permissions.component';

const routes: Routes = [

  // { path: 'login', component: AuthenticationComponent },
  // { path: '', canActivate:[AuthGuard], component: FullLayoutComponent, children: Pages_Routes },
  // { path: '**', component: MkNotFoundComponent }

  { path: 'login', component: AuthenticationComponent },
  { path: 'pernotfound', component: MkWithoutPermissionsComponent},

  { path: '', 
    canActivate:[AuthGuard], 
    component: FullLayoutComponent, children: Pages_Routes },
  { path: '**', component: MkNotFoundComponent },
];

@NgModule({
  imports: [[RouterModule.forRoot(routes, {
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'enabled',
    relativeLinkResolution: 'legacy',
    preloadingStrategy: PreloadAllModules
    // enableTracing: true
  })],
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
