<div class="modal-header">
    <h6 class="modal-title" id="modal-basic-title"> Editar Imágenes</h6>
    <button type="button" class="close" aria-label="Close" (click)="close()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body"> 
    <div class="row">
        <div class="col-12">
            <div class="card px-3 pt-3">
                <div class="d-flex w-100 justify-content-between mb-3">
                    <div>
                        <h6 class="color-tx mb-0 tx-16">Imágenes adjuntas</h6>
                        <span class="text-muted lh-1">Máximo <b>10</b> imágenes.  <span class="text-danger">Tamaño máximo por archivo 5 MB.</span></span>
                        <small class="text-info my-auto ml-2"> </small>
                    </div>
                    
                    <div class="my-auto">
                        <!-- <button type="button" class="btn btn-primary " (click)="openModal(modalFile, 'md')">
                            <i class="fa fa-plus"></i> Agregar
                        </button> -->
                        <div class="input-group file-browser">
                            <label class="w-100 m-0">
                            <span *ngIf="files?.length < 10" class="btn btn-block btn-dark cursor-pointer lh-6" >
                                <i class="fa fa-plus mr-1"></i> Agregar
                                <input accept=".jpg, .png, .jpeg, .dwg" (change)="addFile($event)" type="file" style="display: none;" multiple >
                            </span>
                            </label>
                        </div>
                    </div>
                </div>
                <div class="css_imgContainer">
                    <ng-container *ngFor="let img of Images ; index as i">
                        <div class="img mb-3">
                            <img [src]="(img?.id) ? (url + img?.name) : (img?.name)  | imagesLoad">
                            <div class="_close bg-danger cursor-pointer"
                                *ngIf="img?.name != null "
                                (click)="deleteImage(i)"><i
                                    class="fa fa-close text-white tx-12"></i>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div> 
</div>

<div class="modal-footer justify-content-between">
    <button type="button" class="btn btn-danger" (click)="close()">Cancelar</button>
    <div>
        <button class="btn btn-success pl-4 pr-4" type="submit"
            (click)="submit()">Guardar</button>
    </div>
</div>