<form [formGroup]="DinamicForm">
        <mk-loader *ngIf="loadingFiles" class="loader-spinner" style="z-index: 119"></mk-loader>
        <div class="card m-0 main">
            <div class="d-flex w-100 justify-content-between">
                <div>
                    <h6 class="color-tx mb-0 tx-16"><span [innerHtml]="sanitizer.bypassSecurityTrustHtml(check?.label)"></span> <span class="text-danger" *ngIf="validateRequired()">*</span></h6>
                    <span class="text-muted" style="line-height: 1;">Listado de archivos adjuntos</span>
                </div>
                <div class="my-auto">
                    <button *ngIf="editable"  type="button " class="btn btn-primary text-white" (click)="openModal(modalFile); modalOption.type = 'ADD'">
                        <i class="fa fa-plus"></i> Agregar
                    </button>
                </div>
            </div>
            <div class="d-flex flex-wrap" style="--gridSize: {{(check?.gridSize || 3)}}">
                <ng-container *ngFor="let file of files; index as i">
                    <app-file-item [file]="file" [filesmanager]="true" (onSplice)='removeSelectedFile($event)' (click)="validDownload(file.file?.name, file.file?.id)"
                    [blocked]="!editable" [class.mr-0]="(check?.gridSize || 3) % i"
                    ></app-file-item>
                </ng-container>
                <ng-container *ngIf="!files?.length">
                    <div class="card _placeholder border-0"><div class="text-center text-muted m-auto">Sin Archivos</div></div>
                </ng-container>
            </div>
        </div>
    </form>
<ng-template #modalFile let-modal>
    <app-file-modal [tooltip]="check?.tooltip" [filesmanager]="true" (pushFile)="pushFile($event)" (onClose)="modal.close()" [modalOption]="modalOption" (onDidUpload)="getDetail(ticket.id)"></app-file-modal>
</ng-template>