<form [formGroup]="DinamicForm">

    <dnc-title [check]="check" [editable]="editable"></dnc-title>
    <div class="input-group  mb-0">
        <ng-select class="form-control" [placeholder]="check.target?.placeholder"
            [formControlName]="check?.formControlName" [clearable]="check.target?.clearable" (change)="dynamicEmit()"
            [disabled]="!editable"
            [readonly]="!editable"
            >

            <ng-option *ngFor="let item of Options" [value]="(check?.targetValue) ? item[check.targetValue] : item._id">
                {{check?.targetText ? item[check.targetText] : item?.name}}</ng-option>
        </ng-select>
    </div>
    <small class="text-danger"
        *ngIf="editable && fieldisValid.campoEsValido(this.check.formControlName, DinamicForm)">{{check?.validateMessage}}</small>
</form>