
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MktDateComponent } from './components/mkt-date/mkt-date.component';
import { MktLogComponent } from './components/mkt-log/mkt-log.component';
import { MktNumberComponent } from './components/mkt-number/mkt-number.component';
import { MktRatingComponent } from './components/mkt-rating/mkt-rating.component';
import { MktStatusComponent } from './components/mkt-status/mkt-status.component';
import { MktTagComponent } from './components/mkt-tag/mkt-tag.component';
import { MktTextComponent } from './components/mkt-text/mkt-text.component';
import { MkTableComponent } from './mk-table.component';
import { MktDropwdownComponent } from './components/mkt-dropwdown/mkt-dropwdown.component';
import { MktLinkComponent } from './components/mkt-link/mkt-link.component';
import { MktFilesComponent } from './components/mkt-files/mkt-files.component';
import { MktCheckComponent } from './components/mkt-check/mkt-check.component';
import { MktUserComponent } from './components/mkt-user/mkt-user.component';
import { DragulaModule } from 'ng2-dragula';
import { MktTitleComponent } from './components/mkt-title/mkt-title.component';
import { MktRowComponent } from './components/mkt-row/mkt-row.component';
import { MktInputComponent } from './components/mkt-input/mkt-input.component';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MktStatusChangeComponent } from './components/mk-status-change/mkt-status-change.component';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { MktPaginatorComponent } from './components/mkt-paginator/mkt-paginator.component';
import { MkDropdownModule } from './components/mkt-dropwdown/mkt-dropwdown.module';
import { MktSelectComponent } from './components/mkt-select/mkt-select.component';
import { MktDynamicSelectComponent } from './components/mkt-dynamic-select/mkt-dynamic-select.component';
import { MktImagesComponent } from './components/mkt-images/mkt-images.component';
import { MktLongTextComponent } from './components/mkt-longtext/mkt-longtext.component';
import { AuthModalComponent } from '../auth-modal/auth-modal.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { DpDatePickerModule } from 'ng2-date-picker';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { ComponentsModule } from '../components.module';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { TextMaskModule } from 'angular2-text-mask';
import { MkBidComponent } from './components/mkt-bid/mkt-bid.component';
import { MktCurrencyComponent } from './components/mkt-currency/mkt-currency.component';
import { IvyCarouselModule } from 'angular-responsive-carousel';
import { MktPdfComponent } from './components/mkt-pdf/mkt-pdf.component';
import { MktImportBtnComponent } from './components/mkt-import-btn/mkt-import-btn.component';
import { ValidateFormsDirective } from '../../directives/validate-forms.directive';
import { QuoteConfirmModalComponent } from '../../pages/home/components/quote-confirm-modal/quote-confirm-modal.component';
import { PipesModule } from '../../shared/pipes/pipes.module';

@NgModule({
  declarations: [
    AuthModalComponent,
    MkTableComponent, 
    MktTextComponent, 
    MktStatusComponent, 
    MktDateComponent, 
    MktNumberComponent, 
    MktTagComponent, 
    MktRatingComponent, 
    MktLogComponent,
    MktLinkComponent,
    MktFilesComponent,
    MktCheckComponent,
    MktUserComponent,
    MktTitleComponent,
    MktRowComponent,
    MktInputComponent,
    MktStatusChangeComponent,
    MktPaginatorComponent,
    MktSelectComponent,
    QuoteConfirmModalComponent,
    MktDynamicSelectComponent,
    MktImagesComponent,
    MktLongTextComponent,
    MkBidComponent,
    MktCurrencyComponent,
    MktPdfComponent,
    MktImportBtnComponent
  ],
  exports: [
    AuthModalComponent,
    MkTableComponent, 
    MktTextComponent, 
    MktStatusComponent, 
    MktDateComponent, 
    MktNumberComponent, 
    MktTagComponent, 
    MktRatingComponent, 
    MktLogComponent,
    MktLinkComponent,
    MktFilesComponent,
    MktCheckComponent,
    MktUserComponent,
    MktTitleComponent,
    MktRowComponent,
    MktInputComponent,
    MktStatusChangeComponent,
    MktPaginatorComponent,
    MktSelectComponent,
    MktDynamicSelectComponent,
    MktImagesComponent,
    MktLongTextComponent,
    MkBidComponent,
    MktCurrencyComponent,
    MktPdfComponent
    
  ],
  imports: [
    CommonModule,
    NgbModule,
    NgxSliderModule,
    DragulaModule.forRoot(),
    FormsModule,
    PipesModule,
    NgSelectModule,
    DpDatePickerModule,
    NgCircleProgressModule.forRoot(),
    NgxDaterangepickerMd.forRoot(), 
    SweetAlert2Module.forRoot(),
    MkDropdownModule,
    ReactiveFormsModule,
    ComponentsModule,
    TextMaskModule,
    IvyCarouselModule
  ],
  providers:[
    ValidateFormsDirective
  ]
})
export class MkTableModule { }
