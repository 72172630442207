import { Component, ElementRef, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CryptoSharkService } from 'src/app/shared/services/crypto-shark.service';
import { HttpService } from 'src/app/shared/services/http.service';
import { SocketService } from 'src/app/shared/services/socket.service';
import { UserService } from 'src/app/shared/services/user.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit {

  @ViewChild('input') input: ElementRef;
  @Input() idChat: string = "";
  @Input() disableChat: boolean = false;
  @Output() onReadMessage: EventEmitter<any> = new EventEmitter<any>();

  public messages: any = [];
  public chatForm: FormGroup;
  public isLoading: boolean = false;
  public pageMessages: number = 1;
  public messagesPerPage = 20;
  public chat: any;
  public element: HTMLElement;
  public isSendMessage: boolean = false;


  public url: string;
  public arrayMembers: any[] = [];
  public members: any[] = [];
  public mentions: any[] = [];
  public tmpMessage: any;
  public tmpMessageMentions: any;
  private isTagging: boolean = false;

  constructor(
    private httpService: HttpService,
    private fb: FormBuilder,
    private userServices: UserService,
    private socketServices: SocketService,
    private cryptoService: CryptoSharkService
  ) {
  }

  async ngOnInit() {
    this.chatForm = this.fb.group({
      text: [null, Validators.compose([Validators.required])],
      chat: [null, Validators.compose([Validators.required])],
      type: ['MESSAGE', Validators.compose([Validators.required])],
      mentions: [null]
    });
    this.url = environment.apiUrl;
  }

  async ngOnChanges() {

    if (this.idChat && this.idChat !== "") {
      this.chat = await this.httpService.get(`api/chat/${this.idChat}`).toPromise();


      var dataMentions = this.chat?.dataUsers;
      this.arrayMembers = dataMentions.filter(member => { return member._id !== this.userServices.user._id });
      this.members = this.arrayMembers;

      this.socketServices.clearRooms();
      this.socketServices.addRoom(this.userServices.user?.socketRoom);
      this.socketServices.addRoom(this.userServices.user?.socketRoom + this.chat?._id);
      this.socketServices.addRoom(this.userServices.user?.socketRoom + this.userServices.user._id);
      this.socketServices?.disconnect();
      this.socketServices.connect();

      this.socketServices.listenData(`newMessage${this.idChat}`).subscribe(async (data) => {
        if (!this.isSendMessage) {
          this.messages.push(data);
          this.onReadMessage.emit();
        } else {
          this.isSendMessage = false;
        }
      });
      if (this.chat?._id) {
        this.chatForm.patchValue({
          chat: this.chat?._id
        });
        this.getMessages();
        this.setReadMessage(this.chat?._id);
      }
    }
    else {
      this.members = [];
      this.messages = [];
    }
  }

  public async sendMessage() {
    if (!this.disableChat) {

      try {
        this.isSendMessage = true;
        this.mentions = this.input.nativeElement.children;

        let arreglo = [];
        for (const item of this.mentions) {
          var reg = arreglo.filter(mention => { return mention == item.dataset?.id });
          if (!reg.length && item.localName == 'span') {
            arreglo.push(item.dataset?.id);
          }
        }

        this.chatForm.patchValue({
          mentions: arreglo,
        })

        if((this.input.nativeElement.textContent)?.trim() != ''){
          this.chatForm.value.text = this.cryptoService.encryptAes(this.input.nativeElement.innerHTML, this.chat.key);

          let resp = await this.httpService.post(`api/messages/send`, this.chatForm.value).toPromise();
          this.chatForm.value.user = { _id: this.userServices.user._id, name: this.userServices.user.name };
          this.messages.push(this.chatForm.value);
          this.chatForm.get('text').setValue('');
          this.input.nativeElement.innerHTML = '';
          // this.message = '';
        }else{
          this.input.nativeElement.innerHTML = ''
        }
      } catch (error) {
        this.chatForm.get('text').setValue('');
        this.input.nativeElement.innerHTML = '';
        // this.message = '';
        console.log(error);
      }
    }
  }

  async getMessages() {
    try {
      this.isLoading = true;
      const response = await this.httpService.get(`api/messages/list/${this.chat?._id}/${this.pageMessages}`).toPromise();

      this.messages = response;
    } catch (error) {
      console.log(error);
    }finally{
      this.isLoading = false;
    }
  }

  private async setReadMessage(idChat: string) {
    try {
      await this.httpService.get(`api/messages/view/${idChat}`).toPromise();
      this.messages?.find(msg => {
        !(msg?.views?.find(view => { return view == this.userServices.user._id })) ? msg?.views?.push(this.userServices.user._id) : null;
      });
      this.onReadMessage.emit();
    } catch (error) {
      console.log(error);

    }
  }

  isMyMessage(id) {
    if (this.userServices.user._id == id) return true;
    return false;
  }

  public setFocus() {
    this.setReadMessage(this.chat?._id);
  }

  public validReadMessage(message: any): boolean {
    let read: boolean = (message?.views?.find(item => { return this.userServices?.user?._id == item })) ? true : false;
    return read;
  }

  onItemSelected(e: any) {

    setTimeout(() => {
      let html = this.input.nativeElement.innerHTML;
      this.input.nativeElement.innerHTML = html.replace(/{/g, `<span class="__mention" contenteditable="false" data-id="${e._id}">`).replace(/\}/g, '</span> &nbsp;');

      const selection = window.getSelection();
      const range = document.createRange();
      selection.removeAllRanges();
      range.selectNodeContents(this.input.nativeElement);
      range.collapse(false);
      selection.addRange(range);
      this.input.nativeElement.focus();

      this.updateMentions();
    }, 2);

  }

  updateMentions() {
    var _mentions = this.input.nativeElement.children;
    let arreglo = [];
    for (const item of _mentions) {
      var reg = arreglo.filter(mention => { return mention == item.dataset?.id });
      if (!reg.length && item.localName == 'span') {
        arreglo.push(item.dataset?.id);
      }
    }
    if(arreglo.length > 0) {
      var filtered = this.filterArray(this.arrayMembers, arreglo);
      this.members = filtered;
    }
    else{
      this.members = this.arrayMembers;
    }
  }

  filterArray = (arr1: any[], arr2: any[]) => {
    const filtered = arr1.filter(el => {
      return arr2.indexOf(el?._id) === -1;
    });
    return filtered;
  };


  onKeyDown(e) {
    var evt = e || window.event;
    var keyCode = evt.charCode || evt.keyCode;
    
    switch (keyCode) {
      /* ENTER */ case 13: { if (this.isTagging) this.isTagging = false; else ((this.input.nativeElement.textContent)?.trim() != '' ? this.sendMessage() : ""); } break;
      /* @ */ case 50: { this.isTagging = true;} break;
        detault: break;
    }
    
    this.updateMentions();
  }

  format(item: any) {
    return `{${item.name}}`;
  }

}
