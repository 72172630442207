import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { AlertService } from 'src/app/shared/services/alert.service';
import { HttpService } from 'src/app/shared/services/http.service';

@Component({
  selector: 'modal-preview',
  templateUrl: './modal-preview.component.html',
  styleUrls: ['./modal-preview.component.scss']
})
export class ModalPreviewComponent implements OnInit {
  
  @Output() onClose : EventEmitter<any> = new EventEmitter<any>();
  @Input() options: {url?: string, title?: string, type?: string, area?: string[], filter?: any[]}
  
  public pdf = null;

  constructor(
    private httpService: HttpService,
    private alertService: AlertService,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    let arrayFilter = [];
    for (const filter of this.options?.filter || []) {
      arrayFilter.push(filter.name);
    }
    let obj = {
      type: this.options.type,
      area: this.options.area,
      filter: arrayFilter
    }
    this.httpService.postBlob(this.options?.url, obj, "application/json").subscribe((response:any)=>{
      let url = URL.createObjectURL(response);
      this.pdf = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    });
  }

  public close(): void {this.onClose.emit()};

}
