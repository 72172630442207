
<div class="col-sm-12">
    <mk-loader *ngIf="isLoading" style="z-index: 120" class="loader-spinner"></mk-loader>

    <form [formGroup]="GenInfoForm">
        <div class="row mt-3">
            <div class="col-md-6">
                <h6>Título de licitación <span class="text-danger">*</span></h6>
                <input type="text" formControlName="title" maxlength="50" class="form-control tx-18 font-weight-bold" placeholder="Ingresa título de licitación">
                <small class="text-danger" *ngIf="fieldisValid.campoEsValido('title', GenInfoForm)"> El campo Título es requerido. Maximo 50 caracteres.</small>
    
            </div>
            <div class="col-md-3">
                <h6>Inicio y fin de licitación<span class="text-danger">*</span></h6>
                <div class="input-group date">
                    <div class="input-group-prepend"><div class="input-group-text"><i class="fe fe-calendar lh--9 op-6"></i></div></div>
                    <!-- <input formControlName="dateEnd" type="number" class="form-control" placeholder="Selecciona fecha de inicio y fin"> -->
                    <input type="text" 
                    ngxDaterangepickerMd 
                    [disabled]="disableForm"
                    [ngModelOptions]="{standalone: true}"
                    [timePicker]="false" 
                    [timePickerIncrement]="5" 
                    [timePicker24Hour]="true" 
                    [locale]="dateOptions" 
                    [singleDatePicker]="false" 
                    [(ngModel)]="DateEndbid" 
                    [autoApply]="true"
                    (change)="setDateBid()" 
                    [minDate]="today" 
                    [closeOnAutoApply]="false"  name="date" class="form-control" placeholder="DD/MM/YY - DD/MM/YY" autocomplete="off" readonly  tabindex="-1"/>
                </div>
            </div>
    
            <div class="col-md-3">
                <h6>Fecha límite entrega de {{userService?.modulesName?.bill || 'factura'}} <span class="text-danger">*</span></h6>
                <div class="input-group date">
                    <div class="input-group-prepend"><div class="input-group-text"><i class="fe fe-calendar lh--9 op-6"></i></div></div>
                    <!-- <input formControlName="dateEnd" type="number" class="form-control" placeholder="Selecciona fecha de inicio y fin"> -->
                    <input type="text" 
                    [disabled]="disableForm"
                    ngxDaterangepickerMd 
                    [ngModelOptions]="{standalone: true}"
                    [timePicker]="true" 
                    [timePickerIncrement]="5" 
                    [timePicker24Hour]="true" 
                    [locale]="dateOptions" 
                    [singleDatePicker]="true" 
                    [(ngModel)]="DateBill" 
                    [autoApply]="true"
                    (change)="setDateBill()" 
                    [minDate]="todayBill" 
                    [closeOnAutoApply]="false"  name="date" class="form-control" placeholder="DD/MM/YY - DD/MM/YY" autocomplete="off" readonly  tabindex="-1"/>
                </div>
            </div>
        </div>
        <div class="row mt-2 mb-2">
            <div class="col-md-12">
                <h6>Descripción <span class="text-danger">*</span></h6>
                <textarea rows="3" formControlName="description" type="text" class="form-control" placeholder="Ingresa descripción de la licitación"></textarea>
                <small class="text-danger" *ngIf="fieldisValid.campoEsValido('description', GenInfoForm)"> El campo Descripción es requerido.</small>
            </div>
        </div>
        <div class="row my-3">
            <div class="col-9">
                <div class="card p-3">
                    <div class="d-flex w-100 justify-content-between">
                        <div>
                            <h6 class="color-tx mb-0 tx-16">Archivos adicionales</h6>
                            <span class="text-muted lh-1">Archivos adjuntos de la licitación</span>
                        </div>
                        <div class="my-auto">
                            <!-- <button type="button" class="btn btn-primary " (click)="openModal(modalFile, 'md')">
                                <i class="fa fa-plus"></i> Agregar
                            </button> -->
                            <div class="input-group file-browser" placement="top" ngbTooltip="Tamaño máximo por archivo 5 MB." tooltipClass="tooltip-danger">
                                <label class="w-100 m-0">
                                <span class="btn btn-block btn-dark cursor-pointer">
                                    <i class="fa fa-plus mr-1"></i> Agregar
                                    <input [disabled]="disableForm" accept=".pdf, .xlsx, .xls, .jpg, .png, .jpeg, .dwg" (change)="addFile($event)" type="file" style="display: none;">
                                </span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div  class="d-flex flex-wrap" style="margin: 0 -7.5px;">
                        <ng-container *ngFor="let file of Files">
                            <app-file-item [filesmanager]="true" [file]="file" (onSplice)='removeSelectedFile($event)' [deletebtn]="disableForm" (click)="validDownload(file.name, file?.id)"></app-file-item>
                        </ng-container>
                    </div>
                </div>
                
            </div>
            <div class="col-3">
                <!-- CON AUDIO -->
                <h6>Audio</h6>
                        <div *ngIf="audio" class="d-flex _fileCard bg-success justify-content-between my-auto">
                            <!-- <h6 class="color-tx my-auto" style="font-size: 1.2em;">Audio</h6> -->
                            <label class="py-0 my-auto">
                                <audio class="_audioTag" controls type="audio/ogg">
                                    <source [src]="apiUrl+audio">
                                </audio>
                            </label>
                            <label [swal]="deleteAudioSwal" *ngIf="status != 'CLOSED' && status != 'FINISHED'" (confirm)="deleteAudio()" class="_btn btn-danger" ><i class="fa fa-trash"></i></label>
                        </div>
                        <!-- SIN AUDIO CREADO EN WEB -->
                        <div class="_fileCard card input-group cursor-pointer" [class.bg-success]="audio" *ngIf="!audio">

                            <input accept=".mp3, .m4a, .opus, .ogg, .wav" id="input_audio"
                                type="file" style="display: none;" (change)="status != 'CLOSED' && status != 'FINISHED' ? addAudio($event) : ''">
                    
                                <ng-container *ngIf="!audioFile">
                                    <label class="text-overflow cursor-pointer"  for="input_audio">
                                        <i class="fa fa-upload mr-1"></i> Subir Audio
                                    </label>
                                </ng-container>
                                <ng-container *ngIf="audioFile">
                                    <label class="text-overflow cursor-pointer" (click)="openModal(modalFile)">
                                        <i class="fa fa-file mr-1"></i> {{audioFile?.name}}
                                    </label>
                                </ng-container>
                                
                                
                    
                                <!-- <label class="_btn btn-info" *ngIf="audioFile" [for]="check?.formControlName" ><i class="fa fa-refresh"></i></label> -->
                                <label class="_btn btn-danger" *ngIf="audioFile" [swal]="deleteAudioSwal" (confirm)="deleteLocalAudio()" ><i class="fa fa-trash"></i></label>
                        </div>

            </div>
        </div>
        <div *ngIf="!id" class="row border-top bg-gray">
            <tab-participants [tabIndex]="0" [infoTab]="false" [id]="id"></tab-participants>
        </div>
    </form>
</div>
<div class="modal-footer justify-content-between">
        <button type="button" class="btn btn-dark" (click)="close()">Cerrar</button>

    <div>
        <ng-container *ngIf="id">
            <button type="button"  *ngIf="status == 'CLOSED' || status == 'CANCELLED'"  class="btn btn-info mr-3" (click)="openModal(modalExtend, 'xl')"><i class="mdi mdi-calendar-clock mr-1"></i> Extender Licitación</button>
            <button type="button" *ngIf="!disableForm" class="btn btn-danger mr-3" [swal]="cancelBid" (confirm)="Bidcancel()"><i class="mdi mdi-cancel mr-1"></i> Cancelar Licitación</button>
            <button type="button" class="btn btn-success" [disabled]="disableForm"  (click)="updateBid()"><i class="mdi mdi-check mr-1"></i>Actualizar Información</button>
        </ng-container> 
        <ng-container *ngIf="!id">
            <button type="button" class="btn btn-success" [swal]="createBidSwal" (confirm)="createBid()">Crear Licitación<i class="mdi mdi-send ml-1"></i></button>
        </ng-container>
        
    </div>
</div>

<ng-template #modalFile let-modal>
    <app-file-modal [filesmanager]="true" (pushFile)="recivido($event)" (onClose)="modal.close()" [modalOption]="modalOption"></app-file-modal>
</ng-template>


<ng-template #modalExtend let-modal>
    <modal-extend-bid  [id]="id" (onClose)="modal.dismiss()" (onUpdate)="detailBid();modal.dismiss();"></modal-extend-bid>
</ng-template>

<swal #cancelBid class="d-none" title="¿Estas seguro de cancelar la licitación? Se limpiaran los datos de la licitacion" icon="question" [showCancelButton]="true" [focusCancel]="true" [swalOptions]="{confirmButtonText: 'Aceptar', cancelButtonText: 'Cancelar', confirmButtonColor: '#fd3666'}">
</swal>
<swal #createBidSwal class="d-none" title="¿Estas seguro de crear la licitación?" icon="question" [showCancelButton]="true" [focusCancel]="true" [swalOptions]="{confirmButtonText: 'Aceptar', cancelButtonText: 'Cancelar', confirmButtonColor: '#fd3666'}">
</swal>

<swal #deleteAudioSwal class="d-none" title="¿Estas seguro de eliminar el audio?" icon="question" [showCancelButton]="true" [focusCancel]="true" [swalOptions]="{confirmButtonText: 'Aceptar', cancelButtonText: 'Cancelar', confirmButtonColor: '#fd3666'}">
</swal>