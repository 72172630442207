import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'mkt-number',
  templateUrl: './mkt-number.component.html',
  styleUrls: ['./mkt-number.component.scss', '../../mk-table.comp.scss', '../../mk-table.comp.input.scss'],
  host:{
    "class" : 'mkt_col col-number',
    "(click)": '!readonly && !disabled ? this.setFocus(true) : {}',
    "[class.has-change]": "_hasChange",
    "[class.disabled]": "disabled"
  }
})
export class MktNumberComponent implements OnInit {

  @Input() options: any;

  @Input() value: number;
  @Output() valueChange: EventEmitter<number> = new EventEmitter<number>();
  @Input() readonly: boolean;
  @Input() disabled: boolean;

  public focus = false;
  private _hasChange: boolean = false;

  @ViewChild('input', {static: false}) input: ElementRef;
  
  public setFocus = (focus: boolean) => {this.focus = focus; setTimeout(() => {if(focus) this.input.nativeElement.select()}, 10);}

  constructor() { }

  ngOnInit(): void {
    
  }

  public isNumeric(evt, value) {
    if(evt.key == "Enter") {this.emitValue(value); this.setFocus(false)};
    var theEvent = evt || window.event;
    var key = theEvent.keyCode || theEvent.which;
    key = String.fromCharCode(key);
    var regex = /[0-9]|\./;
    if (!regex.test(key)) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) theEvent.preventDefault();
    }
  }

  //ngOnChanges(): void{this._hasChange = true; setTimeout(() => {this._hasChange = false}, 2000);}
  public emitValue(ev: any){this.valueChange.emit(parseFloat(ev) || 0)}
}
