<form [formGroup]="DinamicForm" >
  <dnc-title [check]="check" [editable]="editable" class="m0"></dnc-title>
  <div class="d-flex" [ngClass]="(check?.target?.readonly || !editable) ? 'disabled' : ''" >
    <label class="custom-switch w-100" [for]="check.target.id"  [ngClass]="(check?.target?.readonly || !editable) ? '' : 'cursor-pointer'" > 
      <input class="custom-switch-input" type="checkbox"
      *ngIf="!(check?.target?.readonly || !editable)"
      (change)="dynamicEmit($event)" [id]="check.target.id"
       [value]="check.target.value"
        [formControlName]="this.check.formControlName" >

      <input class="custom-switch-input" type="checkbox"
      *ngIf="(check?.target?.readonly || !editable)"
       disabled
       [id]="check.target.id"
       [value]="check.target.value"
       [formControlName]="this.check.formControlName" >

      <span class="custom-switch-indicator"></span>
    </label>
  </div>
  <small *ngIf="false" class="text-danger">
    {{check.validateMessage}}
  </small>
</form>
