import { Component, OnInit, Output, Input } from '@angular/core';
import { GlobalAlertService } from 'src/app/shared/services/global-alert.service';

@Component({
  selector: 'alert-content',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
})
export class GlobalAlertComponent implements OnInit {

  @Input() alert: any;
  @Output() onClose: any;

  constructor(private service: GlobalAlertService) { 
  }

  ngOnInit(): void{}

  ngOnChanges(){
  }

  close(){
    this.onClose.emit(true)
  }

}
