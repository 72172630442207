import { Component, EventEmitter, Input, Output, OnInit, SimpleChange, SimpleChanges } from '@angular/core';
import { UserService } from 'src/app/shared/services/user.service';
import { textChangeRangeIsUnchanged } from 'typescript';

@Component({
  selector: 'dnc-table',
  templateUrl: './dnc-table.component.html',
  styleUrls: ['./dnc-table.component.scss', '../../dynForm.comp.scss'],
  host: {
    "[style.order]": "check.position",
    "[class]": "'col-sm-12 col-md-' + (check.col_size || 12)",
  },
})
export class DncTableComponent implements OnInit {

  @Input() check: any;
  @Input() dataInput: any;
  @Input() iniTable;

  @Output() onClickEdit: EventEmitter<any> = new EventEmitter<any>();
  @Output() onClickDelete: EventEmitter<any> = new EventEmitter<any>();

  public dataTable: any = [];
  public dataColumn = [];
  public action = false;
  public size = null;

  constructor(
    public userService: UserService
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes?.editForm?.firstChange) { // Reasigna los valores dependiendo del elemento a renderizar      
      
      if (this.check?.data == changes?.dataInput?.currentValue?.value) {
        this.dataTable = changes?.dataInput?.currentValue?.data;
      }
    // if(this.dataTable){
    //   for (const key in this.dataTable[0]) {
    //     if(key != 'primary')this.dataColumn.push(key);
    //    }
    // } 
    }
  }

  ngOnInit(): void {

    // Habilitar campo accion
    this.check.target.columns.forEach(element => {
      if (element.label == "Acción") {
        this.action = true
        this.size = element?.width;
      }

      if (element?.value && element?.value !== "primary") {
        this.dataColumn.push(element?.value);        
      }
    });
  }

  public removeRegister(index: any): void {
    this.onClickDelete.emit({position:index, array:this.check?.data});
  }

  public editRegister(index: any): void {
    this.onClickEdit.emit({position:index, array:this.check?.data});
  }

  ChangePrimaryContact(e: number, primary: boolean) {
    if (!primary) {
      for (let index = 0; index < this.dataTable.length; index++) {
        const cnt = this.dataTable[index];
        cnt.primary = (index === e);
      }
    }
    else {
      this.dataTable[e].primary = false;
    }
  }

}
