import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DropdownService } from 'src/app/shared/services/dropdown.service';

@Component({
  selector: 'mkt-longtext',
  templateUrl: './mkt-longtext.component.html',
  styleUrls: ['./mkt-longtext.component.scss', '../../mk-table.comp.scss'],
  host:{
    "class" : 'mkt_col col-longtext',
    "[class.has-change]": "_hasChange"
  }
})
export class MktLongTextComponent implements OnInit {

  @Input() value: string;
  @Output() valueChange: EventEmitter<string> = new EventEmitter<string>();
  @Input() readonly: boolean;
  
  private _hasChange: boolean = false;
  public focus = false;

  @ViewChild('input', {static: false}) input: ElementRef;

  public setFocus = (focus: boolean) => {
    this.focus = focus;
  }

  constructor(private dropdown: DropdownService) { }

  ngOnInit(): void {
  }

  //ngOnChanges(): void{this._hasChange = true; setTimeout(() => {this._hasChange = false}, 2000);}

  public emitValue(ev: any){this.valueChange.emit(ev)}

  inputText(template: any, event: any){
    this.dropdown.openDialog(template, event, 150);
    setTimeout(() => {if(focus) this.input.nativeElement.focus()}, 10)
  }
}
