import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'mkt-tag',
  templateUrl: './mkt-tag.component.html',
  styleUrls: ['./mkt-tag.component.scss', '../../mk-table.comp.scss', '../../mk-table.comp.input.scss'],
  host:{
    "class" : 'mkt_col col-tag',
    "(click)": '!readonly ? this.setFocus(true) : {}',
    "[class.has-change]": "_hasChange"
  }
})
export class MktTagComponent implements OnInit {

  @Input() options: any;
  @Input() value: string;
  @Output() valueChange: EventEmitter<string> = new EventEmitter<string>();
  @Input() readonly: boolean;
  
  @ViewChild('input', {static: false}) input: ElementRef;

  private _hasChange: boolean = false;
  public focus = false;
  public setFocus = (focus: boolean) => {this.focus = focus; setTimeout(() => {if(focus) this.input.nativeElement.focus()}, 10);}

  constructor() { }

  ngOnInit(): void {
  }

  //ngOnChanges(): void{this._hasChange = true; setTimeout(() => {this._hasChange = false}, 2000);}
  public emitValue(ev: any){this.valueChange.emit(ev)}
}
