<div class="modal-body py-0"> 
  <div class="row border-bottom py-3 _title">
    <div class="col-12 d-flex justify-content-between">
      <div>
        <h3 class="text-primary m-0"><i class="mdi mdi-tag"></i> Licitación</h3>
        <span class="text-muted">Administración de licitación</span>
      </div>
      <div class="my-auto">
        <span class="status badge badge-st-{{(!status ? 'na' : status) | lowercase}}">
            <span class="mt-2 mb-{{extendedFlag ? 1 : 2}}">{{status | status:"BIDS" }}</span>
            <small *ngIf="extendedFlag" class="d-block mb-1 tx-14"><i class="mdi mdi-calendar-clock"></i> Extendida</small>
        </span>
      </div>
    </div>
  </div>
    <div class="row _tabs">
        <span [class.active]="tabIndex == 0" (click)="tabIndex = 0">Información General</span>
        <span [class.active]="tabIndex == 1" (click)="tabIndex = 1" [hidden]="!id">Participantes</span>
        <span [class.active]="tabIndex == 2" (click)="tabIndex = 2" [hidden]="!id">Chats <span class="badge badge-danger" *ngIf="bidData?.countUnread > 0">{{bidData?.countUnread}}</span></span>
        <span [class.active]="tabIndex == 3" (click)="tabIndex = 3" [hidden]="!id">Estimaciones</span>
        <span [class.active]="tabIndex == 4" (click)="tabIndex = 4" [hidden]="!id || status == 'CLOSED' ">{{(userService?.modulesName?.bill | titlecase) || 'Factura'}}</span>

    </div>

    <div class="row">
        <ng-container [ngTemplateOutlet]="tabInfo" *ngIf="tabIndex == 0"></ng-container>
        <ng-container [ngTemplateOutlet]="tabParticipants" *ngIf="tabIndex == 1"></ng-container>
        <ng-container [ngTemplateOutlet]="tabChats" *ngIf="tabIndex == 2"></ng-container>
        <ng-container [ngTemplateOutlet]="tabEstimate" *ngIf="tabIndex == 3"></ng-container>
        <ng-container [ngTemplateOutlet]="tabBill" *ngIf="tabIndex == 4"></ng-container>

    </div>
    
</div>
<!-- <div class="modal-footer justify-content-between">
    <button type="button" class="btn btn-danger" (click)="close()">Cerrar</button>
    <!-- <button type="button" class="btn btn-success" (click)="close()">Guardar y Enviar <i class="mdi mdi-send ml-1"></i></button> - ->
</div> - ->


<!-- TABS  -->

<ng-template #tabInfo> <tab-info [(id)]="id" (onClose)="close()" (updateStatus)="updatestatus($event)" (onSubmit)="submitBid($event)" (onExtend)="openModal(modalExtend)" [movementId]="movementId"></tab-info> </ng-template>

<ng-template  #tabParticipants> <tab-participants [tabIndex]="tabIndex" [status]="status" (onClose)="close()" [id]="id"></tab-participants> </ng-template>

<ng-template #tabChats> <tab-chats (onClose)="close()" [id]="id" [status]="status" (onReadMessage)="loadUnreadMessage()"></tab-chats> </ng-template>

<ng-template #tabEstimate> <tab-estimate (onClose)="close()" [status]="status" [id]="id"></tab-estimate> </ng-template>

<ng-template #tabBill> <tab-bill (onClose)="close()" [bidding]="bidData" [id]="id"></tab-bill> </ng-template>


<!-- MODALES  -->
<!-- 
<ng-template #pdf let-modalpdf>
    <div class="modal-header">
        <h6 class="modal-title" id="modal-basic-title">PDF</h6>
        <button type="button" class="close" aria-label="Close" (click)="modalpdf.close()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body p-0">
        <div class="_pdf"></div>
    </div>
    <div class="modal-footer justify-content-end">
        <button type="button" class="btn btn-dark" (click)="modalpdf.close()">Cerrar</button>
    </div>
</ng-template> -->

<!-- <ng-template #modalFile let-modal>
    <app-file-modal [filesmanager]="true" (pushFile)="recivido($event)" (onClose)="modal.close()" [modalOption]="modalOption"></app-file-modal>
</ng-template> -->