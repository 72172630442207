<div class="center">
    <div class="_block cursor-pointer text-center" [class._input]="!readonly" placement="top" [ngbTooltip]="formatDate(value)" (click)="!readonly && !options?.typeDate ? SelectDate(picker, $event) : {}" tooltipClass="tooltip-small">
      
        <ng-container *ngIf="options?.single && !options?.typeDate">
            <ng-container *ngIf="options?.format">
                {{value? ((this.value.startDate?this.value.startDate:this.value) | moment:this.options?.format || 'DD MMM'):'Sin Fecha' }}
                <!-- {{this.value?.startDate | moment:this.options?.format }} -->

            </ng-container>
            <ng-container *ngIf="!options?.format">
                {{value? ((this.value.startDate?this.value.startDate:this.value) | moment:'DD MMM YYYY'):'Sin Fecha' }}
            </ng-container>
        </ng-container>
        <ng-container *ngIf="(!options?.single || false) && !options?.typeDate">
            {{ value? (value?.startDate | moment:'DD MMM'):"..." }} - {{ value?(value?.endDate | moment:'DD MMM'): "..." }}
        </ng-container>

        <ng-container style="width: 110%;" *ngIf="options?.typeDate">
        <!-- Input para fecha Single-->

            <ng-container *ngIf="options?.single">
                <input  #input type="text" [textMask]="{mask: mask}" [value]="value? ((this.value.startDate?this.value.startDate:this.value) | moment: options?.entryFormat ? options?.entryFormat : 'MMM YYYY'):'Sin Fecha' " [placeholder]="placeholder" *ngIf="focus" (blur)="setFocus(false)" (change)="emitValue(input.value || '');">
                <ng-container *ngIf="!this.focus">
                          {{value? ((this.value.startDate?this.value.startDate:this.value) | moment: options?.showFormat ? options?.showFormat : 'MMM YYYY'):'Sin Fecha' }}
                </ng-container> 
            </ng-container>
        <!-- Input para fechas con rango-->
            <ng-container *ngIf="!options?.single">
                <input  #input type="text" [textMask]="{mask: mask}" [value]="value? ((this.value.startDate?this.value.startDate:this.value) | moment: options?.entryFormat ? options?.entryFormat : 'MMM YYYY'):'Sin Fecha' " [placeholder]="placeholder" *ngIf="focus" (blur)="setFocus(false)" (change)="emitValue(input.value || '');">
                <ng-container *ngIf="!this.focus">
                          {{value? ((value.startDate) | moment: options?.showFormat ? options?.showFormat : 'MMM YYYY'):'Sin Fecha' }} - {{value? ((value.endDate) | moment: options?.showFormat ? options?.showFormat : 'MMM YYYY'):'Sin Fecha' }}
                </ng-container> 
            </ng-container>
        </ng-container>

    </div>

</div>


<ng-template #picker>
    <div class="mkt_dropdown mkt_col">
        <div class="head">Selecciona Fecha</div>
        <div class="content py-0">
            <app-calendar [single]="options?.single" [value]="value" (onSelect)="emitValue($event);"></app-calendar>
        </div>
    </div>
</ng-template>

<!-- Banderas--> 
<!--Las siguientes configuraciones deberan de encontrarse dentro del objeto options-->

<!--typeDate = boleano //  Habilita el input para ingresar la fecha manualmente-->
<!--showFormat = string // Formato con el que mostrara la fecha-->
<!--entryFormat = string // Formato con el que se ingresara la fecha en modalidad input-->
