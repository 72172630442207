<form [formGroup]="DinamicForm">
    <dnc-title [check]="check" [editable]="editable"></dnc-title>

    <ng-container *ngIf="(editable && !check?.target?.readonly) else readonlyFile">

        <!-- placement="bottom"  [ngbTooltip]="editable && !check?.target?.readonly ? contractFile[0]?.name == null ? 'Buscar Archivo' : 'Ver Archivo' : ''" -->
    <div class="card input-group cursor-pointer" [class.bg-success]="Filename" >

        <input [accept]="check.accept" 
        (change)="contractFile[0].name !== null ? onFileChanged(contractFile[0], $event, true) : onFileChanged(contractFile[0], $event)" 
        type="file" [id]="check?.formControlName" style="display: none;"
        [disabled]="!editable">

            <ng-container *ngIf="!Filename">
                <label class="text-overflow cursor-pointer" [title]="Filename" [for]="check?.formControlName">
                    <i class="fa fa-upload mr-1"></i> Subir archivo
                </label>
            </ng-container>
            <ng-container *ngIf="Filename">
                <label class="text-overflow cursor-pointer" (click)="openModal(modalFile)">
                    <i class="fa fa-file mr-1"></i> Ver archivo
                </label>
            </ng-container>
            

            <label class="_btn btn-info" *ngIf="editable && Filename" [for]="check?.formControlName" ><i class="fa fa-refresh"></i></label>
            <label class="_btn btn-danger" *ngIf="contractFile[0]?.name" [swal]="deleteFileSwal" (confirm)="deleteFile()"><i class="fa fa-trash"></i></label>

        
        <!-- <div class="input-group-append">

            <button *ngIf="Download" class="btn btn-primary px-2" placement="top" (click)="download(this.contractFile[0]?.name)">
                <i class="fa fa-download"></i>
            </button>
        </div> -->
    </div>
</ng-container>

    <small class="text-danger" *ngIf="editable && fieldisValid.campoEsValido(check.formControlName, DinamicForm)">{{check.validateMessage}}</small>
</form>

<ng-template #readonlyFile>
        <ng-container *ngIf="!Filename">
            <div class="card input-group justify-content-between m-0 bg-muted">
                <label class="text-overflow cursor-pointer">
                    <i class="fa fa-file mr-1"></i> Sin archivo
                </label>
            </div>
        </ng-container>
        <ng-container *ngIf="Filename">
            <div class="card input-group justify-content-between m-0 bg-success">
                <label class="text-overflow cursor-pointer" (click)="openModal(modalFile)">
                    <i class="fa fa-file mr-1"></i> Ver archivo
                </label>
            </div>
        </ng-container>
</ng-template>

<ng-template #modalFile let-modal>
    <div class="modal-header">
    <h6 class="modal-title" id="modal-basic-title">Archivo</h6>
     <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
    </div>
    <div class="modal-body p-0"> 
        <ng-container *ngIf="url && (Filename.split('.')[1] == 'pdf' || Filename.split('.')[1] == 'png' || Filename.split('.')[1] == 'jpeg' || Filename.split('.')[1] == 'jpg')">
            <div class="row">
                <div class="col-12">
                  <div class="pdf">
                    <ng-container *ngIf="url">
                      <embed [src]="url" class="w-100 h-100">
                    </ng-container>
                  </div>
                </div>
              </div>
        </ng-container>
        <ng-container *ngIf="!url || (Filename.split('.')[1] != 'pdf' && Filename.split('.')[1] != 'png' && Filename.split('.')[1] != 'jpeg' && Filename.split('.')[1] != 'jpg')">
            <div class="p-4">
                <app-file-item class="w-100 mx-0" [file]="{name: Filename, file: {name:Filename}}" [blocked]="true" [filesmanager]="true"></app-file-item>
            </div>
        </ng-container>
    </div>
    <div class="modal-footer justify-content-between">
        <div>
            <ng-container *ngIf="!url">
                <button class="btn btn-info mr-3" (click)="download(this.contractFile[0]?.name)"><i class="fa fa-download mr-1"></i>Descargar</button>
            </ng-container>
            <button type="button" class="btn btn-dark" (click)="modal.dismiss()">Cerrar</button>
        </div>
    </div>
</ng-template>

<swal #deleteFileSwal class="d-none" title="¿Estas seguro de eliminar el archivo?" icon="question" [showCancelButton]="true" [focusCancel]="true" [swalOptions]="{confirmButtonText: 'Aceptar', cancelButtonText: 'Cancelar', confirmButtonColor: '#fd3666'}">
</swal>
