import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Menu, NavService } from '../../services/nav.service';
import { UserService } from '../../services/user.service';
import { environment } from '../../../../environments/environment';
import { SocketService } from '../../services/socket.service';

@Component({
  selector: 'app-sidemenu',
  templateUrl: './sidemenu.component.html',
  styleUrls: ['./sidemenu.component.scss']
})
export class SidemenuComponent implements OnInit {

  public menuItems: Menu[];
  public url: any;
  public logo: string;
  public badgetValue = {};
  public badget: any;

  @Input() isOpen: boolean = false;
  maySelect: boolean = false;

  constructor(
    private router: Router,
    public navServices: NavService,
    private userService: UserService,
  ) {

    this.logo = environment.urlAdmin + this.userService?.login?.company?.logo || "../../assets/img/brand/logoFeedbakWhite.svg"
    this.navServices.getModules().subscribe(menuItems => {

      this.menuItems = menuItems;
      this.router.events.subscribe((event) => {
        
        if (event instanceof NavigationEnd) {
          menuItems.filter(items => {
            if (items.path === event.urlAfterRedirects) {
              this.setNavActive(items);
            }
            if (!items.children) { return false; }
            items.children.filter(subItems => {
              if (subItems.path === event.urlAfterRedirects) {
                this.setNavActive(subItems);
              }
              if (!subItems.children) { return false; }
              subItems.children.filter(subSubItems => {
                if (subSubItems.path === event.urlAfterRedirects) {
                  this.setNavActive(subSubItems);
                }
              });
            });
          });
        }
      });
    });
    
  }

  ngOnChanges(){
    setTimeout(() => {
      this.maySelect = this.isOpen;
    }, this.isOpen ? 401 : 0);
  }

  //Active NavBar State
  setNavActive(item) {
    this.menuItems.filter(menuItem => {
      item.active = true;

      if (menuItem !== item) {
        menuItem.active = false;
        document.querySelector('.main-body').classList.remove('main-sidebar-show')       
      }
      if (menuItem.children && menuItem.children.includes(item)) {
        menuItem.active = true;
      }
      if (menuItem.children) {
        menuItem.children.filter(submenuItems => {
          if (submenuItems.children && submenuItems.children.includes(item)) {
            menuItem.active = true;
            submenuItems.active = true;
          }
        });
      }
    });
  }

  async ngOnInit() {
    // this.navServices._dataBadget[menuItem?.module]
    this.navServices?.requestProfile();
    this.navServices.changeBadget.subscribe((data:any)=>{
      this.badget = data;
    });
  }
}
