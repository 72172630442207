import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'mkt-paginator',
  templateUrl: './mkt-paginator.component.html',
  styleUrls: ['./mkt-paginator.component.scss'],
  host: {
    'class': 'd-flex justify-content-between',
  }
})
export class MktPaginatorComponent implements OnInit {

  @Input() arrayLength: number;
  @Input() pageSize: number;
  @Input() color: string;

  public index: number = 0;
  public _pages: any[] = [];

  @Output() onSelect: EventEmitter<number> = new EventEmitter<number>()

  constructor() { }

  ngOnInit(): void{}

  ngOnChanges(): void {this.getBtns();}

  public getBtns() {this._pages = new Array(Math.ceil((this.arrayLength / this.pageSize) || 1));}

  public selectIndex(number):void {this.index = (number >= 0 ? number : 0); this.onSelect.emit(this.index)}

  public getCurrentLength(): number{
    var stack = this.pageSize * this.index;
    return stack + this.pageSize > this.arrayLength ? (stack + (this.arrayLength - stack)) : (stack + this.pageSize);
  }
}
