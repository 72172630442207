import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { DynamicControlsService } from 'src/app/shared/services/dynamic-controls.service';
import { DyamicFormService } from 'src/app/shared/services/dynamic-form.service';

@Component({
  selector: 'dnc-dropdown',
  templateUrl: './dnc-dropdown.component.html',
  styleUrls: ['./dnc-dropdown.component.scss', '../../dynForm.comp.scss'],
  host:{
    "[style.order]": "check.position",
    "[class]": "'col-sm-12 col-md-' + (check.col_size || 4)"
  }
})
export class DncDropdownComponent implements OnInit {

  @Output() onChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() onCondition: EventEmitter<any> = new EventEmitter<any>();

  @Input() editable : boolean = true;
  @Input() check: any;
  @Input() editForm: any;
  @Input() touched : boolean = false;
  @Input() editConditions: any = [];

  public DinamicForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    public dynamicControls: DynamicControlsService,
    private dynamicForm: DyamicFormService,
    public sanitizer: DomSanitizer

  ) { }

  async ngOnChanges(change: SimpleChanges){
    if (!change.editForm?.firstChange) { // Reasigna los valores dependiendo del elemento a renderizar
      if (this.check?.form == change.editForm?.currentValue?.name) {
        this.DinamicForm = this.dynamicForm.ReMapEditForm(change.editForm?.currentValue?.form, this.DinamicForm);
        this.DynamicValidators();
      }   
     }

     if (change.touched?.currentValue) {
      this.DinamicForm.markAllAsTouched();
    }

     // Validadores Dynamicos
     if (!change.editConditions?.firstChange) {      
      if ((change.editConditions?.currentValue?.data)?.some(item => {return item?.condition?.target == this.check?.formControlName})) {
        let conditionConfig = (change.editConditions?.currentValue?.data).find(async item => {return item?.condition?.target == this.check?.formControlName});

      
        // let field = conditionConfig.condition?.target;
        let validatorsRAW = conditionConfig?.validators;
    
        // this.DinamicForm.controls[field].clearValidators();
        // window[this.check?.form + ""].controls[field].clearValidators();        

        // let result = await this.dynamicForm.ArrayValidators(validatorsRAW);

        // await this.DinamicForm.get(field).setValidators(result);
        // this.DinamicForm = this.fb.group({
        //   [this.check.formControlName]:[window[this.check?.form]["value"][this.check.formControlName], Validators.compose(result)]
        // });

        // window[this.check?.form + ""].controls[field].setValidators(result);
        this.DinamicForm.markAsUntouched();

        if (validatorsRAW.required && this.check?.target?.validators?.required) {
          this.check.target.validators.required = true;  
     
        }else if(!validatorsRAW.required && this.check?.target?.validators?.required){
          this.check.target.validators.required = false;

        }else if(validatorsRAW.required && !this.check?.target?.validators?.required){
          this.check.target.validators.required = true;          

        }else if(validatorsRAW.required && !this.check?.target?.validators?.required){
          this.check.target.validators.required = false;
        } 
        
        // window[this.check?.form + ""] = await this.dynamicForm.MapForm(this.DinamicForm, window[this.check?.form + ""]);     
      }
      
    }
  }

  async ngOnInit() {
    let selected = this.check?.target?.default ? this.check?.target?.options[this.check.target.default]?.value : this.check?.target?.options[0]?.value; 
   
    this.DinamicForm = this.fb.group({
      [this.check.formControlName]:[selected]
    });
      
    this.DinamicForm = await this.dynamicControls.asignControls(this.check,  this.DinamicForm);        

    //Establece los valores de inicio del formulario
    this.dynamicEmit();
    
  }

  dynamicEmit(){
    this.onChange.emit({ event:this.DinamicForm, form: this.check?.form, automation: this.check?.target?.automation, fieldType: this.check?.type, field: this.check?.formControlName});   

    this.DynamicValidators();
  }

  public validateRequired(): boolean{
    let required: boolean = this.check?.target?.validators?.required || false;
    return required;
  }

  public DynamicValidators(){
    try {
      if(this.check?.target?.ValidCondition){
        let conditionValue = [];
          
          for (const valid of this.check?.target?.ValidCondition) {
            switch (valid?.condition?.condition) {
              case "EQUAL":                
                 conditionValue  = this.check?.target?.ValidCondition.filter(item => {return item?.condition?.value == this.DinamicForm?.value[this.check?.formControlName] && (item?.condition?.condition == 'EQUAL')})
                break;
            
              default:
                break;
            }
          }
           
          this.onCondition.emit(conditionValue);
      }; 
    } catch (error) {
     console.log(error);
    }
  }

}
