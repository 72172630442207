import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'mk-table',
  templateUrl: './mk-table.component.html',
  styleUrls: ['./mk-table.component.scss'],
})
export class MkTableComponent implements OnInit {

  @Input() columns: any;
  @Input() data: any;
  @Input() color: string = "#0918a1";


  @Input() input: boolean = true;

  @Input() table_id: string = "";

  @Input() dragConfig: IDragConfiguration = {group: 'DRAG_TABLE', id: this.table_id};

  @Output() onAdd: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }

  addItem(value: any){this.onAdd.emit(value)}

}

interface IDragConfiguration {group: string, id: string}