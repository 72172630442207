
<div class="card-body py-3 px-2 d-flex flex-column" #scrollMe [scrollTop]="scrollMe?.scrollHeight">
    <mk-loader *ngIf="isLoading"></mk-loader>
    <ng-container *ngFor="let item of messages"> 
        <!-- <div class="d-flex w-100 mb-1 justify-content-between">
            <div class="card _msg {{ msg?.user?._id == userServices?.user?._id ? 'admin':'client'}}">
                <div class="d-flex w-100 mb-1 justify-content-between">
                    <span class="text-overflow">{{msg?.user?.name}}</span>
                    <span class="ml-2" [ngStyle]="{'font-weight': !validReadMessage(msg) ? 'bold' : ''}">{{msg.date | moment:'lll'}}</span>
                </div>
                <div class="w-100 lh-3">{{msg.text | decryptChat: chat?.key}}</div>
            </div>
        </div> -->
        <div class="bubble {{item?.color}}" [class.self]="isMyMessage(item?.user?._id)"> <!--validar que el usuario sea yo-->
            <div class="head">
            <span>{{!isMyMessage(item?.user?._id)?(item?.user?.name):''}}  <span>{{item?.date | moment:'l - hh:mm A'}}</span></span>
            </div>
            <div class="body">
            <div class="img" *ngIf="!isMyMessage(item?.user?._id)">
                <img [src]="((url + item?.user?.image)  | imagesLoad) || '../../../assets/img/placeholder-user.png'" alt="" *ngIf="item?.user?.image">
                <ion-icon name="person" *ngIf="!item?.user?.image"></ion-icon>
            </div>
            <div class="text" [innerHtml]="item?.text | decryptChat: chat?.key"></div>
            <span class="ml-2" ngbDropdown *ngIf="msg?.user?._id == userServices?.user?._id"><i class="fa fa-ellipsis-v cursor-pointer op-5" aria-hidden="true" ngbDropdownToggle id="commentOptions"></i>
                <div ngbDropdownMenu="commentOptions">
                    <button ngbDropdownItem [swal]="deleteCommentSwal" (confirm)="deleteComment(msg?.id, i)"><i class="fa fa-trash mr-2 op-5"></i>Borrar</button>
                </div>
            </span>
            </div>
        </div>
    </ng-container>


    <div style="height: 60px;" class="w-100 d-flex my-auto" *ngIf="this.messages?.length === null || !this.messages?.length">
        <span class="m-auto text-muted">Sin Mensajes</span>
    </div>
</div>
<form [formGroup]="chatForm">
    <div class="card-footer border-top p-0">
        <div class="input-group position-relative">

            <div class="form-control border-0 ___i" 
            type="text" (focus)="setFocus()" placeholder="Mensaje..."
            #input
            [contentEditable]="!disableChat"
            [mention]="members" 
            [mentionConfig]="{labelKey:'name', mentionSelect: format, dropUp:true}" 
            [mentionListTemplate]="mentionListTemplate"
            (onItemSelected)="onItemSelected($event)"
            (keydown)="onKeyDown($event)"></div>
            <div class="input-group-append"> <!--  (!input.value && chatForm.invalid)  -->
            <button class="btn btn-clear text-primary border-0 py-0" [disabled]="disableChat || !input.innerHTML.length"
            (click)="idChat ? sendMessage() : {}" 
            ><i class="mdi mdi-send tx-24"></i></button>
        </div>




        <!--  
        <input class="form-control border-0" type="text" (focus)="setFocus()" placeholder="Mensaje..."
        #input formControlName="text" [readonly]="disableChat" >
            <div class="input-group-append">
                <button class="btn btn-clear text-primary border-0 py-0" [disabled]="disableChat || !input.value.length"
                (click)="idChat ? sendMessage() : {}" ><i class="mdi mdi-send tx-24"></i>
            </button>
        </div>
        -->
 
        </div>
    </div> 

</form>



<ng-template #mentionListTemplate let-item="item">
    <div class="itemMention">
        <img class="mr-3" alt="" width="30" height="30" [src]="url+(item?.image)"  >
        &nbsp;
        <span>{{item?.name}}</span>
    </div>
</ng-template>
    