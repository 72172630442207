import { TitleCasePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core'
import { UserService } from '../services/user.service';

@Pipe({ name: 'roleName' })

export class RoleNamePipe implements PipeTransform {

    public json = {
        "roles": {
            "SUPPORT": "Soporte",
            "SUPERVISOR": "Supervisor",
            "ADMIN": "Director",
            "PROVIDER": this.titlecase.transform((this.userService.modulesName?.provider || 'Proveedor')),
            "CONTRACTOR": this.titlecase.transform((this.userService.modulesName?.contractor || 'Contratista')),
            "CLIENT": this.userService.modulesName?.client || "Cliente",
            "AREA_LEADER": this.titlecase.transform((this.userService.modulesName?.area_leader || 'Lider de área'))
        },
    }

    constructor(
        public userService: UserService,
        public titlecase: TitleCasePipe
    ) { }

    transform(value: string, module: string = "roles"): string {
        return this.json[module][value];
    }
}
