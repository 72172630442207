import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AlertService } from 'src/app/shared/services/alert.service';
import { HttpService } from 'src/app/shared/services/http.service';
import { SocketService } from 'src/app/shared/services/socket.service';

@Component({
  selector: 'tab-chats',
  templateUrl: './tab-chats.component.html',
  styleUrls: ['./tab-chats.component.scss']
})
export class TabChatsComponent implements OnInit {

  @Input() id: string = null;
  @Input() status;

  @Output() onClose: EventEmitter<any> = new EventEmitter<any>();
  @Output() onReadMessage: EventEmitter<any> = new EventEmitter<any>();
  close = ()=>{this.onClose.emit()}
  
  public isLoading: boolean = false;
  public chats: any = null;
  public chatSelected: any = null;
  public disableChat: boolean = false;
  constructor(
    private httpService: HttpService,
    private alertService: AlertService,
    private socketServices: SocketService
  ) { 
  }

  async ngOnInit() {
    await this.loadChats();

    if (this.status != "CREATED" && this.status != "STARTED") {//? Invalida el chat cuando el estatus de la licitacion no sea STARTED o CREATED
      this.disableChat = true;      
    }

    // for (const chat of this.chats){ 
    //   console.log(chat);
      
    //   this.socketServices.listenData(`newMessage${chat?._id}`).subscribe(async (data) => { this.loadChats(); }); 
    // }
  }

  private async loadChats(){
    return new Promise( async (resolve, reject) => {
      try {
        this.isLoading = true;
        this.chats = await this.httpService.get(`api/chat/byBidding/${this.id}`).toPromise();
        resolve(null);
      } catch (error) {
        this.isLoading = false;
        resolve(null);
      }
    });
  }

  public async selectChat(chat:any){
    try {
      this.chatSelected = chat;
      this.chats.filter(item => {if(item._id != chat._id)item.selected = false;});
      
    } catch (error) {
      console.log(error);
      
    }
  }

  public getUnreadMessage():void{
    this.loadChats();
    this.onReadMessage.emit();
  }

}
