import { Component } from '@angular/core';
import * as moment from 'moment';
import data from '../../package.json';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Tickets';
  // public isSpinner = true;
  public version = data?.version;

  ngOnInit(): void {
    // setTimeout(() => {
    //   this.isSpinner = false;
    // },1000)

    // this.setZoom();
    moment.locale('ES'); 
  }


  setZoom(){
    document.body.setAttribute('style', 'zoom: .85');
  }


}
