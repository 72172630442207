import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/shared/services/http.service';

@Injectable({
  providedIn: 'root'
})
export class DataBankService {
  private _users: ISelects[];
  private _provider: ISelects[];
  private _contratista: ISelects[];
  private _department: ISelects[];
  private _customer: ISelects[];
  private _contract: ISelects[];
  private _support_users: ISelects[];
  private allCollection = ['users', 'provider', 'contractor', "department", "customer", "contract", "support_users"]

constructor(
  private httpService: HttpService,
) { }

  public loadData(){
    for (const collection of this.allCollection) {
      if (collection != "support_users") {
        this.dataLoad(collection);
      }else{
        this.SupportUsersCatalog();
      }
    }
  }

  public data(collection:string){
    return this['_'+collection];
  }

  async dataLoad(collection:string){
    return new Promise( async (resolve, reject) => {
      try {
        this['_'+collection] = await this.httpService.get(`api/catalogue/${collection}`).toPromise();
        resolve(this['_'+collection]);
      } catch (error) {
        resolve(false);
        console.log(error);
      }
    });
  }

  async SupportUsersCatalog(){
    return new Promise( async (resolve, reject) => {
      try {
        this._support_users = await this.httpService.get(`api/user/filter/supportLeader`).toPromise();
        resolve(this._support_users);
      } catch (error) {
        resolve(false);
        console.log(error);
      }
    });
  }

  public async dataLoadById(collection: string, target: string, id: string){
    return new Promise( async (resolve, reject) => {
      try {
        let response = await this.httpService.get(`api/catalogue/${collection}/${target}/${id}`).toPromise();
        resolve(response);
      } catch (error) {
        resolve(false);
        console.log(error);
      }
    });
  }
  
}

export interface ISelects{
  _id: string
  name: string
}
