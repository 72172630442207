import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
/**
 * Servicio para generar alertas.
 * INSTRUCCIONES DE USO: Se inyecta en el constructor de tu componente y mandas llamar a cada método
 */
export class AlertService {

  constructor() { }

  /**
   * Genera una alerta de tipo success
   * @default confirmButtonText "Aceptar"
   * @default timerProgressBar false
   * @default timer 1000
   */
  public successAlert = (title: string, text: string, confirmButtonText: string = "Aceptar", timerProgressBar: boolean = false, timer: number = 2000): any => Swal.fire({ title, text, icon: 'success', confirmButtonText, timerProgressBar, timer, showConfirmButton: !timerProgressBar })

  /**
   * Genera un toast de tipo success
   * @default timer 3000
   * @default timerProgressBar true
   */
  public successToast = (title: string, timer: number = 3000, timerProgressBar: boolean = true): any => Swal.fire({
    title,
    icon: 'success',
    showConfirmButton: false,
    background: '#0918a1', 
    iconColor: '#fff',
    customClass: { title: 'text-white'},
    position: 'top-end',
    timerProgressBar,
    timer,
    toast: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })

  /**
   * Genera una alerta de tipo error
   * @default confirmButtonText "Aceptar"
   */
  public error = (title: string, text: string, confirmButtonText: string = "Aceptar"): any => Swal.fire({ title: title, text: text, icon: 'error', confirmButtonText: confirmButtonText })

  /**
   * Genera un toast de tipo success
   * @default timer 3000
   * @default timerProgressBar true
   */
   public errorToast = (title: string, timer: number = 3000, timerProgressBar: boolean = true): any => Swal.fire({
    title,
    icon: 'error',
    showConfirmButton: false,
    background: '#fd6074', 
    iconColor: '#fff',
    customClass: { title: 'text-white'},
    position: 'top-end',
    timerProgressBar,
    timer,
    toast: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })

  /**
   * Genera una alerta de tipo warning
   * @default confirmButtonText "Aceptar"
   */
  public warning = (title: string, text: string, confirmButtonText: string = "Aceptar"): any => Swal.fire({ title: title, text: text, icon: 'warning', confirmButtonText: confirmButtonText })

  /**
   * Genera un toast de tipo info
   * @default timer 3000
   * @default timerProgressBar true
   */
   public warningToast = (title: string, timer: number = 3000, timerProgressBar: boolean = true): any => Swal.fire({
    title,
    icon: 'info',
    showConfirmButton: false,
    background: '#ff9b21', 
    iconColor: '#fff',
    customClass: { title: 'text-white'},
    position: 'top-end',
    timerProgressBar,
    timer,
    toast: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })

  /**
   * Genera una alerta de tipo info
   * @default confirmButtonText "Aceptar"error
   */
  public info = (title: string, text: string, confirmButtonText: string = "Aceptar"): any => Swal.fire({ title: title, text: text, icon: 'info', confirmButtonText: confirmButtonText })

  /**
   * Genera un toast de tipo info
   * @default timer 3000
   * @default timerProgressBar true
   */
   public infoToast = (title: string, timer: number = 3000, timerProgressBar: boolean = true): any => Swal.fire({
    title,
    icon: 'info',
    showConfirmButton: false,
    background: '#01b8ff', 
    iconColor: '#fff',
    customClass: { title: 'text-white'},
    position: 'top-end',
    timerProgressBar,
    timer,
    toast: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })


  public confirmAlert(title: string, callback, html=''){
    Swal.fire({
      title: title,
      html: html,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Aceptar', 
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed) {
        return callback()
      }
    })
  }

}
