import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment'

@Pipe({
  name: 'diffTime'
})
export class diffTimePipe implements PipeTransform {
  public language: Object = {
    "ES": {
      DAY: "Dia(s)",
      MONTH: "Mes(es)",
      YEAR: "Año(s)" 
    },
    "EN": {
      DAY: "DAY",
      MONTH: "MONTH",
      YEAR: "YEAR"
    }
  };
  
  constructor(){}

  transform(date1, date2, yearDecimal, locale = "ES"): string {  
    if (!date1.isValid()) return "-"
    let ageCalculated = date2.diff(date1,"years", true).toFixed(2);
    let valid: boolean = parseInt(ageCalculated) == 0;
    if(yearDecimal) {valid = (parseInt(ageCalculated) == 0 || (ageCalculated % 1) > 0)}
    if (valid) {
      ageCalculated = date2.diff(date1, "months");
      if (ageCalculated == 0) {
          ageCalculated = date2.diff(date1, "days");
          
          return ageCalculated + ` ${this.language[locale]['DAY']}`;
          
      }else{
        return ageCalculated + ` ${this.language[locale]['MONTH']}`;
      }
    }else{ 
      return parseFloat(ageCalculated) + ` ${this.language[locale]['YEAR']}`;
    }
  }
}
 