import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ValidateFormsDirective } from 'src/app/directives/validate-forms.directive';
import { DynamicControlsService } from 'src/app/shared/services/dynamic-controls.service';
import { DyamicFormService } from 'src/app/shared/services/dynamic-form.service';

@Component({
  selector: 'dnc-multiple-select',
  templateUrl: './dnc-multiple-select.component.html',
  styleUrls: ['./dnc-multiple-select.component.scss', '../../dynForm.comp.scss'],
  host:{
    "[style.order]": "check.position",
    "[class]": "'col-sm-12 col-md-' + (check.col_size || 4)"
  }
})
export class DncMultipleSelectComponent implements OnInit {

  @Output() onChange: EventEmitter<any> = new EventEmitter<any>();
  @Input() check: any;
  @Input() editable : boolean = true;
  @Input() editForm: any;
  @Input() touched : boolean = false;


  public DinamicForm: FormGroup
  public itemsSelected: any[] = [];
  public tempChange = null;
  public initComponent: boolean = false; 

  constructor(
    private fb: FormBuilder,
    private dynamicControls: DynamicControlsService,
    public sanitizer: DomSanitizer,
    public fieldisValid: ValidateFormsDirective,
    private dynamicForm: DyamicFormService,

  ) { }

  async ngOnInit(){
    this.DinamicForm = this.fb.group({
      [this.check.formControlName]: [null]
    });

    this.DinamicForm = await this.dynamicControls.asignControls(this.check, this.DinamicForm);

    //Establece los valores de inicio del formulario
    this.dynamicEmit();
  }

  async ngOnChanges(change: SimpleChanges){
    if (!change.editForm?.firstChange) { // Reasigna los valores dependiendo del elemento a renderizar
      
      if (this.check?.form == change.editForm?.currentValue?.name) {// el cambio pertenece al formulario en configuracion?
        if (change.editForm?.currentValue?.form?.value[this.check?.formControlName]) {// en el cambio se encuentra el campo en configuracion
        
          // Se identifica si es que el dato es de tipo Array.
          // .F True
          //En caso de que sea true, se asigna a la variable de ngModel.
          // .R False
          // En caso de ser false, significa que el dato es de tipo string (como se guardaba anteriormente), asi que
          // se realiza un parseo con el algoritmo mostrado a continuacion.
  
          if(!Array.isArray((change?.editForm?.currentValue?.form?.value[this.check?.formControlName]))){
            // .F True ---->
            let parseArray = JSON.parse(change?.editForm?.currentValue?.form?.value[this.check?.formControlName] || "[]");
            
            let array = [];
            await parseArray.forEach(async element => { 
              array.push(this.check?.target?.options.find(opt => {return opt.value == element}))
            });
  
            this.itemsSelected = array;
            
          }else{
            // .R False ---->
            (change?.editForm?.currentValue?.form?.value[this.check?.formControlName]) ? this.itemsSelected = change?.editForm?.currentValue?.form?.value[this.check?.formControlName] : "";          
          }
        }
     
      }   
     }

     if (change.touched?.currentValue) {
      this.DinamicForm.markAllAsTouched();
    }
  }


  dynamicEmit(){
   // SOLUCIONA BUG DE FORMULARIO NO TOUCHED PARA COMPONENTE MULTI-SELECT
    !this.initComponent ? "" : this.DinamicForm?.markAllAsTouched();
    this.initComponent = true;

    this.DinamicForm.patchValue({
      [this.check.formControlName]: this.itemsSelected.length ?  JSON.stringify(this.itemsSelected) : []
    })

    this.onChange.emit({ event:this.DinamicForm, form: this.check?.form});    
  }

  public validateRequired(): boolean {
    let required: boolean = this.check?.target?.validators?.required || false;
    return required;
  }
}
