
<div class="css_mktUser" [placement]="tooltipDirection" ngbTooltip="{{user?.name == 'N/A' ? '' : user?.name}}" tooltipClass="tooltip-small">
    <div class="_avatar bg-{{user?.name == 'N/A' ? 'muted' : color}}" *ngIf="user else noUser">
        <ng-container *ngIf="!user.img">
            <span *ngIf="user?.name != 'N/A' else userNA">{{user?.name?.split(' ')[0][0]}}{{(user?.name?.split(' ')[1] || ' ')[0]}}</span>
        </ng-container>
        <img [src]="user?.img" *ngIf="user.img">
    </div>
</div>



<ng-template #noUser>
    <div class="_avatar bg-muted"></div>
</ng-template>

<ng-template #userNA>
    <span class="text-muted">N/A</span>
</ng-template>