import { LOCALE_ID, NgModule } from '@angular/core';
import { CommonModule, DatePipe, DecimalPipe, registerLocaleData, TitleCasePipe } from '@angular/common';
import { MkImgGroupComponent } from './mk-img-group/mk-img-group.component';
// import { PipesModule } from '../shared/pipes/pipes.module';
import { MkUserComponent } from './mk-user/mk-user.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MkStatusComponent } from './mk-status/mk-status.component';
import { MKLoaderComponent } from './mk-loader/mk-loader.component';
import { MkTableModule } from './mk-table/mk-table.module';
import { TicketDetailComponent } from './ticket-detail/ticket-detail.component';
import { PipesModule } from '../shared/pipes/pipes.module';
import { AgmCoreModule } from '@agm/core';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { QuoteModalComponent } from './quote-modal/quote-modal.component';
import LocaleEs from '@angular/common/locales/es';
import { ContractModalComponent } from './contract-modal/contract-modal.component';
import { MkUserMondayComponent } from './mk-user-monday/mk-user-monday.component'; 
import { CredentialsModalComponent } from './credentials-modal/credentials-modal.component';
import { ValidateFormsDirective } from '../directives/validate-forms.directive';
import { FileItemComponent } from './file-item/file-item.component';
import { FileModalComponent } from './file-modal/file-modal.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { CalendarComponent } from './calendar/calendar.component';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { ChatComponent } from './chat/chat.component';
import { DinamicFormsComponent } from './dinamic-forms/dinamic-forms.component';
import { DncInputComponent } from './dinamic-forms/components/dnc-input/dnc-input.component';
import { DncFilesComponent } from './dinamic-forms/components/dnc-files/dnc-files.component';
import { DncImagesComponent } from './dinamic-forms/components/dnc-images/dnc-images.component';
import { DncNumberComponent } from './dinamic-forms/components/dnc-number/dnc-number.component';
import { DncRadioComponent } from './dinamic-forms/components/dnc-radio/dnc-radio.component';
import { DncDateComponent } from './dinamic-forms/components/dnc-date/dnc-date.component';
import { DncDropdownComponent } from './dinamic-forms/components/dnc-dropdown/dnc-dropdown.component';
import { DncDinamicdropdownComponent } from './dinamic-forms/components/dnc-dinamicdropdown/dnc-dinamicdropdown.component';
import { DncTextareaComponent } from './dinamic-forms/components/dnc-textarea/dnc-textarea.component';
import { DncCheckComponent } from './dinamic-forms/components/dnc-check/dnc-check.component';
import { DncButtonComponent } from './dinamic-forms/components/dnc-button/dnc-button.component';
import { DncTableComponent } from './dinamic-forms/components/dnc-table/dnc-table.component';
import { DncButtonModalComponent} from './dinamic-forms/components/dnc-button-modal/dnc-button-modal.component';
import { ModalBidComponent } from './modal-bid/modal-bid.component';
import { DncEditorComponent } from './dinamic-forms/components/dnc-editor/dnc-editor.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { DecryptPipe } from 'src/app/shared/pipes/decrypt.pipe';
import { DncFilesmanagerComponent } from './dinamic-forms/components/dnc-filesmanager/dnc-filesmanager.component';
import { ChatCardComponent } from './chat-card/chat-card.component';
import { TabInfoComponent } from './modal-bid/components/tab-info/tab-info.component';
import { TabParticipantsComponent } from './modal-bid/components/tab-participants/tab-participants.component';
import { TabChatsComponent } from './modal-bid/components/tab-chats/tab-chats.component';
import { TabEstimateComponent } from './modal-bid/components/tab-estimate/tab-estimate.component';
import { DncInputGroupComponent } from './dinamic-forms/components/dnc-input-group/dnc-input-group.component';
import { DncSelectDateComponent } from './dinamic-forms/components/dnc-select-date/dnc-select-date.component';
import { MentionModule } from 'angular-mentions-v2';
import { TabBillComponent } from './modal-bid/components/tab-bill/tab-bill.component';
import { ImageEditModalComponent } from './image-edit-modal/image-edit-modal.component';
import { MkWithoutPermissionsComponent } from './mk-without-permissions/mk-without-permissions.component';
import { ModalPreviewComponent } from './modal-preview/modal-preview.component';
import { DncSumNumberComponent } from './dinamic-forms/components/calculate-components/dnc-sum-number/dnc-sum-number.component';
import { DncMapComponent } from './dinamic-forms/components/dnc-map/dnc-map.component';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { GoogleMapsModule } from '@angular/google-maps';
import { ContractRentrollModalComponent } from './contract-rentroll-modal/contract-rentroll-modal.component';
import { InfoChangeModalComponent } from './info-change-modal/info-change-modal.component';
import { TagInputComponent } from './tag-input/tag-input.component';
import { ExtendBidComponent } from './modal-bid/components/extend-bid/extend-bid.component';
import { DncMultipleSelectComponent } from './dinamic-forms/components/dnc-multiple-select/dnc-multiple-select.component';
import { DncBlankComponent } from './dinamic-forms/components/dnc-blank/dnc-blank.component';
import { DncTitleComponent } from './dinamic-forms/components/dnc-title/dnc-title.component';

registerLocaleData(LocaleEs, 'es')

@NgModule({
  imports: [
    CommonModule,
    PipesModule,
    SweetAlert2Module.forRoot(),
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    AngularEditorModule,
    MatTableModule,
    MatPaginatorModule,
    NgSelectModule,
    NgxDaterangepickerMd.forRoot(), 
    MatSortModule,
 
    MentionModule,
    GooglePlaceModule,
    GoogleMapsModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyB7AZ8E0BsiK2X10ituHHsFjSBLPUNz1Qk', 
      libraries: ['places']
    }),

  ],
  declarations: [
    TicketDetailComponent, 
    MkImgGroupComponent, 
    MkUserComponent, 
    MkStatusComponent, 
    MKLoaderComponent, 
    QuoteModalComponent,
    ContractModalComponent,
    MkUserMondayComponent,
    CredentialsModalComponent,
    FileItemComponent,
    FileModalComponent,
    CalendarComponent,
    ChatComponent,
    DinamicFormsComponent,
    DncInputComponent,
    DncFilesComponent,
    DncImagesComponent,
    DncNumberComponent,
    DncRadioComponent,
    DncDateComponent,
    DncDropdownComponent,
    DncDinamicdropdownComponent,
    DncTextareaComponent,
    DncCheckComponent,
    DncButtonComponent,
    DncTableComponent,
    DncButtonModalComponent,
    ModalBidComponent,
    DncEditorComponent,
    DncFilesmanagerComponent,
    ChatCardComponent,
    TabInfoComponent,
    TabParticipantsComponent,
    TabChatsComponent,
    TabEstimateComponent,
    DncInputGroupComponent,
    DncSelectDateComponent,
    TabBillComponent,
    ImageEditModalComponent,
    MkWithoutPermissionsComponent,
    ModalPreviewComponent,
    DncSumNumberComponent,
    DncMapComponent,
    ContractRentrollModalComponent,
    InfoChangeModalComponent,
    TagInputComponent,
    ExtendBidComponent,
    DncMultipleSelectComponent,
    DncBlankComponent,
    DncTitleComponent
      ],
  exports: [
    MkImgGroupComponent, 
    MkUserComponent, 
    MkStatusComponent, 
    MKLoaderComponent, 
    TicketDetailComponent, 
    QuoteModalComponent,
    ContractModalComponent,
    MkUserMondayComponent, 
    CredentialsModalComponent,
    FileItemComponent,
    FileModalComponent,
    CalendarComponent,
    ChatComponent,
    DinamicFormsComponent,
    DncInputComponent,
    DncCheckComponent,
    DncRadioComponent,
    DncDropdownComponent,
    DncButtonComponent,
    DncTableComponent,
    DncNumberComponent,
    DncButtonModalComponent,
    ModalBidComponent,
    DncEditorComponent,
    DncFilesmanagerComponent,
    TabInfoComponent,
    TabParticipantsComponent,
    TabChatsComponent,
    TabEstimateComponent,
    DncInputGroupComponent,
    ImageEditModalComponent,
    ModalPreviewComponent,
    DncSumNumberComponent,
    InfoChangeModalComponent,
    TagInputComponent,
    DncMultipleSelectComponent,
    ContractRentrollModalComponent,
    DncBlankComponent,
    DncTitleComponent
  ],
  providers:[
    ValidateFormsDirective,DecryptPipe, TitleCasePipe, DecimalPipe
  ]
})
export class ComponentsModule {}
