<div class="center{{!readonly?'-sm':''}}" (click)="inputText(dialog, $event)">
    <div class="text-overflow" [class._input]="!readonly" *ngIf="!focus"><span class="text-overflow">{{value}}</span></div>
</div>

<ng-template #dialog>
    <div class="mkt_dropdown mkt_col">
        <div class="content p-0">
            <textarea  rows="6" [readonly]="readonly" maxlength="255" class="form-control mktd-block" placeholder="Aa..." #input (blur)="setFocus(false)" (change)="emitValue(input.value); setFocus(false)" [value]="value"></textarea>
        </div>
    </div>
</ng-template>