<div class="row {{rowClass}}">
    <!-- COLUMN TYPE -->
    <ng-container *ngFor="let c of DataFormCopy?.fields">
        <ng-container [ngSwitch]="c.type">
            <ng-container *ngSwitchCase="'BLANK'">
                <dnc-blank [check]="c"></dnc-blank>
            </ng-container>

            <ng-container *ngSwitchCase="'TEXT'">
                <dnc-input *ngIf="!c.hidden" (onChange)="asignedValuesForm($event)" [editForm]="editForm" [check]="c" [dependencie]="dependencie" [editable]="editable" [touched]="touched"></dnc-input>
            </ng-container>
            
            <ng-container *ngSwitchCase="'TEXTAREA'">
                <dnc-textarea *ngIf="!c.hidden" (onChange)="asignedValuesForm($event)" [editForm]="editForm" [check]="c" [editable]="editable" [touched]="touched"></dnc-textarea>
            </ng-container>

            <ng-container *ngSwitchCase="'NUMBER'">
                <dnc-number *ngIf="!c.hidden" (onChange)="asignedValuesForm($event)" (onChangeChindren)="asignedChildrenValuesForm($event)" [editConditions]="editConditions" [dependencie]="dependencie" [editForm]="editForm" [check]="c" [editable]="editable" [touched]="touched" [automat]="automat"></dnc-number>
            </ng-container>

            <ng-container *ngSwitchCase="'RADIO'">
                <dnc-radio *ngIf="!c.hidden" (onChange)="asignedValuesForm($event)" [editForm]="editForm" [check]="c" [editable]="editable"></dnc-radio>
            </ng-container>

            <ng-container *ngSwitchCase="'DATE'">
                <dnc-date *ngIf="!c.hidden" (onChange)="asignedValuesForm($event)" [editForm]="editForm" [check]="c" [editable]="editable" [touched]="touched"></dnc-date>
            </ng-container>

            <ng-container *ngSwitchCase="'FILE'">
                <dnc-files *ngIf="!c.hidden" (onChange)="asignedValuesForm($event)" [editForm]="editForm" [check]="c" [editable]="editable" [touched]="touched"></dnc-files>
            </ng-container>

            <ng-container *ngSwitchCase="'CHECK'">
                <dnc-check *ngIf="!c.hidden" (onChange)="asignedValuesForm($event)" (onChangeDependencie)="asignedDependencieValuesForm($event)" (onChangeDependencieRoot)="DependencieRoot($event)" [editForm]="editForm" [check]="c" [editable]="editable"></dnc-check>
            </ng-container>

            <ng-container *ngSwitchCase="'SELECT'">
                <dnc-dropdown *ngIf="!c.hidden" [editConditions]="editConditions" (onCondition)="ValidCondition($event)" (onChange)="asignedValuesForm($event)" (onChangeChindren)="asignedChildrenValuesForm($event)" [editForm]="editForm" [check]="c" [editable]="editable" [touched]="touched"></dnc-dropdown>
            </ng-container>

            <ng-container *ngSwitchCase="'DSELECT'">
                <dnc-dinamicdropdown *ngIf="!c.hidden" (onChange)="asignedValuesForm($event)" (onChangeChindren)="asignedChildrenValuesForm($event)" [editForm]="editForm" [check]="c" [editable]="editable" [touched]="touched"></dnc-dinamicdropdown>
            </ng-container>

            <ng-container *ngSwitchCase="'BUTTONMODAL'">
                <dnc-button-modal *ngIf="!c.hidden" (onClick)="clickEvent($event)" [check]="c" [editable]="editable" ></dnc-button-modal>
            </ng-container>

            <ng-container *ngSwitchCase="'BUTTON'" >
                <dnc-button (onClick)="clickEventBtn($event)" [editForm]="editForm" [check]="c" [editable]="editable" *ngIf="(editable)"></dnc-button>
            </ng-container>

            <ng-container *ngSwitchCase="'TABLE'">
                <dnc-table *ngIf="!c.hidden" [check]="c" [dataInput]="dataInput" (onClickEdit)="clickBtnEditRegister($event)" [editable]="editable" (onClickDelete)="clickBtnDeleteRegister($event)"></dnc-table>
            </ng-container>

            <ng-container *ngSwitchCase="'EDITOR'">
                <dnc-editor *ngIf="!c.hidden" [check]="c" (onChange)="asignedValuesForm($event)" [editForm]="editForm" [editable]="editable"></dnc-editor>
            </ng-container>

            <ng-container *ngSwitchCase="'FILEMANAGER'">
                <dnc-filesmanager *ngIf="!c.hidden" [check]="c" (onChange)="asignedValuesForm($event)" [editForm]="editForm" [editable]="editable" [touched]="touched"></dnc-filesmanager>
            </ng-container>

            <ng-container *ngSwitchCase="'SELECTDATE'">
                <dnc-select-date *ngIf="!c.hidden" [check]="c" (onChange)="asignedValuesForm($event)" [editConditions]="editConditions" [editForm]="editForm" [editable]="editable" [touched]="touched"></dnc-select-date>
            </ng-container>

            <ng-container *ngSwitchCase="'INPUTGROUP'">
                <dnc-input-group *ngIf="!c.hidden" [check]="c" (onChange)="asignedValuesForm($event)" [editForm]="editForm" [editable]="editable" [touched]="touched"></dnc-input-group>
            </ng-container>

            <ng-container *ngSwitchCase="'SUMNUMBER'">
                <dnc-sum-number *ngIf="!c.hidden" (onChange)="asignedValuesForm($event)" (onChangeChindren)="asignedChildrenValuesForm($event)" [editForm]="editForm" [check]="c" [editable]="editable" [touched]="touched"></dnc-sum-number>
            </ng-container>

            <ng-container *ngSwitchCase="'MAP'">
                <dnc-map *ngIf="!c.hidden" (onChange)="asignedValuesForm($event)" [editForm]="editForm" [check]="c" [editable]="editable" [touched]="touched"></dnc-map>
            </ng-container>

            <ng-container *ngSwitchCase="'MULTIPLE'">
                <dnc-multiple-select *ngIf="!c.hidden" (onChange)="asignedValuesForm($event)" [editForm]="editForm" [check]="c" [editable]="editable" [touched]="touched"></dnc-multiple-select>
            </ng-container>

        </ng-container>
    </ng-container>

</div>