<div class="top d-flex justify-content-between ">
    <div class="d-flex text-overflow">
        <div class="icon bg-primary d-flex">
            <!-- <i class="fa fa-user m-auto" ></i> -->
            <img *ngIf="chat?.image" [src]="(chat?.image)">
            <span *ngIf="!chat?.image" class="main-img-user" >{{chat.name | initials}}</span>
        </div>
        <div class="name px-2 my-auto text-overflow"><b>{{chat?.name | titlecase}}</b>
            <i class="mdi mdi-star text-warning ml-1" *ngIf="chat?.category == 'VIP'"></i>
        </div>
    </div>
    <span class="badge badge-danger" *ngIf="chat.countUnread > 0">{{chat?.countUnread}}</span>
</div>
<div class="body">
    <div class="msg text-clamp-2" [innerHTML]="chat?.lastMessage?.text | decryptChat: chat?.key">
    </div>
    <div class="bot d-flex justify-content-between">
        <span class="text-primary"><b *ngIf="chat?.lastMessage?.create?.date">{{chat?.lastMessage?.create?.date | moment:'hh:mm A'}}</b></span>
        <span class="text-muted">{{chat?.lastMessage?.create?.date ? "Click para ver" : "Sin Mensajes"}}</span>
    </div>
</div>