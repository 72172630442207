import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import * as moment from 'moment';
import { ValidateFormsDirective } from 'src/app/directives/validate-forms.directive';
import { datePicker } from 'src/app/shared/mk-configs/datePicker/datePickerLocale';
import { DynamicControlsService } from 'src/app/shared/services/dynamic-controls.service';
import { DyamicFormService } from 'src/app/shared/services/dynamic-form.service';

@Component({
  selector: 'dnc-date',
  templateUrl: './dnc-date.component.html',
  styleUrls: ['./dnc-date.component.scss', '../../dynForm.comp.scss'],
  host:{
    "[style.order]": "check.position",
    "[class]": "'col-sm-12 col-md-' + (check.col_size || 4)"
  }
})
export class DncDateComponent implements OnInit {

  @Output() onChange: EventEmitter<any> = new EventEmitter<any>();

  @Input() check: any;
  @Input() editForm: any;
  @Input() editable : boolean = true;
  @Input() touched : boolean = false;
  
  public DinamicForm: FormGroup
  public dateOptions: any = datePicker.customRange.spanish_short;
  public selectedDate: any = { startDate: moment(), endDate: moment() };
  public tmpSelectedDate: any ;

  public today = moment();

  constructor(
    public fieldisValid: ValidateFormsDirective,
    private fb: FormBuilder,
    private dynamicControls: DynamicControlsService,
    private dynamicForm: DyamicFormService,
    public sanitizer: DomSanitizer
  ) { }

  ngOnChanges(change: SimpleChanges){
    let flag: boolean = false
    if (!change.editForm?.firstChange) { // Reasigna los valores dependiendo del elemento a renderizar
      if (this.check?.form == change.editForm?.currentValue?.name) {

      // Se asegura que el campo a mapear este dentro del cambio
        for (const key in change?.editForm?.currentValue?.form?.value) {
              key == this.check?.formControlName ? flag = true: '';
          }
        
          if (flag) {
            
           this.DinamicForm = this.dynamicForm.ReMapEditForm(change?.editForm?.currentValue?.value?.form, this.DinamicForm);
        
              //Dependiendo de que tipo de date se recibe, es como se parsea.
              if (this.check.dbSpecifications.type == 'date') {
                
                if(change?.editForm?.currentValue?.form?.value[this.check?.formControlName] == null || change?.editForm?.currentValue?.form?.value[this.check?.formControlName] == 'Fecha inválida'){
                  this.selectedDate = null;
                }else{
                  this.selectedDate = {startDate:moment(change?.editForm?.currentValue?.form?.value[this.check?.formControlName]), endDate:moment(change?.editForm?.currentValue?.form?.value[this.check?.formControlName])};
                }

                this.DinamicForm.patchValue({
                  [this.check.formControlName]: {startDate:this.selectedDate ? moment(change?.editForm?.currentValue?.form?.value[this.check?.formControlName]) : null, endDate: this.selectedDate ? moment(change?.editForm?.currentValue?.form?.value[this.check?.formControlName]) : null}
                });

                //this.selectedDate = {startDate:moment(change?.editForm?.currentValue?.form?.value[this.check?.formControlName]), endDate:moment(change?.editForm?.currentValue?.form?.value[this.check?.formControlName])};
              
              }else if(this.check.dbSpecifications.type == 'daterange'){  
                
                let startDate = change?.editForm?.currentValue?.form?.value[this.check?.formControlName]?.startDate;
                let endDate = change?.editForm?.currentValue?.form?.value[this.check?.formControlName]?.endDate;

                if (startDate == null && endDate == null) {
                    this.selectedDate = '';
                      this.DinamicForm.patchValue({
                        [this.check.formControlName]: {startDate:null, endDate:null}
                      });
                }else{
                    this.selectedDate = {startDate:moment(startDate), endDate:moment(endDate)};
                    this.DinamicForm.patchValue({
                      [this.check.formControlName]: {startDate:moment(startDate), endDate:moment(startDate)}
                    });
                }


                // Valida que el dato que se resive no sea null o sintaxis 'Fecha Invalida', si es asi, se procede a declarar la variable en '', si no, se parcea al formulario normalmente dandole formato con moment y format
                // if((change?.editForm?.currentValue?.value[this.check?.formControlName]?.startDate == null && change?.editForm?.currentValue?.value[this.check?.formControlName]?.endDate == null) ||
                //   (change?.editForm?.currentValue?.value[this.check?.formControlName]?.startDate == 'Fecha inválida' && change?.editForm?.currentValue?.value[this.check?.formControlName]?.endDate == 'Fecha inválida')) {
                //   this.selectedDate = '';

                // }else{
                //   this.selectedDate = {startDate:moment(change?.editForm?.currentValue?.value[this.check?.formControlName]?.startDate), endDate:moment(change?.editForm?.currentValue?.value[this.check?.formControlName]?.endDate)};
                // }

                // this.DinamicForm.patchValue({
                //   [this.check.formControlName]: {startDate:moment(change?.editForm?.currentValue?.value[this.check?.formControlName]?.startDate), endDate:moment(change?.editForm?.currentValue?.value[this.check?.formControlName]?.endDate)}
                // });
              }
          
              // if(this.selectedDate && this.selectedDate !== ""){
              //   this.tmpSelectedDate = this.selectedDate;
              // }
              // else if(this.tmpSelectedDate?.startDate){
              //   this.selectedDate = this.tmpSelectedDate ;

              // }
          } 
        }
    }

    if (change.touched?.currentValue) {
      this.DinamicForm.markAllAsTouched();
    }
  }

  async ngOnInit() {

    this.check.initDateNull ? this.selectedDate = null : this.selectedDate = { startDate: moment(), endDate: moment() };

    this.DinamicForm = this.fb.group({
      [this.check.formControlName]:[null]
    });

    this.DinamicForm = await this.dynamicControls.asignControls(this.check,  this.DinamicForm);

    
    //Establece los valores de inicio del formulario
     this.dynamicEmit();
  }

  dynamicEmit(){

    if(this.check.dbSpecifications.type == 'date'){

        this.DinamicForm.patchValue({
          [this.check.formControlName]: moment(this.selectedDate?.endDate).format("YYYY-MM-DD HH:mm")
        });

    }else if(this.check.dbSpecifications.type == 'daterange'){
      
        this.DinamicForm.patchValue({
          [this.check.formControlName]: {startDate:moment(this.selectedDate?.startDate).format("YYYY-MM-DD HH:mm"), endDate:moment(this.selectedDate?.endDate).format("YYYY-MM-DD HH:mm")}
        });
   
    }


    // if(this.selectedDate && this.selectedDate !== ""){
    //   this.tmpSelectedDate = this.selectedDate;
    // }
    // else if(this.tmpSelectedDate?.startDate){
    //   this.selectedDate = this.tmpSelectedDate ;
    // }

    this.onChange.emit({ event:this.DinamicForm, form: this.check?.form});     
  }

  public validateRequired(): boolean{
    let required: boolean = this.check?.target?.validators?.required || false;
    return required;
  }

  onFilterFormClear(): void {
    this.selectedDate = '';
  }
}


// import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
// import { FormBuilder, FormGroup } from '@angular/forms';
// import * as moment from 'moment';
// import { ValidateFormsDirective } from 'src/app/directives/validate-forms.directive';
// import { datePicker } from 'src/app/shared/mk-configs/datePicker/datePickerLocale';
// import { DynamicControlsService } from 'src/app/shared/services/dynamic-controls.service';
// import { DyamicFormService } from 'src/app/shared/services/dynamic-form.service';

// @Component({
//   selector: 'dnc-date',
//   templateUrl: './dnc-date.component.html',
//   styleUrls: ['./dnc-date.component.scss', '../../dynForm.comp.scss'],
//   host:{
//     "[style.order]": "check.position",
//     "[class]": "'col-sm-12 col-md-' + (check.col_size || 4)"
//   }
// })
// export class DncDateComponent implements OnInit {

//   @Output() onChange: EventEmitter<any> = new EventEmitter<any>();

//   @Input() check: any;
//   @Input() editForm: any;
  
//   public DinamicForm: FormGroup
//   public dateOptions: any = datePicker.locale;
//   //public selectedDate: any = { startDate: null, endDate: null};
//   public selectedDate;

//   public today = moment();

//   constructor(
//     public fieldisValid: ValidateFormsDirective,
//     private fb: FormBuilder,
//     private dynamicControls: DynamicControlsService,
//     private dynamicForm: DyamicFormService
//   ) {

//    }

//   ngOnChanges(change: SimpleChanges){
    
//     if (!change.editForm.firstChange) { // Reasigna los valores dependiendo del elemento a renderizar
//         this.DinamicForm = this.dynamicForm.ReMapEditForm(change?.editForm?.currentValue, this.DinamicForm);
        
//         this.DinamicForm.patchValue({
//           [this.check.formControlName]: {startDate:moment(change?.editForm?.currentValue?.value[this.check?.formControlName]?.startDate), endDate:moment(change?.editForm?.currentValue?.value[this.check?.formControlName]?.endDate)}
//         });
//         this.selectedDate = {startDate:moment(change?.editForm?.currentValue?.value[this.check?.formControlName]?.startDate), endDate:moment(change?.editForm?.currentValue?.value[this.check?.formControlName]?.endDate)};
//     }
//   }

//   async ngOnInit() {

//     this.DinamicForm = this.fb.group({
//       [this.check.formControlName]:[null]
//     });
    
//     this.check?.target?.validators?.required == true ? this.selectedDate = {startDate: moment(), endDate: moment()} : '';

//     this.DinamicForm = await this.dynamicControls.asignControls(this.check,  this.DinamicForm);

//     //Establece los valores de inicio del formulario
//     this.dynamicEmit();
   
//   }

//   dynamicEmit(){
//     if(this.check?.target?.validators?.required == true){
//       this.DinamicForm.patchValue({
//         [this.check.formControlName]: {startDate:moment(this.selectedDate.startDate).format("YYYY-MM-DD HH:mm"), endDate:moment(this.selectedDate.endDate).format("YYYY-MM-DD HH:mm")}
//       });
//     }else{
//       this.DinamicForm.patchValue({
//         [this.check.formControlName]: {startDate: null, endDate: null}
//       });
//     };

//     this.onChange.emit(this.DinamicForm);     
//   }

//   public validateRequired(): boolean{
//     let required: boolean = this.check?.target?.validators?.required || false;
//     return required;
//   }

// }
