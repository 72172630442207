import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'mkt-bid',
  templateUrl: './mkt-bid.component.html',
  styleUrls: ['./mkt-bid.component.scss', '../../mk-table.comp.scss', '../../mk-table.comp.input.scss'],
  host:{
    "class" : 'mkt_col col-bid',
    "[class.has-change]": "_hasChange",
    "[class.warning]": "value.status == 'WARNING'"
  }
})
export class MkBidComponent implements OnInit {

  constructor(private modalService: NgbModal) { }

  ngOnInit(): void {
  }

  @Input() value: any;
  @Input() subId: any;
  @Output() valueChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  private _hasChange: boolean = false;

  //ngOnChanges(): void{this._hasChange = true; setTimeout(() => {this._hasChange = false}, 2000);}

  public modalBid(template: any){
    this.modalService.open(template, {size: 'xl'})
  }

  public submitBid(bidData: any){
    if (!bidData) {
      //? Borra la info local de licitacion
      this.value = 0;
    }else{
      if(!bidData?.bidders){bidData.bidders = this.value?.bidders || 0;}
    }    
    this.valueChange.emit(bidData);
  }
}
