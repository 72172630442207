import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'mkt-input',
  templateUrl: './mkt-input.component.html',
  styleUrls: ['./mkt-input.component.scss'],
  host: {
    "class": 'mkt_row no-drag'
  }

})
export class MktInputComponent implements OnInit {

  @Input() isSubitem: boolean = false;

  @Output() onChange: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }

  change(value: any){this.onChange.emit(value)}

}
