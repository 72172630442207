import { Component, OnInit, Output, Input, EventEmitter, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ValidateFormsDirective } from 'src/app/directives/validate-forms.directive';
import { DecryptPipe } from 'src/app/shared/pipes/decrypt.pipe';
import { DynamicControlsService } from 'src/app/shared/services/dynamic-controls.service';
import { DyamicFormService } from 'src/app/shared/services/dynamic-form.service';
import { HttpService } from 'src/app/shared/services/http.service';

@Component({
  selector: 'dnc-dinamicdropdown',
  templateUrl: './dnc-dinamicdropdown.component.html',
  styleUrls: ['./dnc-dinamicdropdown.component.scss', '../../dynForm.comp.scss'],
  host: {
    "[style.order]": "check.position",
    "[class]": "'col-sm-12 col-md-' + (check.col_size || 4)"
  }
})
export class DncDinamicdropdownComponent implements OnInit {

  @Output() onChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() onChangeChindren: EventEmitter<any> = new EventEmitter<any>();

  @Input() check: any;
  @Input() editForm: any;
  @Input() editable : boolean = true;
  @Input() touched : boolean = false;

  public DinamicForm: FormGroup;
  public Options;
  public AllOptions = [];


  constructor(
    private fb: FormBuilder,
    public dynamicControls: DynamicControlsService,
    private dynamicForm: DyamicFormService,
    private httpService: HttpService,
    public fieldisValid: ValidateFormsDirective,
    public decrypt: DecryptPipe,
    public sanitizer: DomSanitizer

  ) { 
  }


  async ngOnChanges(change: SimpleChanges) {
    if (!change.editForm?.firstChange) { // Reasigna los valores dependiendo del elemento a renderizar
      if (this.check?.form == change.editForm?.currentValue?.name) {
          this.DinamicForm = await this.dynamicForm.ReMapEditForm(change.editForm?.currentValue?.form, this.DinamicForm);
          if (change.editForm?.currentValue?.edit) {
            this.editable = false;

            await this.getAllOptions();
            await this.dynamicEmit();
            
          }
      }
    }

    if (change.touched?.currentValue) {
      this.DinamicForm.markAllAsTouched();
    }
  }

  async ngOnInit() {

    this.DinamicForm = this.fb.group({
      [this.check.formControlName]: [null]
    });

    await this.getOptions();

    this.DinamicForm = await this.dynamicControls.asignControls(this.check, this.DinamicForm);

    //Establece los valores de inicio del formulario
    this.dynamicEmit();

  }

  dynamicEmit() {
    
    this.onChange.emit({event:this.DinamicForm, form: this.check?.form, automation: this.check?.target?.automation, fieldType: this.check?.type, value: this.DinamicForm.value[this.check.formControlName], field: this.check?.formControlName});

    if (this.check?.targetValue && this.DinamicForm.value[this.check.formControlName]) {

      var selectedValue = this.Options.filter(option => {
        return option[this.check.targetValue] === this.DinamicForm.value[this.check.formControlName]
      });

      if (this.check?.childrens && selectedValue) {
        selectedValue = selectedValue[0];
        var childrensValue = [];
        
        this.check?.childrens.forEach(children => {
          var val = selectedValue[children?.value];
          val = (children?.decrypt === true) ? this.decrypt.transform(val) : val;
          childrensValue.push({
            formControlName: children?.formControlName,
            value: val
          });
        });
        
        
        this.onChangeChindren.emit({event:childrensValue, form:this.check?.form})
      }
    }
  }


  public async getOptions() {
    try {

      
      this.Options = await this.httpService.get(`${this.check?.target?.request}`).toPromise();
      
      this.Options.forEach(element => {
        var text = (this.check?.targetText) ? this.check?.targetText : '_id';
        if (this.decrypt.transform(element[text]) == '') {
          element[text]
        } else {
          element[text] = this.decrypt.transform(element[text]);
        };
      });
    } catch (error) {
      console.log(error);
      
    }
  }

  public async getAllOptions(){
    try {
      if (this.check?.target?.requestAll) {
        this.Options = await this.httpService.get(`${this.check?.target?.requestAll}`).toPromise();

        this.Options.forEach(element => {
          var text = (this.check?.targetText) ? this.check?.targetText : '_id';
          if (this.decrypt.transform(element[text]) == '') {
            element[text]
          } else {
            element[text] = this.decrypt.transform(element[text]);
          };
        });
      }
    } catch (error) {
      console.log(error);
      
    }
  }

  public validateRequired(): boolean {
    let required: boolean = this.check?.target?.validators?.required || false;
    return required;
  }

}
