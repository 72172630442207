import { Injectable } from '@angular/core';
import { HttpService } from '../../http.service';

@Injectable({
  providedIn: 'root'
})
export class ContractsService {

  constructor(private http: HttpService) { }

    public ContractsData = (concept:string, currency:string, start:string, end:string): Promise<any> => this.http.get(`api/finances/${concept}/${currency}/${start}/${end}`).toPromise();
    public detailContract = (id:string): Promise<any> => this.http.get(`api/finances/detail/${id}`).toPromise();
    public financesSubitem = (id:string, concept:string, currency:string, type: string = 'ALL'): Promise<any> => this.http.get(`api/finances/subitems/${id}/${concept}/${currency}/${type}`).toPromise();
    public getBlobFile = (name:string , type:string): Promise<any> => this.http.getBlob(`api/finances/getPDF/${name}`, type).toPromise();

    public createSubitem = (service: any): Promise<any> => this.http.post('api/finances/subitem', service).toPromise();
    public updateField = (field: any): Promise<any> => this.http.put(`api/finances/subitem/field`, field).toPromise();
    public deleteSubitem = (_id:string): Promise<any> => this.http.delete(`api/finances/subitem/${_id}`).toPromise();
    public updateAttendBy = (user: any): Promise<any> => this.http.put(`api/finances/attendBy`, user).toPromise();

    public dinamicFinances = (api: any): Promise<any> => this.http.get(`${api}`).toPromise();


}
