
<div class="col-md-12 d-flex justify-content-between">
    <h4 class="text-secondary my-3">{{(userService?.modulesName?.bill | titlecase) || 'Factura'}}</h4>
</div>
<ng-container *ngIf="bill">
    <div class="col-sm-12">
        <div class="row mb-3">
            <div class="col-md-6">
                <div class="row">
                    <!-- <div class="col-sm-12">
                        <b class="tx-18 d-block">Licitante: {{'Juan Perez'}}</b>
                    </div> -->
                    <div class="col-sm-12">
                        <h6>Comentario</h6>
                        <textarea class="form-control mb-3" rows="5" placeholder="Comentario" readonly>{{billdata?.comment}}</textarea>  
                    </div>
                    <div class="col-sm-12">
                        <span class="text-info">Subida el {{billdata?.date | moment:'ll'}}</span>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="card">
                    <div class="card-header py-2">
                        <div class="row row-sm justify-content-between">
                            <div class="w-100">
                              <label class="main-content-label mb-0"><i class="fa fa-circle mr-1 text-primary"></i> Archivos adjuntos</label>
                              <div class="d-flex justify-content-between lh-1 tx-12 mb-0">
                                <span class="text-muted text-overflow">Listado de archivos de {{userService?.modulesName?.bill || 'factura'}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex flex-wrap px-1 pb-2">
                        <ng-container *ngFor="let file of bill">
                            <app-file-item [filesmanager]="true" [deletebtn]="true" [file]="file" (click)="download(file.name)"></app-file-item>
                        </ng-container>
                        <ng-container *ngIf="!bill.length"><div class="text-muted text-center my-4">Sin Archivos</div></ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="!bill">
    <div class="col-sm-12 text-center py-4"><span class="text-muted">Sin {{userService?.modulesName?.bill || 'factura'}} adjunta</span></div>
</ng-container>

<div class="modal-footer justify-content-between">
    <button type="button" class="btn btn-dark" (click)="close()">Cerrar</button>
</div>