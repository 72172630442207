<mk-loader *ngIf="isLoading" class="loader-spinner"></mk-loader>
<div class="modal-header">
    <h6 class="modal-title" id="modal-basic-title">Cambio de información</h6>
    <button type="button" class="close" aria-label="Close" (click)="close()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body pb-0">
    <div class="row">
        <div class="col-md-4">
            <h6>Nombre</h6>
            <!-- <div class="form-control"></div> -->
            <input type="text" [value]="ContractorName" class="form-control" readonly>
        </div>
        <div class="col-md-4">
            <h6>Usuario que realizo solicitud</h6>
            <!-- <div class="form-control"></div> -->
            <div class="input-group">
                <div class="input-group-prepend">
                    <div class="input-group-text img">
                        <img [src]="imgUser | imagesLoad" alt="">
                    </div>
                </div>
                <input type="text" [value]="RequestData?.user?.name"  class="form-control" readonly>
            </div>
        </div>
        <div class="col-md-4">
            <h6>Fecha de solicitud</h6>
            <div class="input-group date">
                <div class="input-group-prepend">
                    <div class="input-group-text">
                        <i class="fe fe-calendar lh--9 op-6"></i>
                    </div>
                </div>
            <input type="text" [value]="(RequestData?.date | moment:'l')" disabled placeholder="DD/MM/YYYY" class="form-control">
            <!-- <div class="form-control"></div> -->
            </div>
        </div>
    </div>

    <form [formGroup]="RequestForm" #permissions="ngForm" novalidate>
        <div class="row mt-3">
                <div class="col-sm-12">
                    <span class="d-flex justify-content-between">
                        <h6>Notas</h6>
                        <span class="text-muted">{{textlength}}/250</span>
                    </span>                   
                     <textarea class="form-control" name="" maxlength="250"  formControlName="comment" id="" rows="2" placeholder="Agregar comentario sobre solicitud..."  (input)="asigntextlength($event?.target?.value)"></textarea>
                </div>
        </div>
    </form>
    <div class="row mt-3 border-top">
        <div class="col-md-6">
            <h6 class="text-primary text-center my-3">Información actual</h6>
            <div class="card overflow-hidden">
                <table class="table table-bordered table-striped m-0">
                    <thead>
                        <tr><th>Dato</th><th>Valor</th></tr>
                    </thead>
                    <tbody>
                        <ng-container *ngFor="let item of RequestData?.fields">
                            <tr *ngIf="item?.field != 'note'">
                                <td width="60px"><b>{{item?.field | status}}</b></td>
                                <td>{{item?.current}}</td>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>
            </div>
            <ng-container *ngFor="let item of RequestData?.fields">
                <div class="row mt-2" *ngIf="item?.field == 'note' && item?.current">
                    <div class="col-sm-12">
                        <h6>Notas</h6>
                        <textarea name="" disabled id="" rows="3" [value]="item?.current"  class="form-control mb-3" placeholder="Sin notas"></textarea>
                    </div>
                </div>
            </ng-container>

            
        </div>
        <div class="col-md-6 border-left">
            <h6 class="text-primary text-center my-3">Información nueva</h6>
            <div class="card overflow-hidden">
                <table class="table table-bordered table-striped m-0">
                    <thead>
                        <tr><th>Dato</th><th>Valor</th></tr>
                    </thead>
                    <tbody>
                        <ng-container *ngFor="let item of RequestData?.fields">
                            <tr *ngIf="item?.field != 'note'">
                                <td width="60px"><b>{{item?.field | status}}</b></td>
                                <td>{{item?.new}}</td>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>
            </div>

            <ng-container *ngFor="let item of RequestData?.fields">
                <div class="row mt-2" *ngIf="item?.field == 'note' && item?.new">
                    <div class="col-sm-12">
                        <h6>Notas</h6>
                        <textarea name="" disabled id="" rows="3" [value]="item?.new"  class="form-control mb-3" placeholder="Sin notas"></textarea>
                    </div>
                </div>
            </ng-container>
            <!-- <div class="row mt-2" *ngIf="true">
                <div class="col-sm-12">
                    <h6>Notas</h6>
                    <textarea name="" id="" rows="3" class="form-control mb-3" placeholder="Sin notas"></textarea>
                </div>
            </div> -->
        </div>
    </div>
</div>

<div class="modal-footer justify-content-between">
    <button type="button" class="btn btn-dark" (click)="close()">Cerrar</button>
    <div>
        <button class="btn btn-danger mr-3" [swal]="rejectRequestmodal" (confirm)="rejectRequest()">Rechazar</button>
        <button class="btn btn-success" [swal]="aproveRequest" (confirm)="approveRequest()">Aprobar</button>
    </div>
</div>

<!-- SWALS -->
<swal #aproveRequest class="d-none" title="¿Estas seguro de aprobar esta solicitud?" icon="question" [showCancelButton]="true" [focusCancel]="true" [swalOptions]="{confirmButtonText: 'Aceptar', cancelButtonText: 'Cancelar', confirmButtonColor: '#fd3666'}">
</swal>

<swal #rejectRequestmodal class="d-none" title="¿Estas seguro de rechazar esta solicitud?" icon="question" [showCancelButton]="true" [focusCancel]="true" [swalOptions]="{confirmButtonText: 'Aceptar', cancelButtonText: 'Cancelar', confirmButtonColor: '#fd3666'}">
</swal>