import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment'
import { DecryptPipe } from './decrypt.pipe';

@Pipe({
  name: 'financespipe'
})
export class FinancesFilterPipe implements PipeTransform {

    constructor(public decrypt: DecryptPipe){}

  transform(arreglo:Array<any>, texto: string ): any[] {
    //console.log("Arreglo",arreglo);
    // console.log("Texto", texto);

    if(texto === ''){
      return arreglo;
    }
    if( !arreglo) {
      return arreglo;
    }
    
    texto = texto.toLocaleLowerCase();
    return arreglo.filter( item => 
    // Agregamos las colunas donde querramos buscar 
    String(item?.folio).toLowerCase().includes( texto )  ||   
    item?.tenant?.toLowerCase().includes( texto )  ||
    item?.folio?.toString().includes( texto )  ||
    item?.contractCode?.toLowerCase().includes( texto )  ||
    item?.clientName?.toLowerCase().includes( texto )  ||
    item?.unitName?.toLowerCase().includes( texto )  ||
    item?.leaseType?.toLowerCase().includes( texto )  ||
    moment(item?.increaseDate).format('MMM DD')?.includes( texto )  ||
    item?.plazoPago == Number(texto)   ||
    item?.interesMoratorio == Number(texto)  ||
      

    moment(item?.createBy?.date).format('ll').toLowerCase().includes( texto )  ||
    moment(item?.create_date).format('ll').toLowerCase().includes( texto )   
    );

    

  }

}
