import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CalculatorService {

  constructor() { }

  public async Sum(data, config): Promise<number> {
    return new Promise((resolve) => {
      console.log("config", config);
      console.log("data", data);

      for(let field of config){
        for (const key in data) {
          console.log(key);
          
        }
      }

      // const suma1 = array.reduce((valorAnterior, valorActual) => {
      //   return valorAnterior + valorActual;
      resolve(0)
    });
  }
}
