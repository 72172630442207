import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'mk-status',
  templateUrl: './mk-status.component.html',
  styleUrls: ['./mk-status.component.scss']
})
export class MkStatusComponent implements OnInit {

  @Input() status: string;
  @Input() color: string;
  @Input() module: string = 'DEFAULT';

  @Input() isTicketStatus: boolean = false;

  public classes = {
    DEFAULT:{
      APPROVED: "success",
      REJECTED: "danger",
      PENDING: "warning",
      CANCELLED: "danger",
      INPROGRESS: "warning",
      DONE: "success",
      ACCESS: "secondary",
      CAFETERIA: "secondary",
      LOST: "secondary",
      REPLACEMENT: "info",
      MOBILE: "secondary",
      TABLET: "info",
      ACTIVE: "success",
      INACTIVE: "danger",
      VISIBLE: "success",
      NOVISIBLE: "danger",
      DENIED: "danger",
      ACCEPT: "success",
      PREVENTIVE: "warning",
      Aceptado: "success",
      Cancelado: "danger",
      Pendiente: "warning",
      Rechazado: "danger",
      "N/A": "muted",
      RELEASED: "warning",
      NUEVO: "warning",
      ANALIZANDO: "info",
      TRABAJANDO: "danger", 
      RESUELTO: "success",
      LIBERADO: "info",
      DISPONIBLE: "success",
      NODISPONIBLE: "danger",
      SI: "success",
      NO: "danger",
      _ASSIGNED: "success",
      "AVAILABLE": "success",
      "NOT AVAILABLE": "danger",
      "ASSIGNED": "warning",
  
      CREATED: "warning",
      STARTED: "success",
      REQUEST: "warning",
      FINISHED: "info",
      CLOSED: "danger",
      REJECTED_PROVIDER: 'warning',
      ACCEPTED: "success",
      CURRENT: "success",
      NOCURRENT: "danger",

      NEW: 'new',
    },
    BIDS: {
      CREATED: "created",
      STARTED: "accepted",
      REQUEST: "auth_pen_adm",
      FINISHED: "finished",
      CLOSED: "rejected_provider",
      CANCELLED: "cancelled",
      PENDING: "warning",
      ACCEPTED: "autorizado",
      REJECTED: "cerrado",
      REJECTED_PROVIDER: 'cerrado',
    }
    
  }

  public getStatusClass(status: string):string{return this.classes[this.module][status]}

  constructor() { }

  ngOnInit(): void {
  }

}
 