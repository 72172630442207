import { Component, Input, OnInit, Output, EventEmitter, ElementRef, ViewChild, OnDestroy } from "@angular/core";
import { HttpService } from "src/app/shared/services/http.service";
import * as moment from "moment";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { environment } from "src/environments/environment";
import { AlertService } from "src/app/shared/services/alert.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { DropdownService } from "src/app/shared/services/dropdown.service";
import { UserService } from "src/app/shared/services/user.service";
import { IUser } from "src/app/interfaces/iuser";
import { Subject } from "rxjs";
import { BuiltFormDataService } from "src/app/shared/services/built-form-data.service";
import { ValueTypeService } from "src/app/shared/services/value-type.service";
import { DataBankService } from "../mk-table/services/data-bank.service";
import { DecryptPipe } from "src/app/shared/pipes/decrypt.pipe";

@Component({
  selector: "app-ticket-detail",
  templateUrl: "./ticket-detail.component.html",
  styleUrls: ["./ticket-detail.component.scss"],
})
export class TicketDetailComponent implements OnInit, OnDestroy {
  public destroyed$ = new Subject<void>();

  public OptionsModal = {id: null, type: "ADD"}
  public DT_Binnacle: MatTableDataSource<any>;
  public DT_HistoryQuotes: MatTableDataSource<any>;
  public DT_money: MatTableDataSource<any>;
  public DT_time: MatTableDataSource<any>;
  public images = [
    // {path: '../../../assets/img/bg-login.webp'},
    // {path: '../../../assets/img/owl-carousel/cursor.png'},
  ];
  public image;
  public notes = null;
  public description = null;
  public title = null;
  public modalImgSrc: any;
  public customers:any;
  public displayColumns: string[] = ["user", "description", "date"];
  public displayColumnsHQ: string[] = [
    "folio",
    "createBy",
    "description",
    "unit",
    "quantity",
    "unit_price",
    "amount",
    "status",
  ];
  public displayColumnsUnit: string[] = ['folio','description', 'quantity', 'unitPrice','amount','av_financiero','av_fisico','updateDate','createDate'];


  @ViewChild("Paginator", {static: false}) paginator: MatPaginator;
  @ViewChild("Sort", {static: false}) sort: MatSort;

  @ViewChild("PaginatorHQ", {static: false}) paginatorHQ: MatPaginator;
  @ViewChild("SortHQ", {static: false}) sortHQ: MatSort;

  @ViewChild('SortMoney') SortMoney: MatSort;
  @ViewChild('SortTime') SortTime: MatSort;

  @Input() ticket: any;
  @Input() confTable: any;

  @Output() ticketChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() updateImgInfo: EventEmitter<any> = new EventEmitter<any>();

  public comentsForm: FormGroup;
  public mapPos: Object;
  public detail;
  public _detailFocus = {title: false, description: false, notes: false }
  public investedTime = [];
  public timeTable = [];
  public moneyTable = [];
  public contractors = [];
  public providers = [];
  public files = [];
  public _id: string;
  public url: string;
  public fileList: any[] = [];
  public selectedFile: any;
  public img: string = "";
  public customerSelected = null;
  public contractSelected = null;

  public detailTab:number = 0;

  public loading: boolean = false;
  public loadingQuotes: boolean = false;
  public loadingFiles: boolean = false;
  public loadingAddress: boolean = false;
  public contractPermission: boolean = false;

  public comments: any[] = [];
  public user: IUser = {};
  public total: any;
  public apiUrl = environment.apiUrl;
  public quotesRecord: any[] = [];
  public quoteSelected: number;
  public userAddress: string = "";
  public idContract: string = "";
  public contractCode: string = "";
  public element: HTMLElement;
  public blockDetail: boolean;
  public modalOption: any = {id: "", type: "", images: null};
  private chat: any;
  public fileData;
  public editable: boolean = true;
  public contracts: any;
  public viewContract: boolean = true;
  formatDate = (date, format) => {
    return moment(date).format(format);
  };
  today = () => {
    return moment();
  };
  minutesToHours = (minutes): string => {
    let hrs: number = moment.duration(minutes, "minutes").asHours();
    return `${Math.floor(hrs)}:${(hrs - Math.floor(hrs)) * 60}`;
  };
  getSum = (array, property) => {
    let sum = 0;
    array?.forEach((item) => {
      sum += item[property] || 0;
    });
    return sum;
  };
  constructor(
    private httpService: HttpService,
    public userService: UserService,
    private modalService: NgbModal,
    private alertService: AlertService,
    private fb: FormBuilder,
    private dropdown: DropdownService,
    private builtservice: BuiltFormDataService,
    private toast: AlertService,
    private valueType: ValueTypeService,
    private bankData: DataBankService,
    public decrypt: DecryptPipe,


  ) {
    this.url = environment.apiUrl;
    this.DT_Binnacle = new MatTableDataSource();
    this.DT_HistoryQuotes = new MatTableDataSource();
    this.DT_money = new MatTableDataSource();
    this.DT_time = new MatTableDataSource();
  }

  ngOnInit(): void {
    
    this.user = this.userService.user;
    this.editable = this.userService?.editOriginMobile;
        
    this.getCustomer();
    
    var moduleContract =  this.userService.user.permissions.find(item=>{return item.module == "contracts"}) || null;
    this.contractPermission= (moduleContract );

    this.comentsForm = this.fb.group({
      description: [null, Validators.compose([Validators.required])],
    });
    this.detailTab = 0;


  }

  ngOnDestroy(): void {
    //this.destroyed$.next();
    //this.destroyed$.complete();
  }

  async ngOnChanges() {
    await this.getDetail(this.ticket.id); 
    
    if (window.location.pathname == '/tickets' || window.location.pathname == '/memberships' || window.location.pathname.includes("/home")) {
      this.editable = this.userService?.editOriginMobile || this.detail.createIn != 'MOBILE';
      window.location.pathname == '/memberships' ? this.viewContract = false : "";
    }else{
      this.editable = false
    }    

  }

  public filterQuotes(array: any[], isNew: boolean){
    if(isNew) return array.filter(item=>{return item.status == "NEW"})
    else return array.filter(item=>{return item.status != "NEW"})
  }

  private commentsChat(){
    this.element = document.getElementById('chat-messages'); 
    if(this.element) {
      setTimeout(() => {
        this.element.scrollTop = this.element.scrollHeight; 
      },20)
    }
  }

  public async getDetail(id: string, event: boolean = false) {
    try {
      this.loading = true;
      let resp = await this.httpService.get(`api/ticket/${id}`).toPromise();
      this.detail = resp;

      this.modalOption.images = resp?.images;      

      //? Filtra los registros de las tablas por unidad
      this.timeTable = this.detail?.movements.filter(mov => {return mov?.unit == "TIEMPO"});
      this.moneyTable = this.detail?.movements.filter(mov => {return mov?.unit == "DINERO"});

      for (let item of this.moneyTable) {
        item.folio = `${this.detail?.folio}.${item?.folio}`;
        item.updateDate = item?.lastUpdate?.date + "*" + moment(item?.lastUpdate?.date).format("DD/MM/YYYY HH:mm");
        item.createDate = item?.createBy?.date + "*" + moment(item?.createBy?.date).format("DD/MM/YYYY HH:mm");
      }

      this.DT_money.data = this.moneyTable;
      this.DT_money.sort = this.SortMoney;

      for (let item of this.timeTable) {
        item.folio = `${this.detail?.folio}.${item?.folio}`;
        item.updateDate = item?.lastUpdate?.date + "*" + moment(item?.lastUpdate?.date).format("DD/MM/YYYY HH:mm");
        item.createDate = item?.createBy?.date + "*" + moment(item?.createBy?.date).format("DD/MM/YYYY HH:mm");
      }

      this.DT_time.data = this.timeTable;
      this.DT_time.sort = this.SortTime;

      this.images = resp?.images;
      this.image = resp?.image;
      this.notes = resp?.notes;
      this.description = resp?.description;
      this.title = resp?.title;

      await this.searchContract('customerid',resp?.customerid,true);
      this.contractSelected = resp?.contract?.id
      this.customerSelected = resp?.customerid

      this.commentsChat();
      
      event ? this.updateDataImg() : "";
      
      this.chat = await this.httpService.get(`api/chat/byTicket/ticketId/${id}`).toPromise();

      // this.socket.clearRooms();
      // // this.socket.addRoom(this.userService.user?.socketRoom);
      // this.socket.addRoom(this.userService.user?.socketRoom+this.chat?._id);
      // this.socket.disconnect();
      // this.socket.connect();
      
      this.DT_Binnacle.data = resp.history;
      this.DT_Binnacle.paginator = this.paginator;
      this.DT_Binnacle.sort = this.sort;
      
      this.mapPos = resp.coordinates;
      let { latitude, longitude } = resp.coordinates || "";
      this.getAddress(latitude, longitude);
      
      this.files = resp.privateAttachment || [];
      this.files = this.files?.concat(resp.publicAttachments || []);
      this.img = `${this.url}${resp?.image}`;

      this.comments = resp.comments;
      this._id = resp._id;
      this.modalOption.id = resp._id;
      this.blockDetail = (this.detail?.status == "LIBERADO" || this.detail?.status == "CANCELLED")
      this.getHistoryQuotes(resp._id);
      if(this.ticket?.type == 'CLIENT') this.detailTab = 2;
      if(this.ticket?.type == 'INTERNAL') this.detailTab = 1;
      this.loading = false;
      
    } catch (error) {
      if (error.status != 403) {
        console.log(error.message);
      }
      this.loading = false;
      // this.loadingFiles = false;
    }
  }

  private getAddress(latitude: string, longitude: string) {
    // this.loadingAddress = true;
    // this.loadingFiles = true;
    try {
      let Http = new XMLHttpRequest();
      Http.responseType = "json";
      let key = "&key=AIzaSyB7AZ8E0BsiK2X10ituHHsFjSBLPUNz1Qk";
      let url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude} ${longitude} ${key}`;

      Http.open("POST", url);
      Http.send();
      Http.onreadystatechange = () => {
        if (Http.status == 200) {
          let resp = Http.response;
          this.userAddress = resp?.results[0]?.formatted_address;
        } else {
          this.userAddress = "";
        }
        // this.loadingAddress = false;
        // this.loadingFiles = false;
      };
    } catch (error) {
      console.log(error);
      // this.loadingAddress = false;
      // this.loadingFiles = false;
    }
  }

  private async getHistoryQuotes(_id: string) {
    try {
      this.loadingQuotes = true;
      let resp = await this.httpService.get(`api/quote/list/${_id}`).toPromise();
      this.quotesRecord = resp;
      this.loadingQuotes = false;
    } catch (error) {
      this.loadingQuotes = false;
      this.DT_HistoryQuotes.data = [];
      this.total = "";
    }
    
  }

  public async addComment() {
    try {
      const data = this.comentsForm.value;
      let resp = await this.httpService.post(`api/ticket/comment/${this._id}`, data).toPromise();
      this.alertService.successToast(resp.message);
      this.comentsForm.reset();
      this.getDetail(this._id);
      this.commentsChat();
    } catch (error) {
      this.comentsForm.reset();
      console.log(error);
    }
  }

  public async removeSelectedFile(_id: string, file: string) {
    try {
      let resp = await this.httpService.deleteBody(`api/ticket/attachment`, { ticket: _id, file: file }).toPromise();
      this.alertService.successToast(resp.message);
      this.getDetail(this.ticket.id);
    } catch (error) {
      console.log(error.message);
    }
  }

  private buildFormData(formData, data, parentKey?):void {
    if ( data && typeof data === "object" && !(data instanceof Date) && !(data instanceof File) ) {
      Object.keys(data).forEach((key) => {
        this.buildFormData(formData, data[key], parentKey ? `${parentKey}` : key );
      });
    } else {
      // const value = data == null ? "" : data;
      const value = data == null ? '' : ((typeof data === 'object' && !(data instanceof File))?JSON.stringify(data):data);
      formData.append(parentKey, value);
    }
  }

  public search(event):void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.DT_Binnacle.filter = filterValue.trim().toLowerCase();
  }

  public openModal(modal, size: string = "lg"):void {
    this.modalService.open(modal, { size: size, backdrop: 'static', scrollable: true });
  }

  public openImg(template: any, img: any, event: any):void {
    this.modalImgSrc = this.url + img?.name;
    this.dropdown.openDialog(template, event, 250, true);
  }

  public async deleteComment(id:string){
    try {
      await this.httpService.deleteBody(`api/ticket/comment/${this.detail?._id}`,{id: id}).toPromise();
      this.comments = this.comments.filter(comment => {return comment.id != id});
      this.alertService.successToast("Se borro correctamente el comentario.");
    } catch (error) {
      if (error.status != 403) {
        console.log(error);
        this.alertService.errorToast("No se pudo borrar el comentario.");
      }
    }
  }

  public validateIncludeOnChat(type: string):boolean{
    let isInclude: boolean = false;
    isInclude = this.getChatType(type)?.members?.includes(this.userService.user._id);
    return isInclude;
    
  }

  public getChatType(type:string):any{
    return this.chat?.find(chat => {return chat.type == type}) || null;
    
  }

  public dataFileAsign(event){
        this.fileData = event;
  }

  public async addAudio( event){
    if (event.target.files[0].size < 6000000) {
      //Tomar audio del input
      let file: File = event.target.files[0];
   
      try {

        let audioContext = new AudioContext();
        let buffer = await file.arrayBuffer();
        let audioBuffer = await audioContext.decodeAudioData(buffer);
        let float32Array = audioBuffer.getChannelData(0);

        const wavBytes = this.getWavBytes(float32Array.buffer, {
          isFloat: true,       // floating point or 16-bit integer
          numChannels: 1,
          sampleRate: audioBuffer.sampleRate,
        })
        const blob = new Blob([wavBytes], { type: 'audio/wav' })

        var fileConverted = new File([blob], file?.name+".wav", { type:"audio/mpeg"} );        
        
        let obj = {
          field: "audio",
          file: fileConverted,
          id: this.ticket.id
        }                

        let formData = new FormData();
        this.builtservice.buildFormData(formData, obj);
  
        await this.httpService.put(`api/ticket/file`, formData).toPromise();
        this.toast.successToast('Audio guardado correctamente');
        this.files = [];
        
      } catch (error) {
        console.log(error);
      }
        
      try {
        await this.getDetail(this.ticket.id);
        this.onUpdateAudio('add');

        } catch (error) {
        console.log(error);
      }
   
    } else {
      this.alertService.infoToast("El archivo de audio debe ser menor a 5MB.");
      event.target.value = '';
    }
  }

  private getWavBytes(buffer, options) {
    const type = options.isFloat ? Float32Array : Uint16Array
    const numFrames = buffer.byteLength / type.BYTES_PER_ELEMENT
  
    const headerBytes = this.getWavHeader(Object.assign({}, options, { numFrames }))
    const wavBytes = new Uint8Array(headerBytes.length + buffer.byteLength);
  
    // prepend header, then add pcmBytes
    wavBytes.set(headerBytes, 0)
    wavBytes.set(new Uint8Array(buffer), headerBytes.length)
  
    return wavBytes
  }
  
  // returns Uint8Array of WAV header bytes
  private getWavHeader(options) {
    const numFrames =      options.numFrames
    const numChannels =    options.numChannels || 1
    const sampleRate =     options.sampleRate || 44100
    const bytesPerSample = options.isFloat? 4 : 2
    const format =         options.isFloat? 3 : 1
  
    const blockAlign = numChannels * bytesPerSample
    const byteRate = sampleRate * blockAlign
    const dataSize = numFrames * blockAlign
  
    const buffer = new ArrayBuffer(44)
    const dv = new DataView(buffer)
  
    let p = 0
  
    function writeString(s) {
      for (let i = 0; i < s.length; i++) {
        dv.setUint8(p + i, s.charCodeAt(i))
      }
      p += s.length
    }
  
    function writeUint32(d) {
      dv.setUint32(p, d, true)
      p += 4
    }
  
    function writeUint16(d) {
      dv.setUint16(p, d, true)
      p += 2
    }
  
    writeString('RIFF')              // ChunkID
    writeUint32(dataSize + 36)       // ChunkSize
    writeString('WAVE')              // Format
    writeString('fmt ')              // Subchunk1ID
    writeUint32(16)                  // Subchunk1Size
    writeUint16(format)              // AudioFormat 
    writeUint16(numChannels)         // NumChannels
    writeUint32(sampleRate)          // SampleRate
    writeUint32(byteRate)            // ByteRate
    writeUint16(blockAlign)          // BlockAlign
    writeUint16(bytesPerSample * 8)  // BitsPerSample
    writeString('data')              // Subchunk2ID
    writeUint32(dataSize)            // Subchunk2Size
  
    return new Uint8Array(buffer)
  }

  public downloadAudio(convertedAudioDataObj) {
    let a = document.createElement("a");
    a.href = convertedAudioDataObj.data;
    a.download = convertedAudioDataObj.name + "." + convertedAudioDataObj.format;
    a.click();
}

  public async deleteAudio(){
    try {

      let obj = {
        field: "audio",
        file: [],
        id: this.ticket.id
      }
  
      await this.httpService.put(`api/ticket/file`, obj).toPromise();
      this.toast.successToast("Audio Eliminado correctamente");
      this.getDetail(this.ticket.id);
      this.onUpdateAudio('delete');

    } catch (error) {
      if (error.status != 403) {
        this.toast.errorToast("Ocurrio un error");
      }
      console.log(error);
    }
  }

  public async updateDataImg(){

    let obj = {
      group: this.ticket?.group,
      row: this.ticket?.id,
      target: 'images',
      value: this.image || null
    }    
    let objimage = {
      group: this.ticket?.group,
      row: this.ticket?.id,
      target: 'image',
      value: this.images
    }        
    this.updateImgInfo.emit(obj);    
    this.updateImgInfo.emit(objimage);    

  }

  public async onUpdateAudio(event:string){
    var value;
    event == 'add' ? value = this.detail.audio : ""

    let obj = {
      group: this.ticket?.group,
      row: this.ticket?.id,
      target: 'audio',
      value: value
    }

    this.updateImgInfo.emit(obj);

  }

  public getType(target:string):string{
    let options = this.ticket?.subitems?.find(item => {return item.target == target})?.options || null;
    // console.log(options);
    
    return options?.prefix;
  }

  public async updateInfo(value,field:string){

    let temptype = "";
    let tempname = "";
    let row = this.confTable?.main.filter(grp => {return grp.target == field})[0];
            
    if(field == 'notes'){
      temptype = 'LONGTEXT';
      tempname = 'Notas'
    };
    
    if(field == 'contract'){
      temptype = 'DYNAMIC-SELECT'
      tempname = this.userService?.modulesName?.contract || 'Contrato';
    };
    if(field == 'customerid'){
      tempname = this.userService?.modulesName?.client || 'Cliente';
      temptype = 'DYNAMIC-SELECT';
    };
    if (field == 'description') {
      tempname = 'Descripcion'
      temptype = 'TEXT';
    }
    if (field == 'title'){
      tempname = 'Asunto'
      temptype = 'TEXT';
    }
    
    let obj = {
      id: this.detail?._id,
      field: field,
      value: value,
      table: this.confTable?._id,
      position: this.detail?.position,
      name: row?.name ? row?.name  : tempname, 
      type: this.valueType.getType(row ? row?.type: temptype),
    }

  try {
      await this.httpService.put(`api/ticket/detail/field`, obj).toPromise();
      this.alertService.successToast("campo actualizado correctamente")
    } catch (error) {
      console.log(error);
    }

  }

  // openSnackBar(message: string, action: string, targe: any, id:any, create_date: any, table: any, position: any) {
  //   this._snackBar.open(message, action, {
  //     horizontalPosition: this.horizontalPosition,
  //     verticalPosition: this.verticalPosition,
  //     duration: this.durationInSeconds
  //   }).onAction().subscribe(() => targe == "department" ? this.undoChangesGroup() : this.undoChangesColumns(targe, id, create_date, table, position)); 
  // }

  // undoChangesGroup(){}

  public async getCustomer(){
    try {
      this.customers = await this.bankData.dataLoad('customer');
      this.customers.forEach(element => {
        element.email = this.decrypt.transform(element.email);
        element.name = this.decrypt.transform(element.name);
        element.phone = this.decrypt.transform(element.phone);

      });
    } catch (error) {
      console.log(error);
    }
  }

  public async searchContract(field,id,detail){
    
    this.contractSelected = null;
    try {
      if (id) {
        const data = await this.httpService.get(`api/catalogue/contract/customerid/${id}`).toPromise();
        this.contracts = data;
      }

      if (!detail) {
        this.updateInfo(id,field);
        //? si se desasigna el cliente, se hace reset a contrato
        this.updateInfo(null,'contract');
      }
 
    } catch (error) {
      console.log(error);
    }
  }
}
