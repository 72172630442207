
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MktDropwdownComponent } from './mkt-dropwdown.component';

@NgModule({
  declarations: [
    MktDropwdownComponent,
  ],
  exports: [
    MktDropwdownComponent,
  ],
  imports: [
    CommonModule,
    NgbModule,
  ],
})
export class MkDropdownModule { }
