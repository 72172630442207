<div class="center">
    <span class="_block text-overflow _input cursor-pointer"  placement="top" [ngbTooltip]="(value | status: module) | uppercase" tooltipClass="tooltip-small" [style.background-color]="statusColor(value)" (click)="!readonly && !disabled ? SelectStatus(menu, $event) : {}">{{((value | status: module) | uppercase) }}</span>
</div>
<!-- <div class="stick"><mkt-dropdown *ngIf="focus"></mkt-dropdown></div> -->

<ng-template #menu>
    <div class="mkt_dropdown mkt_col col-status">
        <div class="head">Seleccionar</div>
        <div class="content pb-0">
            <ng-container *ngFor="let status of options">
                <div *ngIf="!status.hidden" (click)="emitValue(status.name);setFocus(false)" [style.background-color]="status?.color" class="_block text-overflow _input cursor-pointer mb-2 ">{{(status?.name | status: module) | uppercase }}</div>
            </ng-container>
        </div>
    </div>
</ng-template>