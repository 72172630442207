<form [formGroup]="DinamicForm">

    <div class="row">
        <div class="col-sm-12">
          <dnc-title [check]="check" [editable]="editable"></dnc-title>

        <input type="text" ngx-google-places-autocomplete #placesRef="ngx-places" 
        [ngClass]="(check?.target?.readonly || !editable) ? 'disabled' : ''" 
        (onAddressChange)="handleAddressChange($event)" 
        class="form-control inputNumber"  
        [formControlName]="this.check.formControlName"
        [placeholder]="check.target.placeholder"
        maxlength="500"
        [readonly]="(check?.target?.readonly || !editable)"        
        [maxlength]="check.target.validators?.maxLength"
        />

        <small class="text-danger" *ngIf="editable && fieldisValid.campoEsValido(this.check.formControlName, DinamicForm)">{{check.validateMessage}}</small> 
        </div>  
    </div>

    <div class="row row-sm mt-2">
        <div class="col-sm-12 border-top p-0 map-height" style="--height: {{check?.target?.height}}">
          <agm-map #googlemap 
          
            [latitude]="mapPos.lat" 
            [longitude]="mapPos.lng" 
            [zoom]="mapPos.zoom"  
            [streetViewControl]="check?.target?.streetViewControl" 
            [maxZoom]="check?.target?.maxZoom" 
            [mapTypeControl]="check?.target?.mapTypeControl" 
            [fitBounds]="centerBounds"
            [styles]="mapStyle"
            [zoomControl]="check?.target?.zoomControl" 
            [mapDraggable]="check?.target?.mapDraggable && editable" 
            (mapDblClick)="(check?.target?.mapClickeable && editable) ? markerDragEnd($event) : ''"
            >
            <agm-marker [latitude]="markerPos.lat" [longitude]="markerPos.lng" [zIndex]="1000" 
            [animation]="check?.target?.animation || 'DROP'" (dragEnd)="markerDragEnd($event)"
            [markerDraggable]="check?.target?.markerDraggable && editable"
              >
              <agm-info-window>
                <strong>Info del marcador</strong>
                <br>
                <span>Coordenadas: {{markerPos.lat}} , {{markerPos.lng}}</span>
              </agm-info-window>
            </agm-marker>
          </agm-map>
        </div>
      </div>
    
</form>
