import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ValidateFormsDirective } from 'src/app/directives/validate-forms.directive';
import { AlertService } from 'src/app/shared/services/alert.service';
import { CryptoSharkService } from 'src/app/shared/services/crypto-shark.service';
import { HttpService } from 'src/app/shared/services/http.service';

@Component({
  selector: 'auth-modal',
  templateUrl: './auth-modal.component.html',
  styleUrls: ['./auth-modal.component.scss']
})
export class AuthModalComponent implements OnInit {

  @Output() onClose: EventEmitter<any> = new EventEmitter<any>();
  @Output() onConfirm: EventEmitter<any> = new EventEmitter<any>();

  public form: FormGroup; 
  public isLoading: boolean = false;

  constructor(
    private fb: FormBuilder,
    private alertService: AlertService,
    private httpService: HttpService,
    public validatorForm: ValidateFormsDirective,
    private cryptoService: CryptoSharkService
  ) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      user: [null, Validators.compose([Validators.required])],
      nip: [null, Validators.compose([Validators.required])],
    });
  }

  async submit(){ 
    try {
      
      if(!this.form.valid){
        this.form.markAllAsTouched();
        this.alertService.infoToast("Campos incompletos");
        return;
      };
      this.isLoading = true;

      this.httpService.post(`api/user/valid/nip`, {
        user: this.form?.value?.user,
        nip: this.cryptoService.encryptSha( this.form.value?.nip )
      }).subscribe(response=>{
        this.onConfirm.emit();
        this.close(); 
        this.isLoading = false;
      },error => {
        this.isLoading = false;
        if (error.status != 403) {
          this.alertService.errorToast(error.error.message);
        }
      })
    } catch (error) {
      this.isLoading = false;
      console.log(error);
      if (error.status != 403) {
        this.alertService.errorToast("No se pudo autorizar.");
      }
    }
  }

  public close(): void { this.onClose.emit(); }
}
