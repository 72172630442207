<span class="text-muted">
    Mostrando <b>{{getCurrentLength() ? ((pageSize * index) + 1) : 0}}</b> a <b>{{getCurrentLength()}}</b> de <b>{{arrayLength}}</b> Registro(s)
</span>
<div class="d-flex" style="--color: {{color}}">
    <button class="border arrow" (click)="selectIndex(index - 1)" [class.disabled]="index == 0">
        <b><i class="fa fa-chevron-left"></i></b>
    </button>
    <ng-container *ngIf="_pages.length < 5">
        <ng-container *ngFor="let b of _pages; index as i">
            <button class="border" [class.active]="i == index" (click)="selectIndex(i)">
                <b>{{i + 1}}</b>
            </button>
        </ng-container>
    </ng-container>

    <ng-container *ngIf="_pages.length >= 5">
        <button class="border" [class.active]="0 == index" (click)="selectIndex(0)">
            <b>1</b>
        </button>
        <span class="separator" *ngIf="(index > 1)"><i class="fas fa-ellipsis-h"></i></span>

        <ng-container *ngFor="let b of [-1,0,1]; index as i">
            <ng-container *ngIf="((index + b) >+ 0) && (index + b) < _pages.length - 1">
                <button class="border" [class.active]="(index + b) == index" (click)="selectIndex(index + b)">
                    <b>{{(index + b) + 1}}</b>
                </button>
            </ng-container>
        </ng-container>

        <span class="separator" *ngIf="index < (_pages.length - 2)"><i class="fas fa-ellipsis-h"></i></span>
        <button class="border" [class.active]="_pages.length - 1 == index" (click)="selectIndex(_pages.length - 1)">
            <b>{{_pages.length}}</b>
        </button>
    </ng-container>
    <button class="border arrow" (click)="selectIndex(index + 1)" [class.disabled]="!(index < (_pages.length - 1))">
        <b><i class="fa fa-chevron-right"></i></b>
    </button>
</div>