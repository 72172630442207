import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { DynamicControlsService } from 'src/app/shared/services/dynamic-controls.service';
import { DyamicFormService } from 'src/app/shared/services/dynamic-form.service';

@Component({
  selector: 'dnc-check',
  templateUrl: './dnc-check.component.html',
  styleUrls: ['./dnc-check.component.scss', '../../dynForm.comp.scss'],
  host:{
    "[style.order]": "check.position",
    "[class]": "'col-sm-12 col-md-' + (check.col_size || 4)",
    "[class.horizontal]": "check.target.horizontal || false"
  }
})
export class DncCheckComponent implements OnInit {

  @Output() onPrueba: EventEmitter<any> = new EventEmitter<any>();
  @Output() onChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() onChangeDependencie: EventEmitter<any> = new EventEmitter<any>();
  @Output() onChangeDependencieRoot: EventEmitter<any> = new EventEmitter<any>();

  @Input() editable : boolean = true;


  constructor(

    private fb: FormBuilder,
    public dynamicControls: DynamicControlsService,
    private dynamicForm: DyamicFormService,
    public sanitizer: DomSanitizer,
  ) { 
  }

  @Input() check: any;
  @Input() editForm: any;

  public DinamicForm: FormGroup;

  ngOnChanges(change: SimpleChanges){
    if (!change?.editForm?.firstChange) { // Reasigna los valores dependiendo del elemento a renderizar
      if (this.check?.form == change?.editForm?.currentValue?.name) {
        this.DinamicForm = this.dynamicForm.ReMapEditForm(change?.editForm?.currentValue?.form, this.DinamicForm);

        //? Realiza cambios en las dependencias especificadas en configuracion
        if (this.check?.actions?.length) {          
          // this.onChangeDependencie.emit({depend:this.check?.actions[0], value: change?.editForm?.currentValue?.form?.value[this.check?.actions[0]?.formControlName]})   
          this.check?.actions.forEach(element => {          
            this.onChangeDependencie.emit({depend:element, value:change?.editForm?.currentValue?.form?.value[element?.formControlName], actions: this.check?.actions})
          });       
        }
      }
    }
  }

  async ngOnInit() {

    this.DinamicForm = this.fb.group({
      [this.check.formControlName]:[false]
    });

    // Controls
    this.DinamicForm = await this.dynamicControls.asignControls(this.check,  this.DinamicForm);

    //Establece los valores de inicio del formulario
    this.dynamicEmit(event);
  }

  dynamicEmit(event){    
  

    this.onChange.emit({event:this.DinamicForm, form: this.check?.form});  

    if (this.check?.actions?.length) {      
      // this.check.actions[0].value = event.target.value;
      if (this.check?.notrendersections) {//? define el tipo de accion se realizara y a que escala
        this.onChangeDependencieRoot.emit({depend:this.check?.actions, value: event?.target?.checked})
        
      }else{
        this.check?.actions.forEach(element => {          
          this.onChangeDependencie.emit({depend:element, value: event?.target?.checked, type: element?.type, actions: this.check?.actions})
        });
      }
     

      
    }
  
  }

  public validateRequired(): boolean {
    let required: boolean = this.check?.target?.validators?.required || false;
    return required;
  }

}
