<div class="center">
    <div class="__u" [class._border]="!readonly" (click)="!readonly ? selectUser(menu, $event) : {}">
        <ng-container *ngIf="!_value?.length">
            <ng-container *ngTemplateOutlet="userTemplate; context:{user: null}"></ng-container>
        </ng-container>
        <ng-container *ngFor="let user of _value?.slice(0,2); index as i">
            <ng-container *ngTemplateOutlet="userTemplate; context:{user: user, stack: i ? _value.length - _value?.slice(0,2)?.length : 0}"></ng-container>
        </ng-container>
    </div>
</div>

<ng-template #userTemplate let-u="user" let-s="stack">
    <div class="css_mktUser" [placement]="labelPosition" ngbTooltip="{{(u?.name?.name || u?.name)}}" tooltipClass="tooltip-small">
        <div class="_avatar" [class.fill]="(u || !(u.name))" *ngIf="(u?.name?.name || u?.name) else noUser">
            <ng-container *ngIf="!u.img">
                <span *ngIf="u?.name else userNA">{{((u?.name?.name || u?.name) | initials)}}</span>
            </ng-container>
            <img [src]="apiUrl+u?.img | imagesLoad" *ngIf="u?.img">
            <div class="delete"><i class="fa fa-close"></i></div>
        </div>
    </div>
    <div class="plus" *ngIf="s">+{{s}}</div>
</ng-template>

<ng-template #noUser>
    <div class="_avatar"></div>
</ng-template>

<ng-template #userNA>
    <span class="text-muted">N/A</span>
</ng-template>

<ng-template #menu>
    <div class="mkt_dropdown mkt_col">
        <div class="head">Usuario</div>
        <div class="content p-0">
            <div class="_browser"><i class="fa fa-search"></i> <input type="text" class="_block mktd-block" placeholder="Buscar" (keyup)="filter(inputsearch.value)" #inputsearch/></div>
                <div class="pt-1">
                    <ng-container *ngFor="let user of filtering">
                        <div class="__u _border" [class.selected]="isSelected(user.id)" (click)="isSelected(user.id) ? deleteUser(user) : emitValue(user)">
                            <ng-container *ngTemplateOutlet="userTemplate; context:{user: user}" ></ng-container>
                        </div>
                    </ng-container>
                </div>
        </div>
    </div>
</ng-template>