<div class="modal-header">
    <h6 class="modal-title" id="modal-basic-title"> Detalle {{userService?.modulesName?.contract || 'Contrato'}}</h6>
    <button type="button" class="close" aria-label="Close" (click)="close()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<form [formGroup]="FormContract" novalidate>
    <div class="modal-body py-0" [class.border-top-0]="!isLoading">
        <mk-loader style="z-index: 120;" class="loader-spinner" *ngIf="isLoading"></mk-loader>
    <div class="row">
            <ng-container *ngFor="let item of DataForm?.sections; last as last " [stepTitle]="item.name">
                <div class="col-sm-12 border-top py-3">
                <dinamic-forms (onFormChange)="FormChange($event)" (onFormChildrensChange)="FormChildrensChange($event)"
                    [dataInput]="dataInput" [editForm]="editForm" [DataForm]="item" [editable]="false"></dinamic-forms>
                </div>
        </ng-container>
    </div>
</div>
</form>
<div class="modal-footer justify-content-end">

    <button type="button" class="btn btn-dark" (click)="close()">Cerrar</button>
</div>

<!-- 

<mk-loader *ngIf="isLoading" class="loader-spinner" style="z-index: 120;"></mk-loader>
<div class="modal-body pt-4"> 
    <div class="row">
        <div class="col-12">
            <span class="text-muted">Contrato #{{contractCode}}</span>
            <h4 class="mt-0 mb-2">{{contract?.title}}</h4>
        </div>
    </div>
    <div class="row">
        <div class="col-12 d-flex justify-content-between">
            <span class="text-primary">Celebrado  por <b>{{contract?.whoCelebrate}}</b> </span>
            <span class="text-muted">Termina el <b>{{contract?.finish | moment: 'lll'}}</b></span> 
            <span class="text-muted">Creado el <b>{{contract?.createBy.date | moment: 'lll'}}</b></span>
        </div>
    </div>
    <div class="row border-top mt-3 pt-3">
        <div class="col-12">
            <h6> Descripción</h6>
            <p>{{contract?.description}}</p>
        </div>
    </div>
    <div class="row border-top mt-3 pt-3">
        <div class="col-4">
            <h6>Domicilio</h6>
            <input type="text" class="form-control" [value]="contract?.address"  readonly tabindex="-1">
        </div>
        <div class="col-4">
            <h6>Constitución</h6>
            <input type="text" class="form-control" [value]="contract?.constitution" readonly tabindex="-1">
        </div>
        <div class="col-4">
            <h6>Seguros</h6>
            <input type="text" class="form-control" [value]="contract?.insurance" readonly tabindex="-1">
        </div>
    </div>
    <div class="row border-top mt-3 pt-3">
        <div class="col-4">
            <h6>Renta</h6>
            <input type="text" class="form-control" [value]="contract?.rentAmount"  readonly tabindex="-1">
        </div>
        <div class="col-4">
            <h6>Servicios Publicos</h6>
            <input type="text" class="form-control" [value]="contract?.publicServices" readonly tabindex="-1">
        </div>
        <div class="col-4">
            <h6>Mantenimiento</h6>
            <input type="text" class="form-control" [value]="contract?.parkMaintenance" readonly tabindex="-1">
        </div>
    </div>
</div>
<div class="modal-footer justify-content-end">
    <button type="button" class="btn btn-dark" (click)="close()">Cerrar</button>
</div>


    Campos para agregar del contrato! 
    -informacion de la empresa
    descripcion,
    direccion
    agregar descripcion
    [value]="contract.insurances.join(', ')" 
-->
