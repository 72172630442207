<mk-loader *ngIf="isLoading" class="loader-spinner"></mk-loader>

<div class="col-sm-12">
    <div class="row">
        <div class="col-md-2 d-flex">
            <h4 class="text-secondary my-3">Licitantes</h4>
        </div>
        <div class="col-md-10 d-flex">
            <div class="w-100 my-auto">
                <div class="input-group my-1">
    
                    <ng-select class="form-control border-right-0" placeholder="Selecciona Tags"
                    [clearable]="false"
                    [items]="tagsArray"
                    bindLabel="text" 
                    bindValue="_id" 
                    [(ngModel)]="selectedTag"
                    [multiple]="true"
                    (change)="getCategory()"
                    ></ng-select>
    
                    <ng-select class="form-control border-right-0" placeholder="Selecciona Categoria"
                    [items]="typeMembers"
                    bindLabel="label" 
                    [clearable]="false"
                    [(ngModel)]="selectedCategory"
                    (change)="getCategory()"
                    >
                    </ng-select>
               
                    <ng-select class="form-control border-right-0" [placeholder]="selectlabel"
                    [items]="membersData"
                    bindLabel="name" 
                    [clearable]="false"
                    [(ngModel)]="selectedMember"
                    (change)="getUsers($event)"
                    >
                    </ng-select>
    
                    <ng-select class="form-control" placeholder="Selecciona Participante"
                        [items]="usersFilter"
                        bindLabel="name" 
                        [clearable]="true"
                        [(ngModel)]="selectedUser"
                        [disabled]="validUser">
                    </ng-select>
                    <div class="input-group-append">
                        <button class="btn btn-icon btn-dark" [disabled]="!selectedUser" (click)="addMember()"><i class="fa fa-plus"></i></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-12">
            <div class="card table-responsive bg-white mb-3">
                <table class="table table-striped table-bordered mb-0 material-table-container" mat-table [dataSource]="DT_members" 
                    matSort #Sort="matSort" matSortActive="name" matSortDirection="desc">
                    
                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Nombre </th>
                        <td mat-cell *matCellDef="let item">
                            <b>{{item?.name}}</b> 
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="provider">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{userService.modulesName?.provider || 'proveedor'}} o {{userService.modulesName?.contractor || 'contratista'}} </th>
                        <td mat-cell *matCellDef="let item">
                            <i class="fa fa-handshake text-muted mr-1" *ngIf="false"></i><b>{{item?.provider}}</b> 
                        </td>
                    </ng-container>
    
                    <ng-container matColumnDef="email">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Correo </th>
                        <td mat-cell *matCellDef="let item">
                            {{item?.email}}
                        </td>
                    </ng-container>
    
                    <ng-container matColumnDef="phone">
                        <th mat-header-cell *matHeaderCellDef width="120px"> <div class="text-center">Teléfono</div> </th>
                        <td mat-cell *matCellDef="let item">
                            <div class="text-center">{{item.phone}}</div>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="tags">
                        <th mat-header-cell *matHeaderCellDef> <div class="text-center">Tags</div> </th>
                        <td mat-cell *matCellDef="let item">
                            <div class="text-center">
                                <ng-container *ngFor="let tag of item?.tags || []">
                                    <span class="badge badge-primary" [class.ml-1]="!i">{{tag?.text}}</span>
                                </ng-container>
                            </div>
                        </td>
                    </ng-container>
    
                    <ng-container matColumnDef="status">
                        <th mat-header-cell *matHeaderCellDef> <div class="text-center">Estatus</div> </th>
                        <td mat-cell *matCellDef="let item">
                            <div class="d-flex justify-content-center">
                                <mk-status [isTicketStatus]="true" [status]="(item?.status)" class="my-auto"></mk-status>
                                <button *ngIf="item?.status == 'REJECTED_PROVIDER' ? true: false" class="btn btn-primary my-auto ml-2" [swal]="enableProviderSwal" (confirm)="enableProvider(item)" title="Restaurar participante"><i class="fa fa-refresh"></i></button>
                                <button *ngIf="item?.requestData?.comment" class="btn btn-dark my-auto ml-2" [disabled]="item?.name == 'Ivonne'" title="Ver comentario" (click)="openModal(modalComment, item?.requestData?.comment)"><i class="fa fa-comment"></i></button>
                            </div>
                        </td>
                    </ng-container>
    
                    <ng-container matColumnDef="invite">
                        <th mat-header-cell *matHeaderCellDef width="60px"> Invitar </th>
                        <td mat-cell *matCellDef="let item">
                            <div class="text-center"><label class="ckbox lg cursor-pointer"><input type="checkbox" value="contractor" checked><span></span></label></div>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="action">
                        <th mat-header-cell *matHeaderCellDef width="40px" class="text-center"><small>Eliminar</small></th>
                        <td mat-cell *matCellDef="let item">
                            <div class="text-center">
                                <button class="btn btn-danger" [disabled]="status == 'CLOSED' || status == 'FINISHED'" [swal]="deleteMemberSwal" (confirm)="deleteMember(item)"><i class="fa fa-trash"></i></button>
                            </div>
                        </td>
                    </ng-container>
    
                    <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayColumns;"></tr>
    
                    <tr class="mat-row" *matNoDataRow><td class="mat-cell text-center" colspan="10"> {{isLoading?"Cargando ...":"Sin datos encontrados"}}</td></tr>
    
                </table>
    
            </div>
        </div>
    </div>
</div>
<ng-container *ngIf="infoTab">
    <div class="modal-footer justify-content-between">
        <button type="button" class="btn btn-dark" (click)="close()">Cerrar</button>
        <button type="button" class="btn btn-success" [disabled]="disabledbtn" (click)="submit()">Guardar <i class="mdi mdi-floppy ml-1"></i></button>
    </div>
</ng-container>

<swal #deleteMemberSwal class="d-none" title="¿Estás seguro de eliminar este participante?" text="No podrás revertir el cambio." icon="question" [showCancelButton]="true" [focusCancel]="true" [swalOptions]="{confirmButtonText: 'Aceptar', cancelButtonText: 'Cancelar'}">
</swal>

<swal #enableProviderSwal class="d-none" title="¿Estás seguro de reactivar el participante?" text="No podrás revertir el cambio." icon="question" [showCancelButton]="true" [focusCancel]="true" [swalOptions]="{confirmButtonText: 'Aceptar', cancelButtonText: 'Cancelar'}">
</swal>

<ng-template #modalComment let-modal>
        <div class="modal-body">
            <div class="row">
                <div class="col-sm-12 text-center">
                    <label class="text-info">Comentario del licitante</label>
                    <h4 class="mt-3 mb-0 d-flex w-100 justify-content-center">
                        <span class="mr-2">  &ldquo;</span>
                        <div class="overflow-hidden">
                            {{messageData}}
                        </div>
                        <span class="ml-2"> &rdquo;</span>
                    </h4>
                </div>
            </div>
        </div>
        <div class="modal-footer justify-content-end">
            <button class="btn btn-dark" (click)="modal.dismiss()">Cerrar</button>
        </div>
</ng-template>