<div class="row m-0 border-bottom _head">
    <div class="col-12 text-right">
        <h4 class="my-3 lh-9">Notificaciones</h4>
    </div>
</div>
<div class="w-100 d-flex flex-column">
    <div class="w-100 py-3 _hover cursor-pointer text-center text-secondary border-bottom" (click)="markAsReadAll()">Marcar todo como leído</div>
    <ng-container *ngFor="let item of notifications">
        <div class="_notif border-bottom {{item.type | lowercase}}" [class.seen]="item.view" (click)="goToNotification(item, $event);">
            <div class="head w-100 d-flex justify-content-between mb-1">
                <!-- <span class="text-muted">Contrato: {{item.contract}}</span> -->
                <span class="text-muted"></span>
                <div class="dot my-auto"></div>
            </div>
            <div class="top d-flex flex-column mb-1">
            <b class="mb-1"><i class="mdi mdi-{{iconType[item.type] || 'circle'}} mr-2 text-color"></i>{{item?.title || '...'}}</b>
            <span class="" [innerHtml]="item?.description || '...'"></span>
            </div>
            <div class="info d-flex justify-content-between">
                <b class="text-color">{{item.page | status}}</b>
                <span class="text-muted">{{item.date | moment: "lll"}}</span>
            </div>
        </div>
    </ng-container>
</div>

<ng-template #detail>
    <app-ticket-detail [ticket]="clickNotification" style="--det-color: {{detailColor}}"></app-ticket-detail>
</ng-template>

<ng-template #modal let-modal>
    <app-modal-bid (onClose)="modal.dismiss()" [id]="clickNotification?.id || null" [movementId]="clickNotification?.movementId" [status]="clickNotification?.status" [goTo]="2"></app-modal-bid>
</ng-template>

<ng-template #detailBidding>
    <request-detail [biddingId]="clickNotification?.id || null" [goTo]="clickNotification?.step"></request-detail>
</ng-template>

<ng-template #modalContract let-modal>
    <app-contract-rentroll-modal [idContract]="clickNotification?.id" [contractCode]="clickNotification?.code" (onClose)="modal.close();"></app-contract-rentroll-modal>
</ng-template>