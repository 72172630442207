import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ValidateFormsDirective } from 'src/app/directives/validate-forms.directive';
import { DynamicControlsService } from 'src/app/shared/services/dynamic-controls.service';
import { DyamicFormService } from 'src/app/shared/services/dynamic-form.service';

@Component({
  selector: 'dnc-textarea',
  templateUrl: './dnc-textarea.component.html',
  styleUrls: ['./dnc-textarea.component.scss', '../../dynForm.comp.scss'],
  host:{
    "[style.order]": "check.position",
    "[class]": "'col-sm-12 col-md-' + (check.col_size || 4)"
  }
})
export class DncTextareaComponent implements OnInit {
  @Input() check: any;
  @Input() editable : boolean = true;
  @Input() editForm: any;
  @Input() touched : boolean = false;
  
  public DinamicForm: FormGroup
  @Output() onChange: EventEmitter<any> = new EventEmitter<any>();


  constructor(
    private fb: FormBuilder,
    private dynamicControls: DynamicControlsService,
    public fieldisValid: ValidateFormsDirective,
    private dynamicForm: DyamicFormService,
    public sanitizer: DomSanitizer

  ) { }

  ngOnChanges(change: SimpleChanges) {
    if (!change?.editForm?.firstChange) { // Reasigna los valores dependiendo del elemento a renderizar
      if (this.check?.form == change?.editForm?.currentValue?.name) {
        this.DinamicForm = this.dynamicForm.ReMapEditForm(change?.editForm?.currentValue?.form, this.DinamicForm);
      }
      
    }

    if (change.touched?.currentValue) {
      this.DinamicForm.markAllAsTouched();
    }
  }

   async ngOnInit() {
    this.DinamicForm = this.fb.group({
      [this.check.formControlName]:[null]
    });

    this.DinamicForm = await this.dynamicControls.asignControls(this.check,  this.DinamicForm);
  }

  dynamicEmit(){
    this.onChange.emit({ event:this.DinamicForm, form: this.check?.form});
  }

  public validateRequired(): boolean{
    let required: boolean = this.check?.target?.validators?.required || false;
    return required;
  }
}
