import { Injectable } from '@angular/core';
import {  FormBuilder, FormGroup, Validators } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class DyamicFormService {

  constructor(
    private fb: FormBuilder,

  ) { }

  public _form;
  public _dynamicform;

  public async validForm(validatorArray: any){
    return new Promise <any> (async (resolve) =>{

      try {
        let obj = {};

        // Genera Formulario principal
        for (const item of validatorArray) {
          let validators = [];
          
          for (const key1 of item.fields) {

            for (const key2 in key1.target?.validators) {

              const validator = key1.target.validators[key2];
              (key2 == "required" && validator ) ?  validators.push((Validators[key2])) : 
                (key2 == "pattern_email" && validator ) ? validators.push((Validators.pattern(/^[a-zA-Z0-9\._\-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3}$/)))  :
                (key2 == "maxLength") ? validators.push((Validators.maxLength(validator))) :
                (key2 == "minLength") ? validators.push((Validators.minLength(validator))) :
                (key2 == "max") ? validators.push((Validators.max(validator))) :
                (key2 == "min") ? validators.push((Validators.min(validator))) :
                (key2 == "required" && !validator ) ? '' : validators.push(Validators[key2](validator));
          }

          if(key1.formControlName){
            if (key1.type == 'array') {
               obj[key1.formControlName] = [[null], Validators.compose(validators)]
            }else{
               obj[key1.formControlName] = [null, Validators.compose(validators)]

            }
          }

          validators = []
        }
        
      }
      this._form = obj;
      this._dynamicform =  this.fb.group(obj);

        resolve(this._dynamicform);

      } catch (error) {
        console.log(error);
        resolve(false)
      }
    });

  }

  public async ArrayValidators(object: any){
    return new Promise <any> (async (resolve) =>{

      try {
          let validators = [];
                  
          for (const key1 in object) {
           let value =  object[key1];

              (key1 == "required" && value) ?  validators.push((Validators[key1])) : 
                (key1 == "pattern_email" ) ? validators.push((Validators.pattern(/^[a-zA-Z0-9\._\-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3}$/)))  :
                (key1 == "maxLength") ? validators.push((Validators.maxLength(value))) :
                (key1 == "minLength") ? validators.push((Validators.minLength(value))) :
                (key1 == "max") ? validators.push((Validators.max(value))) :
                (key1 == "min") ? validators.push((Validators.min(value))) : 
                (key1 == "required" && !value ) ? '' : validators.push(Validators[key1](value));

          }           

          resolve(validators);

      } catch (error) {
        console.log(error);
        resolve(false)
      }
    });

  }

  public  MapForm(form: FormGroup, asignForm: FormGroup){
    // Se mapean los valores del formulario individual al general
    try {
      for (const iterator in form?.controls) {
        asignForm?.patchValue({
       [iterator]: form?.value[iterator]
     })
     
   }
    } catch (error) {
      console.log(error);
    }finally{
      return(asignForm);
    }
    
  }

  public ReMapEditForm(generalform: FormGroup, dynamicform: FormGroup){
    // Reasigna los valores obtenidos a cada formulario individual
    if (generalform) {
      for (const item in generalform.controls) {
            for (const item2 in dynamicform.controls) {
             if (item == item2){dynamicform.patchValue({ [item2]: generalform.controls[item2].value})};
            }
      }
    }
    return(dynamicform);
  }


  public async createFormGroups(config):Promise<boolean>{
    //? config --> Configuracion del formulario guardado en DB

    return new Promise((resolve) => {
      try{
          for (const item of config?.sections) {

              this.validForm([item]).then( res =>  window[item.formName + "Form"] = res);
          } 
                
        resolve(true);

      } catch (error) {
        console.log(error);
        resolve(false);
      }

   });
  }

}
