import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'mkt-text',
  templateUrl: './mkt-text.component.html',
  styleUrls: ['./mkt-text.component.scss', '../../mk-table.comp.scss', '../../mk-table.comp.input.scss'],
  host:{
    "class" : 'mkt_col col-text',
    "(click)": '!readonly && !disabled ? this.setFocus(true) : {}',
    "[class.has-change]": "_hasChange",
    "[class.disabled]": "disabled"
  }
})
export class MktTextComponent implements OnInit {

  @Input() value: string;
  @Output() valueChange: EventEmitter<string> = new EventEmitter<string>();
  @Input() readonly: boolean;
  @Input() disabled: boolean;

  private _hasChange: boolean = false;
  public focus = false;

  @ViewChild('input', {static: false}) input: ElementRef;

  public setFocus = (focus: boolean) => {this.focus = focus; setTimeout(() => {if(focus) this.input.nativeElement.focus()}, 10);}

  constructor() { }

  ngOnInit(): void {
  }

  // ngOnChanges(): void{this._hasChange = true; setTimeout(() => {this._hasChange = false}, 2000);}

  public emitValue(ev: any){this.valueChange.emit(ev)}
}
