import { Component, OnInit, OnChanges } from '@angular/core';
import { tick } from '@angular/core/testing';
import * as moment from 'moment';
import { IColumns } from 'src/app/shared/interfaces/IColumns';
import { ITables } from 'src/app/shared/interfaces/ITables';
import { datePicker } from 'src/app/shared/mk-configs/datePicker/datePickerLocale';
import { HttpService } from 'src/app/shared/services/http.service';
import { LayoutService } from 'src/app/shared/services/layout.service';
import { TableService } from 'src/app/shared/services/table.service';
import { UserService } from 'src/app/shared/services/user.service';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';

@Component({
  selector: 'app-provider-contractor',
  templateUrl: './provider-contractor.component.html',
  styleUrls: ['./provider-contractor.component.scss']
})
export class ProviderContractorComponent implements OnInit {
  public textoBuscar: string = '';
  public detailColor = '';

  public isLoading = false;


  //? LA CONFIGURACION DE LAS TABLAS VIENEN DE BASE DE DATOS
  //? REVISA LOS CAMPOS QUE REQUIERE UN PROVEEDOR/CONTRATISTA PARA VER SUS LICITACIONES Y QUE SEA ADMINISTRABLE
  //? NO SON CAMPOS FINALES

  //todo   NOTA: EL ESTATUS VA A INDICAR QUE SI LA LICITACION:
  //todo   ESTA POR INICIAR
  //todo   ESTA EN PROCESO
  //todo   TERMINO
  //todo   SI SE GANO O PERDIO LA LICITACION

  public _TABLESIZE: number = 20;

  //? LA DATA VIENE DEL REQUEST SEPARADA POR GRUPOS QUE SON LAS TABLAS, CADA GRUPO (INDICE) CORRESPONDE AL _id DE SU TABLA CORRESPONFDIENTE

  //! NOTA: ES POSIBLE QUE HAYA FUNCIONES QUE FALTEN O SOBREN EN ESTA PAGINA, PUEDES CONSULTARLAS DE home.ts PERO QUE NO SOBREPASE LA FUNCIONALIDAD QUE NECESITA EL USUARIO 

  public data: any = {};

  //Calendar
  public filterDate = { startDate: moment().startOf("month"), endDate: moment().endOf("month") };
  public dateOptions: any = datePicker.locale.spanish;
  public dateRanges: any = datePicker.customRange.spanish;
  public dateRangeFired: boolean = false;
  public detailTicket: any;
  public biddingSelectedId: any;


  public DT_Ticket: MatTableDataSource<any>;
  public _TABLESCONFIG: ITables[]
  public _COLUMNSCONFIG: IColumns
  public _hideTables: boolean[] = []; //colapsa las tablas en el indice

  constructor(
    public user: UserService,
    private layoutService: LayoutService,
    private httpService: HttpService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    //Validacion rol usuario logeado tipo proveedor o contratista
    if(this.user.user.roleName !==  "PROVIDER" &&this.user.user.roleName !==  "CONTRACTOR" ) {
       this.router.navigateByUrl('/');
    }
    
    this.initTable();
  }

  async initTable() {
    try {
      this.isLoading = true;
      await this.httpService.get(`api/group/list/licitaciones`).toPromise()
        .then(async (response: any) => {
          this._TABLESCONFIG = response;

          setTimeout(() => {
            this.listRegisters();
          }, 100);

        }).catch((e) => {
          console.log(e);
        })

        .finally(() => {
          setTimeout(() => {
            this.isLoading = false;
          }, 2000);
        })

    } catch (error) {
      console.log(error);
      this.isLoading = false;
    }
  }

  async openSubitems(row: any) {
    try {
      if (row.subitems.active) { row.subitems.active = false }
      else {
        var type = row?.type;
        await this.httpService.get(`api/bidding/byTicket/${row._id}/${type}`).toPromise()
          .then(async (reg: any) => {
            if (reg.biddings) {
              // reg.biddings.forEach(lct => {
              //   var status = lct.status;
              //   var valStatus = this.arrayStatus.find(sts => sts.value === status);
              //   lct.status = valStatus.name;
              // });

              // for (let index = 0; index < reg.biddings.length; index++) {
              //   const bidding = reg.biddings[index];

              //   if (bidding.biddingStatus === "FINISHED" && bidding.request !== null) {
              //     reg.biddings[index].biddingStatus = (bidding.request?.status) ? bidding.request?.status : bidding.biddingStatus;
              //     reg.biddings[index].status = reg.biddings[index].biddingStatus;
              //   }
              // }

              row.subitems.active = !row.subitems?.active
              row.subitems.data = reg.biddings;

            } else {
              row.subitems = [{ active: true, data: [] /*subitems*/ }]
            }
          }).catch((e) => {
            console.log(e);
          })

      }

    } catch (error) {
      console.log(error);
    }
  }

  async subDetailEmit(row: any) { }

  async subDetail(event: any, template: any) {
    this.biddingSelectedId = this.detailTicket?.idSub;
    this.layoutService.emitChange(event);
    this.layoutService.emitSidebarNotifyChange(event, template)
  }

  async listRegisters() {
    try {
      let groups: string[] = [];
      for (const table of this._TABLESCONFIG) {
        this.data[table._id] = [];
        groups.push(table._id);
      }

      await this.httpService.get(`api/bidding/provider/listBiddings`).toPromise()
        .then(async (res: any) => {
          if (res) {
            var arrayTickets = [];
            //c ------------------------------------------
            //c  dataInProgress
            //c ------------------------------------------
            if (res.dataInProgress) {
              var array = res.dataInProgress, tickets = [];

              for (let index = 0; index < array.length; index++) {
                const ticket = array[index];
                ticket.subitemsCount = (ticket.biddingsOpen);
                ticket.subitems = { active: false, data: [] };
                ticket.type = "biddingsOpen";

                if (ticket?._id) { tickets.push({ ...ticket }) }
              }

              for (const iterator of tickets) {
                iterator.group = this._TABLESCONFIG[0]?._id;
                iterator.subitems = { active: false, data: [] };
              }
              arrayTickets.push(tickets);
            }

            //c ------------------------------------------
            //c  dataAccepted
            //c ------------------------------------------
            if (res.dataAccepted) {
              var array = res.dataAccepted, tickets = [];
              for (let index = 0; index < array.length; index++) {
                const ticket = array[index];
                ticket.subitemsCount = (ticket.biddingsWin);
                ticket.subitems = { active: false, data: [] };
                ticket.type = "biddingsWin";
                if (ticket?._id) { tickets.push({ ...ticket }); }
              }

              for (const iterator of tickets) {
                iterator.group = this._TABLESCONFIG[1]?._id;
                iterator.subitems = { active: false, data: [] };
              }
              arrayTickets.push(tickets);
            }


            //c ------------------------------------------
            //c  dataHistory
            //c ------------------------------------------
            if (res.dataHistory) {
              var array = res.dataHistory, tickets = [];
              for (let index = 0; index < array.length; index++) {
                const ticket = array[index];
                ticket.subitemsCount = (ticket.biddingsClose);
                ticket.subitems = { active: false, data: [] };
                ticket.type = "biddingsClose";

                if (ticket?._id) { tickets.push({ ...ticket }) }
              }

              for (const iterator of tickets) {
                iterator.group = this._TABLESCONFIG[2]?._id;
                iterator.subitems = { active: false, data: [] };
              }
              arrayTickets.push(tickets);
            }

            var i = 0;
            for (const group of groups) {
              this.data[group] = arrayTickets[i];
              i++;
            }
          }
        }).catch((e) => {
          console.log(e);
        })
    } catch (error) {
      console.log(error);
    }
  }



  // hacer el buscador de tipo search que recibe el evento keyup
  search(event) {
    this.textoBuscar = (event.target as HTMLInputElement).value;
  }

  selectedDate(e: any) {
  }
}
