import { Injectable, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import {MktDropwdownComponent} from 'src/app/components/mk-table/components/mkt-dropwdown/mkt-dropwdown.component'
import { classicNameResolver } from 'typescript';


@Injectable({providedIn: 'root'})
export class DropdownService {

  private data = new Subject<any>();
  public dataEvent = this.data.asObservable();
  private avoidClassNames : string[] = ['mktd-block']

  public setData(data){
    this.data.next(data);
  }
  
  constructor() { }

  openDialog(template: any, event: any, offset: number = 0, center = false, setAvoidNames = ['mktd-block']){
    this.data.next({action: "open", show: true,center: center, template: template, event: event, offset: offset});
    this.avoidClassNames = setAvoidNames;
  }

  closeDialog(event: any = null){
    if(!this.avoidClassNames.some(className => event?.target.classList.contains(className))) {
      this.data.next({show: false,center: false, template: null, event: {pageX: 0, pageY: 0}, offset: 0, height: 'auto'});
      this.avoidClassNames = ['mktd-block'];
      setTimeout(() => {
        this.setHeight("auto");
      }, 0);
    }
  }

  setHeight(height: any = 'auto'){
    this.data.next({action: "height", height: height});
  }
}
