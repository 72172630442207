<form [formGroup]="DinamicForm">
    <dnc-title [check]="check" [editable]="editable"></dnc-title>
    <ng-select class="form-control" [multiple]="true" [placeholder]="check?.target?.placeholder"
    appearance="true"
    [bindLabel]="check?.target?.label"
    [items]="check?.target?.options"
    [readonly]="check?.target?.readonly || !editable"
    (change)="dynamicEmit()"
    [(ngModel)]="itemsSelected" [ngModelOptions]="{standalone: true}" [hideSelected]>

        <!-- <ng-option *ngFor="let option of check?.target?.options" [value]="option">{{option.name}}</ng-option> -->

    </ng-select>

    <small class="text-danger" *ngIf="editable && fieldisValid.campoEsValido(this.check?.formControlName, DinamicForm)">{{check.validateMessage}}</small>    

</form>



<!-- [bindLabel]="check?.target?.label" appearance="true" [bindValue]="check?.target?.value" -->
