import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'imagesLoad'
})
export class ImagesLoadPipe implements PipeTransform {
  constructor(private domSanitizer: DomSanitizer){}
  transform(img: any): any {
    let domImg = (img ? img: 'assets/default.jpeg');
     

    return this.domSanitizer.bypassSecurityTrustResourceUrl(domImg);
  }

}
