<!-- <mk-loader *ngIf="loading" class="loader-spinner" style="z-index: 120"></mk-loader> -->
<div class="sidebar-title">
    <div class="d-flex justify-content-between">
        <div class="d-flex">
            <h1 class="m-0">#{{biddingDetail?.folio}}</h1>
            <div class="d-flex flex-column pl-3">
                <h4 class="color-tx m-0">Licitación</h4>
                <div class="d-flex">
                    <span class="tx-16">Del {{biddingDetail?.dateStart | moment: 'll'}} Al {{biddingDetail?.dateEnd |
                        moment: 'll'}}</span>
                    <!-- <div><span class="ml-2 badge badge-danger-light d-block cursor-pointer">Eliminar</span></div> -->
                </div>
            </div>
        </div>
        <span class="status badge badge-st-{{((biddingDetail?.status === 'FINISHED' && request?.status) ? request?.status : biddingDetail?.biddingStatus)  | lowercase}}">
            {{ ((biddingDetail?.status === 'FINISHED' && request?.status) ? request?.status : biddingDetail?.biddingStatus) | status:"BIDS"}}
        </span>
    </div>
</div>
<div class="row _tabs">
    <span [class.active]="detailTab == 0" (click)="detailTab = 0">Información General</span>
    <span [class.active]="detailTab == 1" (click)="detailTab = 1">Chat
        <!-- <div class="badge badge-danger mr-1" title="Mensajes administrativos">2</div> -->
    </span>
    <span [class.active]="detailTab == 2" (click)="detailTab = 2">Estimación
        <!-- <mk-status [status]="request?.status" [module]="'BIDS'" [isTicketStatus]="true" *ngIf="request?.status"></mk-status> -->
    </span>
    <span [class.active]="detailTab == 3" (click)="detailTab = 3" *ngIf="showBill">{{(userService?.modulesName?.bill | titlecase) || 'Factura'}}</span>
</div>

<ng-container [ngSwitch]="detailTab">
    <ng-container *ngSwitchCase="0" [ngTemplateOutlet]="tabInfo"></ng-container>
    <ng-container *ngSwitchCase="1" [ngTemplateOutlet]="tabChat"></ng-container>
    <ng-container *ngSwitchCase="2" [ngTemplateOutlet]="tabRequest"></ng-container>
    <ng-container *ngSwitchCase="3" [ngTemplateOutlet]="tabBill"></ng-container>
</ng-container>

<!-- TABS  -->

<ng-template #tabInfo>
    <div class="row">
        <div class="col-md-6 d-flex flex-column justify-content-between">
            <div class="row">
                <div class="col-sm-12">
                    <div class="info">
                        <small class="text-muted d-block">Título</small>
                        <h2 class="color-tx">{{biddingDetail?.title || "..."}}</h2>
                        <small class="text-muted d-block">Descripción</small>
                        <span class="tx-18">{{biddingDetail?.description || "..."}}</span>
                    </div>
                </div>
            </div>
            <button *ngIf="declineBidding" class="btn btn-block btn-danger my-3" (click)="confirmDeclineBidding(1)">No participar en licitación</button>


        </div>

        <div class="col-md-6">
            <h4 class="mb-3">Coordinadores</h4>
            <ng-container *ngFor="let user of biddingDetail?.attendUser">
                <div class="client mb-3">
                    <div class="bg border">
                        <div class="p-2 pb-0">
                            <div class="d-flex">
                                <div class="img">
                                    <img [src]="false || apiUrl+user.photo " alt="" />
                                </div>
                                <div class="d-flex flex-column pl-3">
                                    <!-- <span class="text-muted">Coordinado por:</span> -->
                                    <h4 class="my-auto">{{user.name}}
                                        <!-- <small class="font-weight-normal text-info">(Feedbak)</small> -->
                                    </h4>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex justify-content-between color-tx p-2 border-top">
                            <a [href]="'tel:'+user.phone" class="m-0 tx-12">
                                <i class="fa fa-phone op-5 mr-2"></i>
                                <span>{{user.phone}}</span>
                            </a>
                            <a [href]="'mailto:'+user.email" class="m-0 tx-12">
                                <i class="fa fa-envelope op-5 mr-2"></i>
                                <span>{{user.email}}</span>
                            </a>
                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="!biddingDetail?.attendUser.length">
                <div class="card bg py-4">
                    <h4 class="font-weight-light text-muted text-center m-0">Sin Coordinadores</h4>
                </div>
            </ng-container>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-12">
            <div class="files">
                <div class="card bg border">
                    <div class="card-header py-2">
                        <div class="row row-sm justify-content-between">
                  
                            <div class="w-75">
                                <label class="main-content-label mb-0"><i
                                        class="fa fa-circle mr-1 color-tx"></i> Archivos adicionales</label>
                                <div class="d-flex justify-content-between lh-1 tx-12 mb-0">
                                    <span class="text-muted text-overflow">Archivos de licitación</span>
                                </div>
                            </div>

                            <div class="w-25 my-auto">
                                <!-- CON AUDIO -->
                                <div *ngIf="audio" class="d-flex justify-content-between my-auto">
                                    <h6 class="color-tx my-auto" style="font-size: 1.2em;">Audio</h6>
                                    <!-- <button [swal]="deleteAudioSwal" (confirm)="deleteAudio()" class="btn btn-danger btn-circle mr-2" style="border-radius: 50%;"><i class="fa fa-trash"></i></button> -->
                                
                                    <audio class="_audioTag" controls type="audio/ogg">
                                        <source [src]="apiUrl+audio">
                                    </audio>
                                </div>
                                <!-- SIN AUDIO CREADO EN WEB -->
                                <label class="d-flex justify-content-between my-auto" *ngIf="!audio" placement="bottom" tooltipClass="tooltip-info">
                                    <!-- <h6 class="color-tx my-auto" style="font-size: 1.2em;">Audio</h6> -->
                                    <div class="_audio" *ngIf="true"><span class="text-muted m-auto">Sin Audio</span>
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    <mk-loader *ngIf="loadingFiles" class="loader-spinner" style="z-index: 119"></mk-loader>
                    <div class="row">
                        <div class="col-12 p-3">
                            <div class="d-flex flex-wrap" style="margin: 0px;">
                                <ng-container *ngFor="let file of biddingDetailFiles; index as i" class="">
                                    <app-file-item [filesmanager]="true" [file]="file" [blocked]="true"
                                        (click)="validDownload(file.name, file?.id)"></app-file-item>
                                </ng-container>
                            </div>
                            <ng-container *ngIf="!biddingDetailFiles.length">
                                <div class="text-center w-100 text-muted py-5">Sin Archivos</div>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #tabChat>
    <div class="chat">
        <div class="card bg overflow-hidden">
            <div class="card-header py-2">
                <div class="row row-sm justify-content-between">
                    <div class="w-100">
                        <label class="main-content-label mb-0">Chat</label>
                        <div class="d-flex justify-content-between lh-1 tx-12 mb-0">
                            <span class="text-muted text-overflow">Chat con el coordinador de la licitación</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                    <div class="col-md-4 pr-0 border-right">
                    <div class="row m-0 border-bottom">
                        <div class="col-sm-12 py-3">
                            <h6 class="color-tx m-0">{{chatMembers.length || 0}} Participantes</h6>
                        </div>
                    </div>
                    <div class="_scrollUsers">
                        <ng-container *ngFor="let item of chatMembers index as i; even as even">
                            <div class="row m-0 border-bottom" [class.even]="even">
                                <div class="col-sm-12 d-flex py-2 text-overflow">
                                    <div class="_avatar">
                                        <img [src]="(url+(item.image) | imagesLoad) || '../../../assets/img/placeholder-user.png'" alt=""></div>
                                    <div class="d-flex flex-column">
                                        <div class="text-overflow">{{item.name}}</div>
                                        <small class="text-muted">{{(item.role | status) || '...'}}</small>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div> 
               

                <div class="col-8 pl-0">
                    <chat [idChat]="chatId" 
                    [disableChat]="!(biddingDetail?.biddingStatus === 'STARTED' || request?.status === 'ACCEPTED')"></chat>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #tabRequest>
    <mk-loader *ngIf="loadingTabRequest" class="loader-spinner" style="z-index: 120"></mk-loader>
    <form [formGroup]="FormRequest">
        <div class="row">
            <div class="col-md-6">
                <div class="row">
                    <div class="col-sm-12">
                        <div class="card bg">
                            <div class="card-header py-2">
                                <div class="row row-sm justify-content-between">
                                    <div class="w-100">
                                        <label class="main-content-label mb-1">Estimación</label>

                                        <div class="d-flex justify-content-between lh-1 tx-12 mb-0">
                                            <span class="text-muted text-overflow">Propuesta de estimación para la
                                                licitación. <span class="text-info">(MXN)</span></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- TOTAL  -->
                            <div class="px-2 py-5 btn-color __texture text-white">
                                <h2 class="m-0 text-center font-weight-normal"><span class="op-7">Total </span> 
                                    {{amount?.value | currency}}</h2>
                            </div>
                            <small *ngIf="validatorForm.campoEsValido('amount',FormRequest)" class="text-danger">Campo
                                requerido!</small>
                            <input class="form-control text-right" formControlName="amount" disabled readonly hidden
                                type="text" data-type="currency" #amount />
                            <div class="p-2">
                                <div class="row">
                                    <div class="col-sm-6 col-md-7">
                                        <h6 class="mb-1">Subtotal<span class="text-danger">*</span></h6>
                                        <div class="input-group">
                                            <input class="form-control text-right" formControlName="subtotal"
                                                placeholder="0.00"
                                                onkeypress='return (event?.charCode >= 48 && event?.charCode <= 57) || (event?.charCode === 46 && !(event?.target?.value.includes(".")) ); '
                                                (keyup)="calculateAmount()"
                                                (change)="isNumeric($event); calculateAmount()" type="text"
                                                maxlength="15" />
                                            <!-- <div class="input-group-append">
                                        <div class="input-group-text lh--9 op-6 txt-currency"> MXN </div>
                                    </div> -->
                                        </div>

                                        <small *ngIf="validatorForm.campoEsValido('subtotal',FormRequest)"
                                            class="text-danger">*Campo requerido!</small>
                                    </div>
                                    <div class="col-sm-6 col-md-5">
                                        <h6 class="mb-1">IVA<span class="text-danger">*</span></h6>
                                        <div class="input-group">
                                            <select class="form-control text-right" placeholder="Iva"
                                                formControlName="iva" (change)="calculateAmount()">
                                                <ng-container *ngFor="let op of optionsIva">
                                                    <option [value]="op">{{op}}</option>
                                                </ng-container>
                                            </select>
                                            <div class="input-group-append">
                                                <div class="input-group-text"><i class="fa fa-percent lh--9 op-6"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <small *ngIf="validatorForm.campoEsValido('iva',FormRequest)"
                                            class="text-danger">*Campo requerido!</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row mt-3">
                    <div class="col-sm-12">
                        <div class="card bg">
                            <div class="card-header py-2">
                                <div class="row row-sm d-flex justify-content-between">
                                    <div class="">
                                        <label class="main-content-label mb-0">Periodo de trabajo
                                            <span class="text-danger">*</span>
                                        </label>
                                        <div class="d-flex justify-content-between lh-1 tx-12 mb-0">
                                            <span class="text-muted text-overflow">Inicio y fin del trabajo
                                                propuesto</span>
                                        </div>
                                    </div>
                                    <span class="text-muted my-auto">{{daysPeriod?.value}} días</span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="p-2">
                                        <div class="input-group date">
                                            <!-- <div class="input-group-prepend">
                                                <div class="input-group-text"><i class="fe fe-calendar lh--9 op-6"></i>
                                                </div>
                                            </div> -->
                                            <input [minDate]="minDate" type="text" ngxDaterangepickerMd
                                                [ngModelOptions]="{standalone: true}" [timePicker]="false"
                                                [locale]="dateOptions" [singleDatePicker]="false"
                                                [(ngModel)]="DatePeriod" (change)="setPeriodValue()" [autoApply]="true"
                                                [closeOnAutoApply]="false" name="date" class="form-control text-center tx-16" placeholder="DD/MM/YY - DD/MM/YY"
                                                autocomplete="off" readonly tabindex="-1" drops="down" opens="right" 
                                                [disabled]="!(createRequest || editRequest)"
                                                />
                                        </div>
                                        <input class="form-control text-right" formControlName="daysPeriod"
                                            placeholder="0" #daysPeriod readonly hidden type="text" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="row">
                    <div class="col-sm-12">

                <div class="card bg">
                    <div class="card-header py-2">
                        <div class="row row-sm justify-content-between">
                            <div class="w-100">
                                <label class="main-content-label mb-0">Comentario</label>
                                <div class="d-flex justify-content-between lh-1 tx-12 mb-0">
                                    <span class="text-muted text-overflow">Deja un comentario sobre la estimación</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12">
                            <textarea class="form-control border-0" formControlName="comment" maxlength="500" rows="5"
                                type="text" placeholder="Aa..." style="height: 328px;"></textarea>
                            <small *ngIf="validatorForm.campoEsValido('comment',FormRequest)" class="text-danger">Campo
                                requerido!</small>
                        </div>
                    </div>
                </div>

                    </div>
                </div>

                <!-- <div class="row">
                    <div class="col-sm-12">
                        <div class="card bg">
                            <div class="card-header py-2">
                                <div class="row row-sm d-flex justify-content-between">
                                    <div class="">
                                        <label class="main-content-label mb-0">Referencia</label>
                                        <div class="d-flex justify-content-between lh-1 tx-12 mb-0">
                                            <span class="text-muted text-overflow">Envia una referencia del producto propuesto</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="p-2">
                                <div class="input-group">
                                    <select name="" id="" class="form-control" style="max-width: 100px;">
                                        <option value="">PDF</option>
                                        <option value="">Imagen</option>
                                        <option value="">Enlace</option>
                                    </select>
                                    <input type="text" class="form-control" placeholder="" *ngIf="false">
                                    <input type="file" class="form-control" placeholder="" *ngIf="true">
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-sm-12">

                <div class="files">
                    <h4 class="mb-3">Archivos</h4>
                    <mk-loader *ngIf="loadingFiles" class="loader-spinner" style="z-index: 119"></mk-loader>
                    <div class="bg border px-3">
                        <div class="row">
                            <div class="col-12 py-3">
                                <div class="d-flex w-100 justify-content-between">
                                    <div>
                                        <h6 class="mb-0 tx-16">Agregar Archivos</h6>
                                        <span class="text-info lh-1">Máximo 3 archivos.</span>
                                    </div>
                                    <div class="file-browser" placement="top" ngbTooltip="Tamaño máximo 5 MB."
                                        tooltipClass="tooltip-danger">
                                        <label class="w-100 m-0">
                                            <span class="btn btn-block btn-dark"
                                                [ngClass]="(this.files.length >=3 || !(createRequest || editRequest)) ? 'disabled' : ''">
                                                <i class="fa fa-plus mr-1"></i> Agregar
                                                <input accept=".jpg, .jpeg, .png, .doc, .docx, .xls, .xlsx, .pdf"
                                                    (change)="addFile($event)" type="file" style="display: none;"
                                                    [disabled]="(this.files.length >=3 || !(createRequest || editRequest))">
                                            </span>
                                        </label>
                                    </div>



                                    <!-- <div placement="top" ngbTooltip="Tamaño máximo 5 MB." tooltipClass="tooltip-danger">
                                        <span class="btn btn-block btn-dark"
                                            [ngClass]="(this.files.length >=3 || !(createRequest || editRequest)) ? 'disabled' : ''">
                                            <i class="fa fa-plus mr-1"></i> Agregar
                                            <input accept=".jpg, .jpeg, .png, .doc, .docx, .xls, .xlsx, .pdf"
                                                (change)="addFile($event)" type="file" style="display: none;"
                                                [disabled]="(this.files.length >=3 || !(createRequest || editRequest))">
                                        </span>
                                    </div>  
                                
                                    <div class="file-browser" placement="top" ngbTooltip="Tamaño máximo 5 MB."
                                        tooltipClass="tooltip-danger">
                                        <label class="w-100 m-0">
                                            <span class="btn btn-block btn-dark"
                                                [ngClass]="(this.files.length >=3 || !(createRequest || editRequest)) ? 'disabled' : ''">
                                                <i class="fa fa-plus mr-1"></i> Agregar
                                                <input accept=".jpg, .jpeg, .png, .doc, .docx, .xls, .xlsx, .pdf"
                                                    (change)="addFile($event)" type="file" style="display: none;"
                                                    [disabled]="(this.files.length >=3 || !(createRequest || editRequest))">
                                            </span>
                                        </label>
                                    </div>
                                -->
                                </div>
                                <div class="d-flex flex-wrap" style="margin: 0 -10px;">
                                    <ng-container *ngFor="let file of files; index as i" class="row">
                                        <app-file-item [filesmanager]="true" [file]="file"
                                            (onSplice)='removeSelectedFile(i, "files")'
                                            [blocked]="!(createRequest || editRequest)"
                                            (click)="validDownload(file.name, file?.id)"></app-file-item>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>



        <div class="row justify-content-between mt-3"  *ngIf=" request.status == 'REJECTED_PROVIDER'">
                
            
                <div class="col-sm-12">
                    <div class="card bg">

            
                <div class="card-header py-2">
                    <div class="row row-sm justify-content-between">
                        <div class="w-100">
                            <label class="main-content-label mb-1 text-danger" >Rechazaste la Licitación</label>
                            <div class="d-flex justify-content-between lh-1 tx-12 mb-0">
                                <span class="text-muted text-overflow">Para reactivar tu participación debes contactarte con la empresa responsable.</span>
                            </div>
                        </div>
                    </div>
                </div>

                </div>
                
            </div>

        
            
        
    </div>
        
        <div class="row justify-content-between mt-3">
            <div class="col-sm-6 d-flex" *ngIf="request?._id">
                <span class="text-info my-auto">Última actualización: <b>{{ ((request?.update) ? request?.update?.date :
                        request?.create?.date) | moment:"ll - HH:mm A"}}</b></span>
            </div>
            <div class="col-sm-6">
                <ng-container *ngIf="(createRequest || editRequest)">
                    <button class="btn btn-success btn-lg btn-block"
                        (click)="Submit()">{{textBtnSubmit}}</button>
                </ng-container>

                <ng-container *ngIf="cancelRequest">
                    <button class="btn btn-danger btn-lg btn-block" (click)="confirmCancelRequest(1)">Cancelar
                        estimación </button>
                </ng-container>
            </div>
        </div>
    </form>




</ng-template>

<ng-template #tabBill>

    <div class="row">
        <div class="col-md-6">
            <div class="files">
                <h4>{{(userService?.modulesName?.bill | titlecase) || 'Factura'}}</h4>
           
                <span class="text-info">Fecha límite para subir {{userService?.modulesName?.bill || 'factura'}}: <b>{{ biddingDetail?.dateBill | moment:"ll - hh:mm A" }}</b></span>
                <mk-loader *ngIf="loadingFiles" class="loader-spinner" style="z-index: 119"></mk-loader>
                <div class="bg border px-3 mt-3">
                    <div class="row">
                        <div class="col-12 py-3">
                            <div class="d-flex w-100 justify-content-between">
                                <div>
                                    <h6 class="color-tx mb-0 tx-16">Archivos</h6>
                                    <span class="text-muted lh-1">Archivos adjuntos, Máximo 3</span>
                                </div>
                                <div class="file-browser" placement="top" ngbTooltip="Tamaño máximo 5 MB."
                                    tooltipClass="tooltip-danger">
                                    <label class="w-100 m-0">
                                        <span class="btn btn-block btn-dark"
                                            [ngClass]="(filesBill.length >=3 || !saveBill ) ? 'disabled' : ''">
                                            <i class="fa fa-plus mr-1"></i> Agregar
                                            <input accept=".jpg, .jpeg, .png, .doc, .docx, .xls, .xlsx, .pdf"
                                                (change)="addFile($event)" type="file" style="display: none;"
                                                [disabled]="(filesBill.length >=3 || !saveBill)">
                                        </span>
                                    </label>
                                </div>

                                <!-- <div placement="top" ngbTooltip="Tamaño máximo 5 MB." tooltipClass="tooltip-danger">
                                    <span class="btn btn-block btn-dark"
                                        [ngClass]="(filesBill.length >=3 || !saveBill ) ? 'disabled' : ''">
                                        <i class="fa fa-plus mr-1"></i> Agregar
                                        <input accept=".jpg, .jpeg, .png, .doc, .docx, .xls, .xlsx, .pdf"
                                            (change)="addFile($event)" type="file" style="display: none;"
                                            [disabled]="(filesBill.length >=3 || !saveBill)">
                                    </span>
                                </div> -->
                            </div>
                            
                            <div class="d-flex flex-wrap" style="margin: 0 0px;">
                                <ng-container *ngFor="let file of filesBill; index as i" class="row">
                                    <app-file-item 
                                    [filesmanager]="true" 
                                    [filesBill]="true" 
                                    [file]="file"
                                    (onSplice)='removeSelectedFile(i, "filesBill")' 
                                    [blocked]="!saveBill"
                                    (click)="validDownload(file.name, file?.id)"></app-file-item>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-6">

            <div class="card bg mb-4">
                <div class="card-header py-2">
                    <div class="row row-sm justify-content-between">
                        <div class="w-100">
                            <label class="main-content-label mb-0">Comentario</label>
                            <div class="d-flex justify-content-between lh-1 tx-12 mb-0">
                                <span class="text-muted text-overflow">Deja un comentario sobre la {{userService?.modulesName?.bill || 'factura'}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <textarea class="form-control border-0" [(ngModel)]="commentBill" maxlength="500" rows="5"
                            [disabled]="(!saveBill)" type="text" placeholder="Aa..."></textarea>
                        <small *ngIf="validatorForm.campoEsValido('comment',FormRequest)" class="text-danger">Campo
                            requerido!</small>
                    </div>
                </div>
            </div>


            <div class="row justify-content-between mt-4">
                <div class="col-12">
                    <ng-container *ngIf="saveBill">
 
                        <div class="d-flex justify-content-between lh-1 tx-12 mb-1">
                            <span class="text-muted text-overflow">*Se debe agregar al menos un archivo para guardar {{userService?.modulesName?.bill || 'factura'}}.</span>
                        </div>

                        <button class="btn btn-success btn-lg btn-block" 
                        [disabled]="filesBill.length === 0"
                        (click)="SaveBill()">Guardar {{(userService?.modulesName?.bill | titlecase) || 'Factura'}}</button>
                    </ng-container>
                </div>
                <div class="col-sm-12 pt-2" *ngIf="(dateBill !== '')">
                    <span class="text-info">Última actualización: <b>{{ request?.bill?.date | moment:"ll - hh:mm A"}}</b></span>
                </div>
            </div>
        </div>
    </div>
</ng-template>


<!-- TEMPLATES -->
<ng-template #noFiles>
    <div class="text-center text-muted" style="line-height: 120px;">Sin Archivos</div>
</ng-template>

<ng-template #modalFile let-modal>
    <app-file-modal [filesmanager]="true" (pushFile)="recivido($event)" (onClose)="modal.close()"
        [modalOption]="modalOption">
    </app-file-modal>
</ng-template>