import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'dnc-title',
  templateUrl: './dnc-title.component.html',
  styleUrls: ['./dnc-title.component.scss']
})
export class DncTitleComponent implements OnInit {

  @Input() check;
  @Input() editable : boolean = true;

  constructor(
    public sanitizer: DomSanitizer
    ) { }

  ngOnInit(): void {
  }

  public validateRequired(): boolean{
    return this.check?.target?.validators?.required || false;
  }
}
