import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DropdownService } from 'src/app/shared/services/dropdown.service';
import { HttpService } from 'src/app/shared/services/http.service';
import { DataBankService } from '../../services/data-bank.service';

@Component({
  selector: 'mkt-select',
  templateUrl: './mkt-select.component.html',
  styleUrls: ['./mkt-select.component.scss', '../../mk-table.comp.scss', '../../mk-table.comp.input.scss'],
  host:{
    "class" : 'mkt_col col-select',
    "(click)": '!readonly ? this.setFocus(true) : {}',
    "[class.has-change]": "_hasChange"
  }
})
export class MktSelectComponent implements OnInit {

  public focus = false;

  private _hasChange: boolean = false;
  @ViewChild('input', {static: false}) input: ElementRef;

  @Input() options: any;
  @Input() value: string;
  @Output() valueChange: EventEmitter<string> = new EventEmitter<string>();
  @Input() readonly: boolean;

  public componentOptions: any = [];
  public filtering: any = [];
  public byId: boolean = false;
  public decryp: boolean = false;
  
  public setFocus = (focus: boolean) => {this.focus = focus; if(!focus) this.dropdown.closeDialog();}

  constructor(
    private dropdown: DropdownService,
    private httpService: HttpService,
    private bankData: DataBankService
  ) { }

  ngOnInit(): void {
    this.componentOptions = this.bankData[this.options.collection];
    this.filtering = this.componentOptions;
    this.byId = this.options.byId || false;
    this.decryp = this.options.decryp || false;
  }

  //ngOnChanges(): void{this._hasChange = true; setTimeout(() => {this._hasChange = false}, 2000);}
  public emitValue(ev: any){this.valueChange.emit(this.byId? ev._id : ev.name)}

  async SelectValue(template: any, event: any){
    this.componentOptions = await this.bankData.dataLoad(this.options.collection);
    this.filtering = this.componentOptions;
    this.dropdown.openDialog(template, event, 60);
    this.dropdown.setHeight(42 + ((this.filtering.length * 32) > 300 ? 300 : (this.filtering.length * 32)));
  }

  public filter(filter: string):void{
    this.filtering = this.componentOptions?.filter(search => {return search?.name?.toLowerCase()?.includes(filter.toLowerCase())});
  }

}

interface IOption{name: string, id: string}