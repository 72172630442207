<div class="col-sm-12">
    <div class="row">
        <div class="col-md-6 border-right">
            <div class="row border-bottom py-3">
                <div class="col-md-12 d-flex justify-content-between">
                    <h4 class="text-secondary my-auto">Chats</h4>
                    <span class="text-muted my-auto"><i class="fa fa-comments mr-2"></i> {{chats?.length || 0}} chats</span>
                </div>
            </div>
            <div class="row">
                <div class="col-12 py-3 _scroll">
                    <ng-container *ngFor="let chatData of chats">
                        <chat-card class="mb-3" [chat]="chatData" (click)="selectChat(chatData); chatData.selected = true;" [selected]="chatData.selected"></chat-card>
                    </ng-container>
                </div>
            </div>
        </div>
        <div class="col-md-6 p-0">
            <div class="row border-bottom m-0 p-2" [class.op-5]="!chatSelected">
                <div class="col-md-12 d-flex p-0">
                    <div class="_avatar d-flex" [class.fill]="chatSelected">
                        <span *ngIf="!chatSelected?.image" class="main-img-user" >{{(chatSelected?.name)?(chatSelected?.name | initials):''}}</span>
                    </div>
                    <div class="d-flex flex-column">
                        <h5 class="my-auto">{{chatSelected?.name || "Selecciona Chat"}}</h5>
                        <span class="text-muted my-auto lh-1">Ultimo mensaje: <b>{{(chatSelected?.lastMessage?.create?.date)?(chatSelected?.lastMessage?.create?.date | moment:'hh:mm A'):'...'}}</b></span>
                    </div>
                </div>
            </div>
            <chat *ngIf="chatSelected" [idChat]="chatSelected?._id" [disableChat]="disableChat" (onReadMessage)="getUnreadMessage()"></chat>
        </div>
    </div>
</div>

<div class="modal-footer justify-content-between">
    <button type="button" class="btn btn-dark" (click)="close()">Cerrar</button>
</div>