import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'mk-user-monday',
  templateUrl: './mk-user-monday.component.html',
  styleUrls: ['./mk-user-monday.component.scss']
})
export class MkUserMondayComponent implements OnInit {

  @Input() name: string;
  @Input() tooltipDirection: string = 'top';
  @Input() color: string = 'primary';

  constructor() { }

  ngOnInit(): void {
  }

}
