import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BuiltFormDataService {

  constructor() { }

  buildFormData(formData, data, parentKey?) {
    if (data && (typeof data === 'object' && !parentKey) && !(data instanceof Date) && !(data instanceof File)) {
      Object.keys(data).forEach(key => {
        this.buildFormData(formData, data[key], parentKey ? `${parentKey}` : key);
      });
    } else {
      if (data) {
        if((typeof data === 'object' && !(data instanceof File)) && !(Array.isArray(data) && (data[0] instanceof File))){
          formData.append(parentKey, JSON.stringify(data));
        }else if(Array.isArray(data) && (data[0] instanceof File)){
          for (let i = 0; i < data.length; i++) {
            const element = data[i];
            if (element instanceof File) {
              this.buildFormData(formData, element, parentKey);
            }
          }
        }else{
          formData.append(parentKey, data);
        }
      }else{
        formData.append(parentKey, '');
      }
    }
  }

// -Y FORMATO A CAMPOS DINAMICOS POR TIPO DECLARADO EN dbSpecifications
  dynamicBuildFormData(formData, data, dataForm, parentKey?) {
    var value = null;

    if (parentKey) {
      for (let index = 0; index < dataForm.sections.length; index++) {
        const section = dataForm.sections[index];
        var field = section.fields.filter(f => { return f.formControlName === parentKey });
        if (field?.length) { break; }
      }

      field = field[0];
      var type = (field?.dbSpecifications?.type) ? field?.dbSpecifications?.type.toLowerCase() : "";
      
      if (type === "objectgroup" ||  type === "daterange") {
        value = (data == null) ? null : JSON.stringify(data);
      }

      else if (type === "arrayfilemanager" ) {
        
        data.forEach(fm => {
          formData.append(parentKey, fm.file)
        });
        
        value = (data == null) ? null : JSON.stringify(data);
      }

      else if ((type === "file" || type === "image" ) && (data && typeof data === 'object' && !(data instanceof File))) {
        value = data == null ? null : JSON.stringify(data);
      }
      
      else {
        value = data == null ? '' : data;
      }
    }

    else if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
      Object.keys(data).forEach(key => {
        this.dynamicBuildFormData(formData, data[key], dataForm, parentKey ? `${parentKey}` : key);
      });
    }
    
    else {
      value = data == null ? '' : data;
    }
    formData.append(parentKey, value);
  }

}
