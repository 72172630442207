import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dnc-images',
  templateUrl: './dnc-images.component.html',
  styleUrls: ['./dnc-images.component.scss', '../../dynForm.comp.scss'],
  host:{
    "[style.order]": "check.position",
    "[class]": "'col-sm-12 col-md-' + (check.col_size || 4)"
  }
})
export class DncImagesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
