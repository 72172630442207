import { Component, OnInit, Output, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'chat-card',
  templateUrl: './chat-card.component.html',
  styleUrls: ['./chat-card.component.scss'],
  host: {
    "class": "card m-0",
    "[class.selected]": "selected"
  }
})
export class ChatCardComponent implements OnInit {

  @Input() chat: any;
  @Input() selected: boolean = false;

  user: any;
  
  constructor() { }

  ngOnInit(): void {
    
  }
  
  ngAfterViewInit() {
    if (this.chat.user_id !== null && this.chat.user_id !== undefined) {
      var user = this.chat;
  
      var shortName = user.name.split(' ');
  
      if (shortName.length === 1) {
        shortName = shortName[0].substring(0, 2)
      }
  
      else {
        shortName = shortName[0].substring(0, 1) + shortName[1].substring(0, 1);
      }
  
      this.user = user;
      this.user.shortName = shortName;
    }
  }


  ngOnChanges(changes: SimpleChanges) {
    if (changes?.selected?.currentValue) {
      this.chat.countUnread = 0;
    }
  }
}
