import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ValueTypeService {

  private componentList = {
    "TEXT": 'STRING',
    "NUMBER": 'NUMBER',
    "USER": 'ANY',
    "STATUS": 'STRING',
    "STATUS-CHANGE": 'STRING',
    "LOG": '',
    "DATE": 'RANGEDATE',
    "RATING": 'NUMBER',
    "FILES": '',
    "IMAGES":'',
    "LINK": 'STRING',
    "TAG": 'STRING',
    "CHECK": 'BOOLEAN',
    "SELECT": 'OBJECTID',
    "BLOCKROW": 'BOOLEAN',
    "LONGTEXT": 'STRING',
    "DYNAMIC-SELECT": 'OBJECTID'
  }

  public getType(type: string): string{
    return this.componentList[type] || '';
  }

}
