import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { datePicker } from 'src/app/shared/mk-configs/datePicker/datePickerLocale';
import { AlertService } from 'src/app/shared/services/alert.service';
import { HttpService } from 'src/app/shared/services/http.service';
import { UserService } from 'src/app/shared/services/user.service';
import { convertTypeAcquisitionFromJson } from 'typescript';

@Component({
  selector: 'modal-extend-bid',
  templateUrl: './extend-bid.component.html',
  styleUrls: ['./extend-bid.component.scss']
})
export class ExtendBidComponent implements OnInit {
  @Input() id: string = null;
  @Output() onClose: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onUpdate: EventEmitter<boolean> = new EventEmitter<boolean>();

  close = () => {this.onClose.emit();}

    // !NOMBRE DE LICITACION
  public name = "licitacion cemento"
  public isLoading:boolean = false;
  public BidData = null;
  public participants = [];
  public extendForm: FormGroup
  public DateEndbid: any = {startDate: moment(), endDate: moment()}
  public DateBill: any = {startDate: moment(), endDate: moment()}
  public dateOptions: any = datePicker.locale.spanish;
  public today = moment();
  public todayBill = moment();
  
  constructor(private fb: FormBuilder, 
              public userService: UserService,     
              private httpService: HttpService,
              public alertService: AlertService
              ) { }

  async ngOnInit() {
    this.extendForm = this.fb.group({
      dateEnd          : [null, Validators.compose([Validators.required])],
      dateBill         : [null, Validators.compose([Validators.required])],
    });

   await this.detailBid()
  }

  public setDateBid(){
    this.extendForm.patchValue({
      dateEnd: moment(this.DateEndbid.endDate).format("YYYY-MM-DD"),
      dateStart: moment(this.DateEndbid.startDate).format("YYYY-MM-DD"),
    });

    this.DateBill = this.DateEndbid;
    this.todayBill = moment(this.DateEndbid.endDate).add(1,'d').startOf('day');
    
  }

  public async detailBid(){
    try {
      this.isLoading = true;
      
      await this.httpService.get(`api/bidding/${this.id}`).toPromise().then( (resp:any) => {
        this.BidData = resp
      });
    } catch (error) {
      console.log(error);
    }finally{
      this.isLoading = false;
    }
  }

  public setDateBill(){
    this.extendForm.patchValue({
      dateBill: moment(this.DateBill.startDate).format("YYYY-MM-DD HH:mm")
    });
  }

  public getParticipants(event){
    this.participants = event;
  }

  public async submitExtend(){
    let idparticipants = [];
    try {
      this.isLoading = true;

      this.participants.forEach(element => {
        idparticipants.push(element?._id)
      });

      let params = {
        id: this.BidData?._id,
        participants: idparticipants,
        dateEnd:  moment(this.extendForm.value.dateEnd).format("YYYY-MM-DD"),
        dateBill: moment(this.extendForm.value.dateBill).format("YYYY-MM-DD HH:mm")
      }
      
      await this.httpService.put(`api/bidding/reopen`, params).toPromise();
      this.alertService.successToast("Licitación Extendida");
      this.onUpdate.emit()
    } catch (error) {
      console.log(error);
    }finally{
      this.isLoading = false;
    }
  }

}
