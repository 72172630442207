import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { DyamicFormService } from 'src/app/shared/services/dynamic-form.service';

@Component({
  selector: 'dnc-button',
  templateUrl: './dnc-button.component.html',
  styleUrls: ['./dnc-button.component.scss', '../../dynForm.comp.scss'],
  host:{
    "[style.order]": "check.position",
    "[class]": "'col-sm-12 col-md-' + (check.col_size || 4)"
  }
})
export class DncButtonComponent implements OnInit {

  @Input() check: any;
  @Output() onClick: EventEmitter<any> = new EventEmitter<any>();
  @Input() editable : boolean = true;
  @Input() editForm: any;

  constructor(
    private dynamicForm: DyamicFormService

  ) { }

  ngOnChanges(change: SimpleChanges) {
    if (!change.editForm?.firstChange) { // Reasigna los valores dependiendo del elemento a renderizar

      if (this.check?.form == change.editForm?.currentValue?.name) {
        // this.DinamicForm = this.dynamicForm.ReMapEditForm(change.editForm.currentValue?.form, this.DinamicForm);
        if (change?.editForm?.currentValue?.edit) {
          this.editable = false;
        }
      }
      
    }
  }

  ngOnInit(): void {
  }

  public dynamicEmit(){
    this.onClick.emit(this.check.target);
  }

}
