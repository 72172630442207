import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DynamicControlsService } from 'src/app/shared/services/dynamic-controls.service';
import { DyamicFormService } from 'src/app/shared/services/dynamic-form.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'dnc-filesmanager',
  templateUrl: './dnc-filesmanager.component.html',
  styleUrls: ['./dnc-filesmanager.component.scss', '../../dynForm.comp.scss'],
  host:{
    "[style.order]": "check.position",
    "[class]": "'col-sm-12 col-md-' + (check.col_size || 12)"
  }
})
export class DncFilesmanagerComponent implements OnInit {

  @Input() check: any;
  @Input() editForm: any;
  @Input() editable : boolean = true;
  @Input() touched : boolean = false;
  @Output() onChange: EventEmitter<any> = new EventEmitter<any>();

  public detail;
  public loadingFiles: boolean = false;
  public blockDetail: boolean = false;
  public files = [];
  public DinamicForm: FormGroup
  public position;
  public apiUrl = environment.apiUrl;
  public modalOption = {type: "ADD", id: null}

  constructor(
    private modalService: NgbModal,
    private dynamicForm: DyamicFormService,
    private fb: FormBuilder,
    private dynamicControls: DynamicControlsService,
    public sanitizer: DomSanitizer
  ) { }

  ngOnChanges(change: SimpleChanges){
   if (!change?.editForm?.firstChange) { // Reasigna los valores dependiendo del elemento a renderizar
    if (this.check?.form == change?.editForm?.currentValue?.name) {
      
      this.DinamicForm = this.dynamicForm.ReMapEditForm(change?.editForm?.currentValue?.form, this.DinamicForm);
    
        this.files = this.DinamicForm.value[this.check?.formControlName];
      
      this.onChange.emit({event:this.DinamicForm, form: this.check?.form});   
    }
   }

    if (change.touched?.currentValue) {
      this.DinamicForm.markAllAsTouched();
    }
  }
  async ngOnInit() {

    this.DinamicForm = this.fb.group({
      [this.check.formControlName]:[[]],
    });

    this.DinamicForm = await this.dynamicControls.asignControls(this.check,  this.DinamicForm);
  }

  public openModal(modal, size: string = "md"):void {
    this.modalService.open(modal, { size: size });
  }

  public pushFile(event){

    event.index = this.DinamicForm.value[this.check?.formControlName]?.length + 1;
    
    this.DinamicForm.value[this.check?.formControlName]?.length ? this.DinamicForm.value[this.check?.formControlName].push(event) : this.DinamicForm.value[this.check?.formControlName] = [event];     
    this.onChange.emit({event:this.DinamicForm, form: this.check?.form});     
    
    this.files = this.DinamicForm.value[this.check?.formControlName];
    
  }

  removeSelectedFile(event){  

    this.files = this.files.filter(file => {return file?.file?.name != event});

    this.DinamicForm.value[this.check?.formControlName] = [];

    this.files.forEach(element => {
      this.DinamicForm.value[this.check?.formControlName].push(element);
     }); 

     this.onChange.emit({event:this.DinamicForm, form: this.check?.form});     
     
  }

  async download(file: string) {    
    window.open(`${this.apiUrl}${file}`, "_blank");
  }

  public validDownload(file, id){
    id ? this.download(file) : "";
  }
  
  public validateRequired(): boolean{
    let required: boolean = this.check?.target?.validators?.required || false;
    return required;
  }

}
