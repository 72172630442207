import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DropdownService } from 'src/app/shared/services/dropdown.service';
import { HttpService } from 'src/app/shared/services/http.service';
import { environment } from 'src/environments/environment';
import { DataBankService, ISelects } from '../../services/data-bank.service';

@Component({
  selector: 'mkt-user',
  templateUrl: './mkt-user.component.html',
  styleUrls: ['./mkt-user.component.scss', '../../mk-table.comp.scss', '../../mk-table.comp.input.scss'],
  host:{
    "class" : 'mkt_col col-user',
    "[class.has-change]": "_hasChange"
  }
})
export class MktUserComponent implements OnInit {

  @Input() options: any = {multiple: false};
  @Input() labelPosition: string = 'top';
  @Input() value: any;
  @Output() valueChange: EventEmitter<any[]> = new EventEmitter<any[]>();
  @Input() readonly: boolean;

  public _value: any[] = [];

  public usersList: any;
  public filtering: any = [];
  public apiUrl: string = environment.apiUrl;

  private _hasChange: boolean = false;
  constructor(
    private dropdown: DropdownService,
    private httpService: HttpService,
    private dataBank: DataBankService
  ) { }

  ngOnInit(): void {
    this.usersList = this.dataBank.data("support_users");
    this.filtering = this.usersList;
    this.parseValue();
  }

  //? Cambia el value a un arreglo estatico
  private parseValue(){this._value = (Array.isArray(this.value)) ? this.value : [this.value]}

  //? Cada que haya cambios actualiza el valor estatico 
  ngOnChanges(): void{this.parseValue()}
  //ngOnChanges(): void{this._hasChange = true; setTimeout(() => {this._hasChange = false}, 2000);}
  
  public emitValue(ev: any){    
    ev.action = this.options?.multiple?"PUSH":"SINGLE";
    if (ev.action == "PUSH") {
      let existe:boolean = (this._value.find(item => {return item?._id == ev?._id}))?true:false;
      if (!existe) { ev.value = this._value.concat(ev); };
    }
        
    this.valueChange.emit(ev);
    this.dropdown.closeDialog();
  }

  async selectUser(template: any, event: any){
    this.usersList = await this.dataBank.SupportUsersCatalog();
    this.filtering = this.usersList;
    this.dropdown.openDialog(template, event);
  }

  public filter(filter: string):void{
    this.filtering = this.usersList?.filter(search => {return search?.name?.toLowerCase()?.includes(filter.toLowerCase())});
  }

  isSelected(id): boolean{

    return this.options?.isSub?false:this._value.some(item => id === (item?._id || item?.name?._id) );
  }

  public deleteUser(ev: any):void{
    ev.action = "PULL";
    ev.value = this._value.filter(item => {return item?._id != ev?._id});
    
    this.valueChange.emit(ev);
  }
}

interface IUserMK{_id?: string, name?: string, img?: string}