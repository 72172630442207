import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'initials'
})
export class InitialsPipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): unknown {
    value = value.split('[')[0]
    return `${value.split(' ')?.[0]?.[0] || ''}${value.split(' ')?.[1]?.[0] || ''}`;
  }

}
