<form [formGroup]="DinamicForm" >
    <dnc-title [check]="check" [editable]="editable"></dnc-title>
    <div class="input-group mb-0">
        <ng-select class="form-control" #type [placeholder]="check.target.placeholder1" [clearable]="false" [(ngModel)]="monthSelected" [ngModelOptions]="{standalone: true}"
            (change)="monthSelect($event)"
            [readonly]="(!editable)" 
            [disabled]="(!editable)" 
            >
            <ng-option *ngFor="let item of check.target.options" [value]="item">{{item.label}}</ng-option>
        </ng-select>
        <ng-select class="form-control" 
        [markFirst]="true" #type [clearable]="false" [placeholder]="check.target.placeholder2" [(ngModel)]="daySelected" [ngModelOptions]="{standalone: true}"
        
        [readonly]="disableSelect ||  check?.target?.readonly || !editable"  
            [disabled]="disableSelect || check?.target?.readonly || !editable"  
            (change)="dynamicEmit()">
            <ng-option *ngFor="let item of days" [value]="item.value">{{item.label}}</ng-option>
        </ng-select>
    </div>
    <small class="text-danger" *ngIf="editable && !DinamicForm.valid && DinamicForm.touched">{{check?.validateMessage}}</small>    
</form>