import { TitleCasePipe } from '@angular/common';
import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { takeUntil, debounceTime } from 'rxjs/operators';
import { HttpService } from './http.service';
import { UserService } from './user.service';


//Menu Bar
export interface Menu {
  headTitle?: string;
  title?: string;
  path?: string;
  icon?: string;
  type?: string;
  badgeClass?: string;
  badgeValue?: number;
  active?: boolean;
  module?: string,
  children?: Menu[];
  section?:number
}


@Injectable({
  providedIn: 'root'
})
export class NavService implements OnDestroy {

  private unsubscriber: Subject<any> = new Subject();
  public screenWidth: BehaviorSubject<number> = new BehaviorSubject(window.innerWidth);

  public megaMenu: boolean = false;
  public megaMenuCollapse: boolean = window.innerWidth < 1199 ? true : false;
  public collapseSidebar: boolean = window.innerWidth < 991 ? true : false;
  private permissions: any;
  private dataBadget:any ;
  public changeBadget = new Subject();

  constructor(
    private router: Router,
    private users: UserService,
    private httpService: HttpService,
    private titlecase: TitleCasePipe,
  ) {

    this.setScreenWidth(window.innerWidth);
    fromEvent(window, 'resize').pipe(
      debounceTime(1000),
      takeUntil(this.unsubscriber)
    ).subscribe((evt: any) => {
      this.setScreenWidth(evt.target.innerWidth);
      if (evt.target.innerwidth < 991) {
        this.collapseSidebar = false;
        this.megaMenu = false;
      }

      if (evt.target.innerWidth < 1199) {
        this.megaMenuCollapse = true;
      }
    });
    if (window.innerWidth < 991) {
      this.router.events.subscribe(event => {
        this.collapseSidebar = false;
        this.megaMenu = false;
      })
    }

    
  }

  private setScreenWidth(width: number): void {
    this.screenWidth.next(width);
  }

  ngOnDestroy() {
    this.unsubscriber.next();
    this.unsubscriber.complete();
  }

  getModules(){

    let departments = [];
    if (this.users.user.roleName == "ADMIN") {
      this.httpService.get(`api/department/list/select`).toPromise().then((resp: any) => {

        resp.sort(function (a, b){
          const nameA = a.name.toUpperCase();
          const nameB = b.name.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });

        for (const department of resp || []) {
          departments.push({path: `/home/${department._id}`, title: department.name, type: 'link', module: "home"})
        }
      });
    }else{

      this.users?.user?.department.sort(function (a, b){
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });

      for (const department of this.users?.user?.department || []) {
        departments.push({path: `/home/${department._id}`, title: department.name, type: 'link', module: "home"})
      }
    }
    
    
    let MENUITEMS : Menu[] = [
      //TOS
      { headTitle: 'Secciones', section:0},
      { path: '/tickets', title: "Supervisión", type: 'link', icon: 'fa fa-code-fork', active: false, module: "tickets", section:0 , badgeValue: 0},
      { title: 'Áreas', type: 'sub', icon: 'mdi mdi-apps', active: false, section:0, module: "home", children: departments, badgeValue: 0 },
      { path: '/ticketHistory', title: 'Historial de ' + this.titlecase.transform(this.users?.modulesName?.tickets || 'tickets'), type: 'link', icon: 'fa fa-history', active: false, module: "ticketHistory", section:0 , badgeValue: 0},
      { path: '/quoteHistory', title: 'Historial Cotizaciones', type: 'link', icon: 'zmdi zmdi-assignment', active: false, module: "quoteHistory", section:0, badgeValue: 0 },
      // { path: '/finances', title: 'Finanzas', type: 'link', icon: 'zmdi zmdi-money', active: false, module: "finances", section:0, badgeValue: 0 },
      { path: '/binnacle', title: 'Bitácora', type: 'link', icon: 'mdi mdi-clock', active: false, module: "binnacle", section:0 , badgeValue: 0},
      { path: '/bids', title: 'Licitaciones', type: 'link', icon: 'mdi mdi-tag', active: false, module: "bids", section:0, badgeValue: 0 },
      { path: '/memberships', title: 'Membresías', type: 'link', icon: 'fa fa-address-card', active: false, module: "memberships", section:0, badgeValue: 0 },
      { path: '/finances', title: 'Finanzas', type: 'link', icon: 'fa fa-money', active: false, module: "finances", section:0, badgeValue: 0 },

      { headTitle: 'Catálogos', section:1},
      // { path: '/sla', title: 'SLA', type: 'link', icon: 'fa fa-list-ol', active: false, module: "sla", section:1 },
      { path: '/contracts', title: this.titlecase.transform((this.users?.modulesName?.contracts || "Contratos")), type: 'link', icon: 'fa fa-file-text', active: false, module: "contracts", section:1 },
      // { path: '/contracts-temp', title: this.titlecase.transform((this.users?.modulesName?.contracts || "Contratos")), type: 'link', icon: 'fa fa-file-text', active: false, module: "contracts-temp", section:1},
      { path: '/clients', title: this.titlecase.transform((this.users?.modulesName?.clients || "Clientes")), type: 'link', icon: 'fa fa-users', active: false, module: "clients", section:1, badgeValue: 0  },
      { path: '/contractors', title: this.titlecase.transform((this.users?.modulesName?.contractors || "Contratistas")), type: 'link', icon: 'fa fa-handshake', active: false, module: "contractors", section:1, badgeValue: 0  },
      { path: '/providers', title: this.titlecase.transform((this.users?.modulesName?.providers || "Proveedores")), type: 'link', icon: 'fa fa-user-tie', active: false, module: "providers", section:1, badgeValue: 0  },
      { path: '/properties', title: this.titlecase.transform((this.users?.modulesName?.properties || "Unidades")), type: 'link', icon: 'fa fa-building', active: false, module: "properties", section:1, badgeValue: 0  },
      { headTitle: 'Inventario', section:2, module: 'inventory'},
      { title: 'Inventario', type: 'sub', icon: 'fa fa-cube', active: false, section:2, module: "inventory", children: [{path: `/inventory`, title: "Inventario", type: 'link', module: "inventory"},{path: `/inventory/assignments`, title: "Asignaciones", type: 'link', module: "inventory"},{path: `/inventory/articles`, title: "Artículos", type: 'link', module: "inventory"},{path: `/inventory/locations`, title: "Ubicaciones", type: 'link', module: "inventory"}, {path: `/inventory/categories`, title: "Categorías", type: 'link', module: "inventory"},  {path: `/inventory/responsible`, title: "Responsables", type: 'link', module: "inventory"}], badgeValue: 0 }, 
      { headTitle: 'Usuarios', section:3},
      { path: '/departments', title: 'Registro de Áreas', type: 'link', icon: 'fa fa-users', active: false, module: "departments", section:3, badgeValue: 0 },
      { path: '/users', title: 'Usuarios', type: 'link', icon: 'fa fa-user', active: false, module: "users", section:3 , badgeValue: 0},
      { path: '/roles', title: 'Roles', type: 'link', icon: 'fa fa-id-card', active: false, module: "roles", section:3, badgeValue: 0 },
      { path: '/reports', title: 'Reportes', type: 'link', icon: 'zmdi zmdi-assignment', active: false, module: "reports", section:0, badgeValue: 0 },

      { headTitle: 'Cuenta', section:4},
      { path: '/supports', title: 'Soporte', type: 'link', icon: 'fa fa-question-circle', active: false, module: "supports", section:3 , badgeValue: 0},

    ]
    
    this.permissions = this.users.user.permissions;
  
      // this.permissions.push({module: "bids", access: true})
      //? Se valida bandera para poder visualizar modulo de Membresias.
      !this.users?.membershipModule ? MENUITEMS = MENUITEMS.filter(menu => menu?.module != 'memberships'): "";
      !this.users?.unitsModule ? MENUITEMS = MENUITEMS.filter(menu => menu?.module != 'properties'): "";
      !this.users?.financesModule ? MENUITEMS = MENUITEMS.filter(menu => menu?.module != 'finances'): "";

      if(!this.users?.inventoryModule){
        MENUITEMS = MENUITEMS.filter(menu => menu?.module != 'inventory' && menu?.module != 'reports')
      };
    
    if (this.users.user.roleName != "ADMIN") {
      //Elimina los modulos que se tiene permisos
      MENUITEMS = MENUITEMS.filter(menu => !menu.module || this.permissions?.find(permission => { return permission?.module == menu.module })?.access);
     
      const index = MENUITEMS.findIndex(menu => menu.module);
      MENUITEMS[index].active = true;
      
      //Se descarta el apartado de Areas si es que no hay ni un departamento al cual se tenga permiso
      if (departments.length == 0) {
        MENUITEMS = MENUITEMS.filter(modules => { return modules?.module != "home" });
      }

      //Elimina los titulos que no cuentan con modulo
      let hist = {};
      MENUITEMS.map(function (a) { if (a.section in hist) hist[a.section]++; else hist[a.section] = 1; });
      MENUITEMS = MENUITEMS.filter(menu => hist[menu.section] > 1);


    }

    //Ocultar modulo licitaciones
    if (this.users.user.roleName !== "PROVIDER" && this.users.user.roleName !== "CONTRACTOR") {
      MENUITEMS = MENUITEMS.filter(menu => { return menu.path !== "/bids" });
    }

    return new BehaviorSubject<Menu[]>(MENUITEMS);
  }

  public async requestProfile(){
    this.dataBadget = await this.httpService.get(`api/requestProfile/count`).toPromise();
    this.changeBadget.next(this.dataBadget);
  }
  
}

