import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { log } from 'console';
import { join } from 'path';
import { AlertService } from 'src/app/shared/services/alert.service';
import { HttpService } from 'src/app/shared/services/http.service';
import Swal from 'sweetalert2'

@Component({
  selector: 'mkt-import-btn',
  templateUrl: './mkt-import-btn.component.html',
  styleUrls: ['./mkt-import-btn.component.scss'],
  host:{ "class" : 'mkt_col col-import-btn'}
})
export class MktImportBtnComponent implements OnInit {

  @Input() options: any;
  @Input() value: string;
  @Output() valueChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() valueSelectChange: EventEmitter<any> = new EventEmitter<any>();
  @Input() readonly: boolean;
  @Output() isLoading: EventEmitter<any> = new EventEmitter<any>();
  @Output() updateCount: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    public alertService: AlertService,
    private httpService: HttpService
  ) { }

  ngOnInit(): void {
  }

  public async setFileSubitems(event) {
    try {
      const selectedFile = event.target.files[0];      
      const formData = new FormData();
              
       formData.append('file', selectedFile);
       formData.append('ticket', this.value);
       
    if (selectedFile) {
      this.isLoading.emit(true);//Activa Loading en pantalla principal
      await this.httpService.put("api/ticket/importSubitems", formData).toPromise().then((resp: any) => {
        this.alertService.successToast(resp?.message);
        this.isLoading.emit(false);//Desactiva Loading en pantalla principal
        this.updateCount.emit({added:resp?.savedSubitems, ticket:this.value}); // Actualiza contador de subelementos
        event.target.value = null;// Limpia el input        
      })
      .catch((err: any) => {
        if (err.status != 403) {
          console.log("err", err);
          
          var details = "";
          err?.error?.arrayErrors.forEach(element => {details +=`${element}\n ` });
          
          Swal.fire({
            title: "<b>Estructura de Archivo Incorrecta</b>",
            icon: "error",
            html: `<label style="white-space: pre; text-align:start;">${details}<label>`,
            focusConfirm: true,
            confirmButtonText: `Confirmar`
          });

          this.isLoading.emit(false);//Desactiva Loading en pantalla principal
        }
      }); 
    }
      
    } catch (error) {
      console.log(error);      
      this.isLoading.emit(false);//Desactiva Loading en pantalla principal
    }finally{
      this.isLoading.emit(false);//Desactiva Loading en pantalla principal
    }
  }

}
