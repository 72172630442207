<!-- <div class="modal-header">
    <h6 class="modal-title" id="modal-basic-title">Cotizacion</h6>
     <button type="button" class="close" aria-label="Close" (click)="close()">
        <span aria-hidden="true">&times;</span>
    </button>
</div> -->
<div class="modal-body p-0"> 
    <form [formGroup]="extendForm">
    <div class="row border-bottom p-3 _title">
        <div class="col-6 d-flex">
            <div class="my-auto">
                <h3 class="text-info m-0"><i class="mdi mdi-calendar-clock"></i> Extender <i>{{BidData?.title}}</i></h3>
                <span class="text-muted">Administrar nuevas fechas y participantes de licitación</span>
            </div>
        </div>
        <div class="col-md-3 d-flex flex-column justify-content-center">
            <h6>Nuevo fin de licitación<span class="text-danger">*</span></h6>
            <div class="input-group date">
                <div class="input-group-prepend"><div class="input-group-text"><i class="fe fe-calendar lh--9 op-6"></i></div></div>
                <!-- <input formControlName="dateEnd" type="number" class="form-control" placeholder="Selecciona fecha de inicio y fin"> -->
                <input type="text" 
                ngxDaterangepickerMd 
                [ngModelOptions]="{standalone: true}"
                [singleDatePicker]="true"
                [timePicker]="false" 
                [timePickerIncrement]="5" 
                [timePicker24Hour]="true" 
                [locale]="dateOptions" 
                [(ngModel)]="DateEndbid" 
                [autoApply]="true"
                (change)="setDateBid()" 
                [minDate]="today" 
                [closeOnAutoApply]="false"  name="date" class="form-control" placeholder="DD/MM/YY - DD/MM/YY" autocomplete="off" readonly  tabindex="-1"/>
            </div>
        </div>
        <div class="col-md-3 d-flex flex-column justify-content-center">
            <h6>Nueva Fecha límite {{userService?.modulesName?.bill || 'factura'}} <span class="text-danger">*</span></h6>
            <div class="input-group date">
                <div class="input-group-prepend"><div class="input-group-text"><i class="fe fe-calendar lh--9 op-6"></i></div></div>
                <!-- <input formControlName="dateEnd" type="number" class="form-control" placeholder="Selecciona fecha de inicio y fin"> -->
                <input type="text"
                ngxDaterangepickerMd 
                [ngModelOptions]="{standalone: true}"
                [singleDatePicker]="true"
                [timePicker]="true" 
                [timePickerIncrement]="5" 
                [timePicker24Hour]="true" 
                [locale]="dateOptions"
                [(ngModel)]="DateBill" 
                [autoApply]="true"
                (change)="setDateBill()" 
                [minDate]="todayBill" 
                [closeOnAutoApply]="false"  name="date" class="form-control" placeholder="DD/MM/YY - DD/MM/YY" autocomplete="off" readonly  tabindex="-1"/>
            </div>
        </div>
    </div>
    </form>
    <tab-participants [tabIndex]="0" [id]="id" (participants)="getParticipants($event)" [infoTab]="false"></tab-participants>
</div>
<div class="modal-footer justify-content-between">
    <button type="button" class="btn btn-danger" (click)="close()">Cancelar</button>
    <button type="button" class="btn btn-success" [swal]="extendedBidSwal"  (confirm)="submitExtend()">Guardar y enviar <i class="mdi mdi-send ml-1"></i></button>
</div>

<!-- SWALS -->
<swal #extendedBidSwal class="d-none" title="¿Estas seguro de extender las fechas de esta licitación?" icon="question" [showCancelButton]="true" [focusCancel]="true" [swalOptions]="{confirmButtonText: 'Aceptar', cancelButtonText: 'Cancelar', confirmButtonColor: '#fd3666'}">
</swal>