import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { DynamicControlsService } from 'src/app/shared/services/dynamic-controls.service';
import { DyamicFormService } from 'src/app/shared/services/dynamic-form.service';

@Component({
  selector: 'dnc-radio',
  templateUrl: './dnc-radio.component.html',
  styleUrls: ['./dnc-radio.component.scss', '../../dynForm.comp.scss'],
  host:{
    "[style.order]": "check.position",
    "[class]": "'col-sm-12 col-md-' + (check.col_size || 4)"
  }
})
export class DncRadioComponent implements OnInit {

  constructor(

    private fb: FormBuilder,
    public dynamicControls: DynamicControlsService,
    private dynamicForm: DyamicFormService,
    public sanitizer: DomSanitizer

  ) { }

  @Input() check: any;
  @Input() editForm: any;
  @Input() editable : boolean = true;

  @Output() onChange: EventEmitter<any> = new EventEmitter<any>();

  public DinamicForm: FormGroup

  public target: [];

  ngOnChanges(change: SimpleChanges){
    if (!change.editForm.firstChange) { // Reasigna los valores dependiendo del elemento a renderizar
        this.DinamicForm = this.dynamicForm.ReMapEditForm(change.editForm.currentValue, this.DinamicForm);
    }
  }

  async ngOnInit() {

    this.DinamicForm = this.fb.group({
      [this.check.formControlName]:[this.check.target.options[0].value]
    });

    this.DinamicForm = await this.dynamicControls.asignControls(this.check,  this.DinamicForm);

    //Establece los valores de inicio del formulario
    this.dynamicEmit();

  }

  dynamicEmit(){
      this.onChange.emit(this.DinamicForm);    
  }
  
  public validateRequired(): boolean{
    let required: boolean = this.check?.target?.validators?.required || false;
    return required;
  }

}
