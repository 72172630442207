import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'mkt-log',
  templateUrl: './mkt-log.component.html',
  styleUrls: ['./mkt-log.component.scss', '../../mk-table.comp.scss', '../../mk-table.comp.input.scss'],
  host:{
    "class" : 'mkt_col col-log',
    "[class.has-change]": "_hasChange"
  }
})
export class MktLogComponent implements OnInit {

  @Input() value: any;
  @Output() valueChange: EventEmitter<any> = new EventEmitter<any>();
 
  private _hasChange: boolean = false;
  constructor() { }

  ngOnInit(): void {}

  //ngOnChanges(): void{this._hasChange = true; setTimeout(() => {this._hasChange = false}, 2000);}
}
