import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpService } from 'src/app/shared/services/http.service';
import { UserService } from 'src/app/shared/services/user.service';

@Component({
  selector: 'app-modal-bid',
  templateUrl: './modal-bid.component.html',
  styleUrls: ['./modal-bid.component.scss']
})
export class ModalBidComponent implements OnInit {

  public tabIndex: number = 0;
  public bidData: any;
  public extendedFlag = false;
  @Input() id: string = null;
  @Input() status: any = null;
  @Input() movementId: string = null;
  @Input() goTo: number = 0;

  @Output() onClose: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onSubmit: EventEmitter<any> = new EventEmitter<any>();
  
  constructor(
    private modalService: NgbModal,
    private httpServices: HttpService,
    public userService: UserService
    ) { }

  ngOnInit() {
    if (this.id) {
      this.loadUnreadMessage();
    }
    this.tabIndex = this.goTo;     
  }

  close(){this.onClose.emit()}

  openModal(template: any, size: string = 'lg'){
    this.modalService.open(template, {size: size})
  }

  public updatestatus(event){        
      this.status = event?.status;
      this.extendedFlag = event?.extended;
  }

  private async loadUnreadMessage(){
    try {      
      this.bidData = await this.httpServices.get(`api/bidding/${this.id}`).toPromise();  
      this.extendedFlag = this.bidData?.extended;
          
    } catch (error) {
      console.log(error); 
    }
  }

  public submitBid(bidData: any){    
    this.onSubmit.emit(bidData);
    this.id = bidData?._id;
    this.status = bidData?.status;
  }

  
}
