import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import {  } from 'hammerjs';

@Component({
  selector: 'mk-img-group',
  templateUrl: './mk-img-group.component.html',
  styleUrls: ['./mk-img-group.component.scss'],
  host:{
    'class': "d-inline-flex justify-content-center"
  }
})
export class MkImgGroupComponent implements OnInit {

  @Input('src') srcArray: any[];
  @Input() length: number = null;
  @Input() sanitize: boolean = true;

  constructor(private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
  }

  getImgWidth(imgCount: number): SafeStyle{ return this.sanitizer.bypassSecurityTrustStyle(`calc(calc(100% / ${imgCount}) - 2px)`) }

}
