import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DecryptPipe } from 'src/app/shared/pipes/decrypt.pipe';
import { DropdownService } from 'src/app/shared/services/dropdown.service';
import { DataBankService } from '../../services/data-bank.service';
import { CryptoSharkService } from 'src/app/shared/services/crypto-shark.service';
import { UserService } from 'src/app/shared/services/user.service';

@Component({
  selector: 'mkt-dynamic-select',
  templateUrl: './mkt-dynamic-select.component.html',
  styleUrls: ['./mkt-dynamic-select.component.scss', '../../mk-table.comp.scss', '../../mk-table.comp.input.scss'],
  host:{
    "class" : 'mkt_col col-dynamic-select',
    "(click)": '!readonly ? this.setFocus(true) : {}',
    "[class.has-change]": "_hasChange"
  }
})
export class MktDynamicSelectComponent implements OnInit {

  public focus = false;

  private _hasChange: boolean = false;
  @ViewChild('input', {static: false}) input: ElementRef;

  @Input() options: any;
  @Input() value: string;
  @Input() valueSelect: string;
  @Output() valueChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() valueSelectChange: EventEmitter<any> = new EventEmitter<any>();
  @Input() readonly: boolean;

  public componentOptions: any = [];
  public filtering: any = [];
  public disable: boolean = true;
  public collection: string;
  public dinamicOptions: boolean = false;
  public filteringValue: any = [];

  constructor(
    private dropdown: DropdownService,
    private bankData: DataBankService,
    private decrypt: DecryptPipe,
    private crypto: CryptoSharkService,
    private userServices: UserService
  ) { }

  ngOnInit(): void {
    this.collection = this.options?.options?.find(option => {return option?.name == this.value})?.collection || (this.options?.secondOptions?.collection || '');
    this.dinamicOptions = this.options.dinamicOptions || false;
    this.disable = this.value?false:true;
    if (this.dinamicOptions) {
      this.options.options = this.bankData["_"+this.options?.collection];
    }else{
      this.componentOptions = this.bankData["_"+this.collection];
    }
    
    this.filtering = this.componentOptions;
  }

  public emitValue(ev: any){
    this.collection = ev?.collection || "";
    this.disable = false;
    this.valueChange.emit(this.dinamicOptions? ev._id : ev.name);
    this.valueSelectChange.emit({value: null, target: this.options.secondTarget});
  }

  public emitValueSelect(ev: any){
    this.valueSelectChange.emit({value: ev, target: this.options.secondTarget});
  }

  public setFocus = (focus: boolean) => {this.focus = focus; if(!focus) this.dropdown.closeDialog();}

  public statusColor = (name: string)=>{return this.options?.options?.find(op => op.name == name)?.color || '#797e93'}

  SelectStatus(template: any, event: any){
    if (this.dinamicOptions) {
      this.options.options = this.bankData["_"+this.options?.collection];
      if (!this.options.options?.find(item => {return item._id == null})) {
        this.options?.options?.unshift({_id: null, name: this.crypto?.encryptAes("N/A", this.userServices.login.company.dk)});
      }
    }
    this.filteringValue = this.options.options;
    this.dropdown.openDialog(template, event, 60);
    
    this.dropdown.setHeight(42 + ((this.filteringValue.length * 40) > 300 ? 300 : (this.filteringValue.length * 40)));
  }

  async SelectValue(template: any, event: any){
    this.collection = this.options?.options?.find(option => {return option?.name == this.value})?.collection || (this.options?.secondOptions?.collection || '');
    if (this.dinamicOptions) {
      this.componentOptions = this.value ? await this.bankData.dataLoadById(this.collection, this.options?.secondOptions?.index, this.value || null) : [];
      this.componentOptions = this.componentOptions.filter(item => {return item.status != 'INACTIVE'});
    }else{
      this.componentOptions = await this.bankData.dataLoad(this.collection);
    }

    if (!this.componentOptions?.find(item => {return item._id == null})) {
      this.componentOptions?.unshift({_id: null, name: "N/A"});
    }
    this.filtering = this.componentOptions;
    this.dropdown.openDialog(template, event, 60);
    
    this.dropdown.setHeight(42 + ((this.filtering.length * 32) > 300 ? 300 : (this.filtering.length * 32)));
  }

  public filter(filter: string):void{
    this.filtering = this.componentOptions?.filter(search => {return search?.name?.toLowerCase()?.includes(filter.toLowerCase()) || this.decrypt.transform(search?.name)?.toLowerCase()?.includes(filter.toLowerCase())});
  }

  public filterValue(filter: string):void{
    this.filteringValue = this.options.options?.filter(search => {return search?.name?.toLowerCase()?.includes(filter.toLowerCase()) || this.decrypt.transform(search?.name)?.toLowerCase()?.includes(filter.toLowerCase())});
  }

}