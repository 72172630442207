import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { ValidateFormsDirective } from 'src/app/directives/validate-forms.directive';
import { AlertService } from 'src/app/shared/services/alert.service';
import { DyamicFormService } from 'src/app/shared/services/dynamic-form.service';
import { HttpService } from 'src/app/shared/services/http.service';
import { ValidatorsService } from 'src/app/shared/services/validators.service';
import { datePicker } from 'src/app/shared/mk-configs/datePicker/datePickerLocale';
import { WizardComponent } from 'angular-archwizard';
import Swal from 'sweetalert2/dist/sweetalert2.js'

import { BuiltFormDataService } from 'src/app/shared/services/built-form-data.service';
import { UserService } from 'src/app/shared/services/user.service';
@Component({
  selector: 'app-contract-rentroll-modal',
  templateUrl: './contract-rentroll-modal.component.html',
  styleUrls: ['./contract-rentroll-modal.component.scss']
})
export class ContractRentrollModalComponent implements OnInit {


@Output() onClose: EventEmitter<any> = new EventEmitter<any>();
@Output() onSave: EventEmitter<any> = new EventEmitter<any>();
@ViewChild(WizardComponent) public wizard: WizardComponent;
@Input() modalOptions: any = {
  type : "EDIT"
};
@Input() idContract: any = null;


public isLoading = true;
public Options: [] = [];
public dataInput;
public editForm;
public filterData: object = [];
public fileConstitutiveAct: any;
public timeZone: any = moment().tz(moment.tz.guess()).format('z');
public dateOptions: any = datePicker.locale.spanish;
public dateRanges: any = datePicker.customRange.spanish;
public nextstep = false;
public layoutSelected;
public dfSelected: boolean = false;
// public FormContract: FormGroup;
public tmpFormContract: FormGroup;
private dataContract = null;
public idContractType = "";
public FormNameContract = "ConfidentialityAgreementForm";
public dinamicFormGroups = [];
public DataFormContracts;
public contract = null; 
public filterDate = { startDate: moment() };
public tmpArrayFields = [];
private tmpDynamicForm = null;
public contractCode: string = null;
// public FormName = "FormRentroll";
public DataForm;



  constructor(
    public validateForm: ValidateFormsDirective,
    private httpService: HttpService,
    private alertService: AlertService,
    public validatorService: ValidatorsService,
    public dynamicForm: DyamicFormService,
    public fieldisValid: ValidateFormsDirective,
    private fb: FormBuilder,
    private builtFormDataService : BuiltFormDataService,
    public userService: UserService
  ) 
  {}

  async ngOnInit() { 
    this.DataForm = await this.httpService.get(`api/contract/detail/${this.idContract}`).toPromise();
    this.idContractType = this.DataForm?.DataForm?.idContractType; 
    this.modalOptions.type = "EDIT";
    await this.getCustomers();    
    await this.detailContract(); 

  }


  private getCustomers(): void {
    this.httpService.get('api/customer').subscribe(resp => { this.filterData = resp });
  }

  public async detailContract() {
    if (this.modalOptions.type == "EDIT") {
      const _id = this.idContract;
      let Form = null;
      try {

        this.DataForm = await this.httpService.get(`api/dynamicForms/byFormName/${this.FormNameContract}`).toPromise();
        // await this.createFormGroups(this.DataForm);//? Crea los formularios necesarios 
        await this.dynamicForm.createFormGroups(this.DataForm);            
        this.idContractType = this.DataForm._id; 
        this.contract = await this.httpService.get(`api/contract/detail/${_id}`).toPromise();     
        
        if (this.DataForm?.formperSeccion) {
          for (const iterator of this.DataForm?.sections) {
            if (iterator?.primaryKey) {            
               Form = await this.httpService.get(`${iterator?.apiUrl}/${ this.contract[iterator?.primaryKey]}`).toPromise();                          
            }
  
            //? Asigna Datos de edicion al Formulario ////
            setTimeout(() => {
  
              //asigna los datos de edicion
                let formName = iterator?.formName + "Form";
                let name = iterator?.formName;
          
                if (iterator?.master) {
                  for (const value in window[formName]?.controls) {
                    if (value == "code") {
                      this.contractCode = this.contract[value]?.toUpperCase();
                    }

                    window[formName].patchValue({
                      [value]: Form[value]
                    });
                  }
                }else{
                  window[formName].patchValue({
                    [iterator?.primaryKey]:  this.contract[iterator?.primaryKey]
                  });
                }
  
                this.editForm = {form:window[iterator?.formName + "Form"], name:iterator?.formName + "Form", edit: true};
                this.dfSelected = true;
                
                setTimeout(() => {
                  this.isLoading = false;
                }, 3000);
           
            }, 250);
          }
        }else{
          for (const iterator of this.DataForm?.sections) {
            //? Asigna Datos de edicion al Formulario ////
            setTimeout(() => {
  
              //asigna los datos de edicion
                let formName = iterator?.formName + "Form";
        
                  for (const value in window[formName]?.controls) {
                    if(value == 'code'){
                      this.contractCode = this.contract[value]?.toUpperCase();

                      window[formName].patchValue({
                        [value]: this.contract[value]?.toUpperCase()
                      });
                    }else{
                      window[formName].patchValue({
                        [value]:  this.contract[value]
                      });
                    }
                  }                
                this.editForm = {form:window[formName], name:formName, edit: true};
                this.dfSelected = true;
  
            }, 500);
          }
        }

      } catch (error) {
        console.log(error);
      } finally {
        setTimeout(() => {
          this.isLoading = false;
        }, 5000);
      }
    }
  }


  public async FormChange(event: any) {    
    let formName = event.form + "";    
    let dataform: FormGroup;

    dataform = this.fb.group({
      address: [null],
    });
    
    if (event?.type == "MAP") {
      dataform.patchValue({
        address: event?.event.value
      });
    }else{
      dataform = event?.event;
    }

    if (window[formName]) {
      window[formName] = this.dynamicForm.MapForm(dataform, window[formName]);        
    }
     
  }


  public FormChildrensChange(childrens: any) {
    try {
      
    let formName = childrens?.form + "";

    for (const chil of childrens?.event) {
      window[formName].patchValue({ [chil?.formControlName]: chil?.value })      
    }

    
    setTimeout(() => {
      this.editForm = {form:window[formName], name:childrens?.form};      
      
    }, 300);
    } catch (error) {
      console.log(error); 
    }finally{
      
    }
    
  }


  public async layoutchange(event) {
    if (event) {
      this.dfSelected = true
      try {

        //Se obtiene el formulario dinamico
        const Form = await this.httpService.get(`api/dynamicForms/${event}`).toPromise();
        this.DataForm = Form; 
        
        if (this.modalOptions.type == "ADD") {
          await this.dynamicForm.createFormGroups(this.DataForm);
        }
    
        this.DataFormContracts?.sections.forEach(element => {
          this.dinamicFormGroups.push({[element.formName + "Form"]: window[element.formName + "Form"]})
        });
            
      } catch (error) {
        console.log(error);
      } finally {
      }
    } else {
      this.dfSelected = false;
    }
  }

  public async getDynamicForm() {
    try {
      const FormContracts = await this.httpService.get(`api/dynamicForms/byFormName/${this.FormNameContract}`).toPromise();
      this.DataForm = FormContracts;
    } catch (error) {
      console.log(error);
    } finally {
    }
  }

  public FormGroup(item : any): FormGroup{
    return window[item?.formName + "Form"];
   }

   public async clickbtn(event){
   }

  // Cerrar modal
  close(): void { this.onClose.emit() }

}
