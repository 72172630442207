
export const environment = {
  production: false,
  urlAdmin: 'https://admin.fdkapp.com/', //  servidor local y pruebas (subdominios: local y tospruebas)
  // urlAdmin: 'http://localhost:9000/', //  servidor local y pruebas (subdominios: local y tospruebas)

  // urlAdmin: 'http://10.0.10.58:9000/',
  //urlAdmin: 'https://mkdm1.fdkapp.com/', //  para correr local de la misma maquina  (subdominio: localTos)
  //urlAdmin: 'http://45.32.161.248:9000/', //  para correr local de la misma maquina  (subdominio: localTos)
  apiUrl: 'http://localhost:8010/',
  clientUrl: '',
  socketUrl: 'http://localhost:8010/',
  subdomain: '',
  url_monday: 'https://api.monday.com/v2',
  token_monday: 'eyJhbGciOiJIUzI1NiJ9.eyJ0aWQiOjQ4NjQwMTY3LCJ1aWQiOjE0NDk4ODIxLCJpYWQiOiIyMDIwLTA2LTA5VDAxOjU4OjE1LjAwMFoiLCJwZXIiOiJtZTp3cml0ZSJ9.S3uFnk5FIim4KKacAv2V5BbFvakaEj51lZQBJd5w_ZA',
  columna_app_monday: 'Tickets'
};
  
