import { Injectable } from '@angular/core';
import { FormArray, FormGroup, FormControl, Validators } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class DynamicControlsService {

  public validSentence = [];
  public DinamicFormElement: FormControl;

  constructor() { }

  public asignControls(check: any, DinamicForm: FormGroup ){
    return new Promise <any> (async (resolve, reject) =>{

  //Se establecen los controles para cada elemento por separado del formulario
    for (const item in check?.target?.validators) {
      if(item == "required" && check.target.validators[item])this.validSentence.push(Validators.required);
      else if(item == "maxLength")      this.validSentence.push(Validators.maxLength(check.target.validators[item]));
      else if(item == "pattern_email")  this.validSentence.push(Validators.pattern(/^[a-zA-Z0-9\._\-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3}$/));
      else if(item == "minLength")      this.validSentence.push(Validators.minLength(check.target.validators[item]));
      else if(item == "max")            this.validSentence.push((Validators.max(check.target.validators[item])));
      else if(item == "min")            this.validSentence.push((Validators.min(check.target.validators[item])));
    }
    
    DinamicForm.controls[check.formControlName].setValidators(this.validSentence);
    this.validSentence = [];

    resolve(DinamicForm);

  });
  }
}
