import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { DaterangepickerComponent } from 'ngx-daterangepicker-material';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent implements OnInit {

  @ViewChild(DaterangepickerComponent, {static: true}) datePicker: DaterangepickerComponent;
  
  @Input() value: any;
  @Input() single: any;
  @Output() onSelect: EventEmitter<any> = new EventEmitter<any>();

  public dateOptions: any = {
    displayFormat: 'DD/MM/YYYY ',
      "customRangeLabel": "Personalizadas",
      "applyLabel": "Aplicar",
      "cancelLabel": "Cancelar",
      "daysOfWeek": [
        "Do",
        "Lu",
        "Ma",
        "Mi",
        "Ju",
        "Vi",
        "Sa"
      ],
      "monthNames": [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre"
      ],
      "firstDay": 1
  };

  constructor() { }

  ngOnInit(): void {
    if (this.value) {
      this.updateDatepicker()
    }
  }

  select(event){
    this.onSelect.emit(event)
  }

  updateDatepicker() {
    this.datePicker?.setStartDate(this.value?.startDate);
    this.datePicker?.setEndDate(this.single ? this.value.startDate : this.value.endDate);
    this.datePicker?.updateCalendars();
  }

}
