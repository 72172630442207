import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgApexchartsModule } from 'ng-apexcharts';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { ChartsModule } from 'ng2-charts';
import { DragulaModule } from 'ng2-dragula';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { ComponentsModule } from 'src/app/components/components.module';
import { MkTableModule } from 'src/app/components/mk-table/mk-table.module';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { ProviderContractorRoutingModule } from './provider-contractor-routing.module';
import { ProviderContractorComponent } from './provider-contractor.component';
import { RequestDetailComponent } from './components/request-detail/request-detail.component';

import { NgSelectModule, NgOption } from '@ng-select/ng-select';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';



@NgModule({
  imports: [
    ProviderContractorRoutingModule,
    CommonModule,
    NgbModule,
    ChartsModule,
    NgCircleProgressModule.forRoot(),
    DragulaModule.forRoot(),
    NgxDaterangepickerMd.forRoot(), 
    NgApexchartsModule,
    ComponentsModule,
    MkTableModule,
    FormsModule,
    PipesModule,
    ComponentsModule,
    NgSelectModule, 
    ReactiveFormsModule,
    SweetAlert2Module.forRoot(),
    MatTableModule,
    MatPaginatorModule, 
    MatSortModule,
  ],
  declarations: [
    ProviderContractorComponent, 
    RequestDetailComponent,
  ],
  exports: [
    RequestDetailComponent
  ]
})
export class ProviderContractorModule { }
