<!-- <div class="modal-header">
    <h6 class="modal-title" id="modal-basic-title">Cotizacion</h6>
     <button type="button" class="close" aria-label="Close" (click)="close()">
        <span aria-hidden="true">&times;</span>
    </button>
</div> -->
<div class="modal-body pt-4 pb-0"> 
  <div class="row border-bottom pb-3 _title">
    <div class="col-12 mb-2 d-flex justify-content-between">
      <div>
        <h3 class="text-primary m-0">Detalle de cotización</h3>
        <span class="text-muted">Vista de cotizacion</span>
      </div>
      <div class="my-auto badge badge-st-{{quotesRecord?.status | lowercase}}">{{quotesRecord?.status | status}}</div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">

      <div class="row py-3">
        <div class="col-12 d-flex justify-content-between">
            <h4 class="m-0"><span class="text-muted">#{{quotesRecord?.folio}}</span> {{quotesRecord?.title}}</h4>
          
        </div>
        <div class="col-12 d-flex my-3">
            <mk-user class="md m-0" [user]="{img: url+quotesRecord?.client?.photo, name: quotesRecord?.client?.user}"></mk-user>
            <div class="d-flex flex-column pl-2" style="width: calc(100% - 45px);">
                <span>{{userService?.modulesName?.client || 'Cliente'}}</span>
                <b>{{quotesRecord?.client?.user}}</b>
            </div>
        </div>
        <div class="col-12">
            <div class="d-flex justify-content-between">
                <span class="text-muted">Creado el {{quotesRecord?.createBy?.date | moment:'l - HH:mm A'}}</span>
                <label class="custom-switch cursor-pointer">
                  <h6 class="mr-3 my-auto">Vista {{userService.modulesName?.client || 'Cliente'}}</h6>
                  <input type="checkbox" name="custom-switch-checkbox" class="custom-switch-input" [checked]="isClientView" (change)="isClientView = !isClientView">
                  <span class="custom-switch-indicator"></span>
              </label>
            </div>
        </div>
      </div>
      <div class="row border-top">
        <div class="css_quote p-0"><label>Archivos de cotización</label></div>
        <div class="col-12 pb-3">
          <div class="card p-2 {{quotesRecord?.files ? 'bg-dark text-white __texture' : ''}}">
            <div class="row" *ngFor="let item of quotesRecord?.files; index as i" [class.border-top]="i">
              <div class="col-12 d-flex justify-content-between">
                <h5 class="my-auto">{{item.nameOriginal}}</h5>
                <h5 class="m-0"><button class="btn btn-sm btn-white btn-circle" (click)="dowloadFile(item)"><i class="fa fa-download"></i></button></h5>
              </div>
            </div>
            <div class="css_quote p-0" *ngIf="!quotesRecord?.files?.length"><label class="my-1">Sin Archivos</label></div>
          </div>
        </div>
      </div>
      <div class="row border-top py-2">
        <div class="col-12 d-flex">
            <mk-user class="md m-0" [user]="{img: url+quotesRecord?.createBy?.photo} | imagesLoad"></mk-user>
            <div class="d-flex flex-column pl-2" style="width: calc(100% - 45px);">
                <div class="d-flex justify-content-between">
                    <span>Cotizado el {{quotesRecord?.createBy?.date | moment:'lll'}}</span>
                </div>
                <b>Por: {{quotesRecord?.createBy?.user}}</b>
            </div>
        </div>
      </div>
      <div class="row border-top mb-2">
        <div class="col-12">
        <div class="css_quote p-0"><label>Autorización</label></div>  <!-- ARREGLA ESTA FECHA PLEASE vvvvvv -->
        <h4>Anticipo: <b class="text-success _money">{{quotesRecord?.advance?.amount | currency}}</b><small class="text-muted">({{quotesRecord?.advance?.percent}}%)</small></h4>
        <span class="text-muted mb-2">Comentario</span>
        <textarea name="" id="" cols="30" rows="4" [value]="quotesRecord?.authComment || ''" class="form-control mb-2" placeholder="Sin Comentario"  readonly></textarea>
        <span class="text-info" *ngIf="quotesRecord?.update">Autorizado por <b>{{quotesRecord?.update?.user}}</b> el {{quotesRecord?.update?.date | moment: 'll - HH:mm A'}}</span>
        </div>
      </div>
    </div>

    <div class="col-md-6 border-left d-flex">
      <div class="css_quote my-auto">
        <label>Cotizado</label>
        <ng-container *ngFor="let m of filterDeducible(quotesRecord?.movementsArr, false)">
          <!-- *ngIf="m.unit != 'TIEMPO'" -->
            <div class="line">
              <ng-container *ngIf="!isClientView">
                <div class="type"><span class="{{m?.unit | lowercase}}">{{m?.unit | titlecase}}</span><span class="descr"></span></div>
                <div class="type"><span>Cant: {{m?.quantity || 0}} &bull; Ganancia: {{m?.earnMargin || 0}}%</span> <span class="money _money">{{(m?.amount  || 0) | currency}} + {{(m?.earnValue || 0) | currency}}</span></div>
              </ng-container>
              <ng-container *ngIf="isClientView">
                <div class="type"><span>Cant: {{m?.quantity || 0}}</span> <span class="{{m?.unit | lowercase}}">{{m?.unit | titlecase}}</span></div>
              </ng-container>
              <div class="flex">
                <span class="el fw">{{m.description}}</span>
                <span class="fw">{{(m.amount + m.earnValue || 0) | currency}}</span>
              </div>
            </div>
        </ng-container>
        <div class="line" *ngIf="!filterDeducible(quotesRecord?.movementsArr, false).length">
          <span class="el op-7">Sin Elementos</span>
        </div>
        <label>Deducido por {{company}}</label>
        <ng-container *ngFor="let m of filterDeducible(quotesRecord?.movementsArr, true)">
            <div class="line">
              <div class="type"><span class="{{m.unit | lowercase}}">{{m.unit | titlecase}}</span><span class="descr">Razón: {{m.deducible}}</span></div>
              <div class="type" *ngIf="!isClientView"><span>Cant: {{m.quantity || 0}} &bull; Ganancia: {{m.earnMargin || 0}}%</span> <span class="money _money">{{(m.amount  || 0) | currency}} + {{(m.earnValue || 0) | currency}}</span></div>
              <div class="flex">
                <span class="el fw">{{m.description}}</span>
                <span class="fw _money">{{(m.amount + m.earnValue  || 0) | currency}} </span>
              </div>
            </div>
        </ng-container>
        <div class="line" *ngIf="!filterDeducible(quotesRecord?.movementsArr, true).length">
          <span class="el op-7">Sin Elementos</span>
        </div>
        <div class="total">
          <ng-container *ngIf="!isClientView">
            <div class="line sub">
              <span>Total Bruto</span>
              <span>{{quotesRecord?.amounts?.bruto || 0 | currency}}</span> 
            </div>
            <div class="line sub">
              <span>Total Ganancia</span>
              <span>{{quotesRecord?.amounts?.earn || 0 | currency}}</span>
            </div>
          </ng-container>
          <div class="line sub">
            <span>Total Deducciones</span>
            <span class="descr">- {{quotesRecord?.amounts?.deducible || 0 | currency}}</span>
          </div>
          <div class="line sub">
            <span>Subtotal</span>
            <span>{{quotesRecord?.amounts?.subtotal || 0 | currency}}</span> 
          </div>
          <div class="line sub">
            <span>IVA ({{porcentIva}}%)</span>
            <span>{{quotesRecord?.amounts?.iva || 0 | currency}}</span>
          </div>
          <div class="line">
            <span>Total</span>
            <span class="_money">{{quotesRecord?.amounts?.total || 0 | currency}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer justify-content-end">
    <button type="button" class="btn btn-dark" (click)="close()">Cerrar</button>
</div>