import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { HttpService } from './http.service';
import { SocketService } from './socket.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  public horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  public verticalPosition: MatSnackBarVerticalPosition = 'top'; 
  public timeAlert: number = this.userService.user.timeAlert || 5;
  public durationInSeconds: number = this.timeAlert * 1000;
  
  public _Notifications: any = [];
  public unread: number = 0;

  constructor(
    public snackBar: MatSnackBar,
    public userService: UserService,
    public httpService: HttpService
  ) { 
    this.listNotifications();
  }
  
  public notificationTicket(message: string, action: string){
  return this.snackBar.open(message, action ,{
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      duration: this.durationInSeconds
    }); 
  }

  public openSnackBar(message: string, action: string, targe: any, id:any, create_date: any, table: any, position: any, temporalInfo:any):void {
    this.snackBar.open(message, action, {
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      duration: this.durationInSeconds
    }).onAction().subscribe(() => this.undoChangesColumns(targe, id, create_date, table, position, temporalInfo)); 
  }
  
  public undoChangesColumns = (targe, id:any, create_date: any, table: any, position: any, temporalInfo: any) => { 
    let data = {
      field: targe, 
      value: temporalInfo, 
      id: id,
      table: table,
      position: position
    }
  }

  public update(notification: any):void{
    this._Notifications.unshift(notification);
  }

  public updateNotifications(all:boolean = true, id:string = null){
    return new Promise( async (resolve, reject) => {
      try {
        if (all) {
          await this.httpService.get(`api/notifications/view/all`).toPromise();
          for (const notification of this._Notifications) {
            notification.view = true;
          }
          this.unread = 0
        }else{
          if (id) {
            await this.httpService.get(`api/notifications/view/${id}`).toPromise();
            this._Notifications.find(notification => {return notification?._id == id}).view = true;
            this.unread = this._Notifications?.filter(notification => {return !notification.view})?.length || 0;
          }
        }
        resolve(true);
      } catch (error) {
        console.log(error);
        resolve(false);
      }
   });
  }

  public async listNotifications(){
    return new Promise( async (resolve, reject) => {
      try {
        this._Notifications = await this.httpService.get(`api/notifications/list`).toPromise();
        this.unread = this._Notifications?.filter(notification => {return !notification.view})?.length || 0;
        resolve(true);
      } catch (error) {
        console.log(error);
        resolve(false);
      }
    });
  }
    
}
