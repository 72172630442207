import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ValidateFormsDirective } from 'src/app/directives/validate-forms.directive';
import { DecryptPipe } from 'src/app/shared/pipes/decrypt.pipe';
import { DynamicControlsService } from 'src/app/shared/services/dynamic-controls.service';
import { DyamicFormService } from 'src/app/shared/services/dynamic-form.service';
import { ValidatorsService } from 'src/app/shared/services/validators.service';
import { createThis } from 'typescript';

@Component({
  selector: 'dnc-number',
  templateUrl: './dnc-number.component.html',
  styleUrls: ['./dnc-number.component.scss', '../../dynForm.comp.scss'],
  host:{
    "[style.order]": "check.position",
    "[class]": "'col-sm-12 col-md-' + (check.col_size || 4)"
  }
})
export class DncNumberComponent implements OnInit {

  @Output() onChangeChindren: EventEmitter<any> = new EventEmitter<any>();
  @Output() onChange: EventEmitter<any> = new EventEmitter<any>();
  @Input() check: any;
  @Input() editable : boolean = true;
  @Input() editForm;
  @Input() touched : boolean = false;
  @Input() dependencie: any;
  @Input() automat : any = null;
  @Input() editConditions: any = [];
  public eventMessage = true;

  public DinamicForm: FormGroup
  public tempChange = null;
  public tempChangeForm: FormGroup;

  constructor(

    public validatorsService: ValidatorsService,
    public fieldisValid: ValidateFormsDirective,
    private fb: FormBuilder,    
    private dynamicControls: DynamicControlsService,
    private dynamicForm: DyamicFormService,
    public decrypt: DecryptPipe,
    public sanitizer: DomSanitizer

  ) { }
  async ngOnChanges(change: SimpleChanges){      
    if (!change.editForm?.firstChange) { // Reasigna los valores dependiendo del elemento a renderizar    
      if (this.check?.form == change.editForm?.currentValue?.name) {
                  
        if (this.check?.target?.readObj && change?.editForm?.currentValue?.form?.value[this.check?.formControlName]?.length) {       
            if (change?.editForm?.currentValue?.form?.value[this.check?.formControlName]?.length) {
              
              let data = change?.editForm?.currentValue?.form?.value[this.check?.formControlName].filter(dat => {return dat[this.check?.target?.readObj?.targetcondition]})[0][this.check?.target?.readObj?.field];
              this.tempChange = change?.editForm?.currentValue?.form?.value[this.check?.formControlName];
              this.DinamicForm.patchValue({
                [this.check.formControlName]: data
              });  
            }     
          }else{

            this.DinamicForm = this.dynamicForm.ReMapEditForm(change.editForm.currentValue?.form, this.DinamicForm);
        
          }
      }
    }
    if (!change.dependencie?.firstChange) {
      if (this.check?.formControlName == change?.dependencie?.currentValue?.depend?.formControlNametarget) {
        this.check.target.readonly =  !change?.dependencie?.currentValue?.value;       
        if (!change?.dependencie?.currentValue?.value) {
          this.DinamicForm.patchValue({
            [this.check.formControlName]: [null]
          })
        }        
      }
    }
    
    // Validadores Dynamicos
    if (!change.editConditions?.firstChange) {  
      let compare = (change.editConditions?.currentValue?.data)?.find(item => {return item?.condition?.target == this.check?.formControlName}) ;
    
      if (compare?.condition?.target == this.check?.formControlName) {
        let conditionConfig = compare;

        let field = conditionConfig?.condition?.target;
        let validatorsRAW = conditionConfig?.validators;

        await this.DinamicForm.controls[field].clearValidators();
        await window[this.check?.form + ""].controls[field].clearValidators();        

        let result = await this.dynamicForm.ArrayValidators(validatorsRAW);

        this.DinamicForm = this.fb.group({
          [this.check.formControlName]:[window[this.check?.form]["value"][this.check.formControlName], Validators.compose(result)]
        });

        window[this.check?.form + ""].controls[field].setValidators(result);
        this.DinamicForm.markAsUntouched();

        if (validatorsRAW.required && this.check?.target?.validators?.required) {
          this.check.target.validators.required = true;  
          this.eventMessage = true
     
        }else if(!validatorsRAW.required && this.check?.target?.validators?.required){
          this.check.target.validators.required = false;
          this.eventMessage = false

        }else if(validatorsRAW.required && !this.check?.target?.validators?.required){
          this.check.target.validators.required = true;          
          this.eventMessage = true

        }else if(validatorsRAW.required && !this.check?.target?.validators?.required){
          this.check.target.validators.required = false;
          this.eventMessage = false
        } 
        
        window[this.check?.form + ""] = await this.dynamicForm.MapForm(this.DinamicForm, window[this.check?.form + ""]);     
      }
      
    }

    if (change.touched?.currentValue) {    
      this.DinamicForm.markAllAsTouched();  
    }
  }

  async ngOnInit() {

    this.DinamicForm = this.fb.group({
      [this.check.formControlName]: null
    });
    this.tempChangeForm = this.fb.group({
      [this.check.formControlName]:[null]
    });

    this.DinamicForm = await this.dynamicControls.asignControls(this.check,  this.DinamicForm);

    //Establece los valores de inicio del formulario
     this.dynamicEmit(this.DinamicForm);
        
  }
  public validType(event, value = null){
    this.check.target.isInteger ?  this.validatorsService.isInteger(event)  :  this.validatorsService.isDecimal(event, value.value || null);
  }

  public pasteIsInteger(event){
    let clipboardData = event.clipboardData;
  let pastedText = clipboardData.getData('text');
 
     var value = this.validatorsService.ValuePasteIsInteger(pastedText);
     value ? this.DinamicForm.value[this.check.formControlName] = value : event.preventDefault();
  }

  dynamicEmit(event){ 
    this.validatorsService.isInteger(event);
    var value = this.DinamicForm.value[this.check.formControlName];
    
    // Parsea el dato ingresado a float y lo inserta en el formulario
   
    if (event?.target?.value) {
      let validValue = event?.target?.value != '' ? parseFloat(event?.target?.value) : null;
      this.DinamicForm.patchValue({
        [this.check.formControlName]: validValue
      });   
    }else{
      this.DinamicForm.patchValue({
        [this.check.formControlName]: null
      }); 
    }
    

    
    // this.onChange.emit({ event:this.DinamicForm, form: this.check?.form, automation: this.check?.target?.automation}); 
  
     // Codigo para obtener contacto primario de un cliente
  if (this.check?.target?.readObj) {
    
    let index = this.tempChange?.findIndex(tmp => {return tmp[this.check?.target?.readObj?.targetcondition]});
    
    if (index >= 0) {
      // en Caso de existir un contacto primario actualiza el nombre
      this.tempChange[index][this.check?.target?.readObj?.field] = this.DinamicForm.value[this.check?.formControlName];
       this.tempChangeForm.patchValue({
        [this.check?.formControlName]: JSON.stringify(this.tempChange)
       });

       this.onChange.emit({ event:this.tempChangeForm, form: this.check?.form});
    }else{
      if (value) {

         //En caso de que no exista un contacto primario crea uno con el nombre ingresado
         let arraytemp = [];
         this.tempChange?.lenght ?  arraytemp = this.tempChange :  "";
   
           this.tempChangeForm.patchValue({
             [this.check?.formControlName]: {name:this.check?.target.readObj.field, value: value }
            });
   
         this.onChange.emit({ event:this.tempChangeForm, form: this.check?.form, readObj: this.check});
  
      }
    }
    
    }else{
      this.onChange.emit({ event:this.DinamicForm, form: this.check?.form, automation: this.check?.target?.automation, fieldType: this.check?.type, value: this.DinamicForm.value[this.check.formControlName], field: this.check?.formControlName});      
    }

    if (this.check?.childrens) {

      var childrensValue = [];
      
      this.check?.childrens.forEach(children => {
       
       let val = (children?.decrypt) ? this.decrypt.transform(event?.target?.value) : event?.target?.value;
        childrensValue.push({
          formControlName: children.formControlName,
          value: val
        });
      });
      
      this.onChangeChindren.emit(childrensValue)
    }    
  }

  public validateRequired(): boolean{
    let required: boolean = this.check?.target?.validators?.required || false;
    return required;
  }

}

