<form [formGroup]="DinamicForm" >
   <dnc-title [check]="check" [editable]="editable" class="m0"></dnc-title>
    <div class="d-flex flex-wrap">
      <ng-container *ngFor="let item of check.target.options">
        <label class="d-flex cursor-pointer" [for]="item.id">
          <div class="ckbox" >
            <input type="radio" [value]="item.value" (change)="dynamicEmit()" [id]="item.id" [formControlName]="this.check.formControlName">
            <span></span>
          </div>
          <b class="mx-2">{{item.label}}</b>
        </label>
      </ng-container>
    </div>
</form>