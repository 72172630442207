<div class="center-sm">
  <div class="cursor-pointer text-overflow text-left _input __pad"placement="top" [ngbTooltip]="((value?value:'Seleccionar') | selects: options.collection) | decrypt" tooltipClass="tooltip-small" (click)="!readonly ? SelectValue(menu, $event) : {}">
    <span class="text-overflow">{{((value?value:'Seleccionar') | selects: options.collection) | decrypt}}</span>
  </div>
  <i class="fa fa-caret-down"></i>
</div>

<ng-template #menu>
  <div class="mkt_dropdown mkt_col col-select">
      <div class="head">Seleccionar</div>
      <div class="content p-0">
          <div class="_browser"><i class="fa fa-search"></i> <input type="text" class="_block mktd-block" placeholder="Buscar" (keyup)="filter(inputsearch.value)" #inputsearch/></div>
          <div class="p-0">
            <ng-container *ngFor="let option of filtering">
              <div (click)="emitValue(option); setFocus(false)" class="__list-item">{{(option?.name | decrypt) | titlecase}}</div>
          </ng-container>
          <ng-container *ngIf="!filtering.length">
            <div class="__list-item text-muted">Sin Registros</div>
          </ng-container>
          </div>
      </div>
  </div>
</ng-template>