 
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { DropdownService } from 'src/app/shared/services/dropdown.service';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'mkt-images',
  templateUrl: './mkt-images.component.html',
  styleUrls: ['./mkt-images.component.scss', '../../mk-table.comp.scss', '../../mk-table.comp.input.scss'],
  host:{
    "class" : 'mkt_col col-files',
    "[class.has-change]": "_hasChange"
  }
})
export class MktImagesComponent implements OnInit {

  @Input() value: any;
  @Input() item: any;
  @Input() target: any;
  @Input() options: any;
  @Output() valueChange: EventEmitter<any> = new EventEmitter<any>();

  public images = [];

  
  private _hasChange: boolean = false;
  public type: string; 
  public length: number;
  public url: string;

  constructor(private dropdown: DropdownService) { }

  ngOnInit(): void {
    this.type = typeof this.value;
    this.length =  this.value|| 0;
    this.url = environment.apiUrl;   
      
  }

  ngOnChanges(changes: SimpleChanges){
    if (!changes.value.firstChange) {
      this.type = typeof this.value;
      this.length =  this.value|| 0;
    }
  }

  openImg(template: any, event: any){
    this.images = [];
    this.dropdown.openDialog(template, event, 250, true, ["carousel-container", "carousel-arrow", "carousel-cell"]);
  
    this.item?.image.forEach(element => {
      this.images.push({path: this.url + element.name})
    });

  }

}
