<mk-loader *ngIf="loading" class="loader-spinner" style="z-index: 120"></mk-loader>
<div class="sidebar-title">
    <div class="d-flex justify-content-between">
        <div class="d-flex">
            <h1 class="m-0">#{{detail?.folio}}</h1>
            <div class="d-flex flex-column pl-3">
                <h4 class="color-tx m-0">{{((detail?.foreignId ) ? detail?.table : detail?.type )| status}}</h4>
                <div class="d-flex">
                    <span class="tx-16">Registrado el {{ detail?.create_date | moment: 'lll'}}</span>
                    <!-- <div><span class="ml-2 badge badge-danger-light d-block cursor-pointer">Eliminar</span></div> -->
                </div>
            </div>
        </div>
        <span class="status badge badge-st-{{detail?.status | lowercase}}">{{detail?.status | status}}</span>
    </div>
</div>
<div class="row _tabs">
    <span [class.active]="detailTab == 0" (click)="detailTab = 0">Información General</span>
    <span [class.active]="detailTab == 4" (click)="detailTab = 4" *ngIf="detail?.type == 'OBRA'">Avances</span>
    <span [class.active]="detailTab == 1" (click)="detailTab = 1" *ngIf="validateIncludeOnChat('INTERNAL')">Chat Interno  <!-- <div class="badge badge-danger mr-1" title="Mensajes administrativos">2</div> --> </span>
    <span [class.active]="detailTab == 2" (click)="detailTab = 2" *ngIf="userService?.customerChat && validateIncludeOnChat('CLIENT')">Chat {{userService.modulesName?.client || 'Cliente'}}  <!-- <div class="badge badge-danger" title="Mensajes con cliente">4</div> --> </span>
    <span [class.active]="detailTab == 3" (click)="detailTab = 3">Bitácora</span>
</div>

<ng-container [ngSwitch]="detailTab">
    <ng-container *ngSwitchCase="0" [ngTemplateOutlet]="tabInfo"></ng-container>
    <ng-container *ngSwitchCase="1" [ngTemplateOutlet]="tabChatAdmin"></ng-container>
    <ng-container *ngSwitchCase="2" [ngTemplateOutlet]="tabChatClient"></ng-container>

    <!-- TABLAS, EL PAGINADO SE ROMPE DENTRO DEL NG-TEMPLATE  -->

    <!-- BINNACLE  -->
    <div class="row" [hidden]="detailTab != 3">
        <div class="col-12">
            <div class="log">
                <!-- <h4>Bitácora <small class="text-muted">Registros de movimientos</small></h4>  -->
                <div class="card bg overflow-hidden">
                    <div class="card-header py-2">
                        <div class="row row-sm justify-content-between">
                            <div>
                                <label class="main-content-label mb-0">Bitácora</label>
                                <span class="d-block tx-12 mb-0 text-muted text-overflow lh-1">Registros de movimientos</span>
                            </div>
                            <div class="w-50"  style="max-width: 250px;">
                                <div class="input-group mb-0">
                                    <div class="input-group-prepend">
                                        <div class="input-group-text">
                                            <i class="fe fe-search lh--9 op-6"></i>
                                        </div>
                                    </div>
                                    <input (keyup)="search($event)" class="form-control" placeholder="Buscar...">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-body p-0">
                        <mk-loader *ngIf="loading" class="loader-spinner"></mk-loader>
                        <div class="table-responsive">
                        <table mat-table [dataSource]="DT_Binnacle" matSort #Sort="matSort" matSortActive="date" matSortDirection="desc" class="table table-bordered table-striped mb-0 material-table-container">
                            <ng-container matColumnDef="user">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header width="60px">Usuario</th>
                                <td mat-cell *matCellDef="let item" class="py-1">
                                    <mk-user color="dark" [tooltipDirection]="'right'" [user]="{name: item.user?.name, img: (item?.user?.img)?(apiUrl+item?.user?.img):''} "></mk-user>
                                </td>
                            </ng-container> 
    
                            <ng-container matColumnDef="description">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Descripción</th>
                                <td mat-cell *matCellDef="let item">
                                    <div class="d-flex justify-content-between">
                                        <span *ngIf="item?.text1 != 'STATUS'">{{item?.description}} </span>
                                        <span *ngIf="item?.text1 == 'STATUS'">{{item?.description}} <b class="badge tx-12 text-white st-{{item.text3 | lowercase}}">{{item.text3 | status}}</b> a <b class="badge tx-12 text-white st-{{item.text2 | lowercase}}">{{item.text2 | status}}</b></span>
                                        <!-- <b *ngIf="item?.text1 == 'STATUS'" class="badge tx-12 st-{{item.text2 | lowercase}}">{{item.text2 | status}}</b> -->
                                        <!-- <b *ngIf="item?.text1 == 'COTIZACION' && item?.text2=='AUTORIZADO'">{{item.user}}</b>
                                        <b *ngIf="item?.text1 == 'TABLE'">{{item.text2}}</b> -->
                                    </div>
                                </td>
                            </ng-container>
    
                            <ng-container matColumnDef="date">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header width="150px">Fecha/hora</th>
                                <td mat-cell *matCellDef="let item">{{item.date| date: 'dd/MMM/y - h:mm'}}</td>
                            </ng-container>
        
                            <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayColumns;"></tr>
    
                            <tr class="mat-row" *matNoDataRow>
                                <td class="mat-cell text-center" colspan="6">{{loading? "Cargando ...":"Sin datos encontrados"}}</td>
                            </tr>
                        </table>
                            <mat-paginator #Paginator="matPaginator" [pageSize]="15" aria-label="" showFirstLastButtons ></mat-paginator>
                        </div>   
                    </div>
                </div> 
            </div>
        </div>
    </div>
    <!-- ADVANCE TABLE  -->
    <div class="row" [hidden]="detailTab != 4">
        <div class="col-12 ">
            <div class="budget bg">
                <div class="card">
                    <div class="card-header py-2">
                        <div class="row row-sm justify-content-between">
                            <div>
                              <label class="main-content-label mb-0">Dinero</label>
                              <span class="d-block tx-12 mb-0 text-muted text-overflow lh-1">Elementos de cotizacion para la {{(this.userService?.modulesName?.obra) || 'obra'}}</span>
                            </div>
                        </div>
                    </div>

                    <div class="table-responsive">
                        <table mat-table [dataSource]="DT_money" matSort #SortMoney="matSort" matSortActive="folio" matSortDirection="asc" class="table table-bordered table-striped mb-0 material-table-container">
                           
                            <ng-container matColumnDef="folio">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header width="100px"> <div class="text-center"># Folio</div></th>
                                <td mat-cell *matCellDef="let item"><b *ngIf="item.folio"> #{{item.folio}}</b></td>
                                <td mat-footer-cell *matFooterCellDef></td>
                            </ng-container>
    
                            <ng-container matColumnDef="description">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>DESCRIPCIÓN </th>
                                <td mat-cell *matCellDef="let item"><b>{{item.description}}</b></td>
                                <td mat-footer-cell *matFooterCellDef></td>
                            </ng-container>
    
                            <ng-container matColumnDef="quantity">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> CANTIDAD </th>
                                <td mat-cell *matCellDef="let item">{{item.quantity}}</td>
                                <td mat-footer-cell *matFooterCellDef></td>
                            </ng-container>
    
                            <ng-container matColumnDef="unitPrice">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> P.U. </th>
                                <td mat-cell *matCellDef="let item">{{item.unitPrice | currency}}</td>
                                <td mat-footer-cell *matFooterCellDef></td>
                            </ng-container> 
    
                            <ng-container matColumnDef="amount">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> IMPORTE </th>
                                <td mat-cell *matCellDef="let item">{{item.amount || 0 | currency}}</td>
                                <td mat-footer-cell *matFooterCellDef><div class="text-right"><b>{{(getSum(moneyTable, 'amount') || 0) | currency}}</b></div></td>
                            </ng-container>
    
                            <ng-container matColumnDef="av_financiero">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{(userService?.modulesName?.paid | titlecase) || 'Av. Finc.'}} </th>
                                <td mat-cell *matCellDef="let item">{{getType('av_financiero')=='usd' ? ( (item.av_financiero || 0 )| currency) : (item.av_financiero || 0)+'%'}}</td>
                                <td mat-footer-cell *matFooterCellDef><div class="text-right"><b>{{getType('av_financiero')=='usd' ? ( (getSum(moneyTable, 'av_financiero') || 0 )| currency) : (getSum(detail?.movements, 'av_financiero') || 0)+'%'}}</b></div></td>
                            </ng-container>

                            <ng-container matColumnDef="av_fisico">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{(this.userService?.modulesName?.debt | titlecase) || 'Av. Fís.'}} </th>
                                <td mat-cell *matCellDef="let item">{{getType('av_fisico')=='usd' ? ( (item.av_fisico || 0 )| currency) : (item.av_fisico || 0)+'%'}}</td>
                                <td mat-footer-cell *matFooterCellDef><div class="text-right"><b>{{getType('av_fisico')=='usd' ? ( (getSum(moneyTable, 'av_fisico') || 0 )| currency) : (getSum(detail?.movements, 'av_fisico') || 0)+'%'}}</b></div></td>
                            </ng-container>
    
                            <ng-container matColumnDef="updateDate">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header width="140px"> FECHA ACTUALIZADO</th>
                                <td mat-cell *matCellDef="let item">
                                    {{item?.updateDate.split('*')[1]}} 
                                </td>
                                <td mat-footer-cell *matFooterCellDef></td>
                            </ng-container>

                            <ng-container matColumnDef="createDate">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header width="140px"> FECHA CREACIÓN</th>
                                <td mat-cell *matCellDef="let item">
                                    {{item?.createDate.split('*')[1]}} 
                                </td>
                                <td mat-footer-cell *matFooterCellDef></td>
                            </ng-container>
     
                            <tr mat-header-row *matHeaderRowDef="displayColumnsUnit"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayColumnsUnit;"></tr>
                            <tr mat-footer-row *matFooterRowDef="displayColumnsUnit; sticky: true" class="border-top"></tr>
                
                            <tr class="mat-row" *matNoDataRow>
                              <td class="mat-cell text-center" colspan="9">{{loading? "Cargando ...":"Sin datos encontrados"}}</td>
                            </tr>
                          </table>
                    </div>
                    
                </div>
            </div>
        </div>
        <div class="col-12 mt-3" *ngIf="timeTable?.length">
            <div class="budget bg">
                <div class="card">
                    <div class="card-header py-2">
                        <div class="row row-sm justify-content-between">
                            <div>
                                <label class="main-content-label mb-0">Tiempo</label>
                                <span class="d-block tx-12 mb-0 text-muted text-overflow lh-1">Elementos de cotizacion para la {{(this.userService?.modulesName?.obra) || 'obra'}}</span>
                            </div>
                        </div>
                    </div>
                    
                    <div class="table-responsive">
                        <table mat-table [dataSource]="DT_time" matSort #SortTime="matSort" matSortActive="folio" matSortDirection="asc" class="table table-bordered table-striped mb-0 material-table-container">
                           
                            <ng-container matColumnDef="folio">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header width="100px"> <div class="text-center"># Folio</div></th>
                                <td mat-cell *matCellDef="let item"><b *ngIf="item.folio"> #{{item.folio}}</b></td>
                                <td mat-footer-cell *matFooterCellDef></td>
                            </ng-container>
    
                            <ng-container matColumnDef="description">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>DESCRIPCIÓN </th>
                                <td mat-cell *matCellDef="let item"><b>{{item.description}}</b></td>
                                <td mat-footer-cell *matFooterCellDef></td>
                            </ng-container>
    
                            <ng-container matColumnDef="quantity">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> CANTIDAD </th>
                                <td mat-cell *matCellDef="let item">{{item.quantity}}</td>
                                <td mat-footer-cell *matFooterCellDef></td>
                            </ng-container>
    
                            <ng-container matColumnDef="unitPrice">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> P.U. </th>
                                <td mat-cell *matCellDef="let item">{{item.unitPrice | currency}}</td>
                                <td mat-footer-cell *matFooterCellDef></td>
                            </ng-container> 
    
                            <ng-container matColumnDef="amount">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> IMPORTE </th>
                                <td mat-cell *matCellDef="let item">{{item.amount || 0 | currency}}</td>
                                <td mat-footer-cell *matFooterCellDef><div class="text-right"><b>{{(getSum(timeTable, 'amount') || 0) | currency}}</b></div></td>
                            </ng-container>
    
                            <ng-container matColumnDef="av_financiero">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{(userService?.modulesName?.paid | titlecase) || 'Av. Finc.'}} </th>
                                <td mat-cell *matCellDef="let item">{{getType('av_financiero')=='usd' ? ( (item.av_financiero || 0 )| currency) : (item.av_financiero || 0)+'%'}}</td>
                                <td mat-footer-cell *matFooterCellDef><div class="text-right"><b>{{getType('av_financiero')=='usd' ? ( (getSum(timeTable, 'av_financiero') || 0 )| currency) : (getSum(detail?.movements, 'av_financiero') || 0)+'%'}}</b></div></td>
                            </ng-container>

                            <ng-container matColumnDef="av_fisico">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{(this.userService?.modulesName?.debt | titlecase) || 'Av. Fís.'}} </th>
                                <td mat-cell *matCellDef="let item">{{getType('av_fisico')=='usd' ? ( (item.av_fisico || 0 )| currency) : (item.av_fisico || 0)+'%'}}</td>
                                <td mat-footer-cell *matFooterCellDef><div class="text-right"><b>{{getType('av_fisico')=='usd' ? ( (getSum(timeTable, 'av_fisico') || 0 )| currency) : (getSum(detail?.movements, 'av_fisico') || 0)+'%'}}</b></div></td>
                            </ng-container>
    
                            <ng-container matColumnDef="updateDate">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header width="140px"> FECHA ACTUALIZADO</th>
                                <td mat-cell *matCellDef="let item">
                                    {{item?.updateDate.split('*')[1]}} 
                                </td>
                                <td mat-footer-cell *matFooterCellDef></td>
                            </ng-container>

                            <ng-container matColumnDef="createDate">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header width="140px"> FECHA CREACIÓN</th>
                                <td mat-cell *matCellDef="let item">
                                    {{item?.createDate.split('*')[1]}} 
                                </td>
                                <td mat-footer-cell *matFooterCellDef></td>
                            </ng-container>
     
                            <tr mat-header-row *matHeaderRowDef="displayColumnsUnit"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayColumnsUnit;"></tr>
                            <tr mat-footer-row *matFooterRowDef="displayColumnsUnit; sticky: true" class="border-top"></tr>
                
                            <tr class="mat-row" *matNoDataRow>
                              <td class="mat-cell text-center" colspan="9">{{loading? "Cargando ...":"Sin datos encontrados"}}</td>
                            </tr>
                          </table>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
    

</ng-container>

<!-- SECCIONES -->
<ng-template #tabInfo>
    <div class="row"> 
        <div class="col-sm-12 col-lg-7">
            <div class="info mb-3">
                <div class="d-flex justify-content-between">
                    <small class="text-muted d-block">Asunto</small>
                    <small class="text-info d-block" *ngIf="editable && _detailFocus.title">{{title?.length || 0}} / 40</small>
                </div>
                <ng-container *ngIf="!editable else editableTitle">
                    <h2 class="color-tx mb-2">{{title || "..."}}</h2>
                </ng-container>
                <ng-template #editableTitle>
                    <h2><input type="text" class="form-control clear mb-2 color-tx" style="font-weight: 500;" placeholder="..." [(ngModel)]="title" (focus)="_detailFocus.title = true" (blur)="_detailFocus.title = false"
                        (change)="updateInfo($event.target?.value, 'title')" maxlength="40"></h2>
                </ng-template>
                <div class="d-flex justify-content-between">
                    <small class="text-muted d-block">Descripción</small>
                    <small class="text-info d-block" *ngIf="editable && _detailFocus?.description">{{description?.length || 0}} / 250</small>
                </div>
                <ng-container *ngIf="!editable else editableDescription">
                    <span class="tx-18  mb-2">{{description || "..."}}</span>
                </ng-container>
                <ng-template #editableDescription>
                    <textarea class="form-control clear tx-18  mb-2" #txtDesc [style.height.px]="txtDesc?.scrollHeight" rows="1" placeholder="Agrega descripción..." [(ngModel)]="description" (focus)="_detailFocus.description = true" (blur)="_detailFocus.description = false"
                    (change)="updateInfo($event?.target?.value, 'description')" maxlength="250"></textarea>
                </ng-template>

                <div class="d-flex justify-content-between">
                    <small class="text-muted d-block">Notas</small>
                    <small class="text-info d-block" *ngIf="editable && _detailFocus?.notes">{{notes?.length || 0}} / 250</small>
                </div>
                <ng-container *ngIf="!editable else editableNotes">
                    <span class="tx-18  mb-2">{{notes || "..."}}</span>
                </ng-container>
                <ng-template #editableNotes>
                    <textarea class="form-control clear tx-18 mt-1" rows="1" maxlength="250" (focus)="_detailFocus.notes = true" (blur)="_detailFocus.notes = false" #txtNotas [style.height.px]="txtNotas?.scrollHeight"  placeholder="Agrega notas..." [(ngModel)]="notes" (change)="updateInfo($event?.target?.value, 'notes')"></textarea>
                </ng-template>
            </div>
            <ng-container *ngIf="quotesRecord.length">
                <mk-loader *ngIf="loadingQuotes" class="loader-spinner" style="z-index: 120"></mk-loader>
                <div class="quotelog mb-3">
                  <h4 class="text-info">Cotizaciones</h4>
                    <ng-container *ngFor="let q of filterQuotes(quotesRecord, true); index as i">
                        <div class="card bg mb-2 {{q.status | lowercase}}" (click)="quoteSelected = q; openModal(modalQuote, 'lg')">
                            <div class="d-flex justify-content-between ">
                                <b><i class="zmdi zmdi-assignment mr-1 op-8"></i> {{q?.title}}</b>
                                <!-- <span>{{q.status | titlecase}} el {{q?.update ? ( q?.update?.date | moment:'lll')  :  (q.expire | moment: 'lll')}}</span> -->
                                <div class="d-inline-flex">
                                    <span>{{q?.createBy.date | moment:'l'}}</span>
                                    <b>
                                        <i class="fa fa-eye ml-3"></i>
                                        <i class="fa fa-comment ml-3 {{q?.authComment ? 'dot' : ''}}"></i>
                                    </b>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <span class="text-muted d-block mb-2" *ngIf="filterQuotes(quotesRecord, false).length">Autorizadas/Expiradas</span>
                    <ng-container *ngFor="let q of filterQuotes(quotesRecord, false); index as i">
                        <div class="card bg mb-2" (click)="quoteSelected = q; openModal(modalQuote, 'lg')">
                            <div class="d-flex justify-content-between {{q?.status | lowercase}}">
                                <b><i class="zmdi zmdi-assignment mr-1 op-8"></i> {{q?.title}}</b>
                                <!-- <span>{{q.status | titlecase}} el {{q?.update ? ( q?.update?.date | moment:'lll')  :  (q.expire | moment: 'lll')}}</span> -->
                                <div class="d-inline-flex" *ngIf="q.status == 'EXPIRE'">
                                    <span><span style="font-weight: 500;">{{q.status | status | titlecase}}</span> el {{q?.expire | moment: 'l'}}</span>
                                    <b>
                                        <i class="fa fa-eye ml-3"></i>
                                        <i class="fa fa-comment ml-3 {{q?.authComment ? 'dot' : ''}}"></i>
                                    </b>
                                </div>
                                <div class="d-inline-flex" *ngIf="q.status != 'EXPIRE' && q?.update">
                                    <span><span style="font-weight: 500;">{{q?.status | status | titlecase}}</span> el {{q?.update?.date | moment:'l'}}</span>
                                    <b>
                                        <i class="fa fa-eye ml-3"></i>
                                        <i class="fa fa-comment ml-3 {{q?.authComment ? 'dot' : ''}}"></i>
                                    </b>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </ng-container>
            
            <div class="files">
                <div class="w-100 d-flex justify-content-between mb-3">
                    <h4 class="my-auto">Anexos</h4>
                    <!-- CON AUDIO -->
                    <div *ngIf="detail?.audio" class="d-flex justify-content-between my-auto">
                        <!-- <h6 class="color-tx my-auto" style="font-size: 1.2em;">Audio</h6> -->
                        <button *ngIf="detail?.createIn == 'WEB'" [swal]="deleteAudioSwal" (confirm)="deleteAudio()" class="btn btn-danger btn-circle mr-2" style="border-radius: 50%;"><i class="fa fa-trash"></i></button>
                    
                        <audio class="_audioTag" controls >
                            <source [src]="url+detail?.audio" type="audio/mpeg">
                        </audio>
                    </div>
                    <!-- SIN AUDIO CREADO EN WEB -->
                    <label class="d-flex justify-content-between my-auto cursor-pointer" *ngIf="!detail?.audio" [ngbTooltip]="detail?.createIn == 'WEB'? 'Tamaño máximo del archivo 5 MB.' : ''" placement="bottom" tooltipClass="tooltip-info">
                        <!-- <h6 class="color-tx my-auto" style="font-size: 1.2em;">Audio</h6> -->
                        <div class="_audio" *ngIf="!detail?.audio"><span class="text-muted m-auto">Sin Audio</span>
                            <input *ngIf="detail?.createIn == 'WEB'" accept=".mp3, .m4a, .opus, .ogg, .wav" 
                            type="file" style="display: none;" (change)="addAudio($event)">
                        </div>
                    </label>

                </div>
                <mk-loader *ngIf="loadingFiles" class="loader-spinner" style="z-index: 119"></mk-loader>
                <div class="bg border">
                    <div class="row">
                        <div class="col-12 d-flex flex-column">
                            <div class="w-100 d-flex justify-content-between px-2 pt-2">
                                <h6 class="color-tx my-auto">Fotografía del solicitante</h6>
                                <span *ngIf="detail?.createIn == 'WEB'" class="text-info my-auto cursor-pointer" (click)="openModal(modalEditImg, 'md')">Editar </span>
                            </div>
                            <span>
                                <div class="m-auto text-muted d-flex p-1 pl-2" *ngIf="!images?.length">Sin Imagen</div>
                                
                                <div class="img-res d-flex children-{{images?.length}}">
                                <div class="cnt-images">
                                    <ng-container *ngFor="let imag of images" >
                                        <div class="div-img">
                                            <img [src]="url + imag.name" alt="" class="m-2 cursor-pointer" (click)="openImg(modalImg, imag, $event)">
                                        </div>
                                    </ng-container>
                                </div>
                                </div>
                                <!-- <carousel
                                    *ngIf="images.length"
                                    [images]="images"
                                    [loop]="true"
                                    class="mb-2"
                                    [margin]="10"
                                    [borderRadius]="5"
                                    [dots]="true"
                                    transitionTimingFunction="linear"
                                    [counter]="true"
                                    counterSeparator="/"
                                    [lightDOM]="true"
                                >
                                </carousel> -->

                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-12 col-lg-5">
            <div *ngIf="viewContract"  class="contract bg border mb-3 p-2 lh-1" [ngClass]="(contractPermission) ? 'cursor-pointer' : ''" [ariaDisabled]="contractPermission">
                <div  class="w-100 d-flex justify-content-between">
                    <span class="text-muted lh-6 mb-1 color-tx">{{userService?.modulesName?.contract || 'Contrato'}} {{detail?.contract?.code || "..."}}</span>
                    <!-- <span *ngIf="(detail?.contract?.id && contractPermission)" class="text-info cursor-pointer" (click)="idContract =  detail?.contract.id; contractCode = detail?.contract.code; openModal(modalContract);">Ver información</span> -->
                    <span *ngIf="(detail?.contract?.id && (contractPermission || userService?.user?.roleName == 'ADMIN'))" class="text-info cursor-pointer" 
                    (click)="idContract =  detail?.contract.id; contractCode = detail?.contract.code;  (detail?.contract.id && (contractPermission || userService?.user?.roleName == 'ADMIN') ) ? (detail?.contract?.rentRoll ? openModal(modalContractRentroll, 'xxl') : openModal(modalContractRentroll))  : '';  "
                    >Ver información</span>
                </div>
                <ng-container *ngIf="!editable else editableContract">
                    <h4 class="m-0 lh-2">{{detail?.contract.name || "..."}}</h4>
                </ng-container>
                <ng-template #editableContract>
                    <div class="input-group">
                        <div class="input-group-prepend"><div class="input-group-text"><i class="fe fe-file-text lh--9 op-6"></i></div></div>
                        <ng-select [(ngModel)]="customerSelected" [clearable]="false" [items]="customers" bindLabel="name" bindValue="_id" class="form-control s-1" (change)="searchContract('customerid',$event?._id)"  placeholder="Selecciona {{'CLIENT' | status}}"></ng-select>
                        <ng-select [(ngModel)]="contractSelected" [clearable]="false" [items]="contracts" bindLabel="name" bindValue="_id" class="form-control s-2" (change)="updateInfo($event?._id, 'contract')" placeholder="Selecciona {{userService?.modulesName?.contract || 'Contrato'}}"></ng-select>
                    </div>
                </ng-template>
            </div>
            <div class="client">
                <div class="bg border">
                    <mk-loader *ngIf="loadingAddress" class="loader-spinner" style="z-index: 119"></mk-loader>
                    <div class="">
                        <div class="d-flex p-2 border-bottom">
                            <div class="img"><img [src]="url + detail?.create_user_photo || '../../../assets/img/placeholder-user.png'" alt=""></div>
                            <div class="d-flex flex-column pl-3"> 
                                <span class="text-muted lh-1">Solicitado por:</span>
                                <h4 class="m-0">{{detail?.create_user_name}}</h4>
                            </div>
                        </div>
                        <div class="d-flex justify-content-between color-tx p-2">
                            <a href="tel:{{detail?.create_user_phone}}" class="m-0 tx-12">
                                <i class="fa fa-phone op-7 mr-2"></i>
                                <b>{{detail?.create_user_phone}}</b>
                            </a>
                            <a href="mailto:{{detail?.create_user_email}}" class="m-0 tx-12">
                                <i class="fa fa-envelope op-7 mr-2"></i>
                                <b>{{detail?.create_user_email}}</b>
                            </a>
                        </div>
                        <div class="d-flex flex-column text-right px-2 pb-2" *ngIf="userAddress">
                            <span class="text-muted">Dirección:</span>
                            <p class="m-0">{{userAddress}}</p>
                        </div>
                    </div>
                    <div class="map border-top">
                        <ng-container *ngIf="mapPos else noMap">
                            <a href="https://www.google.com/maps/search/?api=1&query={{mapPos?.latitude}},{{mapPos?.longitude}}" target="_blank">
                                <img [src]="'https://maps.googleapis.com/maps/api/staticmap?markers=color:red%7C'+mapPos?.latitude+','+mapPos?.longitude+'&zoom=15&size=384x294&key=AIzaSyA7VA9TK6CJtPGXc-xi_a5Htlauq8FjxFY'" alt="">
                            </a>
                            <span>Ir a Ubicación</span>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-sm-12">

        <div class="card bg p-3">
                <div class="d-flex w-100 justify-content-between">
                    <div>
                        <h6 class="color-tx mb-0 tx-16">Archivos</h6>
                        <span class="text-muted lh-1">Listado de archivos adjuntos</span>
                    </div>
                    <div>
                        <button type="button" *ngIf="!blockDetail" class="btn btn-color text-white" (click)="modalOption.type='ADD'; openModal(modalFile, 'md')">
                            <i class="fa fa-plus"></i> Agregar
                        </button>
                    </div>
                </div>
                <div class="w-100 text-info text-right tx-12 mt-2" *ngIf="files?.length"><i class="fa fa-info-circle mr-1"></i>Haz click sobre el archivo para editar/descargar</div>

                <div class="d-flex flex-wrap" style="margin: 0 -10px;">
                    <ng-container *ngFor="let file of files">
                        <app-file-item [file]="file" (onDelete)='removeSelectedFile(detail?._id, file.file)' (onEdit)="openModal(modalFile, 'md'); modalOption.type='EDIT'; dataFileAsign($event);" (onDownload)="download(file.file)"></app-file-item>
                    </ng-container>
                </div>
        </div>
        </div>
    </div>
</ng-template>
<ng-template #tabChatAdmin>
<div class="row">
    <div class="col-md-12">
            <div class="chat">
                <div class="card bg overflow-hidden">
                    <div class="card-header py-2">
                        <div class="row row-sm justify-content-between">
                            <div class="w-100">
                              <label class="main-content-label mb-0"><i class="fa fa-circle mr-1 color-tx"></i> Chat interno</label>
                              <div class="d-flex justify-content-between lh-1 tx-12 mb-0">
                                <span class="text-muted text-overflow">Chat entre participantes del {{userService?.modulesName?.ticket || 'ticket'}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4 pr-0 border-right">
                                <div class="row m-0 border-bottom">
                                    <div class="col-sm-12 py-3">
                                        <h6 class="color-tx m-0">{{getChatType('INTERNAL')?.dataUsers?.length}} Participantes</h6>
                                    </div>
                                </div>
                            <div class="_scrollUsers">
                                <ng-container *ngFor="let item of getChatType('INTERNAL')?.dataUsers || [] index as i; even as even">
                                    <div class="row m-0 border-bottom" [class.even]="even">
                                        <div class="col-sm-12 d-flex p-2 text-overflow">
                                            <div class="_avatar"><img [src]="(url+(item?.image) | imagesLoad) || '../../../assets/img/placeholder-user.png'" alt=""></div>
                                            <div class="d-flex flex-column">
                                                <div class="text-overflow">{{item.name}}</div>
                                                <small class="text-muted">{{(item?.role | roleName) || '...'}}</small>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                        <div class="col-md-8 pl-0">
                            <chat [idChat]="getChatType('INTERNAL')?._id||null" [field]="'_id'"></chat>
                        </div>
                    </div>
                </div> 
              </div>
    </div>
</div>
</ng-template>
<ng-template #tabChatClient>
<div class="row">
    <div class="col-md-12">
        <div class="chat">
            <div class="card bg overflow-hidden">
                <div class="card-header py-2">
                    <div class="row row-sm justify-content-between">
                        <div class="w-100">
                          <label class="main-content-label mb-0"><i class="fa fa-circle mr-1 color-tx "></i> Chat con {{userService?.modulesName?.client || 'Cliente'}}</label>
                          <div class="d-flex justify-content-between lh-1 tx-12 mb-0">
                            <span class="text-muted text-overflow">Comentarios del {{userService?.modulesName?.ticket || 'ticket'}}</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-4 pr-0 border-right">
                            <div class="row m-0 border-bottom">
                                <div class="col-sm-12 py-3">
                                    <h6 class="color-tx m-0">{{getChatType('CLIENT')?.dataUsers?.length}} Participantes</h6>
                                </div>
                            </div>
                        <div class="_scrollUsers">
                            <ng-container *ngFor="let item of getChatType('CLIENT')?.dataUsers || [] index as i; even as even">
                                <div class="row m-0 border-bottom" [class.even]="even">
                                    <div class="col-sm-12 d-flex p-2 text-overflow">
                                        <div class="_avatar"><img [src]="(url+(item.image) | imagesLoad)" alt=""></div>
                                        <div class="d-flex flex-column">
                                            <div class="text-overflow">{{item.name}}</div>
                                            <small class="text-muted">{{(item?.role | roleName) || '...'}}</small>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                    <div class="col-md-8 pl-0">
                        <chat [idChat]="getChatType('CLIENT')?._id||null" [field]="'_id'"></chat>
                    </div>
                </div>
            </div> 
        </div>
    </div>
</div>
</ng-template>


<!-- MODALES  -->

<ng-template #modalFile let-modal>
    <app-file-modal (onClose)="modal.close()" (onReload)="getDetail(ticket.id)" [ticket]="ticket" [modalOption]="modalOption" [fileInfo]="fileData" (onDidUpload)="getDetail(ticket.id)"></app-file-modal>
</ng-template>

<swal #deleteCommentSwal class="d-none" title="¿Estas seguro de eliminar el comentario?" icon="question" [showCancelButton]="true" [focusCancel]="true" [swalOptions]="{confirmButtonText: 'Aceptar', cancelButtonText: 'Cancelar', confirmButtonColor: '#fd3666'}">
</swal>

<swal #deleteAudioSwal class="d-none" title="¿Estas seguro de eliminar el audio?" icon="question" [showCancelButton]="true" [focusCancel]="true" [swalOptions]="{confirmButtonText: 'Aceptar', cancelButtonText: 'Cancelar', confirmButtonColor: '#fd3666'}">
</swal>

<ng-template #modalImg>
    <div class="mkt_dropdown mkt_col size">
        <div class="head">Imagen</div>
        <div class="content p-0">
            <img [src]="modalImgSrc" alt="">
        </div>
    </div>
</ng-template>

<ng-template #modalQuote let-modal>
    <app-quote-modal (onClose)="modal.close(); quoteSelected = null"  [quotesRecord]="quoteSelected"></app-quote-modal>
</ng-template>

<ng-template #modalContract let-modal>
    <app-contract-modal [idContract]="idContract" [contractCode]="contractCode" (onClose)="modal.close();"></app-contract-modal>
</ng-template>

<ng-template #modalContractRentroll let-modal>
    <app-contract-rentroll-modal [idContract]="idContract" [contractCode]="contractCode" (onClose)="modal.close();"></app-contract-rentroll-modal>
</ng-template>

<ng-template #noMap>
    <div class="w-100 d-flex no-map">
        <span>Sin Ubicación</span>
    </div>
</ng-template>

<!-- no Data -->
<ng-template #noData>
    <div style="height: 155px;" class="w-100 d-flex">
        <span class="m-auto text-muted">Sin Mensajes</span>
    </div>
</ng-template>


<ng-template #modalEditImg let-modal>
    <image-edit-modal (onClose)="modal.close();" [modalOption]="modalOption" (onSubmit)="getDetail(ticket.id, $event);"></image-edit-modal>
</ng-template>