import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from 'src/app/shared/services/alert.service';
import { DropdownService } from 'src/app/shared/services/dropdown.service';
import { HttpService } from 'src/app/shared/services/http.service';
import { UserService } from 'src/app/shared/services/user.service';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'mkt-files',
  templateUrl: './mkt-files.component.html',
  styleUrls: ['./mkt-files.component.scss', '../../mk-table.comp.scss'],
  host:{
    "class" : 'mkt_col col-files',
    "[class.has-change]": "_hasChange"
  },
  encapsulation: ViewEncapsulation.Emulated
})
export class MktFilesComponent implements OnInit {

  @Input() value: any;
  @Input() item: any;
  @Input() target: any;
  @Input() options: any;
  @Input() isSubitem: boolean = false;
  @Output() valueChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() onClose: EventEmitter<any> = new EventEmitter<any>(); 

  private _hasChange: boolean = false;
  public type: string; 
  public length: number;
  public url: string;
  public filesForm: FormGroup;
  public fileList: any[] = [];
  public _id: string;
  public fileEvent: any;
  public modalOption: any = {id: "", type: ""}

  public iconList: any = [    
    { type: "pdf",  icon: "fa fa-file-pdf-o"   },
    { type: "jpg",  icon: "fa fa-file-image-o" },
    { type: "png",  icon: "fa fa-file-image-o" },
    { type: "jpeg", icon: "fa fa-file-image-o" },
    { type: "dwg",  icon: "fa fa-file" }];

  constructor(private modalService: NgbModal,    
              private fb: FormBuilder,
              private dropdown: DropdownService,
              private httpService: HttpService,
              private alertService: AlertService,
              public userService: UserService) {  this.url = environment.apiUrl; }

  ngOnInit(): void {
    this.type = typeof this.value;
    this.length = this.value?.length || 0;
    this.formFiles();  
    this.modalOption.id = this._id;
  }

  formFiles() {
    this.filesForm = this.fb.group({
      title: [null, Validators.compose([Validators.required])],
      description: [null, Validators.compose([Validators.required])],
      file: [null, Validators.compose([Validators.required])],
    });
  }

  getFileExtension(filename:string) { 
    let ext = filename.split(".").pop();
    let obj = this.iconList.filter(row => {
      if (row.type === ext) {
        return true;
      }
    });
    if (obj.length > 0) {
      let icon = obj[0].icon;
      return icon;
    } else {
      return "";
    }
  }


  fieldIsValid(campo: string) {
    return (
      this.filesForm.controls[campo].errors &&
      this.filesForm.controls[campo].touched
    );
  }

  public selectFile(event: any): void {
    if (event.target?.files[0].size < 10485760) {
      let file: File = event.target.files[0];
      this.fileEvent = file;
      var reader = new FileReader();
      reader.readAsDataURL(file);
      this.filesForm.get("file").setValue(this.fileEvent);
    } else {
      this.alertService.infoToast("El archivo debe ser menor a 10MB.");
      event.target.value = "";
    }
  }

  async uploadFile() { 
    let formData = new FormData();
    this.filesForm.value.id = this._id;
    this.buildFormData(formData, this.filesForm.value);

    // Validamos si es subitem.
    if(this.isSubitem){ 
      await this.httpService
      .put(`api/movement/attachment`, formData)
      .toPromise()
      .then((resp: any) => {
        this.alertService.successToast(resp.message); 
        this.item?.attachments?.push(resp.file) || (this.item.attachments = [resp.file]); 
        this.length = this.item?.attachments.length;
        this.filesForm.reset();
        this.close();
      })
      .catch((err: any) => {
        if (err.status != 403) {
          this.alertService.error("Hubo un error", err.message);
        }
      }); 
    }else{
      await this.httpService
      .put(`api/ticket/attachment`, formData)
      .toPromise()
      .then((resp: any) => {
        this.alertService.successToast(resp.message); 
        this.item?.attachments?.push(resp.file) || (this.item.attachments = [resp.file]); 
        this.length = this.item?.attachments.length;
        this.filesForm.reset();
        this.close();

      })
      .catch((err: any) => {
        if (err.status != 403) {
          this.alertService.error("Hubo un error", err.message);
        }
       });
    } 
  }

  removeSelectedFile(_id: string, file: string, index: string) { 
    // Validamos si es subitem. 
    if(this.isSubitem){
      this.httpService
      .deleteBody(`api/movement/attachment`, { id: _id, file: file })
      .toPromise()
      .then((resp: any) => {
        this.alertService.successToast(resp.message); 
        this.item.attachments.splice(index, 1); 
        this.length = this.item?.attachments.length; 
      })
      .catch((err: any) => {
        if (err.status != 403) {
          this.alertService.error("Hubo un error", err.message);
        }     
       });
    }else{
      this.httpService
      .deleteBody(`api/ticket/attachment`, { ticket: _id, file: file })
      .toPromise()
      .then((resp: any) => {
        this.alertService.successToast(resp.message); 
        this.item.attachments.splice(index, 1); 
        this.length = this.item?.attachments.length; 
      })
      .catch((err: any) => {
        if (err.status != 403) {
          this.alertService.error("Hubo un error", err.message);
        }      
      });
    } 
  }

  buildFormData(formData, data, parentKey?) {
    if (
      data &&
      typeof data === "object" &&
      !(data instanceof Date) &&
      !(data instanceof File)
    ) {
      Object.keys(data).forEach((key) => {
        // this.buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
        this.buildFormData(
          formData,
          data[key],
          parentKey ? `${parentKey}` : key
        );
      });
    } else {
      const value = data == null ? "" : data; 
      formData.append(parentKey, value);
    }
  }

  //ngOnChanges(): void{this._hasChange = true; setTimeout(() => {this._hasChange = false}, 2000);}

  openModal(modal, size) { 
    this._id = this.item?._id; 
    this.modalService.open(modal, { size: size , backdrop: 'static'});
  }
 
  async download(file: string, nameOriginal: string) {
    window.open(`${this.url}${file}`, "_blank");
  }

  openImg(template: any, event: any){
    this.dropdown.openDialog(template, event, 250, true);
  }

  close(): void { this.onClose.emit() }

}
